import React, { useContext } from 'react';
import _ from 'lodash';
import { Button } from 'reactstrap';
import { getUserCards } from 'library/common/actions/UserCardsAction';
import { connect } from 'react-redux';
import { SocketContext } from 'main/context/socket';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { URLS } from 'library/common/constants/UrlConstants';
import axiosInstance from 'main/axios';
import { CurrencyFormat } from 'utility';
import { images } from 'library/common/constants/ImageConstants';
import { setApiMessage } from 'library/common/constants/function';
import './styles.scss';

const InvoicesTable = props => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const downloadInvoiceList = async invoiceId => {
    props.setLoading(true);
    try {
      const { status, data, headers } = await axiosInstance.get(URLS.downlodaInvoice(invoiceId), {
        responseType: 'blob',
      });
      if (status === 200) {
        props.setLoading(false);
        if (data?.type === 'application/json') {
          setApiMessage('error', 'Can not download the invoice at the moment');
          return;
        }
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        const fileName = headers['x-file-name'] || headers['X-File-Name'] || 'downloaded_file';
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {
      props.setLoading(false);
    }
  };

  return (
    <div>
      <div className="invoice-table">
        <TableContainer>
          <Table sx={{ minWidth: 900 }}>
            <TableHead className="table-head">
              <TableRow>
                <TableCell sx={{ width: '180px' }}>
                  {OKTION_VARIABLE?.OKTION_INVOICE_NUMBER}
                  <img
                    className="ms-2 cursor-pointer"
                    src={images.DropdownDown}
                    alt=""
                    onClick={() => {
                      props.handleSortInvoice('id', !props?.sortType);
                    }}
                  />
                </TableCell>
                <TableCell sx={{ width: '165px' }}>
                  {OKTION_VARIABLE?.OKTION_DATE}
                  <img
                    className="ms-2 cursor-pointer"
                    src={images.DropdownDown}
                    alt=""
                    onClick={() => {
                      props.handleSortInvoice('createdAt', !props?.sortType);
                    }}
                  />
                </TableCell>
                <TableCell sx={{ width: '300px' }}>{OKTION_VARIABLE?.OKTION_DESCRIPTION}</TableCell>
                <TableCell sx={{ width: '150px' }}>{OKTION_VARIABLE?.OKTION_TOTAL}</TableCell>
                <TableCell sx={{ width: '125px' }}>{OKTION_VARIABLE?.OKTION_INVOICE}</TableCell>
              </TableRow>
            </TableHead>
            {props?.invoiceList?.map(item => (
              <TableRow className="table-row">
                <TableCell>{item?.invoiceNumber ? item?.invoiceNumber : '-'}</TableCell>
                <TableCell>{item?.createdAt ? moment(item?.createdAt).format('DD MMMM, YYYY') : '-'}</TableCell>
                <TableCell>{item?.description ? item?.description : '-'}</TableCell>
                <TableCell>
                  <CurrencyFormat
                    value={item?.totalAmount ? item?.totalAmount : '-'}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={
                      `${item?.totalAmount && item?.currency?.abbreviation ? item?.currency?.abbreviation : 'AUD'} `
                      // ${item?.totalAmount && item?.currency?.symbol ? item?.currency?.symbol : ''}`
                    }
                    thousandSpacing={`${item?.currency?.symbol ? (item?.currency?.symbol === '₹' ? '2s' : '3') : ''}`}
                    decimalScale="2"
                    fixedDecimalScale={true}
                  />
                </TableCell>
                <TableCell className="download-cell">
                  <Button className="download-invoice-button" onClick={() => downloadInvoiceList(item?.id)}>
                    {OKTION_VARIABLE?.OKTION_DOWNLOAD_INVOICE}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

const mapStateToProps = ({ userCardsReducer }) => {
  return {
    userCards: userCardsReducer.userCards,
  };
};

export default connect(mapStateToProps, {
  getUserCards,
})(InvoicesTable);
