import React, { useState } from 'react';
import '../../styles.scss';
import { Carousel, CarouselControl, CarouselIndicators, CarouselItem } from 'reactstrap';
import { identifiers } from '../../../../../../library/common/constants/IdentifierConstants';

const CarouselSlider = props => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === props.images.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? props.images.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = props?.images?.map(item => {
    return (
      <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={item.name}>
        {/*  <img 
          src={identifiers.imageUrlConstant + '' + props.sponser == true ? item?.imagePath : item.name}
          alt="raffle"
        /> */}
        {props.sponser === true ? (
          <img src={identifiers.imageUrlConstant + '' + item?.imagePath} alt="raffle" />
        ) : (
          <img src={identifiers.imageUrlConstant + '' + item.name} alt="raffle" />
        )}
      </CarouselItem>
    );
  });

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous} slide={false}>
      <CarouselIndicators items={props.images} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl
        className="carousel-control-prev"
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl className="carousel-control-next" direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
};

export default CarouselSlider;
