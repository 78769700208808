import React from 'react';
// import Row from 'reactstrap/lib/Row';
// import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import '../../styles.scss';
import TicketSold from './TicketSold';
import AuctionWallCard from './AuctionWallCard';
import AuctionWallPlatinumSponser from './AuctionWallPlatinumSponser';
import AuctionWallTable from './AuctionWallTable';
import axiosInstance from 'main/axios';
import { URLS } from '../../../../../../../../../library/common/constants/UrlConstants';
import PageLoader from '../../../../../../../../../library/common/components/PageLoader';
import moment from 'moment';
import _ from 'lodash';
// import { fetchFromStorage } from '../../../../../../../../../utility';
import { identifiers } from '../../../../../../../../../library/common/constants/IdentifierConstants';
// import { connectSocket, initSocketEvents } from '../../../../../../../../../main/socket/socket.services';
// import { EventEmitter } from 'library/common/constants/event';
// import CarouselSlider from '../../../../../../../../UserProfile/Frame/WinLoss/Frame/WinLossModal/Slider';
import { EventEmitter } from 'library/common/constants/event';
import Slider from 'react-slick';
import Countdown from 'react-countdown';
import { SocketContext } from '../../../../../../../../../main/context/socket';

import { CurrencyFormat } from 'utility';
import { useParams } from 'react-router-dom';

class AuctionWall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feturesData: '',
      currency: '',
      currencySymbol: '',
      loading: false,
      sponsorDetails: '',
      isShow: true,
      isCheckSponsors: false,
      eventDetails: '',
      isSponsors: 0,
      feturesEndDate: '',
    };
  }

  async componentDidMount() {
    // var userDetail = fetchFromStorage(identifiers.userDetail);
    // connect socket
    // var socketConnect = connectSocket('/api/socket.io/');
    // join socket
    // socketConnect !== null && initSocketEvents();
    // socketConnect !== null &&
    //   socketConnect.emit('JoinSocket', {
    //     userId: Number(userDetail?.id),
    //   });
    //AuctionWall emit
    const { userSocket, params } = this.props;
    userSocket.emit('AuctionWall', {
      featureId: Number(params?.id),
    });
    userSocket.on('AuctionWall', data => {
      EventEmitter.dispatch('SocketAuctionWallDataUpdated', data);
    });
    EventEmitter.subscribe('SocketAuctionWallDataUpdated', data =>
      this.setState({
        feturesData: data?.data,
        currencyAbbreviation: data?.data?.currency?.abbreviation,
        currencySymbol: data?.data?.currency?.symbol,
        feturesEndDate: data?.data?.featureDetail?.endTime,
      }),
    );
    // console.log('eventevent', data?.data);
    this.setState({ loading: true });
    try {
      const { status, data } = await axiosInstance.get(URLS.getActionWallDetails(params?.id));
      if (status === 200) {
        /*  this.setState({
          feturesData: data?.data,
          currency: data?.data?.currency?.abbreviation,
        }); */
        const eventDetails = await axiosInstance.get(URLS.getByEventIdData(data?.data?.featureDetail?.eventId));
        const response = await axiosInstance.get(URLS.getSponsors(data?.data?.featureDetail?.eventId));
        this.setState({
          sponsorDetails: response?.data?.data?.sponsorDetails,
          loading: false,
          eventDetails: eventDetails?.data?.data?.events,
        });
        let isCheckSponsors = false;
        let responseCheck =
          response?.data?.data?.sponsorDetails.length > 0 &&
          response?.data?.data?.sponsorDetails.map(item => {
            if (item.sponsors.length > 0) {
              isCheckSponsors = true;
            }
          });
        if (isCheckSponsors) {
          this.setState({ isCheckSponsors: true }, () => this.handleToggle());
        }
      }
    } catch {
      this.setState({ loading: false });
    }
    /*  setInterval(() => {
      this.setState({ isShow: !this.state.isShow });
    }, 2000); */
  }
  handleToggle = () => {
    if (this.state.isCheckSponsors === true) {
      setInterval(() => {
        this.setState({ isShow: !this.state.isShow });
      }, 30000);
    }
  };

  handleswitchCase = slug => {
    const { feturesData, currencyAbbreviation, currencySymbol } = this.state;
    switch (slug) {
      case 'table':
        return (
          <AuctionWallTable
            feturesData={feturesData}
            currencyAbbreviation={currencyAbbreviation}
            currencySymbol={currencySymbol}
          />
        );
      case 'item':
        return (
          <AuctionWallCard
            feturesData={feturesData}
            currencyAbbreviation={currencyAbbreviation}
            currencySymbol={currencySymbol}
          />
        );
      default:
        return '';
    }
  };
  renderTime = ({ hours, minutes, days, seconds }) => {
    return (
      <>
        <div>
          {days > 0 ? ('0' + days).slice(-2) : '00'}:{hours > 0 ? ('0' + hours).slice(-2) : '00'}:
          {minutes > 0 ? ('0' + minutes).slice(-2) : '00'}
        </div>
      </>
    );
  };
  render() {
    const {
      loading,
      feturesData,
      currencySymbol,
      currencyAbbreviation,
      sponsorDetails,
      isShow,
      eventDetails,
      feturesEndDate,
    } = this.state;
    const { params } = this.props;
    let routeName = params.slug !== undefined ? params.slug : null;
    var today = moment();
    var endDate = moment(feturesData && feturesData?.featureDetail?.endTime);
    var totalDays = 0;
    var totalHours = 0;
    var clearHours = 0;
    var totalMinutes = 0;
    var clearMinutes = 0;
    totalDays = endDate.diff(today, 'days');
    totalHours = endDate.diff(today, 'hours');
    clearHours = totalHours % 24;
    totalMinutes = endDate.diff(today, 'minutes');
    clearMinutes = totalMinutes % 60;

    let sponser_sorted = sponsorDetails && _.sortBy(sponsorDetails, 'displayOrder');
    let sponser_image_array = [];
    let sponser_sorted2 =
      sponser_sorted &&
      sponser_sorted.map(item => item?.sponsors?.length > 0 && sponser_image_array.push(item?.sponsors));
    sponser_image_array = _.flatten(sponser_image_array);

    return (
      <div className="raffleWall auctionSmallWall auctionWallWrapper ">
        {loading && <PageLoader />}
        <Container fluid={true} className="p-0">
          <div className="mainBlock ">
            <div className="left-side">
              <div className="">
                {eventDetails && eventDetails?.eventImages !== null && eventDetails?.eventImages[0]?.name !== '' ? (
                  <img
                    className="imageBox"
                    src={
                      eventDetails &&
                      eventDetails?.eventImages.length > 0 &&
                      identifiers.imageUrlConstant + '' + eventDetails?.eventImages[0]?.name
                    }
                    alt="EventImage"
                  />
                ) : (
                  <div className="box" />
                )}
                <div className="text-center mb-5">
                  <div className="auction-wall-title">
                    Live Oktion <br /> Bid Now
                  </div>
                </div>
                <div className="text-center mb-5">
                  <h2 className="fs-36">Time Remaining</h2>
                  <h1 className="fw-normal fs-63">
                    {/*  {totalDays > 0 ? ('0' + totalDays).slice(-2) : '00'}:
                    {clearHours > 0 ? ('0' + clearHours).slice(-2) : '00'}:
                    {clearMinutes > 0 ? ('0' + clearMinutes).slice(-2) : '00'} */}
                    <Countdown date={moment.utc(feturesEndDate)} renderer={this.renderTime} />{' '}
                  </h1>
                </div>
                <div className="text-center mb-3">
                  <h2 className="fs-36">Amount Raised</h2>
                  <h1 className="fw-normal fs-63">
                    {/* {currency} {feturesData?.amountRaised?.auctionAmount} */}
                    <CurrencyFormat
                      value={feturesData?.amountRaised?.auctionAmount || '0.00'}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={
                        eventDetails && eventDetails.currencyId !== null
                          ? currencyAbbreviation + ' ' + currencySymbol
                          : ''
                      }
                      thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                      decimalScale="2"
                      fixedDecimalScale={true}
                      className="fs-50"
                    />
                  </h1>
                </div>{' '}
                {sponser_image_array?.length > 0 ? (
                  <Slider
                    dots={false}
                    infinite={true}
                    speed={0}
                    autoplay={true}
                    autoplaySpeed={5000}
                    swipe={false}
                    touchMove={false}
                    arrows={false}
                    pauseOnHover={false}>
                    {sponser_image_array?.map((item, index) => (
                      <div key={index}>
                        <img
                          className="imageBox"
                          src={identifiers?.imageUrlConstant + '' + item?.imagePath}
                          alt="sponsor"
                        />
                      </div>
                    ))}
                  </Slider>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="rightsideBlock">
              {/*  <AuctionWallPlatinumSponser images={[1]} itemValue="Platinum Sponsors" />
            <AuctionWallPlatinumSponser images={[1]} itemValue="Gold Sponsors" />
            <AuctionWallPlatinumSponser images={[1]} itemValue="Silver Sponsors" /> */}
              {/*   <AuctionWallTable />
            <AuctionWallCard /> */}
              {/* <AuctionWallPlatinumSponser /> */}

              {isShow === true ? (
                <>
                  <div>{this.handleswitchCase(routeName)}</div>
                  {feturesData && feturesData?.item && feturesData?.item.length > 0 ? (
                    <div className="TicketSoldBlock">
                      <TicketSold
                        feturesData={feturesData}
                        currencyAbbreviation={currencyAbbreviation}
                        currencySymbol={currencySymbol}
                        eventDetails={eventDetails}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                <div className="eventDetailsTab auctionWallSponser-wrapper">
                  {sponser_sorted &&
                    sponser_sorted.map(
                      item =>
                        item?.sponsors?.length > 0 && (
                          <AuctionWallPlatinumSponser images={item?.sponsors} itemValue={item?.name} />
                        ),
                    )}
                </div>
              )}
            </div>{' '}
          </div>
        </Container>
        {/*<CongratulationOverlay/>*/}
      </div>
    );
  }
}

const AuctionWallWithSocket = props => {
  const params = useParams();
  return (
    <SocketContext.Consumer>
      {userSocket => <AuctionWall {...props} userSocket={userSocket.socket} params={params} />}
    </SocketContext.Consumer>
  );
};
export default AuctionWallWithSocket;
