import _ from 'lodash';
import * as actionTypes from '../constants/StoreConstants';

const initialState = {
  bankAccounts: [],
  apiStatus: '',
};

const BankAccountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.storeConstants.GET_BANK_ACCOUNTS:
      return {
        ...state,
        bankAccounts: action.payload.data,
      };
    case actionTypes.storeConstants.DELETE_BANK_ACCOUNT:
      return {
        ...state,
        bankAccounts: [..._.filter(state.bankAccounts, item => item.id !== action.payload.deletedId)],
      };
    case actionTypes.storeConstants.CREATE_BANK_ACCOUNT:
      // console.log('action.apiStatus', action.apiStatus);
      return {
        ...state,
        bankAccounts: [...state.bankAccounts, action.payload],
        apiStatus: action.apiStatus,
      };
    case actionTypes.storeConstants.EDIT_BANK_ACCOUNT:
      return {
        ...state,
        bankAccounts: [
          ...state.bankAccounts.map(item => (item.id === action.payload?.id ? { ...action.payload } : item)),
        ],
      };
    default:
      return state;
  }
};

export default BankAccountsReducer;
