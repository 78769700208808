import React from 'react';
import { Button, Modal, ModalBody, Collapse, Input } from 'reactstrap';
import FormGroup from 'library/common/components/FormGroupComponent';
import { images } from '../../../../library/common/constants/ImageConstants';
import PageLoader from '../../../../library/common/components/PageLoader';
import Notice from '../../../../library/common/components/Notice';
import axiosInstance from '../../../../main/axios';
import { URLS } from '../../../../library/common/constants/UrlConstants';
// import { fetchFromStorage } from '../../../../utility';
// import { identifiers } from '../../../../library/common/constants/IdentifierConstants';
import ActionMessage from '../../../../library/common/components/ActionMessage';
// import { ToastContainer, toast } from 'react-toastify';
import { SocketContext } from 'main/context/socket';
import 'react-toastify/dist/ReactToastify.css';
import { setApiMessage } from '../../../../library/common/constants/function';

class SendTicketModal extends React.Component {
  state = {
    isValidateCall: false,
    donationTicketBundle: [
      {
        amount: '',
        name: '',
        toggleBundle: true,
        status: '',
        mobile: '',
        email: '',
      },
    ],
    message: {
      display: false,
      type: '',
      message: '',
    },
    // donationTicketBundle: this.props.ticketData,
    // ticketPurchaseList: [],
    formData: {},
    labelVisible: false,
    loading: false,
    ErrorMessage: [],
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.TicketData !== this.props.TicketData) {
      this.setState({ donationTicketBundle: this.props.TicketData });

      var TICKET_DEFAULT_DATA = this.props.TicketData;
      TICKET_DEFAULT_DATA.map((item, i) => {
        let nameId = `name-${i}`,
          mobileId = `mobile-${i}`,
          emailId = `email-${i}`;

        const TEMPOBJ = {
          [nameId]: item?.name,
          [mobileId]: item?.phoneNumber,
          [emailId]: item?.email,
        };
        this.setState(state => ({
          formData: {
            ...state.formData,
            ...TEMPOBJ,
          },
        }));
        return item;
      });
    }
  }
  setActionMessage = (display = false, type = '', message = '') => {
    this.setState({
      message: {
        display,
        type,
        message,
      },
    });
  };
  componentDidMount() {
    // const eventDetails = fetchFromStorage(identifiers.eventDetails);
    // // this.setState({ loading: true });
    // this.getTicketPurchaseData();
    // try {
    //   var TICKET_PURCHASE_DATA = [];
    //   const { status, data } = axiosInstance.get(URLS.getTicketPurchase(eventDetails.id));
    //   console.log('TICKET_PURCHASE_DATA', status, data);
    //   if (status === 200 || status === 304) {
    //     // TICKET_PURCHASE_DATA = data?.data?.tickets.map(item => {
    //     //   return {
    //     //     amount: item?.user?.firstName,
    //     //     name: item?.user?.phoneNumber,
    //     //     toggleBundle: true,
    //     //     status: '',
    //     //     email: item?.user?.email,
    //     //   };
    //     // });
    //   }
    //   this.setState({ loading: false, donationTicketBundle: TICKET_PURCHASE_DATA });
    // } catch (err) {
    //   this.setState({ loading: false });
    // }
    // var sendData = {
    //   ticketId: 256,
    //   email: 'jahanvi.jnext@gmail.com',
    //   mobileNumber: 9537658953,
    //   name: 'jahanvi',
    // };
    // try {
    //   const { status } = axiosInstance.put(URLS.getTicketPurchase(74));
    //   if (status === 200 || status === 304) {
    //     this.setState({ loading: false });
    //   }
    // } catch (err) {
    //   this.setState({ loading: false });
    // }
  }
  // getTicketPurchaseData = async () => {
  //   try {
  //     var TICKET_PURCHASE_DATA = [];
  //     const eventDetails = fetchFromStorage(identifiers.eventDetails);
  //     const { status, data } = await axiosInstance.get(URLS.getTicketPurchase(eventDetails.id));
  //     console.log('TICKET_PURCHASE_DATA', status, data?.data?.tickets);
  //     if (status === 200 || status === 304) {
  //       TICKET_PURCHASE_DATA = data?.data?.tickets.map(item => {
  //         return {
  //           name: item?.user?.firstName,
  //           amount: item?.user?.phoneNumber,
  //           toggleBundle: true,
  //           status: '',
  //           email: item?.user?.email,
  //           ticketNumber: item?.ticketNumber,
  //           id: item?.id,
  //         };
  //       });
  //     }
  //     this.setState({ loading: false, donationTicketBundle: TICKET_PURCHASE_DATA });
  //   } catch (err) {
  //     this.setState({ loading: false });
  //   }
  // };
  validate = () => {
    let flag = true;
    let ErrorMessages = [];
    const { formData } = this.state;
    var donationTicketBundleLength = this.state.donationTicketBundle.length;
    let amount = '';
    let name = '';
    let mobile = '';
    let email = '';

    for (var i = 0; i < donationTicketBundleLength; i++) {
      let { OKTION_VARIABLE } = this.props;
      amount = `amount-${i}`;
      name = `name-${i}`;
      mobile = `mobile-${i}`;
      email = `email-${i}`;

      if (typeof formData[name] === 'undefined' || formData[name] === '') {
        flag = false;
        ErrorMessages = { ...ErrorMessages, [name]: OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE };
      }
      /*   if (typeof formData[mobile] === 'undefined' || formData[mobile] === '') {
        flag = false;
        ErrorMessages = { ...ErrorMessages, [mobile]: 'This field is required' };
      } */
      if (typeof formData[email] === 'undefined' || formData[email] === '') {
        flag = false;
        ErrorMessages = { ...ErrorMessages, [email]: OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE };
      } else if (!new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(formData[email])) {
        flag = false;
        ErrorMessages = { ...ErrorMessages, [email]: OKTION_VARIABLE?.OKTION_EMAIL_VALID };
      }
      this.setState({ ErrorMessage: ErrorMessages });
    }
    return flag;
  };
  toggleBundle = idx => {
    let donationTicketBundle = [...this.state.donationTicketBundle];
    for (let i = 0; i < donationTicketBundle.length; i++) {
      if (idx === i) {
        donationTicketBundle[i].toggleBundle = !donationTicketBundle[i].toggleBundle;
      }
    }
    this.setState({ donationTicketBundle });
  };
  handleInputChange = event => {
    let { name, value } = event.target;
    this.setState(state => ({
      formData: {
        ...state.formData,
        [name]: value,
      },
      labelVisible: true,
    }));
    // this.validate();
  };
  handleChange = e => {
    const { formData, isValidateCall } = this.state;
    if (['name form-control', 'amount form-control', 'email form-control'].includes(e.target.className)) {
      let donationTicketBundle = [...this.state.donationTicketBundle];
      donationTicketBundle[e.target.dataset.id][e.target.className.split(' ')[0]] = e.target.value;
      this.setState({ donationTicketBundle }, () => {
        isValidateCall && this.validate();
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
    // this.props.handleAmountTabData(this.state.donationTicketBundle);
  };
  handleSubmit = async () => {
    var { OKTION_VARIABLE } = this.props;
    if (this.validate()) {
      this.setState({ loading: true });
      var sendData = [];
      let name = '';
      let mobile = '';
      let email = '';

      sendData = this.state.donationTicketBundle.map((item, index) => {
        name = `name-${index}`;
        mobile = `mobile-${index}`;
        email = `email-${index}`;
        return {
          ticketId: item?.id,
          email: item?.email,
          mobileNumber: parseInt(item?.amount),
          name: item?.name,
        };
      });
      try {
        const { status } = await axiosInstance.put(URLS.purchaseTicketUpdate, sendData);
        this.setState({ loading: false });
        if (status === 200 || status === 304) {
          setApiMessage('success', OKTION_VARIABLE?.OKTION_TICKET_SEND);
          // this.setActionMessage(true, 'Success', 'Send tickets successfully');
          // toast.success(
          //   <>
          //     <img src={images.checkIcon} alt="successIcon" /> {OKTION_VARIABLE?.OKTION_TICKET_SEND}
          //   </>,
          //   {
          //     position: 'top-center',
          //     autoClose: 3000,
          //     hideProgressBar: true,
          //     closeOnClick: false,
          //     pauseOnHover: false,
          //     draggable: false,
          //     progress: undefined,
          //     closeButton: false,
          //     draggable: false,
          //   },
          // );
          setTimeout(() => {
            // this.setActionMessage(false);
            this.props.toggle();
          }, 4000);
        }
      } catch (err) {
        // this.setActionMessage(true, 'Error', 'Somethimg went wrong');
        setApiMessage('error', OKTION_VARIABLE?.SOMETHING_WENT_WRONG);
        // toast.error(
        //   <>
        //     <img src={images.infoIcon} alt="infoIcon" /> {OKTION_VARIABLE?.SOMETHING_WENT_WRONG}
        //   </>,
        //   {
        //     position: 'top-center',
        //     autoClose: 3000,
        //     hideProgressBar: true,
        //     closeOnClick: false,
        //     pauseOnHover: false,
        //     draggable: false,
        //     progress: undefined,
        //     closeButton: false,
        //     draggable: false,
        //   },
        // );
        // setTimeout(() => {
        //   this.setActionMessage(false);
        // }, 3000);
        this.setState({ loading: false });
      }
    }
  };

  render() {
    const { OKTION_VARIABLE } = this.props;
    const { isToggle, closeTicketModal, toggle } = this.props;
    const { loading, donationTicketBundle, ErrorMessage, formData, message } = this.state;
    return (
      <>
        {/* <ToastContainer autoClose={3000} /> */}
        <div>
          <Modal size="xl" className="modal-dialog add-price-modal ticket-purchase-modal" isOpen={isToggle}>
            <div className="text-end">
              <img className="cursor-pointer" onClick={toggle} src={images.close} alt="" />
            </div>
            <ModalBody>
              <div className="ticketBundleTab">
                {loading && <PageLoader />}

                {/* <div className="d-flex justify-content-end addRaffleTicketBundleBtn">
                <Button
                  className="app-button black-button add-ticket-btn mt-4 mb-4 discountBtn"
                  onClick={this.handleAdd}>
                  Add Donation Amount
                </Button>
              </div> */}

                {donationTicketBundle.length > 0 ? (
                  <>
                    <h2 className="pb-3">{OKTION_VARIABLE?.OKTION_TICKET_PURCHASE}</h2>
                    <Notice
                      title={OKTION_VARIABLE?.OKTION_TICKET_SEND_TO_GUEST}
                      image={images.bulb}
                      message={OKTION_VARIABLE?.OKTION_TICKET_SEND_TO_GUEST_DETAILS}
                    />{' '}
                    {message.display && (
                      <div className="btn-block mt-4">
                        <ActionMessage message={message.message} type={message.type} styleClass={message.styleClass} />
                      </div>
                    )}
                    {donationTicketBundle.map((val, idx) => {
                      let amountId = `amount-${idx}`,
                        nameId = `name-${idx}`,
                        mobileId = `mobile-${idx}`,
                        emailId = `email-${idx}`;
                      return (
                        <div className="mt-3 discount-accordion" key={idx}>
                          <div className="row">
                            <div className="col-sm-10 cursor-pointer" onClick={() => this.toggleBundle(idx)}>
                              <h5>{`${val?.ticketNumber} ( ${val?.ticketName} )`}</h5>
                              {/* <h5>{val.name !== '' ? val?.name : `ticket ${idx + 1}`}</h5> */}
                            </div>
                            <div className="col-sm-2 text-end">
                              <span className="accordian_arrow cursor-pointer" onClick={() => this.toggleBundle(idx)}>
                                <img className={`${!val.toggleBundle && 'down'}`} alt="" src={images.uppArraow} />
                              </span>
                            </div>
                          </div>

                          <Collapse isOpen={val.toggleBundle}>
                            <form onChange={this.handleChange}>
                              <FormGroup
                                className={
                                  ErrorMessage[nameId] !== '' &&
                                  ErrorMessage[nameId] !== undefined &&
                                  formData.hasOwnProperty(nameId)
                                    ? ' mt-2 focus_field fixed-fieldset'
                                    : ' mt-2 focus_field '
                                }>
                                <Input
                                  name={nameId}
                                  data-id={idx}
                                  id={nameId}
                                  value={val.name}
                                  className="name"
                                  type="text"
                                  placeholder={OKTION_VARIABLE?.OKTION_TICKET_FULLNAME_REQUIRED}
                                  onChange={e => this.handleInputChange(e)}
                                />
                                <label className="label-helper">{OKTION_VARIABLE?.OKTION_FULL_NAME}</label>
                                {ErrorMessage[nameId] && <span className="text-danger">{ErrorMessage[nameId]}</span>}
                              </FormGroup>
                              {/*  {ErrorMessage[nameId] ||
                              (formData[nameId] === '' && (
                                <span className="text-danger" style={{ color: '#f44336' }}>
                                  {ErrorMessage[nameId]}
                                </span>
                              ))} */}

                              <FormGroup
                                className={
                                  ErrorMessage[mobileId] !== '' &&
                                  ErrorMessage[mobileId] !== undefined &&
                                  formData.hasOwnProperty(mobileId)
                                    ? ' mt-2 focus_field fixed-fieldset'
                                    : ' mt-2 focus_field '
                                }>
                                <Input
                                  name={mobileId}
                                  data-id={idx}
                                  id={mobileId}
                                  value={val.amount}
                                  className="amount ticketAmount"
                                  type="number"
                                  placeholder={OKTION_VARIABLE?.OKTION_TABLE_MOBILE_NUMBER}
                                  onChange={e => this.handleInputChange(e)}
                                  style={{ paddingLeft: '15px' }}
                                />
                                <label className="label-helper">{OKTION_VARIABLE?.OKTION_TABLE_MOBILE_NUMBER}</label>
                                {/* {ErrorMessage[mobileId] && <span className="text-danger">{ErrorMessage[mobileId]}</span>}/ */}
                              </FormGroup>
                              {/* <FormGroup
                              className={
                                ErrorMessage[emailId] !== '' && formData.hasOwnProperty(emailId)
                                  ? ' mt-2 focus_field fixed-fieldset'
                                  : ' mt-2 focus_field '
                              }>
                              <Input
                                name={emailId}
                                data-id={idx}
                                id={emailId}
                                value={val.email}
                                className="email"
                                type="text"
                                placeholder="Email*"
                                onChange={e => this.handleInputChange(e)}
                              />
                              <label className="label-helper">emailId</label>
                              {ErrorMessage[emailId] && formData[emailId] && (
                                <span className="text-danger">{ErrorMessage[emailId]}</span>
                              )}
                            </FormGroup> */}
                              <FormGroup
                                className={
                                  ErrorMessage[emailId] !== '' &&
                                  ErrorMessage[emailId] !== undefined &&
                                  formData.hasOwnProperty(emailId)
                                    ? ' mt-2 focus_field fixed-fieldset'
                                    : ' mt-2 focus_field '
                                }>
                                <Input
                                  name={emailId}
                                  data-id={idx}
                                  id={emailId}
                                  value={val.email}
                                  className="email"
                                  type="text"
                                  placeholder={OKTION_VARIABLE?.OKTION_EMAIL_REQUIRED}
                                  onChange={e => this.handleInputChange(e)}
                                  style={{ maxWidth: '100%' }}
                                />
                                <label className="label-helper">{OKTION_VARIABLE?.OKTION_EMAIL}</label>
                                {/*  {ErrorMessage[emailId] ||
                                (formData[emailId] === '' && (
                                  <span className="text-danger" style={{ color: '#f44336' }}>
                                    {ErrorMessage[emailId]}
                                  </span>
                                ))} */}
                                {ErrorMessage[emailId] && <span className="text-danger">{ErrorMessage[emailId]}</span>}
                              </FormGroup>
                            </form>
                          </Collapse>
                        </div>
                      );
                    })}
                    <div className="mt-5 d-flex justify-content-center">
                      <Button className="app-button black-button mt-2 me-2 ticket-btn" onClick={toggle}>
                        {OKTION_VARIABLE?.OKTION_SKIPFOR_NOW}
                      </Button>
                      <Button
                        className="app-button main-button mt-2 me-2 ticket-btn"
                        onClick={e => {
                          this.handleSubmit();
                          this.setState({ isValidateCall: true });
                        }}>
                        {OKTION_VARIABLE?.OKTION_TICKET_SENDTICKET}
                      </Button>
                      {/* <Button
                    className="app-button blue-button mt-2 ticket-btn"
                    onClick={() => this.handleSubmit('published')}>
                    Save & Publish
                  </Button> */}
                    </div>
                  </>
                ) : (
                  <h3 className="text-center mb-4">{OKTION_VARIABLE?.OKTION_NODATA_FOUND}</h3>
                )}
              </div>
            </ModalBody>
          </Modal>
        </div>
      </>
    );
  }
}

const SendTicketModalWithSocket = props => (
  <SocketContext.Consumer>
    {userSocket => <SendTicketModal {...props} userSocket={userSocket.socket} OKTION_VARIABLE={userSocket?.messages} />}
  </SocketContext.Consumer>
);

export default SendTicketModalWithSocket;
