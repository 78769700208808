import React from 'react';

import './styles.scss';

const Notice = ({ image, title, message }) => {
  return (
    <div className="notice">
      {image && <img src={image} alt="" />}
      <div className="message">
        <p className="mb-2 notice_title fw-500"> {title} </p>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default Notice;
