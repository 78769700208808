import React, { useState, useEffect, useContext } from 'react';
import { isEmail } from 'react-multi-email';
import { Button, Input, InputGroup, Label } from 'reactstrap';
import { FaSearch, FaTimes } from 'react-icons/fa';
import FormGroup from 'library/common/components/FormGroupComponent';
import { useParams, useNavigate } from 'react-router-dom';
import Notice from 'library/common/components/Notice';
import { images } from 'library/common/constants/ImageConstants';
import axiosInstance from '../../../../main/axios';
import { URLS } from '../../../../library/common/constants/UrlConstants';
import PageLoader from '../../../../library/common/components/PageLoader';
import { setApiMessage } from '../../../../library/common/constants/function';
import { identifiers } from '../../../../library/common/constants/IdentifierConstants';
import { fetchFromStorage } from '../../../../utility';
import { SocketContext } from '../../../../main/context/socket';
import { createDraftOrg } from 'modules/Organization/menu-items';
import VerificationModal from '../Verification/VerificationModal';
import './styles.scss';

const TransferOwnership = () => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  document.title = OKTION_VARIABLE?.OKTION_TRANSFER_OWNERSHIP;
  const [filterValue, setFilterValue] = useState('');
  const [errors, setErrors] = useState(false);
  const [userList, setUserList] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const userParam = useParams();
  const [loading, setLoading] = useState(false);
  const [is_view, setIsView] = useState('');
  const [showError, setShowError] = useState(false);
  const [userListData, setUserListData] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [isCardVerifiedModal, setIsCardVerifiedModal] = useState(false);
  const navigate = useNavigate();
  const isNewOrg =
    window.location.pathname === '/organization/organiser-details';
  // const [message, setMessage] = useState({
  //   display: false,
  //   type: '',
  //   message: '',
  // });

  useEffect(() => {
    const is_view = fetchFromStorage(identifiers.is_view);
    const user = fetchFromStorage(identifiers?.userDetail);
    setUserEmail(user?.email);
    setIsView(is_view);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    handleResize();
  }, [width]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    // this.setState({ width: window.innerWidth });
    handleResize();
  };
  const handleResize = () => {
    if (window.innerWidth <= 639) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value.trim();
    setUserListData(false);
    setFilterValue(value);
    setErrors(false);
    // setMessage({});
    // getUserFilterData();
    if (filterValue === '') {
      setUserListData([]);
    }
    // getUserFilterData();
  };
  const handleForm = async (e) => {
    e.preventDefault();
    // setMessage({});
    setErrors(false);
    setShowError(true);

    let validateEmail = filterValue ? isEmail(filterValue) : false;
    if (filterValue !== '' && validateEmail) {
      setLoading(true);
      await getUserFilterData();
    } else if (filterValue === '') {
      setErrors({ email: 'Please enter email' });
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(filterValue)) {
      setErrors({ email: 'Invalid email' });
    }
  };
  const handleSearch = async (e) => {
    const value = e.target.value.trim();
    setUserListData(false);
    setFilterValue(value);
    // setErrors(false);
    // setMessage({});
    // setShowError(true);
    // console.log('filterValue', filterValue);
    let validateEmail = filterValue ? isEmail(filterValue) : false;
    // console.log('validateEmail', validateEmail);

    if (filterValue !== '' && validateEmail) {
      setLoading(true);
      setErrors(false);
      // setMessage({});
      // setShowError(true);
      await getUserFilterData();
    } else if (filterValue === '') {
      setErrors({ email: 'Please enter email' });
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(filterValue)) {
      setErrors({ email: OKTION_VARIABLE?.OKTION_EMAIL_VALID });
    }
  };

  const getUserFilterData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        URLS.getUserData(filterValue)
      );
      if (status === 201) {
        if (data?.data?.users?.length > 0) {
          setUserList(data.data.users);
          setUserListData(data.data.users);
        } else {
          setUserList([
            {
              email: filterValue,
              inviteUser: true,
            },
          ]);
          setUserListData([]);
        }
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleClick = async (id, email) => {
    const orgId = parseFloat(userParam.id);
    const currentOrgId = fetchFromStorage(identifiers.currentOrganization);

    const sendDataValue = {
      organisationId: currentOrgId,
      role: 'owner',
      emails: [email],
      isNewUser: false,
    };
    // if(id && id !== ''){
    //   sendDataValue.userId = id;
    // }
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.post(
        URLS.organizationChangeRole,
        sendDataValue
      );
      if (status === 201) {
        setApiMessage('success', OKTION_VARIABLE?.OKTION_INVITED);
        // setMessage({ display: true, type: 'Success', message: 'Request sent' });
        // setTimeout(() => {
        //   setMessage(false);
        // }, 3000);
        setLoading(false);
        setFilterValue('');
        setUserList('');
        if (isNewOrg) {
          setTimeout(() => {
            navigate(`/organization/${currentOrgId}?transfer-ownership`);
          }, 1000);
        }
      }
      // else if(da)
      // else if(status === 401 && )
    } catch (err) {
      setLoading(false);
      if (err.response.data.msg === 'user already invited as owner') {
        setApiMessage('error', OKTION_VARIABLE?.OKTION_ALREADY_INVITED);
        // setMessage({ display: true, type: 'Error', message: 'User already invited as owner' });
      } else if (
        err.response.data.msg ===
        'Please login in to required organisation to perform this operation'
      ) {
        setApiMessage('error', err.response.data.msg);
        // setMessage({ display: true, type: 'Error', message: err.response.data.msg });
      } else {
        setApiMessage('error', err.response.data.msg);
        // setMessage({ display: true, type: 'Error', message: err.response.data.msg });
      }
      // setTimeout(() => {
      //   setMessage({ display: false });
      // }, 4000);
    }
  };

  // MANGE ROLES AND ORG.
  const handleTransferAndOrg = async (item, id, email) => {
    if (item?.isCardVerified) {
      if (isNewOrg) {
        let { status } = await createDraftOrg();
        if (status) {
          setTimeout(() => {
            handleClick(id, email);
          }, 1000);
        }
      } else {
        handleClick(id, email);
      }
    } else {
      setIsCardVerifiedModal(true);
    }
  };

  return (
    <div className="transfer-ownership">
      <h1 className="text-center page-title text-lg-start mb-2 mb-md-5 mt-4">
        {OKTION_VARIABLE?.OKTION_OWNERSHIP}
      </h1>
      <Notice
        image={images.bulb}
        title={OKTION_VARIABLE?.OKTION_TRANSFER_OWNERSHIP}
        message={OKTION_VARIABLE?.OKTION_TRANSFER_OWNESHIP_DESCRIPTION}
      />
      {/* {message?.display && (
        <div className="mt-2 btn-block">
          <ActionMessage message={message?.message} type={message?.type} styleClass={message?.styleClass} />
        </div>
      )} */}
      <form onSubmit={handleForm}>
        <div className="row mt-4 mt-md-5">
          <div className="col-sm-12 d-flex justify-content-between align-items-end">
            {/* {!isMobile ? ( */}
            <>
              <FormGroup className="col ps-0 mb-0 pe-2">
                <Label className="search-field-label">
                  Search existing users
                </Label>
                <InputGroup className="col ps-0 mb-0 pe-2">
                  <FaSearch className="search-icon" />
                  <Input
                    type="text"
                    value={filterValue}
                    onChange={isMobile ? handleSearch : handleChange}
                    placeholder={OKTION_VARIABLE?.OKTION_TYPE_EMAIL}
                    className="h-45 search-field"
                  />
                  {filterValue && (
                    <FaTimes
                      className="search-clear-icon"
                      onClick={() => {
                        setFilterValue('');
                        setUserListData([]);
                      }}
                    />
                  )}
                </InputGroup>
                {errors?.email !== '' && (
                  <span className="text-danger">{errors?.email}</span>
                )}
              </FormGroup>
              {/* <FormGroup className="col ps-0 mb-0 pe-2">
                  <FormLabel className="search-field-label">
                    Search existing users
                  </FormLabel>
                  <Input
                    onChange={handleChange}
                    className="h-45"
                    type="text"
                    placeholder={OKTION_VARIABLE?.OKTION_TYPE_EMAIL}
                    value={filterValue}
                  />
                  {errors?.email !== '' && (
                    <span className="text-danger">{errors?.email}</span>
                  )}
                </FormGroup> */}
              {!isMobile && (
                <Button
                  className="main-button search-btn fw-500 minWidth-100 w-auto"
                  disabled={is_view === true ? true : false}
                >
                  {OKTION_VARIABLE?.OKTION_SEARCH}
                </Button>
              )}
            </>
            {/* ) : (
              <FormGroup className="col ps-0 mb-0 pe-1">
                <Input
                  onChange={handleSearch}
                  className="h-45"
                  type="text"
                  placeholder={OKTION_VARIABLE?.OKTION_TYPE_EMAIL}
                  value={filterValue}
                />
                {errors?.email !== '' && (
                  <span className="text-danger">{errors?.email}</span>
                )}
              </FormGroup>
            )} */}
          </div>
        </div>
      </form>
      {userListData?.length < 1 && showError && filterValue !== '' && (
        <span className="text-danger">
          {OKTION_VARIABLE?.OKTION_USERNOT_FOUND}
        </span>
      )}

      <div className="row mt-4">
        <div className="col-sm-12">
          <div className="search-item">
            {userList?.length > 0 &&
              userList[0]?.email !== userEmail &&
              userListData?.length > 0 &&
              userList?.map((user, i) => (
                <div
                  className={
                    !isMobile
                      ? 'd-flex align-items-center itemList g-0'
                      : 'd-flex search-list itemList g-0'
                  }
                >
                  <div className={!isMobile ? 'col-sm-8' : ''}>
                    <div className="d-flex align-items-center user_image mb-1">
                      {user.userProfilePicture ? (
                        <img src={user.userProfilePicture} alt="" />
                      ) : (
                        <div className="smallCircle br-50" />
                      )}
                      <p className="ms-3">
                        <span className="fw-500">
                          {user?.inviteUser && user?.inviteUser === true
                            ? user?.email
                            : user.firstName + ' ' + user.lastName}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className={!isMobile ? 'col-sm-4 text-end' : 'mt-2'}>
                    <Button
                      onClick={() =>
                        handleTransferAndOrg(user, user.id, user.email)
                      }
                      className="main-button btn-block transfer-button"
                    >
                      {OKTION_VARIABLE?.OKTION_TRANSFER}
                    </Button>
                    {/* <Button onClick={() => handleClick(user.id, user.email)} className="main-button btn-block">
                      {user?.inviteUser && user?.inviteUser === true ? (
                        <strong>Invite</strong>
                      ) : (
                        <strong>Transfer</strong>
                      )}
                    </Button> */}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      {loading && <PageLoader />}

      <VerificationModal
        isCloseIcon={true}
        isToggle={isCardVerifiedModal}
        setToggleModal={(isToggle) => {
          setIsCardVerifiedModal(isToggle);
        }}
        title={OKTION_VARIABLE?.OKTION_CREDIT_DETAILS_REQUIRED}
        subDesc={OKTION_VARIABLE?.OKTION_CARD_REQUIRED_DETAILS}
        cancelBtn={OKTION_VARIABLE?.OKTION_BACK_BUTTON}
        className="delete-card-modal"
      />
    </div>
  );
};

export default TransferOwnership;
