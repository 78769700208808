import React, { useEffect, useState, useContext } from 'react';
import _ from 'lodash';
/* global google */
import { Button, Col, Label, UncontrolledPopover, PopoverBody, Row } from 'reactstrap';
import FormGroup from 'library/common/components/FormGroupComponent';
import { images } from '../../../../library/common/constants/ImageConstants';
import '../../../Events/CreateEvent/styles.scss';
import './styles.scss';
import ImageDisplay from '../../../../library/common/components/FileUpload/frames/ImageDisplay';
import FileUpload from '../../../../library/common/components/FileUpload';
import Loader from '../../../../library/common/components/PageLoader';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axiosInstance from '../../../../main/axios';
import { URLS } from '../../../../library/common/constants/UrlConstants';
// import { Errors } from '../../../../library/common/constants/ErrorMessage';
// import { updateOrganization } from '../../../../library/common/actions/OrganizationActions';
// import 'react-flags-select/css/react-flags-select.css';
import { fetchFromStorage, saveToStorage } from '../../../../utility';
import { identifiers } from '../../../../library/common/constants/IdentifierConstants';
import { connect } from 'react-redux';
import { getUserDetails } from '../../../../library/common/actions/AuthActions';
import { setApiMessage } from '../../../../library/common/constants/function';
import PublishOrgModal from './PublishOrgModal';
import { SocketContext } from '../../../../main/context/socket';
import VerificationModal from 'modules/Organization/frames/Verification/VerificationModal';

let autocomplete;
let p_autocomplete;
const SignupOrganization = props => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const Errors = {
    mandatory: OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE,
    abn: OKTION_VARIABLE?.OKTION_ABN_MESSAGE,
  };
  const navigate = useNavigate();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [imageName, setImageName] = useState('');
  const [address, setAddress] = useState([]);
  const [isCompany, setCompany] = useState(false);
  const [accountType, setDropdownVal] = useState('');
  const [isToggle, setToggle] = useState(false);
  const [files, setFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [image_dimension, setImageDimension] = useState('');
  const [isImage, setImage] = useState(false);
  const [nameCharLength, setNameCharLength] = useState('');
  const [aboutOrgCharLength, setAboutOrgCharLength] = useState('');
  const [orgId, setOrgId] = useState('');
  const [isDeleted, setRemoveImage] = useState(false);
  const [isDisable, setButtonStatus] = useState(true);
  const [chars_left, setChars_left] = useState(
    2000 - _?.get(props, 'organizationByIdData?.data?.organisations?.aboutTheOrganisation', '').length,
  );
  const [chars_left_name, setChars_left_name] = useState(
    150 - _?.get(props, 'organizationByIdData?.data?.organisations?.name', '').length,
  );

  const [nameLength, setNameLength] = useState(false);
  const [aboutOrgength, setAboutOrgLength] = useState(false);
  const [imageLength, setImageLength] = useState(false);
  const [chars_exceed, setChars_exceed] = useState(false);
  const [from_register, isFormRegister] = useState('');
  // const [abn, setAbn] = useState(0);
  const [loading, setLoading] = useState(false);
  const [is_view, setIsView] = useState('');
  const [updateOrganizationDetail, setUpdateOrganizationDetail] = useState([]);
  const [isDisabled, setisDisabled] = useState(true);
  const [orgStatus, setOrgStatus] = useState('');
  const [publishOrgModal, setPublishOrgModal] = useState(false);
  const [verifyOrgModal, setVerifyOrgModal] = useState(false);
  const [abnRequired, setAbnRequired] = useState(false);
  // const [message, setMessage] = useState({
  //   display: false,
  //   type: '',
  //   message: '',
  // });

  useEffect(() => {
    initAutocomplete();
    initPostalAutocomplete();
    const is_view = fetchFromStorage(identifiers.is_view);
    setIsView(is_view);
    // setFiles([]);
  }, []);
  // console.log('isToggle', isToggle);
  const defaultOrganizationValue = _?.get(props, 'organizationByIdData.data.organisations');

  const checkPostalAddressValue = () => {
    var val = _?.get(props, 'organizationByIdData.data.organisations.organisationAddress');

    return val[0]?.isPostalAddress;
  };

  let defaultValue = { abn: '', aboutTheOrganisation: '', name: '' };

  if (defaultOrganizationValue && !props?.isNewOrganisation) {
    var fileArray = [
      {
        preview: defaultOrganizationValue.imagePath,
      },
    ];

    if (files.length === 0 && isDeleted === false) {
      setFiles(fileArray);
    }

    var orgAddressArray = _.get(defaultOrganizationValue, 'addressDetailsObj');
    var postObj = orgAddressArray && orgAddressArray.filter(item => Object.keys(item)[0] === 'postAddress');
    var companyObj = orgAddressArray && orgAddressArray.filter(item => Object.keys(item)[0] === 'companyAddress');
    defaultValue = {
      name: defaultOrganizationValue.name,
      // isCompany: defaultOrganizationValue.isCompany ? 'Company' : 'Individual',
      abn: _.get(defaultOrganizationValue, 'abn')
        ?.replace(/[^\dA-Z]/g, '')
        ?.replace(/(.{3})/g, '$1 ')
        ?.trim(),
      aboutTheOrganisation: defaultOrganizationValue.aboutTheOrganisation,
      taxExamptOrganisation: defaultOrganizationValue.taxExamptOrganisation,
      imagePath: defaultOrganizationValue.imagePath,
      isPostalAddress: checkPostalAddressValue(),
      status: defaultOrganizationValue.status,
      companyAddress: {
        streetnumber: companyObj && companyObj[0]?.companyAddress?.addressLine1,
        addressLine2: companyObj && companyObj[0]?.companyAddress?.addressLine2,
        city: companyObj && companyObj[0]?.companyAddress?.city,
        state: companyObj && companyObj[0]?.companyAddress?.state,
        country: companyObj && companyObj[0]?.companyAddress?.country,
        postCode: companyObj && companyObj[0]?.companyAddress?.postCode,
      },
      postAddress: {
        streetnumber: postObj && postObj[0]?.postAddress?.addressLine1,
        addressLine2: postObj && postObj[0]?.postAddress?.addressLine2,
        city: postObj && postObj[0]?.postAddress?.city,
        state: postObj && postObj[0]?.postAddress?.state,
        country: postObj && postObj[0]?.postAddress?.country,
        postCode: postObj && postObj[0]?.postAddress?.postCode,
      },
    };
  }

  // setFiles(defaultOrganizationValue?.imagePath);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: defaultValue,
  });

  useEffect(() => {
    var val = _?.get(props, 'organizationByIdData.data.organisations.organisationAddress');

    var isToggleOpen = val !== undefined && val[0]?.isPostalAddress;
    setToggle(isToggleOpen);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let from_register = params.get('register');
    isFormRegister(from_register);

    reset(chars_left);
    reset(chars_left_name);
    reset(defaultValue);
    let leftnameCharCount = props?.organizationByIdData?.data?.organisations?.name;
    let aboutCharCount = props?.organizationByIdData?.data?.organisations?.aboutTheOrganisation;

    if (aboutCharCount !== null && aboutCharCount !== undefined) {
      setChars_left(2000 - aboutCharCount.length || 2000);
    } else {
      setChars_left(2000);
    }
    if (leftnameCharCount !== null && leftnameCharCount !== undefined) {
      setChars_left_name(150 - leftnameCharCount.length || 150);
    } else {
      setChars_left_name(150);
    }
    setImageName(props?.organizationByIdData?.data?.organisations?.imagePath);

    // if (props?.organizationByIdData && props?.organizationByIdData?.data?.organisations?.imagePath !== null) {
    var fileArray1 = [
      {
        preview: props?.organizationByIdData?.data?.organisations?.imagePath,
      },
    ];

    //   if (files.length === 0 && isDeleted === false && files[0]?.preview !== undefined) {
    props?.organizationByIdData?.data?.organisations?.imagePath !== undefined && setFiles(fileArray1);
    //   }
    // }

    // setFiles(props?.organizationByIdData?.data?.organisations?.imagePath !== null && props?.organizationByIdData?.data?.organisations?.imagePath)
  }, [props]);

  // console.log('props?.organizationByIdData?.data', props?.organizationByIdData?.data);

  // useEffect(() => {
  //   setUpdateOrganizationDetail(props?.updateOrganizationnn);
  // }, [props]);

  const handleLimit = (event, type, value) => {
    const valueLength = value - event.target.value.length;
    const charExceed = valueLength > value ? true : false;
    // setCharacterLength(event.target.value.length);

    if (value === 150) {
      setChars_left_name(valueLength);
    } else {
      setChars_left(valueLength);
    }
    setChars_exceed(charExceed);

    if (type === 'name') {
      if (event.target.value.length > 150) setNameLength(true);
      setNameCharLength(event.target.value.length);
    } else if (type === 'aboutTheOrganisation') {
      if (event.target.value.length > 2000) setAboutOrgLength(true);
      setAboutOrgCharLength(event.target.value.length);
    }
  };

  const handleInputChange = event => {
    isValidate();
    setisDisabled(false);
  };
  // const handleAbnInputChange = e => {
  //   const {
  //     target: { value },
  //   } = e;
  //   let abn = e.target.validity.valid ? value : abn;
  //   setAbn(abn);
  // };

  const validateABN = value => {
    if (value?.length > 0) {
      var weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19],
        abn = value.replace(/\s/g, ''),
        result = false;

      // check length is 11 digits
      if (abn.length === 11) {
        // apply ato check method
        var sum = 0,
          weight;

        for (var index = 0; index <= weights.length - 1; index++) {
          weight = weights[index];
          let digit = abn[index] - (index ? 0 : 1);
          sum += weight * digit;
        }

        result = sum % 89 === 0;
      }

      return result;
    } else {
      return true;
    }
  };

  const isValidate = () => {
    let flag = false;
    if (accountType === '') {
      flag = true;
    }
    // if (isCompany) {
    //   if (typeof watch('name') === undefined || watch('name') === '') {
    //     flag = true;
    //   }
    // }
    if (typeof watch('abn') === undefined || watch('abn') === '') {
      flag = true;
    }

    if (typeof watch('aboutTheOrganisation') === undefined || watch('aboutTheOrganisation') === '') {
      flag = true;
    }
    if (typeof watch('companyAddress.addressLine1') === undefined || watch('companyAddress.addressLine1') === '') {
      flag = true;
    }
    if (typeof watch('companyAddress.addressLine2') === undefined || watch('companyAddress.addressLine2') === '') {
      flag = true;
    }
    if (typeof watch('companyAddress.city') === undefined || watch('companyAddress.city') === '') {
      flag = true;
    }
    if (typeof watch('companyAddress.state') === undefined || watch('companyAddress.state') === '') {
      flag = true;
    }
    if (typeof watch('companyAddress.postCode') === undefined || watch('companyAddress.postCode') === '') {
      flag = true;
    }
    if (typeof watch('companyAddress.country') === undefined || watch('companyAddress.country') === '') {
      flag = true;
    }
    if (!watch('isPostalAddress')) {
      // if (val) {
      if (typeof watch('postAddress.addressLine1') === undefined || watch('postAddress.addressLine1') === '') {
        flag = true;
      }
      if (typeof watch('postAddress.addressLine2') === undefined || watch('postAddress.addressLine2') === '') {
        flag = true;
      }
      if (typeof watch('postAddress.city') === undefined || watch('postAddress.city') === '') {
        flag = true;
      }
      if (typeof watch('postAddress.state') === undefined || watch('postAddress.state') === '') {
        flag = true;
      }
      if (typeof watch('postAddress.postCode') === undefined || watch('postAddress.postCode') === '') {
        flag = true;
      }
      if (typeof watch('postAddress.country') === undefined || watch('postAddress.country') === '') {
        flag = true;
      }
    }
    setButtonStatus(flag);
  };

  const handleFileUpload = (file, name) => {
    setisDisabled(false);
    setImageDimension(name);
    if (file[0] && file[0].size <= 10485760) {
      const filesArray = file.map(file =>
        Object.assign(file, {
          mediaType: 'other',
        }),
      );
      const newFiles = [...filesArray];

      setFiles(newFiles);
      uploadImage(file);
      setImageLength(false);
    } else {
      setImageLength(true);
    }
  };
  const handleRemoveImage = index => () => {
    setisDisabled(false);
    const file = files.filter((item, j) => index !== j);
    const deletedFile = file.filter((item, j) => index === j);
    let deletedList = [...deletedFiles];
    if (deletedFile.length > 0 && deletedFile[0].id !== 0 && deletedFile[0].id !== undefined) {
      deletedList.push(deletedFile[0].id);
      setDeletedFiles(deletedList);
    }
    setRemoveImage(true);
    setFiles(file);
    if (!props?.isNewOrganisation) {
      setImage(true);
    }
  };

  const handleMediaTypeChange = (file, index) => event => {
    const { value } = event.target;
    const filesArray = [...files];
    filesArray[index].mediaType = value;
    this.setState({ files: filesArray });
  };

  const uploadImage = async file => {
    setImage(false);
    const body = new FormData();
    const method = 'post';
    const url = URLS.imageUpload;
    setLoading(true);
    for (let i = 0; i < file.length; i++) {
      body.append('file', file[i]);
    }
    file.onload = () => {
      if (file.width === 100 && file.height === 100) {
        return true;
      }
      return true;
    };
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    try {
      const { status, data } = await axiosInstance[method](url, body, config);
      if (status === 201) {
        setLoading(false);
        setImageName(data.file);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const handleSubmitForm = async () => {
    setApiMessage();
    setLoading(true);

    var cobject = watch('companyAddress');
    var pobject = watch('postAddress') !== undefined && watch('postAddress');
    const isCompanyObjectEmpty = !Object.values(cobject).some(x => x !== '');
    const isPostalObjectEmpty = !Object.values(pobject).some(x => x !== '');
    var dataValue = {
      status: 'draft',
      name: watch('name') !== '' ? watch('name') : '',
      // isCompany: watch('isCompany') === 'Company' ? true : watch('isCompany') === 'Individual' ? false : '',
      // abn: watch('abn') !== '' ? watch('abn') : '',
      ...(watch('abn') !== '' && { abn: Number(watch('abn').replace(/\s/g, '')) }),
      aboutTheOrganisation: watch('aboutTheOrganisation') !== '' ? watch('aboutTheOrganisation') : '',
      taxExamptOrganisation: watch('taxExamptOrganisation') === true ? 1 : 0,
      // imagePath: imageName !== null ? imageName : '',
      ...(imageName !== null && { imagePath: imageName }),
      isPostalAddress: isToggle ? true : false,
      companyAddress: !isCompanyObjectEmpty
        ? {
            addressLine1: watch('companyAddress.addressLine1') !== '' ? watch('companyAddress.streetnumber') : '',
            addressLine2: watch('companyAddress.addressLine2') !== '' ? watch('companyAddress.addressLine2') : '',
            city: watch('companyAddress.city') !== '' ? watch('companyAddress.city') : '',
            state: watch('companyAddress.state') !== '' ? watch('companyAddress.state') : '',
            country: watch('companyAddress.country') !== '' ? watch('companyAddress.country') : '',
            postCode: watch('companyAddress.postCode') !== '' ? watch('companyAddress.postCode') : '',
          }
        : {},
      ...(!isToggle && {
        postAddress: !isPostalObjectEmpty
          ? {
              addressLine1: watch('postAddress.addressLine1') !== '' ? watch('postAddress.addressLine1') : '',
              addressLine2: watch('postAddress.addressLine2') !== '' ? watch('postAddress.addressLine2') : '',
              city: watch('postAddress.city') !== '' ? watch('postAddress.city') : '',
              state: watch('postAddress.state') !== '' ? watch('postAddress.state') : '',
              country: watch('postAddress.country') !== '' ? watch('postAddress.country') : '',
              postCode: watch('postAddress.postCode') !== '' ? watch('postAddress.postCode') : '',
            }
          : {},
      }),

      // ...(watch('name') !== '' && { name: watch('name') }),
      // ...(watch('isCompany') !== '' && { isCompany: watch('isCompany') === 'Company' ? true : false }),
      // ...(watch('abn') !== '' && { abn: watch('abn') }),
      // ...(watch('aboutTheOrganisation') !== '' && { aboutTheOrganisation: watch('aboutTheOrganisation') }),
      // taxExamptOrganisation: watch('taxExamptOrganisation') === true ? 1 : 0,
      // ...(imageName !== null && { imagePath: imageName }),

      // ...(!isCompanyObjectEmpty && {
      //   companyAddress: {
      //     ...(watch('companyAddress.addressLine1') !== '' && { addressLine1: watch('companyAddress.streetnumber') }),
      //     ...(watch('companyAddress.addressLine2') !== '' && { addressLine2: watch('companyAddress.addressLine2') }),
      //     ...(watch('companyAddress.city') !== '' && { city: watch('companyAddress.city') }),
      //     ...(watch('companyAddress.state') !== '' && { state: watch('companyAddress.state') }),
      //     ...(watch('companyAddress.country') !== '' && { country: watch('companyAddress.country') }),
      //     ...(watch('companyAddress.postCode') !== '' && { postCode: watch('companyAddress.postCode') }),
      //   },
      // }),

      // ...(!isPostalObjectEmpty && {
      //   postAddress: {
      //     ...(watch('postAddress.addressLine1') !== '' && { addressLine1: watch('postAddress.streetnumber') }),
      //     ...(watch('postAddress.addressLine2') !== '' && { addressLine2: watch('postAddress.addressLine2') }),
      //     ...(watch('postAddress.city') !== '' && { city: watch('companyAddrpostAddressess.city') }),
      //     ...(watch('postAddress.state') !== '' && { state: watch('postAddress.state') }),
      //     ...(watch('postAddress.country') !== '' && { country: watch('postAddress.country') }),
      //     ...(watch('postAddress.postCode') !== '' && { postCode: watch('postAddress.postCode') }),
      //   },
      // }),
    };
    /* } */
    // checking();
    if (props?.isNewOrganisation || Boolean(from_register) === true) {
      try {
        const { status, data } = await axiosInstance.post(URLS.createOrganization, dataValue);
        if (status === 201) {
          props?.getUserDetails();
          setLoading(false);
          setApiMessage('success', data.msg);
          // setTimeout(() => {
          //   setActionMessage(false);
          // }, 3000);
          setOrgId(data.data.organisation.id);
          saveToStorage(identifiers.currentOrganization, data.data.organisation.id);
          setTimeout(() => {
            navigate(`/organization/${data.data.organisation.id}?organiser-details`);
          }, 2000);
          if (Boolean(from_register) === true) {
            if ('register') window.location = '/all-organizations';
          } else {
            // window.location = `/organization/${data.data.organisation.id}?organiser-details`;
          }
        }
      } catch (err) {
        setLoading(false);
        if (err.response) {
          // setActionMessage(true, 'Error', err.response.data.msg);
          setApiMessage('error', err.response.data.msg);
          // setTimeout(() => {
          //   setActionMessage(false);
          // }, 3000);
        } else {
          setApiMessage('error', OKTION_VARIABLE?.SOMETHING_WENT_WRONG);
          // setActionMessage(true, 'Error', `${OKTION_VARIABLE?.SOMETHING_WENT_WRONG}`);
          // setTimeout(() => {
          //   setActionMessage(false);
          // }, 3000);
        }
      }
    } else {
      if (defaultOrganizationValue?.status === 'published') {
        setApiMessage('error', OKTION_VARIABLE?.OKTION_ORGANIZATION_CANNOT_CHANGE_STATUS);
        // setActionMessage(true, 'Error', `${OKTION_VARIABLE?.OKTION_ORGANIZATION_CANNOT_CHANGE_STATUS}`);
        // setTimeout(() => {
        //   setActionMessage(false);
        // }, 3000);
      } else {
        const org_id = fetchFromStorage(identifiers.currentOrganization);
        try {
          const { status } = await axiosInstance.put(URLS.updateOrganization(org_id), dataValue);
          if (status === 201) {
            setLoading(false);
            props?.getUserDetails();
            setApiMessage('success', OKTION_VARIABLE?.OKTION_ORGANIZATION_UPDATE);
            // setActionMessage(true, 'Success', `${OKTION_VARIABLE?.OKTION_ORGANIZATION_UPDATE}`);
            // setTimeout(() => {
            //   setActionMessage(false);
            // }, 3000);
            // setTimeout(() => {
            //   navigate('/all-organizations');
            // }, 2000);
            setTimeout(() => {
              navigate(`/organization/${org_id}?organiser-details`);
            }, 2000);
          }
        } catch (err) {
          setLoading(false);
          if (err?.response?.data?.msg === `${OKTION_VARIABLE?.OKTION_ORGANIZATION_ABN_USED}`) {
            setApiMessage('success', err?.response?.data?.msg);
            // setActionMessage(true, 'Error', err?.response?.data?.msg);
            // setTimeout(() => {
            //   setActionMessage(false);
            // }, 3000);
          } else {
            setApiMessage('error', OKTION_VARIABLE?.SOMETHING_WENT_WRONG);
            // setActionMessage(true, 'Error', `${OKTION_VARIABLE?.SOMETHING_WENT_WRONG}`);
            // setTimeout(() => {
            //   setActionMessage(false);
            // }, 3000);
          }
        }
      }
      // window.location = '/all-organizations';
    }
    setLoading(false);
  };
  const handlePublish = () => {
    navigate(orgStatus);
  };

  const onSubmit = async (dataValue, status) => {
    // setImage(true);
    // setLoading(true);
    var postObject = watch('postAddress');
    var companyObj = watch('companyAddress');
    // let search = window.location.search;
    // let params = new URLSearchParams(search);
    // let from_register = params.get('register');

    // setActionMessage();
    setApiMessage();
    // dataValue.isCompany = dataValue.isCompany === 'Company' ? true : false;
    dataValue.taxExamptOrganisation = dataValue.taxExamptOrganisation === true ? 1 : 0;
    if (dataValue.abn === '') {
      dataValue.abn = null;
    } else {
      dataValue.abn = Number(dataValue.abn.replace(/\s/g, ''));
    }

    // ...(dataValue.abn !== '' && { abn: dataValue.abn }),
    // if ((dataValue.abn === '' || dataValue.abn === null) && dataValue.isCompany === 0) {
    //   // dataValue.abn = 0;
    // } else {
    //   dataValue.abn = dataValue.abn ? _.toNumber(dataValue.abn) : undefined;
    // }

    dataValue.status = status;

    dataValue.companyAddress = {};
    dataValue.companyAddress.addressLine1 = companyObj.streetnumber;
    dataValue.companyAddress.addressLine2 = companyObj.addressLine2;
    dataValue.companyAddress.city = companyObj.city;
    dataValue.companyAddress.state = companyObj.state;
    dataValue.companyAddress.postCode = companyObj.postCode;
    dataValue.companyAddress.country = companyObj.country;

    if (!isToggle) {
      dataValue.postAddress = {};
      dataValue.postAddress.addressLine1 = postObject.streetnumber;
      dataValue.postAddress.addressLine2 = postObject.addressLine2;
      dataValue.postAddress.city = postObject.city;
      dataValue.postAddress.state = postObject.state;
      dataValue.postAddress.postCode = postObject.postCode;
      dataValue.postAddress.country = postObject.country;
    }
    // if (dataValue.isPostalAddress === true) {
    // if (!isToggle) {
    //   dataValue.postAddress = {};
    //   dataValue.postAddress.addressLine1 = dataValue.companyAddress.addressLine1;
    //   dataValue.postAddress.addressLine2 = dataValue.companyAddress.addressLine2;
    //   dataValue.postAddress.city = dataValue.companyAddress.city;
    //   dataValue.postAddress.state = dataValue.companyAddress.state;
    //   dataValue.postAddress.postCode = dataValue.companyAddress.postCode;
    //   dataValue.postAddress.country = dataValue.companyAddress.country;
    // }
    // if (imageName !== '') {
    //   // dataValue.imagePath = imageName || null;
    //   dataValue.imagePath = imageName;
    // }
    if (files.length > 0 && files[0].preview !== null) {
      const url = imageName;
      const spliturl = url?.split('/');

      const imageUrl = spliturl && spliturl[spliturl?.length - 1];

      // dataValue.imagePath = imageName || null;
      dataValue.imagePath = imageName !== undefined && imageUrl;
    }

    if (files.length > 0) {
      setApiMessage();
      // setActionMessage();
      // setLoading(true);
      setLoading(false);
      window.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }

    // if (isImage !== true) {
    createOrg(dataValue);
    // }
  };

  const createOrg = async dataValue => {
    // if (!isImage) {
    setLoading(true);
    if (props?.isNewOrganisation || Boolean(from_register) === true) {
      try {
        const { status, data } = await axiosInstance.post(URLS.createOrganization, dataValue);
        if (status === 201) {
          if (userDetails?.verificationStatus !== 'Verified') {
            setVerifyOrgModal(true);
          } else {
            // setPublishOrgModal(true);
            setTimeout(() => {
              navigate(`/organization/${data.data.organisation.id}?organiser-details`);
            }, 2000);
          }
          setApiMessage('success', OKTION_VARIABLE?.OKTION_ORGANISATOIN_CREATED_SUCCESSFULLY);

          setLoading(false);
          // setActionMessage(true, 'Success', data.msg);
          props?.getUserDetails();
          // setTimeout(() => {
          //   navigate('/');
          // });
          // setOrgStatus('/');
          setOrgId(data.data.organisation.id);
          saveToStorage(identifiers.currentOrganization, data.data.organisation.id);

          if (Boolean(from_register) === true) {
            if ('register') {
              setOrgStatus('/all-organizations');
            }
          } else {
            setOrgStatus(`/organization/${data.data.organisation.id}?organiser-details`);
          }
          // setOrgStatus(orgstatus);
        }
      } catch (err) {
        setLoading(false);
        if (err.response) {
          if (err?.response?.data?.message !== undefined) {
            setApiMessage('error', err?.response?.data?.message);
            // setActionMessage(true, 'Error', err?.response?.data?.message);
            // setTimeout(() => {
            //   setActionMessage(false);
            // }, 3000);
          } else {
            setApiMessage('error', err?.response?.data?.msg);
            // setActionMessage(true, 'Error', err.response.data.msg);
            // setTimeout(() => {
            //   setActionMessage(false);
            // }, 3000);
          }
        } else {
          setApiMessage('error', OKTION_VARIABLE?.SOMETHING_WENT_WRONG);
          // setActionMessage(true, 'Error', `${OKTION_VARIABLE?.SOMETHING_WENT_WRONG}`);
          // setTimeout(() => {
          //   setActionMessage(false);
          // }, 3000);
        }
      }
    } else {
      // await props.updateOrganization(_.get(defaultOrganizationValue, 'id'), dataValue);
      // window.location = '/all-organizations';
      // const org_id = fetchFromStorage(identifiers.currentOrganization);
      // console.log('org_id',org_id);
      // try {
      //   const { status, data } = await axiosInstance.put(URLS.updateOrganization(org_id), dataValue);
      //   if (status === 201) {
      //     setActionMessage(true, 'Success', 'Organisation updated successfully');
      //     setTimeout(() => {
      //       navigate('/all-organizations');
      //     }, 2000);
      //   }
      //   setLoading(false);
      // } catch (err) {
      //   console.log('err', err);
      // }
      const org_id = fetchFromStorage(identifiers.currentOrganization);
      let orgstatus = '';
      try {
        const { status } = await axiosInstance.put(URLS.updateOrganization(org_id), dataValue);
        if (status === 201) {
          setLoading(false);
          if (userDetails?.verificationStatus !== 'Verified') {
            setVerifyOrgModal(true);
          } else {
            // setPublishOrgModal(true);
            setTimeout(() => {
              navigate(`/organization/${org_id}?organiser-details`);
            }, 2000);
          }
          setApiMessage('success', OKTION_VARIABLE?.OKTION_ORGANISATOIN_UPDATED_SUCCESSFULLY);
          props?.getUserDetails();
          // setActionMessage(true, 'Success', 'Organization updated successfully');
          orgstatus = '/all-organizations';
          setOrgStatus(orgstatus);
          // setTimeout(() => {
          //   navigate('/all-organizations');
          // }, 2000);
        }
      } catch (err) {
        setLoading(false);
        if (err?.response?.data?.msg === 'abn already exist') {
          setApiMessage('error', err?.response?.data?.msg);
          // setActionMessage(true, 'Error', err?.response?.data?.msg);
          // setTimeout(() => {
          //   setActionMessage(false);
          // }, 3000);
        } else if (
          err?.response?.status === 403 &&
          err?.response?.data?.msg === 'That Company Registration Number is already taken'
        ) {
          setApiMessage('error', OKTION_VARIABLE?.OKTION_ORGANIZATION_REG_NUMBER);
          // setActionMessage(true, 'Error', `${OKTION_VARIABLE?.OKTION_ORGANIZATION_REG_NUMBER}`);
          // setTimeout(() => {
          //   setActionMessage(false);
          // }, 3000);
        } else if (
          err?.response?.data?.validation?.body?.message === `"abn" is required` ||
          err?.response?.data?.validation?.body?.message === `"abn" must be a number`
        ) {
          setAbnRequired(true);
          // setActionMessage(true, 'Error', 'Please enter company registration number');
        }
        // else if (err?.response?.data?.validation?.body?.message === `"\"abn\" is required"`) {
        //   setActionMessage(true, 'Error', 'Something went wrong');
        // }
        else {
          setApiMessage('error', OKTION_VARIABLE?.SOMETHING_WENT_WRON);
          // setActionMessage(true, 'Error', `${OKTION_VARIABLE?.SOMETHING_WENT_WRONG}`);
          // setTimeout(() => {
          //   setActionMessage(false);
          // }, 3000);
        }
      }
    }
    // }
  };

  // const setActionMessage = (display = false, type = '', message = '') => {
  //   setMessage({
  //     display,
  //     type,
  //     message,
  //   });
  // };

  const handleCompany = e => {
    setisDisabled(false);
    const value = e.target.value;
    if (value === 'Company') {
      setCompany(true);
    } else {
      setCompany(false);
      setAbnRequired(false);
      if (errors.abn) {
        if (errors.abn.type === 'required') {
          errors.abn = '';
        }
      }
    }
    setDropdownVal(value);

    isValidate();
  };
  const handleSamePostalAddress = e => {
    setToggle(isToggle => !isToggle);
    isValidate();
  };
  const handlePublishOrg = () => {
    // setImage(true);
    // if (files === undefined || files[0]?.preview === null) {
    if (files === undefined || files[0]?.preview === null || files[0]?.preview === undefined) {
      setImage(true);
      // handleSubmit(onSubmit)('published');
    } else {
      setImage(false);
      // handleSubmit(onSubmit)('published');
    }
    handleSubmit(onSubmit)('published');
    // files === undefined || files[0]?.preview === null ? setImage(true) : setImage(false)
  };

  const checkError = () => {
    Object.keys(errors).length === 0 ? setPublishOrgModal(true) : setPublishOrgModal(false);
  };

  function initAutocomplete() {
    const options = {
      fields: ['formatted_address', 'geometry', 'name'],
      strictBounds: false,
    };
    autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), { types: [] }, options);
    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    autocomplete.setFields(['address_component']);
    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    autocomplete.addListener('place_changed', fillInAddress);
  }

  function initPostalAutocomplete() {
    p_autocomplete = new google.maps.places.Autocomplete(document.getElementById('p_autocomplete'), {
      types: ['geocode'],
    });
    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    p_autocomplete.setFields(['address_component']);
    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    p_autocomplete.addListener('place_changed', fillInPostalAddress);
  }

  function fillInAddress() {
    // Get the place details from the autocomplete object.
    const place = autocomplete.getPlace();

    // for (const component in componentForm) {
    //   document.getElementById(component).value = '';
    //   document.getElementById(component).disabled = false;
    // }

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    // let data = [];
    // for (const component of place.address_components) {
    //   const addressType = component.types[0];
    //   if (componentForm[addressType]) {
    //     const val = component[componentForm[addressType]];
    //     let obj = { addressType: val };
    //     data.push(obj);
    //     document.getElementById(addressType).value = val;
    //   }
    // }
    // setAddress(data);

    let unit = '',
      street = '',
      street1 = '',
      street2 = '',
      city = '',
      state = '',
      zip = '',
      country = '';
    let address_array = place.address_components;
    address_array.map(element => {
      let type_array = element.types ? element.types : [];
      if (type_array.length > 0) {
        if (type_array.includes('street_number')) {
          unit = element.long_name;
        } else if (type_array.includes('route')) {
          street = element.long_name;
        } else if (type_array.includes('sublocality_level_2')) {
          street1 = element.long_name;
        } else if (type_array.includes('sublocality_level_1')) {
          street2 = element.long_name;
        } else if (type_array.includes('locality')) {
          city = element.long_name;
          clearErrors('companyAddress.city');
        } else if (type_array.includes('administrative_area_level_1')) {
          state = element.short_name;
          clearErrors('companyAddress.state');
        } else if (type_array.includes('country')) {
          country = element.long_name;
          clearErrors('companyAddress.country');
        } else if (type_array.includes('postal_code')) {
          zip = element.long_name;
          clearErrors('companyAddress.postCode');
        }
      }
    });
    var temp_address_value = street1 === '' && street2 === '' ? '' : street1 + ' ' + street2;
    setValue('companyAddress.streetnumber', unit);
    setValue('companyAddress.addressLine2', street !== '' ? street : temp_address_value);
    setValue('companyAddress.city', city);
    setValue('companyAddress.state', state);
    setValue('companyAddress.postCode', zip);
    setValue('companyAddress.country', country);

    clearErrors('companyAddress.addressLine2');

    isValidate();
  }

  function fillInPostalAddress() {
    // Get the place details from the autocomplete object.
    const place = p_autocomplete.getPlace();

    // for (const component in postalComponentForm) {
    //   document.getElementById(`p_${component}`).value = '';
    //   document.getElementById(`p_${component}`).disabled = false;
    // }

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    // let data = [];
    // for (const component of place.address_components) {
    //   const addressType = component.types[0];
    //   if (postalComponentForm[addressType]) {
    //     const val = component[postalComponentForm[addressType]];
    //     let obj = { addressType: val };
    //     data.push(obj);
    //     document.getElementById(`p_${addressType}`).value = val;
    //   }
    // }
    // setAddress(data);
    let unit = '',
      street = '',
      street1 = '',
      street2 = '',
      city = '',
      state = '',
      zip = '',
      country = '';
    let address_array = place.address_components;
    address_array.map(element => {
      let type_array = element.types ? element.types : [];
      if (type_array.length > 0) {
        if (type_array.includes('street_number')) {
          unit = element.long_name;
        } else if (type_array.includes('route')) {
          street = element.long_name;
        } else if (type_array.includes('sublocality_level_2')) {
          street1 = element.long_name;
        } else if (type_array.includes('sublocality_level_1')) {
          street2 = element.long_name;
        } else if (type_array.includes('locality')) {
          city = element.long_name;
          clearErrors('postAddress.city');
        } else if (type_array.includes('administrative_area_level_1')) {
          state = element.short_name;
          clearErrors('postAddress.state');
        } else if (type_array.includes('country')) {
          country = element.long_name;
          clearErrors('postAddress.country');
        } else if (type_array.includes('postal_code')) {
          zip = element.long_name;
          clearErrors('postAddress.postCode');
        }
      }
    });
    var temp_address_value = street1 === '' && street2 === '' ? '' : street1 + ' ' + street2;
    setValue('postAddress.streetnumber', unit);
    setValue('postAddress.addressLine2', street !== '' ? street : temp_address_value);
    setValue('postAddress.city', city);
    setValue('postAddress.state', state);
    setValue('postAddress.postCode', zip);
    setValue('postAddress.country', country);

    clearErrors('postAddress.addressLine2');

    isValidate();
  }

  // Bias the autocomplete object to the user's geographical location,
  // as supplied by the browser's 'navigator.geolocation' object.
  function geolocate() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        const circle = new google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy,
        });
        autocomplete.setBounds(circle.getBounds());
      });
    }
  }

  function postalGeolocate() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        const circle = new google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy,
        });
        p_autocomplete.setBounds(circle.getBounds());
      });
    }
  }
  // console.log('test',props?.updateOrganization);

  const userDetails = props?.userData?.data?.user;

  return (
    <div>
      <section className={`container signupOrganization`}>
        <div className="row justify-content-center">
          <div className={`${props?.isOrgDetailPage ? 'col-sm-10' : 'col-sm-8'} m-auto ps-0 create-event-container`}>
            <h1
              className={
                Boolean(from_register) === true ? 'signup-page-title ' : 'page-title text-center text-lg-start'
              }>
              {OKTION_VARIABLE?.OKTION_ORGANIZATION_DETAILS}
            </h1>
            <Row>
              <Col>{loading && <Loader />}</Col>
            </Row>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="event-section">
                {/* <div className={Boolean(from_register) === true ? 'signup-title-image-section' : 'title-image-section'}> */}
                <div className="section-header">
                  <div className="icon">
                    <img src={images.building_without_bg} alt="" />
                  </div>
                  <h3>{OKTION_VARIABLE?.OKTION_GENERAL}</h3>
                </div>

                <div className="createEvent d-flex justify-content-center">
                  <div>
                    {files.length > 0 && files[0]?.preview !== null && files !== false ? (
                      <ImageDisplay
                        files={files}
                        // onLoad={onImgLoad()}
                        onRemove={e => handleRemoveImage(e)}
                        onChangeMediaType={handleMediaTypeChange}
                      />
                    ) : (
                      <FileUpload
                        accept="image/jpeg, image/png"
                        text={OKTION_VARIABLE?.OKTION_UPLOAD_COMPANY_LOGO}
                        name="image"
                        // {...register('image', { required: true })}
                        onDrop={file => handleFileUpload(file, '250')}
                      />
                    )}
                    {/* {imageLength === true && (
                      <span className="text-danger">{OKTION_VARIABLE?.OKTION_IMAGE_VALIDATION}</span>
                    )} */}
                    {/*    {imageLength !== true
                      ? files.length === 0 && <span className="text-danger mt-2">{Errors.mandatory}</span>
                      : ''} */}
                    {/*   {errors.image && <span className="text-danger">{Errors.mandatory}</span>} */}
                    {/* {isImage === true && files.length === 0 && <span className="text-danger">{Errors.mandatory}</span>}
                    {isImage === true && files[0]?.preview === null && (
                      <span className="text-danger">{Errors.mandatory}</span>
                    )} */}
                  </div>
                  <div className="ms-4 file-uploader-container d-none d-xl-flex">
                    <div className="message">
                      <h3 className="pb-2">{OKTION_VARIABLE?.OKTION_IMAGE_SPECIFICATION}</h3>
                      <p>{OKTION_VARIABLE?.OKTION_FILE_TYPE}</p>
                      <p>{OKTION_VARIABLE?.OKTION_IMAGE_DIMENSION}</p>
                      <p>{OKTION_VARIABLE?.OKTION_FILE_SIZE}</p>
                    </div>
                  </div>
                </div>
                {/* </div> */}
                <div className="section-fields">
                  {/* <select
                    onChange={handleCompany}
                    ref={register({ required: true })}
                    className="form-control mb-3"
                    name="isCompany"
                    placeholder={OKTION_VARIABLE?.OKTION_ACCOUNT_TYPE}>
                    <option value="" hidden>
                      {OKTION_VARIABLE?.OKTION_ACCOUNT_TYPE_REQUIRED}
                    </option>
                    <option value="Individual">{OKTION_VARIABLE?.OKTION_INDIVIDUAL}</option>
                    <option value="Company">{OKTION_VARIABLE?.OKTION_ORGANIZATION}</option>
                  </select>
                  {errors.isCompany && <span className="text-danger">{Errors.mandatory}</span>} */}
                  <FormGroup
                    className={
                      watch('abn') !== '' && watch('abn') !== undefined && watch('abn') !== null
                        ? ' mt-3 focus_field fixed-fieldset'
                        : ' mt-3 focus_field '
                    }>
                    <input
                      className={`form-control ${(errors.abn || abnRequired) && 'errorColor'}`}
                      name="abn"
                      {...register('abn', {
                        required: false,
                        onChange: e => {
                          e.target.value = e.target.value
                            .replace(/[^\dA-Z]/g, '')
                            .replace(/(.{3})/g, '$1 ')
                            .trim();
                          handleInputChange(e);
                          validateABN(e.target.value) ? setAbnRequired(false) : setAbnRequired(true);
                        },
                        maxLength: 14,
                      })}
                      type="text"
                      maxLength="14"
                      // onChange={e => {
                      //   e.target.value = e.target.value
                      //     .replace(/[^\dA-Z]/g, '')
                      //     .replace(/(.{3})/g, '$1 ')
                      //     .trim();
                      //   handleInputChange(e);
                      //   validateABN(e.target.value) ? setAbnRequired(false) : setAbnRequired(true);
                      // }}
                      // pattern="[0-9]*"
                      // onChange={e => handleInputChange(e)}
                      // placeholder={!isCompany ? 'Company Registration Number' : 'Company Registration Number*'}
                      placeholder={OKTION_VARIABLE?.OKTION_AUS_BUSINESS_NUMBER}
                    />
                    {/* <input
                        type="text"
                        className={`form-control ${errors.isCompany && errors.abn && 'errorColor'}`}
                        name="abn"
                        id="abn"
                        pattern="[0-9]*"
                        ref={isCompany === true ? register({ required: true }) : register}
                        onChange={e => handleAbnInputChange(e)}
                        // value={abn}
                        placeholder={!isCompany ? 'Company Registration Number' : 'Company Registration Number*'}
                      /> */}
                    <Label className="label-helper">{OKTION_VARIABLE?.OKTION_AUS_BUSINESS_NUMBER}</Label>
                    {errors.abn && <span className="text-danger">{Errors.mandatory}</span>}
                    {/* {abnRequired && <span className="text-danger">{Errors.mandatory}</span>} */}
                    {abnRequired && <span className="text-danger">{Errors?.abn}</span>}
                  </FormGroup>
                  <FormGroup
                    // className={
                    //   watch('name') !== '' && watch('name') !== undefined
                    //     ? ' mt-3 focus_field fixed-fieldset'
                    //     : ' mt-3 focus_field '
                    // }
                    className={
                      watch('name') !== '' && watch('name') !== undefined && watch('name') !== null
                        ? ' mt-3 focus_field fixed-fieldset'
                        : ' mt-3 focus_field '
                    }>
                    <input
                      className={`form-control ${errors.name && 'errorColor'}`}
                      name="name"
                      {...register('name', {
                        required: true,
                        maxLength: 150,
                        onChange: e => {
                          handleLimit(e, 'name', 150);
                          handleInputChange(e);
                        },
                      })}
                      // onChange={e => {
                      //   handleLimit(e, 'name', 150);
                      //   handleInputChange(e);
                      // }}
                      type="text"
                      placeholder={OKTION_VARIABLE?.OKTION_ORGANIZATION_NAME_REQUIRED}
                    />
                    {nameCharLength < 1 && errors.name && <span className="text-danger">{Errors.mandatory}</span>}
                    {nameLength === true && chars_left_name < 0 && (
                      <span className="text-danger">{OKTION_VARIABLE?.OKTION_EVENT_NAME_VALIDATION}</span>
                    )}
                    <Label className="label-helper">{OKTION_VARIABLE?.OKTION_ORGANIZATION_NAME}</Label>
                    <div className="char_limit">{chars_left_name}</div>
                  </FormGroup>
                  <FormGroup
                    // className={
                    //   watch('aboutTheOrganisation') !== '' && watch('aboutTheOrganisation') !== undefined
                    //     ? ' mt-3 focus_field fixed-fieldset'
                    //     : ' mt-3 focus_field '
                    // }
                    className={
                      watch('aboutTheOrganisation') !== '' &&
                      watch('aboutTheOrganisation') !== undefined &&
                      watch('aboutTheOrganisation') !== null
                        ? ' mt-3 focus_field fixed-fieldset'
                        : ' mt-3 focus_field '
                    }>
                    <textarea
                      className={`form-control ${errors.aboutTheOrganisation && 'errorColor'}`}
                      name="aboutTheOrganisation"
                      {...register('aboutTheOrganisation', {
                        required: true,
                        maxLength: 2000,
                        onChange: e => {
                          handleLimit(e, 'aboutTheOrganisation', 2000);
                          handleInputChange(e);
                        },
                      })}
                      // onChange={e => {
                      //   handleLimit(e, 'aboutTheOrganisation', 2000);
                      //   handleInputChange(e);
                      // }}
                      type="textarea"
                      placeholder={OKTION_VARIABLE?.OKTION_ORGANIZATION_ABOUT_REQUIRED}
                      data-gramm_editor="false"
                    />
                    {aboutOrgCharLength < 1 && errors.aboutTheOrganisation && (
                      <span className="text-danger">{Errors.mandatory}</span>
                    )}
                    {aboutOrgength === true && chars_left < 0 && (
                      <span className="text-danger">{OKTION_VARIABLE?.OKTION_ORG_ABOUT_VALIDATION}</span>
                    )}
                    <Label className="label-helper">{OKTION_VARIABLE?.OKTION_ORGANIZATION_ABOUT}</Label>
                    <div className="char_limit">{chars_left}</div>
                  </FormGroup>
                  <div className="text-start d-flex mt-3 mb-3">
                    <div className="custom-checkbox form-check">
                      <input
                        className="form-check-input"
                        name="taxExamptOrganisation"
                        {...register('taxExamptOrganisation', { required: false })}
                        type="checkbox"
                        id="tax"
                      />
                      <Label className="form-check-label" htmlFor="tax">
                        {OKTION_VARIABLE?.OKTION_TAXEXEMPT_ORGANIZATION}
                      </Label>
                    </div>
                    <div id="premium" type="button" className="question-icon ms-3">
                      ?
                    </div>
                    <UncontrolledPopover placement="right" className="tax-detail" trigger="legacy" target="premium">
                      <PopoverBody>
                        <p>
                          <strong>{OKTION_VARIABLE?.OKTION_TYPESOF_INCOME_TAX}</strong>
                        </p>
                        <p>{OKTION_VARIABLE?.OKTION_TAX_DESCRIPTION_FIRST}</p>
                        <p>{OKTION_VARIABLE?.OKTION_TAX_DESCRIPTION_SECOND}</p>
                      </PopoverBody>
                    </UncontrolledPopover>
                  </div>
                  <div className="event-section mt-5">
                    <div className="section-header">
                      <div className="icon">
                        <img src={images.mapMarkerBlack} alt="" />
                      </div>
                      <h3>{OKTION_VARIABLE?.OKTION_COMPANY_ADDRESS}</h3>
                    </div>
                    <FormGroup
                      className={
                        watch('companyAddress.addressLine1') !== '' &&
                        watch('companyAddress.addressLine1') !== undefined
                          ? ' mt-3 focus_field fixed-fieldset'
                          : ' mt-3 focus_field '
                      }>
                      <input
                        id="autocomplete"
                        className={`form-control ${errors?.companyAddress?.addressLine1 && 'errorColor'}`}
                        name="companyAddress.addressLine1"
                        onFocus={() => geolocate()}
                        // ref={register({ required: true })}
                        type="text"
                        onChange={e => handleInputChange(e)}
                        placeholder={OKTION_VARIABLE?.OKTION_COMPANY_ADDRESS}
                      />
                      <Label className="label-helper">{OKTION_VARIABLE?.OKTION_COMPANY_ADDRESS}</Label>
                      {errors?.companyAddress?.addressLine1 && watch('companyAddress.addressLine1') === '' && (
                        <span className="text-danger">{Errors.mandatory}</span>
                      )}
                    </FormGroup>

                    <FormGroup
                      className={
                        watch('companyAddress.streetnumber') !== '' &&
                        watch('companyAddress.streetnumber') !== undefined &&
                        watch('companyAddress.streetnumber') !== null
                          ? ' mt-3 focus_field fixed-fieldset'
                          : ' mt-3 focus_field '
                      }>
                      <input
                        id="street_number"
                        name="companyAddress.streetnumber"
                        className={`form-control ${errors?.companyAddress?.streetnumber && 'errorColor'}`}
                        {...register('companyAddress.streetnumber', {
                          required: false,
                          onChange: e => {
                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            handleInputChange(e);
                          },
                        })}
                        // onChange={e => handleInputChange(e)}
                        // onChange={e => {
                        //   e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        //   handleInputChange(e);
                        // }}
                        placeholder={OKTION_VARIABLE?.OKTION_UNIT_NAME}
                        autoComplete="anythingGoesHere"
                      />
                      <Label className="label-helper">{OKTION_VARIABLE?.OKTION_UNIT_NAME}</Label>
                      {errors?.companyAddress?.streetnumber && watch('companyAddress.streetnumber') === '' && (
                        <span className="text-danger">{Errors.mandatory}</span>
                      )}
                    </FormGroup>

                    {/* <FormGroup className="focus_field">
                      <input
                        className={`form-control ${errors.venueStartDate && 'errorColor'}`}
                        name="unitnumber"
                        // ref={isLocation ? register({ required: true }) : register}
                        type="text"
                        placeholder="Unit Number*"
                      />
                    </FormGroup> */}

                    <FormGroup
                      className={
                        watch('companyAddress.addressLine2') !== '' &&
                        watch('companyAddress.addressLine2') !== undefined
                          ? ' mt-3 focus_field fixed-fieldset'
                          : ' mt-3 focus_field '
                      }>
                      <input
                        id="route"
                        name="companyAddress.addressLine2"
                        className={`form-control ${errors?.companyAddress?.addressLine2 && 'errorColor'}`}
                        {...register('companyAddress.addressLine2', {
                          required: true,
                          onChange: e => handleInputChange(e),
                        })}
                        // onChange={e => handleInputChange(e)}
                        placeholder={OKTION_VARIABLE?.OKTION_STREET_ADDRESS}
                      />
                      <Label className="label-helper">{OKTION_VARIABLE?.OKTION_STREET_ADDRESS}</Label>

                      {errors?.companyAddress?.addressLine2 && <span className="text-danger">{Errors.mandatory}</span>}
                    </FormGroup>
                    <FormGroup
                      className={
                        watch('companyAddress.city') !== '' && watch('companyAddress.city') !== undefined
                          ? ' mt-3 focus_field fixed-fieldset'
                          : ' mt-3 focus_field '
                      }>
                      <input
                        id="locality"
                        className={`form-control ${errors?.companyAddress?.city && 'errorColor'}`}
                        name="companyAddress.city"
                        {...register('companyAddress.city', { required: true, onChange: e => handleInputChange(e) })}
                        type="text"
                        placeholder={OKTION_VARIABLE?.OKTION_CITY_SUBURB}
                        // onChange={e => handleInputChange(e)}
                        value={address.addressType && address[0].addressType}
                      />
                      <Label className="label-helper">{OKTION_VARIABLE?.OKTION_CITY_SUBURB}</Label>
                      {errors?.companyAddress?.city && <span className="text-danger">{Errors.mandatory}</span>}
                    </FormGroup>
                    <Row>
                      <Col sm="6" className="pe-sm-1">
                        <FormGroup
                          className={
                            watch('companyAddress.state') !== '' && watch('companyAddress.state') !== undefined
                              ? ' focus_field fixed-fieldset'
                              : ' focus_field '
                          }>
                          <input
                            id="administrative_area_level_1"
                            className={`form-control ${errors?.companyAddress?.state && 'errorColor'}`}
                            name="companyAddress.state"
                            {...register('companyAddress.state', {
                              required: true,
                              onChange: e => handleInputChange(e),
                            })}
                            type="text"
                            placeholder={OKTION_VARIABLE?.OKTION_STATE}
                            // onChange={e => handleInputChange(e)}
                            value={address.addressType && address[1].addressType}
                          />

                          <Label className="label-helper">{OKTION_VARIABLE?.OKTION_STATE}</Label>
                          {errors?.companyAddress?.state && <span className="text-danger">{Errors.mandatory}</span>}
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup
                          className={
                            watch('companyAddress.postCode') !== '' && watch('companyAddress.postCode') !== undefined
                              ? ' focus_field fixed-fieldset'
                              : ' focus_field '
                          }>
                          <input
                            id="postal_code"
                            className={`form-control ${errors?.companyAddress?.postCode && 'errorColor'}`}
                            name="companyAddress.postCode"
                            {...register('companyAddress.postCode', {
                              required: true,
                              onChange: e => {
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                handleInputChange(e);
                              },
                            })}
                            type="text"
                            placeholder={OKTION_VARIABLE?.OKTION_POSTAL_CODE}
                            // onChange={e => handleInputChange(e)}
                            // onChange={e => {
                            //   e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            //   handleInputChange(e);
                            // }}
                            value={address.addressType && address[3].addressType}
                          />
                          <Label className="label-helper">{OKTION_VARIABLE?.OKTION_POSTAL_CODE}</Label>
                          {errors?.companyAddress?.postCode && <span className="text-danger">{Errors.mandatory}</span>}
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup
                      className={
                        watch('companyAddress.country') !== '' && watch('companyAddress.country') !== undefined
                          ? ' focus_field fixed-fieldset'
                          : ' focus_field '
                      }>
                      <input
                        id="country"
                        className={`form-control ${errors?.companyAddress?.country && 'errorColor'}`}
                        name="companyAddress.country"
                        {...register('companyAddress.country', { required: true, onChange: e => handleInputChange(e) })}
                        type="text"
                        placeholder={OKTION_VARIABLE?.OKTION_COUNTRY}
                        // onChange={e => handleInputChange(e)}
                        value={address.addressType && address[2].addressType}
                      />
                      <Label htmlFor="Country" className="label-helper">
                        {OKTION_VARIABLE?.OKTION_COUNTRY}
                      </Label>
                      {errors?.companyAddress?.country && <span className="text-danger">{Errors.mandatory}</span>}
                    </FormGroup>
                    <div className="text-start d-flex mt-4 mb-4">
                      <div className="custom-checkbox form-check">
                        <input
                          // onChange={handleSamePostalAddress}
                          className="form-check-input"
                          {...register('isPostalAddress', { required: false, onChange: handleSamePostalAddress })}
                          name="isPostalAddress"
                          type="checkbox"
                          id="postalAddress"
                        />
                        <Label className="form-check-label" htmlFor="postalAddress">
                          {OKTION_VARIABLE?.OKTION_ORG_CHECKBOX_TEXT}
                        </Label>
                      </div>
                    </div>
                    {!isToggle && (
                      <>
                        <FormGroup
                          className={
                            watch('postAddress.addressLine1') !== '' && watch('postAddress.addressLine1') !== undefined
                              ? ' mt-3 focus_field fixed-fieldset'
                              : ' mt-3 focus_field '
                          }>
                          <input
                            id="p_autocomplete"
                            onFocus={() => postalGeolocate()}
                            className={`form-control ${errors?.postAddress?.addressLine1 && 'errorColor'}`}
                            // ref={register({ required: true })}
                            name="postAddress.addressLine1"
                            type="text"
                            onChange={e => handleInputChange(e)}
                            placeholder={OKTION_VARIABLE?.OKTION_POSTAL_ADDRESS}
                          />
                          <Label htmlFor="Postal Address" className="label-helper">
                            {OKTION_VARIABLE?.OKTION_POSTAL_ADDRESS}
                          </Label>
                          {errors?.postAddress?.addressLine1 && <span className="text-danger">{Errors.mandatory}</span>}
                        </FormGroup>
                        <FormGroup
                          className={
                            watch('postAddress.streetnumber') !== '' && watch('postAddress.streetnumber') !== undefined
                              ? ' mt-3 focus_field fixed-fieldset'
                              : ' mt-3 focus_field '
                          }>
                          <input
                            id="p_street_number"
                            name="postAddress.streetnumber"
                            className={`form-control ${errors?.postAddress?.streetnumber && 'errorColor'}`}
                            {...register('postAddress.streetnumber', {
                              required: false,
                              onChange: e => {
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                handleInputChange(e);
                              },
                            })}
                            // onChange={e => {
                            //   e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            //   handleInputChange(e);
                            // }}
                            placeholder={OKTION_VARIABLE?.OKTION_UNIT_NAME}
                          />
                          <Label className="label-helper">{OKTION_VARIABLE?.OKTION_UNIT_NAME}</Label>
                          {/* {errors?.postAddress?.streetnumber && <span className="text-danger">{Errors.mandatory}</span>} */}
                        </FormGroup>
                        <FormGroup
                          className={
                            watch('postAddress.addressLine2') !== '' && watch('postAddress.addressLine2') !== undefined
                              ? ' mt-3 focus_field fixed-fieldset'
                              : ' mt-3 focus_field '
                          }>
                          <input
                            id="p_route"
                            className={`form-control ${errors?.postAddress?.addressLine2 && 'errorColor'}`}
                            {...register('postAddress.addressLine2', { onChange: e => handleInputChange(e) })}
                            name="postAddress.addressLine2"
                            type="text"
                            // onChange={e => handleInputChange(e)}
                            placeholder={OKTION_VARIABLE?.OKTION_STREET_ADDRESS}
                          />
                          <Label htmlFor="Postal Address" className="label-helper">
                            {OKTION_VARIABLE?.OKTION_STREET_ADDRESS}
                          </Label>
                          {errors?.postAddress?.addressLine2 && <span className="text-danger">{Errors.mandatory}</span>}
                        </FormGroup>
                        <FormGroup
                          className={
                            watch('postAddress.city') !== '' && watch('postAddress.city') !== undefined
                              ? ' mt-3 focus_field fixed-fieldset'
                              : ' mt-3 focus_field '
                          }>
                          <input
                            id="p_locality"
                            className={`form-control ${errors?.postAddress?.city && 'errorColor'}`}
                            {...register('postAddress.city', { onChange: e => handleInputChange(e) })}
                            name="postAddress.city"
                            type="text"
                            // onChange={e => handleInputChange(e)}
                            placeholder={OKTION_VARIABLE?.OKTION_CITY_SUBURB}
                          />
                          <Label htmlFor="City" className="label-helper">
                            {OKTION_VARIABLE?.OKTION_CITY_SUBURB}
                          </Label>
                          {errors?.postAddress?.city && <span className="text-danger">{Errors.mandatory}</span>}
                        </FormGroup>
                        <Row>
                          <Col sm="6" className="pe-sm-1">
                            <FormGroup
                              className={
                                watch('postAddress.state') !== '' && watch('postAddress.state') !== undefined
                                  ? ' focus_field fixed-fieldset'
                                  : ' focus_field '
                              }>
                              <input
                                id="p_administrative_area_level_1"
                                className={`form-control ${errors?.postAddress?.state && 'errorColor'}`}
                                {...register('postAddress.state', { onChange: e => handleInputChange(e) })}
                                name="postAddress.state"
                                type="text"
                                // onChange={e => handleInputChange(e)}
                                placeholder={OKTION_VARIABLE?.OKTION_STATE}
                              />
                              <Label className="label-helper">{OKTION_VARIABLE?.OKTION_STATE}</Label>
                              {errors?.postAddress?.state && <span className="text-danger">{Errors.mandatory}</span>}
                            </FormGroup>
                          </Col>
                          <Col sm="6">
                            <FormGroup
                              className={
                                watch('postAddress.postCode') !== '' && watch('postAddress.postCode') !== undefined
                                  ? ' focus_field fixed-fieldset'
                                  : ' focus_field '
                              }>
                              <input
                                id="p_postal_code"
                                className={`form-control ${errors?.postAddress?.postCode && 'errorColor'}`}
                                {...register('postAddress.postCode', {
                                  onChange: e => {
                                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                    handleInputChange(e);
                                  },
                                })}
                                name="postAddress.postCode"
                                type="text"
                                // onChange={e => handleInputChange(e)}
                                // onChange={e => {
                                //   e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                //   handleInputChange(e);
                                // }}
                                placeholder={OKTION_VARIABLE?.OKTION_POSTAL_CODE}
                              />
                              <Label className="label-helper">{OKTION_VARIABLE?.OKTION_POSTAL_CODE}</Label>
                              {errors?.postAddress?.postCode && <span className="text-danger">{Errors.mandatory}</span>}
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup
                          className={
                            watch('postAddress.country') !== '' && watch('postAddress.country') !== undefined
                              ? ' focus_field fixed-fieldset'
                              : ' focus_field '
                          }>
                          <input
                            id="p_country"
                            className={`form-control ${errors?.postAddress?.country && 'errorColor'}`}
                            {...register('postAddress.country', { onChange: e => handleInputChange(e) })}
                            name="postAddress.country"
                            type="text"
                            // onChange={e => handleInputChange(e)}
                            placeholder={OKTION_VARIABLE?.OKTION_COUNTRY}
                          />
                          <Label className="label-helper">{OKTION_VARIABLE?.OKTION_COUNTRY}</Label>
                          {errors?.postAddress?.country && <span className="text-danger">{Errors.mandatory}</span>}
                        </FormGroup>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/* {is_view ? (
                ''
              ) : ( */}

              {/* )} */}
            </form>
            {true && (
              <div className="org-button-view">
                <div className="d-flex button-style">
                  <Button
                    onClick={() => navigate('/all-organizations')}
                    type="button"
                    // disabled={is_view === true ? true : false}
                    className="app-button black-button me-2 h-45">
                    {props?.isOrgDetailPage
                      ? OKTION_VARIABLE?.OKTION_BUTTON_CANCEL
                      : OKTION_VARIABLE?.OKTION_SKIPFOR_NOW}
                  </Button>
                  <Button
                    onClick={() => handleSubmitForm()}
                    // disabled={is_view === true ? true : false}
                    className="app-button main-button me-2 h-45">
                    {props?.isOrgDetailPage
                      ? OKTION_VARIABLE?.OKTION_BUTTON_SAVE
                      : OKTION_VARIABLE?.OKTION_SAVEAND_EXIST}
                  </Button>
                  <Button
                    // disabled={isDisable}
                    // onClick={() => handlePublish()}
                    onClick={() => {
                      !abnRequired && handlePublishOrg();
                    }}
                    // disabled={is_view || isDisable ? true : false}
                    className="app-button blue-button h-45">
                    {props.isOrgDetailPage
                      ? OKTION_VARIABLE?.OKTION_BUTTON_PUBLISH
                      : OKTION_VARIABLE?.OKTION_SAVE_REGISTER}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <PublishOrgModal isToggle={publishOrgModal} toggle={setPublishOrgModal} handleClick={handlePublish} />
        <VerificationModal
          isCloseIcon={false}
          isToggle={verifyOrgModal}
          setToggleModal={isToggle => {
            setVerifyOrgModal(isToggle);
            const org_id = fetchFromStorage(identifiers.currentOrganization);
            navigate(`/organization/${org_id}?organiser-details`);
          }}
          title={''}
          subTitle={OKTION_VARIABLE?.OKTION_ORGANISATION_CREATED}
          subDesc={OKTION_VARIABLE?.OKTION_ORGANISATION_CREATED_DESC}
          cancelBtn={OKTION_VARIABLE?.OKTION_SKIP_FOR_NOW}
          submitBtn={OKTION_VARIABLE?.OKTION_VERIFY_OWNER_IDENTITY}
          handleSubmit={() => {
            navigate(`/user-profile?owner-verification`);
          }}
          className="created-org-modal"
        />
      </section>
    </div>
  );
};

const mapStateToProps = ({ authReducer, OrganizationReducer }) => {
  // setUpdateOrganization(OrganizationReducer?.updateOrganization)
  return {
    organizationByIdData: OrganizationReducer.organizationList,
    loginUserData: authReducer.loginUserData,
    userData: authReducer.userData,
    // updateOrganizationnn: OrganizationReducer?.updateOrganization,
  };
};
export default connect(mapStateToProps, { getUserDetails })(SignupOrganization);
