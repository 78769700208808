import * as actionTypes from '../constants/StoreConstants';

const initialState = {
  userList: [],
};

const AdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.storeConstants.GET_ROLE:
      return {
        ...state,
        userList: action.payload.data && action.payload.data.users,
      };
    default:
      return state;
  }
};

export default AdminReducer;
