import React, { useContext } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { SocketContext } from '../../../../main/context/socket';

// import { images } from '../../../../library/common/constants/ImageConstants';

const PublishOrgModal = ({ isToggle, toggle, handleClick }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  return (
    <div>
      <Modal size="xl" className="modal-dialog add-price-modal logout-modal" isOpen={isToggle}>
        <div className="text-end">
          {/* <img className="cursor-pointer" onClick={toggle} src={images.close} alt="" /> */}
        </div>
        <ModalBody>
          <div>{/* <img src={images.giftImage} alt="" /> */}</div>
          <h3 className="pt-4 pb-4">{OKTION_VARIABLE?.OKTION_ORGANIZATION_PUBLISHED}</h3>
          <p>{OKTION_VARIABLE?.OKTION_ORGMODAL_DESCRIPTION}</p>
          <div className=" d-flex justfycon justify-content-center">
            <Button style={{ width: '100px' }} className="app-button main-button mt-2 me-2 h-45" onClick={handleClick}>
              {OKTION_VARIABLE?.OKTION_CONTINUE}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PublishOrgModal;
