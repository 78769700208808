import React from 'react';

function BankingDetailsICon() {
  return (
    <svg
      throwIfNamespace={false}
      xmlns="http://www.w3.org/2000/svg"
      width="24.082"
      height="21.659"
      viewBox="0 0 24.082 21.659">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_3519" data-name="Rectangle 3519" width="24.081" height="21.659" />
        </clipPath>
      </defs>
      <g id="Group_12364" data-name="Group 12364" transform="translate(0 0)">
        <path
          id="Path_11077"
          data-name="Path 11077"
          d="M12.041,0,0,5.235l.411.944,11.63-5.056,11.63,5.056.411-.944Z"
          transform="translate(0)"
        />
        <rect
          id="Rectangle_3510"
          data-name="Rectangle 3510"
          width="22.642"
          height="1.029"
          transform="translate(0.72 7.251)"
        />
        <path
          id="Path_11078"
          data-name="Path 11078"
          d="M2.428,28.382H1.4V18.091H5.516v8.233H4.486v-7.2H2.428Z"
          transform="translate(-0.679 -8.782)"
        />
        <rect
          id="Rectangle_3511"
          data-name="Rectangle 3511"
          width="17.496"
          height="1.029"
          transform="translate(3.293 18.572)"
        />
        <rect
          id="Rectangle_3512"
          data-name="Rectangle 3512"
          width="22.642"
          height="1.029"
          transform="translate(0.72 20.63)"
        />
        <g id="Group_12363" data-name="Group 12363" transform="translate(0 0)">
          <g id="Group_12362" data-name="Group 12362" transform="translate(0 0)" clip-path="url(#clip-path)">
            <path
              id="Path_11079"
              data-name="Path 11079"
              d="M21.457,9.208a2.058,2.058,0,1,1,2.058-2.058,2.06,2.06,0,0,1-2.058,2.058m0-3.088a1.029,1.029,0,1,0,1.029,1.029A1.03,1.03,0,0,0,21.457,6.12"
              transform="translate(-9.417 -2.471)"
            />
            <rect
              id="Rectangle_3513"
              data-name="Rectangle 3513"
              width="1.544"
              height="1.029"
              transform="translate(1.234 11.882)"
            />
            <rect
              id="Rectangle_3514"
              data-name="Rectangle 3514"
              width="1.544"
              height="1.029"
              transform="translate(1.234 14.455)"
            />
            <rect
              id="Rectangle_3515"
              data-name="Rectangle 3515"
              width="1.544"
              height="1.029"
              transform="translate(1.234 17.028)"
            />
            <path
              id="Path_11080"
              data-name="Path 11080"
              d="M41.516,28.382H40.486V19.12H38.428v7.2H37.4V18.091h4.117Z"
              transform="translate(-18.154 -8.781)"
            />
            <rect
              id="Rectangle_3516"
              data-name="Rectangle 3516"
              width="1.544"
              height="1.029"
              transform="translate(21.303 11.882)"
            />
            <rect
              id="Rectangle_3517"
              data-name="Rectangle 3517"
              width="1.544"
              height="1.029"
              transform="translate(21.303 14.455)"
            />
            <rect
              id="Rectangle_3518"
              data-name="Rectangle 3518"
              width="1.544"
              height="1.029"
              transform="translate(21.303 17.028)"
            />
            <path
              id="Path_11081"
              data-name="Path 11081"
              d="M14.487,26.324H13.457v-6.69a.515.515,0,1,0-1.029,0v6.69H11.4v-6.69a1.544,1.544,0,1,1,3.088,0Z"
              transform="translate(-5.533 -8.782)"
            />
            <path
              id="Path_11082"
              data-name="Path 11082"
              d="M23.487,26.324H22.457v-6.69a.515.515,0,0,0-1.029,0v6.69H20.4v-6.69a1.544,1.544,0,1,1,3.088,0Z"
              transform="translate(-9.902 -8.782)"
            />
            <path
              id="Path_11083"
              data-name="Path 11083"
              d="M32.486,26.324H31.457v-6.69a.515.515,0,1,0-1.029,0v6.69H29.4v-6.69a1.544,1.544,0,1,1,3.087,0Z"
              transform="translate(-14.271 -8.782)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BankingDetailsICon;
