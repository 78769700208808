import Config from '../../../utility/Config';

export const identifiers = {
  token: 'token',
  filters: 'filters',
  userDetail: 'userDetail',
  picturefit: 'picturefit',
  background: 'background',
  language: 'language',
  imageUrlConstant: Config.imageURL,
  // AIzaSyC-lmnnIt8g2wLiv6Yp9HY6vyoF8r3-8-Q
  googleKey: 'AIzaSyCgz409Nwk0LDm0MRNfrpOnOsIH2QZiugM',
  eventDetails: 'eventDetails',
  is_view: 'is_view',
  currentOrganization: 'currentOrganization',
  countryDetails: 'countryDetails',
  color_data: 'color_data',
  publicEventDetails: 'publicEventDetails',
  pagination: 'pagination',
  guestcheckoutdata: 'guestcheckoutdata',
};
export const constantValue = {
  email: new RegExp(
    '^(([^<>()\\[\\]\\\\.,;:\\s@]+(\\.[^<>()\\[\\]\\\\.,;:\\s@]+)*)|(.+))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  ),
};
