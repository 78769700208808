import React, { useState } from 'react';
import './popOver.scss';
import { Popover, PopoverBody } from 'reactstrap';

const PopOver = ({ title, description, target }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);
  return (
    <div className="popOver">
      <div id={`${target}`} className="question-icon ms-3">
        ?
      </div>
      <Popover
        trigger="hover"
        placement="bottom"
        className="tax-detail"
        isOpen={popoverOpen}
        target={`${target}`}
        toggle={toggle}>
        <PopoverBody>
          <p>
            <strong>{title}</strong>
          </p>
          <p>{description}</p>
        </PopoverBody>
      </Popover>
    </div>
  );
};

export default PopOver;
