import React, { useState, useEffect, useContext } from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Notice from '../../../library/common/components/Notice';
import { images } from '../../../library/common/constants/ImageConstants';
import { Button, Input } from 'reactstrap';
import FormGroup from 'library/common/components/FormGroupComponent';
import ItemDetailsModal from './PriceTab/ItemDetailsModal';
// import { supplyItemData } from '../SupplyItemData';
import SupplyItemCard from './PriceTab/SupplyItemCard';
import axiosInstance from '../../../main/axios';
import { URLS } from '../../../library/common/constants/UrlConstants';
import { identifiers } from '../../../library/common/constants/IdentifierConstants';
import PageLoader from '../../../library/common/components/PageLoader';
import { fetchFromStorage } from '../../../utility';
import { EventEmitter } from 'library/common/constants/event';
import { SocketContext } from '../../../main/context/socket';

const AvailableTab = ({ isMonitor, tabValue, defaultSelectedCurrency }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const [isToggle, setToggle] = useState(false);
  const [supplyItemList, setSupplyItemData] = useState([]);
  const [is_view, setIsView] = useState('');
  const [newLot, setNewLot] = useState(false);
  const [loading, setLoading] = useState(false);
  const isNewOrg = window.location.pathname === '/organization/organiser-details';

  const toggle = () => {
    setToggle(!isToggle);
    setNewLot(true);
  };
  useEffect(() => {
    const is_view = fetchFromStorage(identifiers.is_view);
    setIsView(is_view);
  }, []);
  useEffect(() => {
    if (!isNewOrg) {
      getSupplyItem();
    }
  }, [newLot, isNewOrg]);

  useEffect(() => {
    EventEmitter.subscribe('updatesupplylotlist', event => {
      getSupplyItem();
      setTimeout(() => {
        setToggle(false);
      }, 4000);
    });
  }, []);

  const getSupplyItem = async () => {
    setLoading(true);
    try {
      await axiosInstance.get(URLS.getSupplyItemByStatus(tabValue)).then(({ status, data }) => {
        if (status === 201 || status === 200) {
          setLoading(false);
          setSupplyItemData(data?.data?.Items);
        }
      });
    } catch (err) {
      setLoading(false);
    }
  };
  const getData = val => {
    // console.log('val', val);
  };

  const handleSelectChange = async e => {
    let value = e.target.value;
    try {
      await axiosInstance.get(URLS.getSupplyItemByStatus(value.toLowerCase())).then(({ status, data }) => {
        if (status === 201 || status === 200) {
          setSupplyItemData(data?.data?.Items);
        }
      });
    } catch (err) {}
    // console.log('value', value.toLowerCase());
  };
  // const removeImage = () => {
  //   EventEmitter.dispatch('removesupplylotimage');
  // };

  return (
    <div className="pricesForm">
      {loading && <PageLoader />}
      {tabValue === 'available' ? (
        <>
          <Row>
            <Col sm="12">
              <Notice
                image={images.bulb}
                title={OKTION_VARIABLE?.OKTION_SUPPLY_LOTS}
                message={OKTION_VARIABLE?.OKTION_SUPPLY_LOT_HINT}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => {
                // removeImage();
                EventEmitter.dispatch('removesupplylotimage');
                toggle();
              }}
              className="add-ticket-btn mt-4 mb-2 fs-12 fw-500"
              disabled={is_view === true ? true : false}>
              {OKTION_VARIABLE?.OKTION_NEW_LOT}
            </Button>
          </div>
        </>
      ) : (
        ''
      )}
      {tabValue === 'sold' ? (
        <div className="d-flex justify-content-end">
          <div className="d-flex align-items-center">
            <label className="">{OKTION_VARIABLE?.OKTION_SORT_BY} : &nbsp;</label>
            <select
              name="select"
              className="form-select custom-select custom-select-md"
              id="exampleSelect"
              onChange={e => handleSelectChange(e)}>
              <option>{OKTION_VARIABLE?.OKTION_SOLD}</option>
              <option>{OKTION_VARIABLE?.OKTION_UNSOLD}</option>
              <option>{OKTION_VARIABLE?.OKTION_EXPIRED}</option>
            </select>
          </div>
        </div>
      ) : (
        ''
      )}
      <Row className="mt-3 mb-3">
        {supplyItemList.map((item, i) => (
          <Col className="mt-3" sm="6" xs="12">
            <SupplyItemCard
              key={i}
              data={item}
              itemName={item?.name}
              status={item?.itemStatus}
              increment={item?.bidIncrement}
              priceValue={item?.value}
              reserveAmount={item?.reserveAmount}
              startingBid={item?.startingBid}
              isMonitor={isMonitor}
              img={item?.images.length > 0 && identifiers.imageUrlConstant + '' + item?.images[0].name}
              is_view={is_view}
              getSupplyItem={getSupplyItem}
              tabValue={tabValue}
            />
          </Col>
        ))}

        {/*<FileUploader displayText="Raffle Prize" showSpecification={false} isSmall={true}/>*/}
      </Row>
      {isToggle && (
        <ItemDetailsModal
          isToggle={isToggle}
          toggle={toggle}
          newLot={newLot}
          sendData={getData}
          defaultSelectedCurrency={defaultSelectedCurrency}
        />
      )}
      {/*<ItemDetailsSmallModal isToggle={isToggle} toggle={toggle}/>*/}
      {/*<RelistingModal isToggle={isToggle} toggle={toggle} />*/}
    </div>
  );
};

export default AvailableTab;
