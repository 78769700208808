import { storeConstants } from '../constants/StoreConstants';
import axiosInstance from '../../../main/axios';
import { URLS } from '../constants/UrlConstants';
import { fetchFromStorage } from 'utility';
import { identifiers } from '../constants/IdentifierConstants';

const token = fetchFromStorage(identifiers?.token);
export const getNotificationList = (offset, limit) => dispatch => {
  return axiosInstance
    .get(URLS.getNotification(offset, limit))
    .then(response => {
      /*    if (response === 200) { */
      dispatch({
        type: storeConstants.GET_NOTIFICATION,
        payload: response.data.data,
      });
      /*  } */
    })
    .catch(({ status, data }) => {
      dispatch({
        type: storeConstants.GET_NOTIFICATION,
        payload: [],
      });
    });
};

export const setMailList = () => dispatch => {
  if (token) {
    return axiosInstance
      .get(URLS.getUserChatList)
      .then(response => {
        dispatch({
          type: storeConstants.SET_MAIL_LIST,
          payload: response.data,
        });
      })
      .catch(({ status, data }) => {
        dispatch({
          type: storeConstants.SET_MAIL_LIST,
          payload: [],
        });
      });
  }
};
