import React from 'react';
import '../../styles.scss';
import { images } from '../../../../../../../../../library/common/constants/ImageConstants';

const CongratulationOverlay = () => {
  return (
    <div className="congratulationOverlay">
      <img src={images.congratulation} alt="" />
    </div>
  );
};

export default CongratulationOverlay;
