import React from 'react';
import { Spinner } from 'reactstrap';

const PageLoader = () => (
  <div className="loader_wrapper">
    <Spinner type="grow" color="warning" />
  </div>
);

export default PageLoader;
