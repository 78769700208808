import * as React from 'react';
import { isEmail, ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';

class MultiValueSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emails: props.defaultTags || [],
    };
  }

  componentDidUpdate(prevProps) {
    // if (this.state.emails !== prevProps.defaultTags) {
    if (this.props.defaultTags !== prevProps.defaultTags) {
      // this.setState({ emails: prevProps.defaultTags });
      this.setState({ emails: this.props.defaultTags || [] });
    }
  }

  handleOnChange = _emails => {
    const validEmails = this?.props?.isEmail ? _emails.filter(email => isEmail(email)) : _emails;
    this.setState({ emails: validEmails });
    if (this.props.handleChange) {
      this.props.handleChange(validEmails);
    }
  };

  render() {
    const { emails } = this.state;
    return (
      <ReactMultiEmail
        placeholder={this.props.placeholder}
        emails={emails}
        // validateEmail={email => {
        //   return this?.props?.isEmail ? isEmail(email) : email;
        // }}
        onChange={email => {
          this.handleOnChange(email);
        }}
        getLabel={(email, index, removeEmail) => {
          return (
            <div data-tag key={index}>
              {email}
              <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
            </div>
          );
        }}
      />
    );
  }
}

export default MultiValueSelect;
