import { storeConstants } from '../constants/StoreConstants';
import axiosInstance from '../../../main/axios';
import { URLS } from '../constants/UrlConstants';

export const featureList = id => dispatch => {
  return axiosInstance
    .get(URLS.addFeatureList(id))
    .then(response => {
      dispatch({
        type: storeConstants.FEATURELIST,
        payload: response.data?.features?.features,
      });
    })
    .catch(({ status, data }) => {
      dispatch({
        type: storeConstants.FEATURELIST,
        payload: [],
      });
    });
};

export const dashBoardData = id => dispatch => {
  return axiosInstance
    .get(URLS.getDashboardData(id))
    .then(response => {
      dispatch({
        type: storeConstants.DASHBOARD,
        payload: response.data,
      });
    })
    .catch(({ status, data }) => {
      dispatch({
        type: storeConstants.DASHBOARD,
        payload: [],
      });
    });
};

export const getEventStatsData = (id, type) => dispatch => {
  return axiosInstance
    .get(URLS.getEventStatistics(id, type))
    .then(response => {
      dispatch({
        type: storeConstants.EVENT_STATS,
        payload: response.data,
      });
    })
    .catch(({ status, data }) => {
      dispatch({
        type: storeConstants.EVENT_STATS,
        payload: [],
      });
    });
};

export const getFeatureData = (id, type, sortBy) => dispatch => {
  return axiosInstance
    .get(URLS.getDonationByEventId(id, type, sortBy))
    .then(response => {
      dispatch({
        type: storeConstants.FEATURE_RAFFLE,
        payload: response.data,
      });
    })
    .catch(({ status, data }) => {
      dispatch({
        type: storeConstants.FEATURE_RAFFLE,
        payload: [],
      });
    });
};
