import { URLS } from '../library/common/constants/UrlConstants';
import Config from './Config';
import { fetchFromStorage } from '../utility';
import { identifiers } from '../library/common/constants/IdentifierConstants';
import axiosInstance from '../main/axios';

export const errorHandler = async error => {
  if (error.response) {
    const loginURL = Config.BaseURL + URLS.login;
    if (
      error?.response?.status === 401 &&
      error?.response?.data &&
      error?.response?.data?.err?.name === 'TokenExpiredError'
    ) {
      // if (error?.response?.data?.err?.name === 'TokenExpiredError') {
      if (error?.response?.config?.url !== loginURL) {
        const token = fetchFromStorage(identifiers.token);
        const userDetail = fetchFromStorage(identifiers.userDetail);
        var reqParameter = {
          webAppToken: token?.user?.hasOwnProperty('webAppToken') ? token?.user?.webAppToken : '',
        };
        try {
          const { status, data } = await axiosInstance.post(URLS.logoutByUserId(userDetail?.id), reqParameter);
          if (status === 200 || status === 201) {
            localStorage.clear();
            window.location.reload();
            window.location = '/login';
          }
        } catch (err) {}
      }
      // }
    }
  }
};
