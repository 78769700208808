import { combineReducers } from 'redux';

import AuthReducer from 'library/common/reducers/AuthReducer';
import OrganizationReducer from '../../library/common/reducers/OrganizationReducer';
import BankAccountsReducer from '../../library/common/reducers/BankAccountsReducer';
import AdminReducer from '../../library/common/reducers/AdminReducer';
import NotificationReducer from '../../library/common/reducers/NotificationReducer';
import EventReducer from '../../library/common/reducers/EventReducer';
import UserCardsReducer from '../../library/common/reducers/UserCardsReducer';
import SubscriptionReducer from '../../library/common/reducers/SubscriptionReducer';
import FeatureReducer from '../../library/common/reducers/FeatureReducer';
import CustomHeaderReducer from '../../library/common/reducers/CustomHeaderReducer';

export default combineReducers({
  authReducer: AuthReducer,
  OrganizationReducer: OrganizationReducer,
  BankAccountsReducer: BankAccountsReducer,
  adminReducer: AdminReducer,
  eventReducer: EventReducer,
  NotificationReducer: NotificationReducer,
  userCardsReducer: UserCardsReducer,
  subscriptionReducer: SubscriptionReducer,
  featureReducer: FeatureReducer,
  customHeaderReducer: CustomHeaderReducer,
});
