import * as actionTypes from '../constants/StoreConstants';

const initialState = {
  notificationList: [],
  org_id: '',
  mailList: [],
};

const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.storeConstants.GET_NOTIFICATION:
      return {
        ...state,
        notificationList: action.payload,
      };

    case actionTypes.storeConstants.ACCEPT_INVITE:
      return {
        ...state,
        org_id: action.payload.organisationId,
      };
    case actionTypes.storeConstants.SET_MAIL_LIST:
      return {
        ...state,
        mailList: action.payload,
      };
    default:
      return state;
  }
};

export default NotificationReducer;
