import React from 'react';

function SupplyLotIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.491" height="26.811" viewBox="0 0 19.491 26.811">
      <g id="Group_12653" data-name="Group 12653" transform="translate(1145.076 -535.313)">
        <g id="Group_11786" data-name="Group 11786" transform="translate(-1142.594 535.313)">
          <path
            id="Path_11034"
            data-name="Path 11034"
            d="M-1133.37,547.046a5.594,5.594,0,0,1-5.587-5.587,5.386,5.386,0,0,1,.057-.77,2.8,2.8,0,0,1-1.733-2.582,2.8,2.8,0,0,1,2.794-2.794,2.772,2.772,0,0,1,2.176,1.054,5.64,5.64,0,0,1,4.588,0,2.772,2.772,0,0,1,2.176-1.054,2.8,2.8,0,0,1,2.794,2.794,2.8,2.8,0,0,1-1.732,2.582,5.438,5.438,0,0,1,.056.77A5.594,5.594,0,0,1-1133.37,547.046Zm-4.47-10.616a1.678,1.678,0,0,0-1.676,1.676,1.675,1.675,0,0,0,1.362,1.644l.574.109-.135.568a4.513,4.513,0,0,0-.125,1.031,4.475,4.475,0,0,0,4.47,4.47,4.475,4.475,0,0,0,4.47-4.47,4.513,4.513,0,0,0-.125-1.031l-.134-.568.574-.109a1.676,1.676,0,0,0,1.361-1.644,1.678,1.678,0,0,0-1.676-1.676,1.668,1.668,0,0,0-1.484.918l-.266.52-.507-.291a4.52,4.52,0,0,0-4.423,0l-.507.29-.267-.52A1.666,1.666,0,0,0-1137.839,536.43Z"
            transform="translate(1140.633 -535.313)"
            fill="#fff"
          />
        </g>
        <g id="Group_11787" data-name="Group 11787" transform="translate(-1135.889 542.577)">
          <rect
            id="Rectangle_3337"
            data-name="Rectangle 3337"
            width="1.117"
            height="2.235"
            transform="translate(0 0)"
            fill="#fff"
          />
        </g>
        <g id="Group_11788" data-name="Group 11788" transform="translate(-1136.448 542.018)">
          <rect
            id="Rectangle_3338"
            data-name="Rectangle 3338"
            width="2.235"
            height="1.117"
            transform="translate(0 0)"
            fill="#fff"
          />
        </g>
        <g id="Group_11789" data-name="Group 11789" transform="translate(-1138.124 540.342)">
          <rect
            id="Rectangle_3339"
            data-name="Rectangle 3339"
            width="1.117"
            height="1.117"
            transform="translate(0)"
            fill="#fff"
          />
        </g>
        <g id="Group_11790" data-name="Group 11790" transform="translate(-1133.654 540.342)">
          <rect
            id="Rectangle_3340"
            data-name="Rectangle 3340"
            width="1.117"
            height="1.117"
            transform="translate(0)"
            fill="#fff"
          />
        </g>
        <g id="Group_11791" data-name="Group 11791" transform="translate(-1134.433 553.2)">
          <path
            id="Path_11035"
            data-name="Path 11035"
            d="M-1122.059,576.251a4.1,4.1,0,0,1-1.786-.439,3.624,3.624,0,0,1-2.106-2.535,5.048,5.048,0,0,1,.534-3.193,5.046,5.046,0,0,1,2.174-2.4,3.619,3.619,0,0,1,3.294.08,3.622,3.622,0,0,1,2.106,2.535,5.043,5.043,0,0,1-.534,3.193,5.041,5.041,0,0,1-2.174,2.4A3.337,3.337,0,0,1-1122.059,576.251Zm.328-7.806a2.23,2.23,0,0,0-1.011.241,3.96,3.96,0,0,0-1.669,1.886,3.958,3.958,0,0,0-.446,2.478,2.512,2.512,0,0,0,1.5,1.756,2.511,2.511,0,0,0,2.307.087,3.96,3.96,0,0,0,1.668-1.886,3.957,3.957,0,0,0,.446-2.478,2.513,2.513,0,0,0-1.5-1.756A2.976,2.976,0,0,0-1121.731,568.445Z"
            transform="translate(1126.028 -567.328)"
            fill="#fff"
          />
        </g>
        <g id="Group_11792" data-name="Group 11792" transform="translate(-1144.645 553.268)">
          <path
            id="Path_11036"
            data-name="Path 11036"
            d="M-1139.6,576.236a3.421,3.421,0,0,1-.767-.088,5.047,5.047,0,0,1-2.675-1.823c-1.749-2.132-1.678-4.406.187-5.936a3.622,3.622,0,0,1,3.184-.85,5.05,5.05,0,0,1,2.675,1.823,5.045,5.045,0,0,1,1.266,2.979,3.618,3.618,0,0,1-1.453,2.957A3.792,3.792,0,0,1-1139.6,576.236Zm-.839-7.669a2.681,2.681,0,0,0-1.707.684c-1.879,1.543-.827,3.4-.033,4.364a3.956,3.956,0,0,0,2.064,1.443,2.505,2.505,0,0,0,2.223-.624,2.513,2.513,0,0,0,1.046-2.058,3.959,3.959,0,0,0-1.014-2.305h0a3.952,3.952,0,0,0-2.063-1.443A2.225,2.225,0,0,0-1140.438,568.567Z"
            transform="translate(1144.304 -567.45)"
            fill="#fff"
          />
        </g>
        <g id="Group_11793" data-name="Group 11793" transform="translate(-1139.577 547.605)">
          <path
            id="Path_11037"
            data-name="Path 11037"
            d="M-1130.987,564.576a.556.556,0,0,1-.394-.163,18.093,18.093,0,0,0-1.635-1.406c-1.032-.815-1.924-1.519-2.165-2.681a2.582,2.582,0,0,1,.439-2.036,2.322,2.322,0,0,1,1.637-.963,2.744,2.744,0,0,1,2.117.7,2.743,2.743,0,0,1,2.118-.7h0a2.32,2.32,0,0,1,1.637.963,2.581,2.581,0,0,1,.44,2.036c-.241,1.162-1.132,1.866-2.165,2.681a17.991,17.991,0,0,0-1.634,1.406A.558.558,0,0,1-1130.987,564.576Zm-1.842-6.146a1.422,1.422,0,0,0-.154.008,1.219,1.219,0,0,0-.854.51,1.457,1.457,0,0,0-.249,1.153c.158.763.831,1.294,1.762,2.029.413.326.87.686,1.337,1.115.468-.429.924-.789,1.337-1.115.932-.735,1.6-1.266,1.762-2.029a1.461,1.461,0,0,0-.249-1.153,1.219,1.219,0,0,0-.854-.51h0a1.52,1.52,0,0,0-1.487.749.559.559,0,0,1-.509.328.559.559,0,0,1-.509-.328A1.5,1.5,0,0,0-1132.829,558.431Z"
            transform="translate(1135.234 -557.313)"
            fill="#fff"
          />
        </g>
        <g id="Group_11794" data-name="Group 11794" transform="translate(-1136.66 557.614)">
          <path
            id="Path_11038"
            data-name="Path 11038"
            d="M-1128.684,576.392c-.448,0-.895-.013-1.329-.04l.068-1.115a21.117,21.117,0,0,0,2.687-.011l.077,1.115C-1127.667,576.376-1128.173,576.392-1128.684,576.392Z"
            transform="translate(1130.013 -575.227)"
            fill="#fff"
          />
        </g>
        <g id="Group_11795" data-name="Group 11795" transform="translate(-1131.14 552.075)">
          <path
            id="Path_11039"
            data-name="Path 11039"
            d="M-1118.842,567.544a.558.558,0,0,1-.542-.429,1.365,1.365,0,0,0-.53-.8.559.559,0,0,1-.1-.783.56.56,0,0,1,.783-.1,2.489,2.489,0,0,1,.937,1.426.559.559,0,0,1-.413.673A.553.553,0,0,1-1118.842,567.544Z"
            transform="translate(1120.133 -565.314)"
            fill="#fff"
          />
        </g>
        <g id="Group_11796" data-name="Group 11796" transform="translate(-1132.443 544.91)">
          <path
            id="Path_11040"
            data-name="Path 11040"
            d="M-1117.515,562.856l-.377-1.052a1.688,1.688,0,0,0,1.047-.811,2.41,2.41,0,0,0-.146-1.823c-.494-1.318-2.56-4.566-5.475-5.63l.383-1.05c3.308,1.208,5.564,4.758,6.137,6.287a3.42,3.42,0,0,1,.114,2.686A2.764,2.764,0,0,1-1117.515,562.856Z"
            transform="translate(1122.466 -552.49)"
            fill="#fff"
          />
        </g>
        <g id="Group_11797" data-name="Group 11797" transform="translate(-1141.389 552.075)">
          <path
            id="Path_11041"
            data-name="Path 11041"
            d="M-1137.917,567.624a.569.569,0,0,1-.11-.011.559.559,0,0,1-.438-.658,2.493,2.493,0,0,1,.959-1.527.559.559,0,0,1,.783.1.559.559,0,0,1-.1.783,1.371,1.371,0,0,0-.543.86A.559.559,0,0,1-1137.917,567.624Z"
            transform="translate(1138.476 -565.314)"
            fill="#fff"
          />
        </g>
        <g id="Group_11798" data-name="Group 11798" transform="translate(-1145.076 544.91)">
          <path
            id="Path_11042"
            data-name="Path 11042"
            d="M-1143.168,562.856a2.765,2.765,0,0,1-1.684-1.393,3.419,3.419,0,0,1,.114-2.685c.574-1.529,2.829-5.079,6.138-6.287l.383,1.05c-2.915,1.064-4.98,4.312-5.475,5.63a2.412,2.412,0,0,0-.146,1.823,1.684,1.684,0,0,0,1.047.811Z"
            transform="translate(1145.076 -552.49)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}

export default SupplyLotIcon;
