import { storeConstants } from '../constants/StoreConstants';
import axiosInstance from '../../../main/axios';
import { URLS } from '../constants/UrlConstants';

export const getBankAccounts = id => dispatch => {
  return axiosInstance
    .get(URLS.orgBankAccounts(id))
    .then(({ status, data }) => {
      if (status === 201) {
        dispatch({
          type: storeConstants.GET_BANK_ACCOUNTS,
          payload: data,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: storeConstants.GET_BANK_ACCOUNTS,
        payload: [],
      });
    });
};

export const createBankAccount = (id, value) => dispatch => {
  return axiosInstance
    .post(URLS.orgBankAccounts(id), value)
    .then(({ status, data }) => {
      if (status === 201) {
        dispatch({
          type: storeConstants.CREATE_BANK_ACCOUNT,
          payload: data,
          apiStatus: status,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: storeConstants.CREATE_BANK_ACCOUNT,
        payload: [],
      });
    });
};

export const deleteBankAccount = id => dispatch => {
  return axiosInstance
    .put(URLS.removeOrgBankAccount(id))
    .then(({ status, data }) => {
      if (status === 201) {
        dispatch({
          type: storeConstants.DELETE_BANK_ACCOUNT,
          payload: { deletedId: id },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: storeConstants.DELETE_BANK_ACCOUNT,
        payload: [],
      });
    });
};

export const editBankAccount = (id, params) => dispatch => {
  return axiosInstance
    .put(URLS.orgBankAccounts(id), params)
    .then(({ status, data }) => {
      if (status === 201) {
        dispatch({
          type: storeConstants.EDIT_BANK_ACCOUNT,
          payload: params,
        });
      }
    })
    .catch(() => {});
};
