import React, { useEffect, useContext } from 'react';
import { SocketContext } from 'main/context/socket';
import './styles.scss';

const Termsandcondition = props => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  useEffect(() => {});

  return (
    <div className="terms_main_section">
      <p className="header">{OKTION_VARIABLE?.OKTION_TERMS_CONDITION}</p>
      <p>
        {OKTION_VARIABLE?.OKTION_PROVIDED_BY}:
        {/* <a href="https://www.digiground.com.au" target="_blank" rel="noreferrer noopener">DigiGround</a> Pty Ltd<br>This Version: 2018-01<br>Previous Version: N/A<br>Last Update: 01<sup>st</sup> September 2018 */}
      </p>
      <strong>{OKTION_VARIABLE?.OKTION_INTRODUCTION}</strong>
      <div>{OKTION_VARIABLE?.OKTION_AGREEMENT}</div>
      <div>{OKTION_VARIABLE?.OKTION_CONTRACTING_WITH_DIGI}</div>
      <div>{OKTION_VARIABLE?.OKTION_ADVISE_AGREEMENT}</div>
      <div>{OKTION_VARIABLE?.OKTION_TEMS_CONJUCTION}</div>
      <strong>{OKTION_VARIABLE?.OKTION_ABOUT_OKTION}</strong>
      <div>{OKTION_VARIABLE?.OKTION_IS_SERVICE}</div>
      <div>{OKTION_VARIABLE?.OKTION_PROVIDE}</div>
    </div>
  );
};

export default Termsandcondition;
