import React, { useState, useEffect, useContext } from 'react';
import { Button, Col, Input, InputGroup, Row } from 'reactstrap';
import EventTimeDetails from '../EventTimeDetails';
import { images } from '../../../../library/common/constants/ImageConstants';
import moment from 'moment';
import LogoutModal from '../Raffle/LogoutModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import axiosInstance from '../../../../main/axios';
import { URLS } from '../../../../library/common/constants/UrlConstants';
import PageLoader from '../../../../library/common/components/PageLoader';
// import { identifiers } from '../../../../library/common/constants/IdentifierConstants';
// import { fetchFromStorage } from '../../../../utility';
// import ActionMessage from '../../../../library/common/components/ActionMessage';
import { EventEmitter } from 'library/common/constants/event';
import { useParams } from 'react-router-dom';
import { SocketContext } from '../../../../main/context/socket';
import { setApiMessage } from '../../../../library/common/constants/function';
import { fetchFromStorage, saveToStorage } from 'utility';
import { identifiers } from 'library/common/constants/IdentifierConstants';

import { CurrencyFormat } from 'utility';

const useForceUpdate = () => {
  let [value, setState] = useState(true);
  return () => setState(!value);
};
const Donations = ({ eventFeatures, isLoggedIn, eventDetails, whitelabel, timeZone }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const [isToggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [eventOutfit, setEventOutfit] = useState();
  const [donationAmountArray, setDonationAmountArray] = useState([]);
  const [donationAmountArrayOther, setDonationAmountArrayOther] = useState([]);
  const [otherDonationStatus, setotherDonationStatus] = useState('');
  const [cartItem, setClickItemData] = useState('');
  // const [message, setMessage] = useState({
  //   display: false,
  //   type: '',
  //   message: '',
  // });

  const userDetail = fetchFromStorage(identifiers?.userDetail);

  useEffect(() => {
    if (eventFeatures !== undefined) {
      var donationFeatureData = eventFeatures?.filter((item, index) => item.type === 'donation');
      let donationAmount_clone = donationFeatureData[0]?.donations;
      let donationAmountLocal = [];
      let donationAmountOtherLocal = [];

      var donationArray = eventFeatures?.find((item, index) => item.type === 'donation');
      var donationFeatureOption = donationArray?.eventFeatureOptions;
      var otherDonStatus = donationFeatureOption?.find(item => item?.key === 'ischoicesdonation');

      if (donationAmount_clone) {
        donationAmount_clone.map(element => {
          let element_clone = element;
          element_clone['count'] = 1;
          element_clone['checked'] = false;
          element_clone['otherAmount'] = '';
          if (element.amount == null) {
            donationAmountOtherLocal.push(element_clone);
          } else {
            donationAmountLocal.push(element_clone);
          }
        });
      }
      setDonationAmountArray(donationAmountLocal);
      setDonationAmountArrayOther(donationAmountOtherLocal);
      setotherDonationStatus(otherDonStatus);
    }
    // getEventDetailsById();
  }, [eventFeatures]);
  // console.log('eventFeatures', eventFeatures);
  // console.log('otherDonationStatus', otherDonationStatus);
  useEffect(() => {
    // getEventDetailsById();
  }, []);
  // var eventDetails = fetchFromStorage(identifiers.eventDetails);
  // const url = window.location.pathname;
  // const spliturl = url.split('/');
  // const id = spliturl[spliturl.length - 1];
  // var eventId = eventDetails !== null ? eventDetails?.id : id;
  const params = useParams();
  var eventId = params?.id;

  const getEventDetailsById = async () => {
    const previewStatus = location?.state?.previewStatus;
    var prevStatus = previewStatus !== undefined ? previewStatus : 0;

    try {
      const { status, data } = await axiosInstance.get(URLS.getPublicEventByid(eventId, prevStatus));
      if (status === 200 || status === 304) {
        setEventOutfit(data?.data?.events);
      }
    } catch (err) {}
  };

  const forceUpdate = useForceUpdate();
  const toggle = () => {
    setToggle(!isToggle);
  };
  const handleClick = () => {
    navigate('/login', {
      state: {
        redirectTo: 'event-details',
        featureName: 'donation',
        eventId: eventId,
      },
    });
  };
  const handleValidate = () => {
    let flag = true;
    let donationAmountArrayOtherClone = donationAmountArrayOther[0];
    if (donationAmountArrayOtherClone?.checked === true) {
      if (donationAmountArrayOtherClone.otherAmount === '' || donationAmountArrayOtherClone.otherAmount === 0) {
        flag = false;
        setErrorMessage(true);
      }
    }
    return flag;
  };
  const handleAddToCart = async eventId => {
    if (isLoggedIn && userDetail?.userType === 'normal') {
      let donationAmountArrayClone = donationAmountArray;
      let donationAmountArrayOtherClone = donationAmountArrayOther[0];
      var cartArrayClone = donationAmountArrayClone.filter(item => item.checked !== false);
      let cartArray = [];
      cartArrayClone.map(element => {
        let obj = {
          type: 'donation',
          eventId: eventId,
          referenceId: null,
          quantity: element.count,
          purchaseReferenceId: element.id,
          isRaffleTicket: false,
        };
        cartArray.push(obj);
      });

      if (donationAmountArrayOtherClone?.checked === true) {
        if (donationAmountArrayOtherClone?.otherAmount !== '') {
          let obj2 = {
            type: 'donation',
            eventId: eventId,
            referenceId: null,
            isRaffleTicket: false,
            quantity: 1,
            purchaseReferenceId: donationAmountArrayOtherClone.id,
            donationAmount: donationAmountArrayOtherClone.otherAmount,
          };
          cartArray.push(obj2);
        }
      }
      if (handleValidate()) {
        setLoading(true);
        try {
          const { status } = await axiosInstance.post(URLS.addToCart, cartArray);
          if (status === 201 || status === 200) {
            setLoading(false);
            navigate('/checkout', {
              state: {
                FROM_WHERE: 'PUBLIC_EVENT_DETAILS',
                eventId: eventId,
              },
            });
          }
        } catch (err) {
          setLoading(false);
          // setActionMessage(true, 'Error', 'Something went wrong');
          setApiMessage('error', OKTION_VARIABLE?.SOMETHING_WENT_WRONG);
          // setTimeout(() => {
          //   setActionMessage(false);
          // }, 4000);
          // EventEmitter.dispatch('feturesErrorMessage', err?.response?.data?.msg);
        }
      }
    } else if (isLoggedIn && userDetail?.userType === 'guest') {
      updateGuestCardList();
      navigate('/guest-checkout', {
        state: {
          eventId: params?.id,
        },
      });
    } else {
      if (handleValidate()) {
        handleSetCartData(eventId);
        setToggle(true);
      }
    }
  };

  const handleSetCartData = eventId => {
    let donationAmountArrayClone = donationAmountArray;
    let donationAmountArrayOtherClone = donationAmountArrayOther[0];
    var cartArrayClone = donationAmountArrayClone.filter(item => item.checked !== false);
    let cartArray = [];
    cartArrayClone.map(element => {
      let obj = {
        type: 'donation',
        quantity: element.count,
        referenceId: null,
        subscriptionName: null,
        subscriptionPrice: null,
        name: element?.name,
        amount: element?.amount,
        purchaseReferenceId: element.id,
        isRaffleTicket: false,
        donationAmount: element?.amount,
        eventId: eventId,
        currency: eventDetails?.currency?.abbreviation,
        symbol: eventDetails?.currency?.symbol,
        event: eventDetails,
        eventName: eventDetails?.eventName,
        digiCommission: 0,
        itemDetails: element,
        cardCharedAmount: 0,
      };
      cartArray.push(obj);
    });

    if (donationAmountArrayOtherClone?.checked === true) {
      if (donationAmountArrayOtherClone?.otherAmount !== '') {
        let obj2 = {
          type: 'donation',
          quantity: 1,
          referenceId: null,
          subscriptionName: null,
          subscriptionPrice: null,
          name: donationAmountArrayOtherClone?.name,
          amount: donationAmountArrayOtherClone.otherAmount,
          purchaseReferenceId: donationAmountArrayOtherClone.id,
          isRaffleTicket: false,
          donationAmount: donationAmountArrayOtherClone.otherAmount,
          eventId: eventId,
          currency: eventDetails?.currency?.abbreviation,
          symbol: eventDetails?.currency?.symbol,
          event: eventDetails,
          eventName: eventDetails?.eventName,
          digiCommission: 0,
          itemDetails: donationAmountArrayOtherClone,
          cardCharedAmount: 0,
        };
        cartArray.push(obj2);
      }
    }

    setClickItemData(cartArray);
  };

  const updateGuestCardList = () => {
    let donationAmountArrayClone = donationAmountArray;
    let donationAmountArrayOtherClone = donationAmountArrayOther[0];
    var cartArrayClone = donationAmountArrayClone.filter(item => item.checked !== false);
    let cartArray = [];
    cartArrayClone.map(element => {
      let obj = {
        type: 'donation',
        quantity: element.count,
        referenceId: null,
        subscriptionName: null,
        subscriptionPrice: null,
        name: element?.name,
        amount: element?.amount,
        purchaseReferenceId: element.id,
        isRaffleTicket: false,
        donationAmount: element?.amount,
        eventId: eventId,
        currency: eventDetails?.currency?.abbreviation,
        symbol: eventDetails?.currency?.symbol,
        event: eventDetails,
        eventName: eventDetails?.eventName,
        digiCommission: 0,
        itemDetails: element,
        cardCharedAmount: 0,
      };
      cartArray.push(obj);
    });

    if (donationAmountArrayOtherClone?.checked === true) {
      if (donationAmountArrayOtherClone?.otherAmount !== '') {
        let obj2 = {
          type: 'donation',
          quantity: 1,
          referenceId: null,
          subscriptionName: null,
          subscriptionPrice: null,
          name: donationAmountArrayOtherClone?.name,
          amount: donationAmountArrayOtherClone.otherAmount,
          purchaseReferenceId: donationAmountArrayOtherClone.id,
          isRaffleTicket: false,
          donationAmount: donationAmountArrayOtherClone.otherAmount,
          eventId: eventId,
          currency: eventDetails?.currency?.abbreviation,
          symbol: eventDetails?.currency?.symbol,
          event: eventDetails,
          eventName: eventDetails?.eventName,
          digiCommission: 0,
          itemDetails: donationAmountArrayOtherClone,
          cardCharedAmount: 0,
        };
        cartArray.push(obj2);
      }
    }
    let singleItem = cartArray;
    // Fetch the existing guestCheckoutData from storage or initialize it as an empty array
    let guestCheckoutData = fetchFromStorage(identifiers?.guestcheckoutdata) || [];

    // Check if singleItem is an array or object using optional chaining
    if (singleItem?.constructor === Array) {
      // If it's an array, concatenate it with the existing guestCheckoutData
      guestCheckoutData = [...guestCheckoutData, ...singleItem];
    } else if (singleItem) {
      // If it's an object (not null or undefined), push it to the guestCheckoutData array
      guestCheckoutData.push(singleItem);
    }

    // Save the updated guestCheckoutData to storage
    saveToStorage(identifiers?.guestcheckoutdata, guestCheckoutData);

    // Dispatch an event or perform any other necessary actions
    setTimeout(() => {
      EventEmitter.dispatch('updateguestcheckout', guestCheckoutData);
    }, 300);
  };

  const handleAddMinusChecked = (typeEdit, isMinus, count, id) => {
    let donationAmountArrayClone = donationAmountArray;
    if (typeEdit === 1) {
      donationAmountArrayClone.map((element, position) => {
        if (element?.id === id) {
          element.checked = count;
        }
      });
    } else if (typeEdit === 2) {
      donationAmountArrayClone.map((element, position) => {
        if (element?.id === id) {
          element.count = Number(count);
        }
      });
    } else if (typeEdit === 3) {
      donationAmountArrayClone.map((element, position) => {
        if (element?.id === id) {
          if (isMinus) {
            element.count = element.count === 1 ? 1 : element.count - 1;
          } else {
            element.count = element.count + 1;
          }
        }
      });
    }

    setDonationAmountArray(donationAmountArrayClone);
    forceUpdate();
  };
  const handleAddMinusCheckedOther = (typeEdit, count) => {
    let donationAmountArrayClone = donationAmountArrayOther[0];

    if (typeEdit === 1) {
      donationAmountArrayClone['checked'] = count;
      setErrorMessage(false);
    } else if (typeEdit === 2) {
      donationAmountArrayClone['otherAmount'] = Number(count);
      setErrorMessage(false);
    }
    setDonationAmountArrayOther([donationAmountArrayClone]);
    forceUpdate();
  };
  const onKeyPress = event => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-/.test(keyValue)) event.preventDefault();
  };
  var donationFeatureData = eventFeatures?.filter(item => item.type === 'donation');
  var currency = eventDetails?.currency?.abbreviation;
  var symbol = eventDetails?.currency?.symbol;
  var buttonActive = true;
  donationAmountArray.map(element => {
    if (element.checked === true) {
      buttonActive = false;
    }
  });
  donationAmountArrayOther.map(element => {
    if (element.checked === true) {
      buttonActive = false;
    }
  });
  // const setActionMessage = (display = false, type = '', message = '') => {
  //   setMessage({
  //     display,
  //     type,
  //     message,
  //   });
  // };

  return (
    <Row className="res-org-donation-amt">
      {/* {message.display && (
        <div className="btn-block mt-4">
          <ActionMessage message={message.message} type={message.type} styleClass={message.styleClass} />
        </div>
      )} */}
      {/* {donationAmountArray && donationAmountArray.length > 0 ? (
        <> */}
      <Col lg="9" md="8" sm="12">
        <h4 className="pb-3 pt-3">{OKTION_VARIABLE?.OKTION_DONATION_AMOUNTS}</h4>
        <div className="priceCircle">
          {donationAmountArray && donationAmountArray.length > 0 && (
            <Row>
              {donationAmountArray
                ?.filter(element => {
                  return element?.amount !== 0;
                })
                .map((item, index) => (
                  <Col
                    lg="3"
                    md="6"
                    sm="6"
                    key={index}
                    className="text-center mb-4"
                    style={{
                      pointerEvents: donationFeatureData[0]?.isActive === false ? 'none' : '',
                      opacity: donationFeatureData[0]?.isActive === false ? '0.4' : '1',
                    }}>
                    <div
                      className="circle m-auto"
                      style={{
                        borderColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
                      }}>
                      <h2>
                        {/* {currency} {symbol}
                      {item.count * item?.amount} */}
                        <CurrencyFormat
                          value={item.count * item?.amount}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={`${eventDetails?.currency != null ? currency : ''} ${
                            eventDetails?.currency != null ? symbol : ''
                          }`}
                          thousandSpacing={symbol === '₹' ? '2s' : '3'}
                          // thousandSpacing="2s"
                          decimalScale="2"
                          fixedDecimalScale={true}
                        />
                      </h2>
                    </div>
                    <span className="pt-4 mt-1 fw-500 text-start ps-4 d-block">{item?.name}</span>
                    {/* <Input
                      className="pt-2 ps-1 mt-0"
                      type="checkbox"
                      // label={`Donate ${currency} ${symbol}${parseFloat(item?.amount).toFixed(2)}`}
                      label={
                        <CurrencyFormat
                          value={item?.amount || '0.00'}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={`Donate ${eventDetails?.currency != null ? currency : ''} ${
                            eventDetails?.currency != null ? symbol : ''
                          }`}
                          thousandSpacing="2s"
                          decimalScale="2"
                          fixedDecimalScale={true}
                        />
                      }
                      id={index}
                      name={index}
                      value={item.id}
                      onClick={event => handleAddMinusChecked(1, true, event.target.checked, item.id)}
                    /> */}
                    <div className="text-start pt-2 ps-4 mt-0 form-group mb-0">
                      <div className="custom-checkbox form-check ">
                        <input
                          className="form-check-input form-check-label"
                          type="checkbox"
                          id={index}
                          name={index}
                          value={item.id}
                          onClick={event => handleAddMinusChecked(1, true, event.target.checked, item.id)}
                        />
                        <label className="form-check-label">
                          <CurrencyFormat
                            value={item?.amount || '0.00'}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={`Donate ${
                              eventDetails?.currency != null ? currency : ''
                            } ${eventDetails?.currency != null ? symbol : ''}`}
                            thousandSpacing="2s"
                            decimalScale="2"
                            fixedDecimalScale={true}
                          />
                        </label>
                      </div>
                    </div>
                    {/* <div className="d-flex justify-content-center mt-3">
                    <Button
                      id={index}
                      className="addButtons"
                      onClick={() => handleAddMinusChecked(3, true, 1, index)}
                      disabled={!donationFeatureData[0]?.isActive || whitelabel === true ? true : false}
                      style={{
                        backgroundColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
                        borderColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
                        color: eventDetails?.primaryTextColour ? eventDetails?.primaryTextColour : '',
                      }}>
                      -
                    </Button>

                    <input
                      value={item.count}
                      onChange={event => handleAddMinusChecked(2, true, event.target.value, index)}
                      className="addButtonInput h-45"
                      type="number"
                      placeholder=""
                      name={index}
                    />
                    <Button
                      id={index}
                      className="addButtons"
                      onClick={() => handleAddMinusChecked(3, false, 1, index)}
                      disabled={!donationFeatureData[0]?.isActive || whitelabel === true ? true : false}
                      style={{
                        backgroundColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
                        borderColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
                        color: eventDetails?.primaryTextColour ? eventDetails?.primaryTextColour : '',
                      }}>
                      +
                    </Button>
                  </div> */}
                  </Col>
                ))}
            </Row>
            // ) : (
            //   <h3 className="text-center ps-4 mt-4">No data found</h3>
          )}

          {donationAmountArrayOther && donationAmountArrayOther.length > 0 && (
            /* {otherDonationStatus?.value === 'true' && ( */
            <div
              className="mt-5  ps-4"
              style={{
                pointerEvents: donationFeatureData[0]?.isActive === false ? 'none' : '',
                opacity: donationFeatureData[0]?.isActive === false ? '0.4' : '1',
              }}>
              {/* <Input
                type="checkbox"
                name="checkbox2"
                onClick={event => handleAddMinusCheckedOther(1, event.target.checked)}
                className="feature-item mb-2"
                id="other"
                label="Other amount"
              /> */}
              <div className="custom-checkbox form-check ">
                <input
                  type="checkbox"
                  name="checkbox2"
                  onClick={event => handleAddMinusCheckedOther(1, event.target.checked)}
                  className="form-check-input form-check-label"
                  id="other"
                />
                <label className="form-check-label">Other amount</label>
              </div>
              <InputGroup>
                <input
                  className="h-45 mb-3 ps-5 input_field-style form-control"
                  onChange={e => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    const cleanedValue = e.target.value.replace(/[^0-9.]/g, '');
                    e.target.value = cleanedValue.startsWith('-') ? cleanedValue.slice(1) : cleanedValue;
                    handleAddMinusCheckedOther(2, e.target.value);
                  }}
                  pattern="[0-9]*"
                  type="number"
                  onKeyPress={onKeyPress}
                  placeholder={OKTION_VARIABLE?.OKTION_ENTER_AMOUNT}
                  onBlur={e => (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : '')}
                />
                <span className="span-style"> {currency}</span>
                {errorMessage === true && <p className="text-danger">{OKTION_VARIABLE?.OKTION_PLEASE_ENTER_AMOUNT}</p>}
              </InputGroup>{' '}
            </div>
          )}
          {((donationAmountArray && donationAmountArray.length > 0) ||
            (donationAmountArrayOther && donationAmountArrayOther.length > 0)) && (
            /*   {((donationAmountArray && donationAmountArray.length > 0) || otherDonationStatus?.value === 'true') && (*/
            <Button
              disabled={buttonActive || whitelabel === true ? true : false}
              onClick={() => handleAddToCart(donationFeatureData[0].eventId)}
              className={
                eventDetails?.primaryColour ? 'btn-block  bigButton ms-3' : 'main-button btn-block mt-2 bigButton ms-3'
              }
              style={{
                backgroundColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
                borderColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
                color: eventDetails?.primaryTextColour ? eventDetails?.primaryTextColour : '',
              }}>
              {OKTION_VARIABLE?.OKTION_ADD_TO_CART}
            </Button>
          )}
        </div>
        {/* {console.log('buttonActive', buttonActive)} */}
      </Col>
      <Col lg="3" md="4" sm="12" className="pt-4 mt-3 mb-3">
        {timeZone !== undefined && (
          <>
            {/* <EventTimeDetails
              title={OKTION_VARIABLE?.OKTION_DONATION_FINISH_DATE}
              img={images.calendar}
              // address={moment(donationFeatureData[0]?.endTime).utcOffset(timeZone).format('DD/MM/YYYY')}
              address={moment(donationFeatureData[0]?.endTime).format('DD/MM/YYYY')}
              isLocation
            /> */}
            <EventTimeDetails
              isLocation
              title={OKTION_VARIABLE?.OKTION_DONATION_FINISH_DATE}
              img={images.watch}
              address={moment(donationFeatureData[0]?.endTime).format('DD/MM/YYYY hh:mm A')}
              // endDate={moment(donationFeatureData[0]?.endTime).format('DD/MM/YYYY hh:mm A')}
              // address={moment(donationFeatureData[0]?.endTime).utcOffset(timeZone).format('hh:mm A')}
            />
          </>
        )}
      </Col>
      <LogoutModal
        isToggle={isToggle}
        toggle={toggle}
        handleClick={handleClick}
        isGuestCheckout
        singleItem={cartItem}
      />
      {/* //   </> */}
      {/* // ) : (
      //   <h3 className="text-center ps-4 mt-4">No data found</h3>
      // )} */}
      {loading && <PageLoader />}
    </Row>
  );
};
const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};

export default connect(mapStateToProps, {})(Donations);
