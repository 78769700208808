import React from 'react';

function TransferIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26.152" height="26.041" viewBox="0 0 16.152 23.041">
      <g
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1.3"
        data-name="Group 11334"
        transform="translate(1035.533 -477.483)">
        <path d="M0 0L14.314 0" data-name="Line 677" transform="translate(-1034.614 483.148)"></path>
        <path d="M-1025.505 477.943l5.205 5.205-5.205 5.205" data-name="Path 5556"></path>
        <path d="M14.314 0L0 0" data-name="Line 678" transform="translate(-1034.614 494.86)"></path>
        <path d="M-1029.409 489.655l-5.205 5.205 5.205 5.205" data-name="Path 5557"></path>
      </g>
    </svg>
  );
}

export default TransferIcon;
