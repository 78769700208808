import React, { useContext } from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import '../../styles.scss';
// import { raffleWallData } from '../../RaffleData';
import { SocketContext } from 'main/context/socket';
import { identifiers } from '../../../../../../../../../library/common/constants/IdentifierConstants';
import Slider from 'react-slick';

const RaffleWallCard = ({ raffleWallData }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  return (
    <div className="raffleWallCard">
      <Slider
        dots={false}
        infinite={true}
        speed={0}
        autoplay={true}
        autoplaySpeed={5000}
        swipe={false}
        touchMove={false}
        arrows={false}
        pauseOnHover={false}
        slidesToShow={1}>
        {raffleWallData &&
          raffleWallData?.map((item, i) => {
            var PBV =
              item?.providedBy && item?.providedBy === 'sponsoredBy'
                ? OKTION_VARIABLE?.OKTION_SPONSOR_BY
                : item?.providedBy === 'donatedBy'
                  ? OKTION_VARIABLE?.OKTION_DONATION_BY
                  : OKTION_VARIABLE?.OKTION_PROVIDED_BY;
            return (
              <Row key={i} className="g-0 mb-3">
                <Col sm="4">
                  <div className="card-img">
                    <img src={identifiers.imageUrlConstant + item?.images[0]?.name} alt="" />
                  </div>
                </Col>
                <Col sm="8">
                  <div className="card-content">
                    <h1 className="mb-2">{item?.prizeName}</h1>
                    <h4 className="fw-normal mb-3">{`${PBV} ${item?.itemProvidedByName}`}</h4>
                    <h4 className="mb-1">{OKTION_VARIABLE?.PRIZE_PLACE}</h4>
                    <h4 className="mb-2 fw-normal">{item?.prizePlace}</h4>
                    <h4 className="mb-1">{OKTION_VARIABLE?.PRIZE_VALUE}</h4>
                    <h4 className="mb-1 fw-normal">{item?.priceValue}</h4>
                  </div>
                </Col>
              </Row>
            );
          })}
      </Slider>
    </div>
  );
};

export default RaffleWallCard;
