import React from 'react';

function AdminIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26.083" height="24.97" viewBox="0 0 26.083 24.97">
      <g id="Group_11990" data-name="Group 11990" transform="translate(-387.633 -547.051)">
        <g id="Group_4184" data-name="Group 4184" transform="translate(393.67 558.047)">
          <path
            id="Path_198"
            data-name="Path 198"
            d="M401.328,572.454a2.58,2.58,0,1,1,2.58-2.58A2.583,2.583,0,0,1,401.328,572.454Zm0-3.8a1.222,1.222,0,1,0,1.222,1.222A1.224,1.224,0,0,0,401.328,568.652Z"
            transform="translate(-398.748 -567.294)"
            fill="#fff"
          />
        </g>
        <g id="Group_4185" data-name="Group 4185" transform="translate(388.239 560.219)">
          <path
            id="Path_199"
            data-name="Path 199"
            d="M391.328,576.454a2.58,2.58,0,1,1,2.58-2.58A2.583,2.583,0,0,1,391.328,576.454Zm0-3.8a1.222,1.222,0,1,0,1.222,1.222A1.224,1.224,0,0,0,391.328,572.652Z"
            transform="translate(-388.748 -571.294)"
            fill="#fff"
          />
        </g>
        <g id="Group_4186" data-name="Group 4186" transform="translate(392.056 559.635)">
          <path
            id="Path_200"
            data-name="Path 200"
            d="M397.1,582.487l-1.329-.285,1.269-5.923a2.828,2.828,0,0,1,2.529-2.206l3.077-.513,3.7-3.342.422.72c.5.853,1.012,2.61-.234,3.788l-2.438,2.413-3.722,1.137-.4-1.3,3.4-1.04,2.211-2.188a1.344,1.344,0,0,0,.309-1.3l-2.64,2.383-3.455.576c-1.091.2-1.339.7-1.437,1.153Z"
            transform="translate(-395.775 -570.218)"
            fill="#fff"
          />
        </g>
        <g id="Group_4187" data-name="Group 4187" transform="translate(396.943 566.197)">
          <rect
            id="Rectangle_1512"
            data-name="Rectangle 1512"
            width="5.539"
            height="1.358"
            transform="translate(0 5.432) rotate(-78.69)"
            fill="#fff"
          />
        </g>
        <g id="Group_4188" data-name="Group 4188" transform="translate(405.913 559.676)">
          <path
            id="Path_201"
            data-name="Path 201"
            d="M423.866,575.454a2.58,2.58,0,1,1,2.58-2.58A2.583,2.583,0,0,1,423.866,575.454Zm0-3.8a1.222,1.222,0,1,0,1.222,1.222A1.224,1.224,0,0,0,423.866,571.652Z"
            transform="translate(-421.286 -570.294)"
            fill="#fff"
          />
        </g>
        <g id="Group_4189" data-name="Group 4189" transform="translate(401.988 565.108)">
          <path
            id="Path_202"
            data-name="Path 202"
            d="M424.485,587.136l-1.217-4.234c-.136-.628-.395-1.25-1.888-1.25h-5.565a1.383,1.383,0,0,0,1.219.815h4.345v1.358h-4.345a3.087,3.087,0,0,1-2.842-2.74l-.131-.79h7.319c1.8,0,2.88.766,3.2,2.278l1.2,4.188Z"
            transform="translate(-414.061 -580.294)"
            fill="#fff"
          />
        </g>
        <g id="Group_4190" data-name="Group 4190" transform="translate(407.04 567.728)">
          <rect
            id="Rectangle_1513"
            data-name="Rectangle 1513"
            width="1.358"
            height="4.045"
            transform="matrix(0.94, -0.341, 0.341, 0.94, 0, 0.463)"
            fill="#fff"
          />
        </g>
        <g id="Group_4191" data-name="Group 4191" transform="translate(387.633 565.121)">
          <path
            id="Path_203"
            data-name="Path 203"
            d="M388.961,587.1l-1.328-.285.8-3.751a2.828,2.828,0,0,1,2.529-2.206l2.708-.542.266,1.332-2.716.543c-1.113.209-1.361.7-1.458,1.157Z"
            transform="translate(-387.633 -580.318)"
            fill="#fff"
          />
        </g>
        <g id="Group_4192" data-name="Group 4192" transform="translate(387.762 548.719)">
          <path
            id="Path_204"
            data-name="Path 204"
            d="M390.536,560.033l.42-3.857-3.086-2.386,8.572-3.669.535,1.249-6.448,2.759,1.859,1.437-.251,2.3,6.442-2.757.535,1.249Z"
            transform="translate(-387.87 -550.121)"
            fill="#fff"
          />
        </g>
        <g id="Group_4193" data-name="Group 4193" transform="translate(395.104 547.051)">
          <rect
            id="Rectangle_1514"
            data-name="Rectangle 1514"
            width="1.358"
            height="15.269"
            transform="translate(0 0.535) rotate(-23.221)"
            fill="#fff"
          />
        </g>
        <g id="Group_4194" data-name="Group 4194" transform="translate(401.959 563.122)">
          <rect
            id="Rectangle_1515"
            data-name="Rectangle 1515"
            width="1.358"
            height="2.599"
            transform="translate(0 0.518) rotate(-22.44)"
            fill="#fff"
          />
        </g>
        <g id="Group_4195" data-name="Group 4195" transform="translate(403.852 567.704)">
          <rect
            id="Rectangle_1516"
            data-name="Rectangle 1516"
            width="1.358"
            height="4.11"
            transform="translate(0 0.518) rotate(-22.441)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}

export default AdminIcon;
