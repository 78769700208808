import React, { useContext } from 'react';
import { Table } from 'reactstrap';
// import { AdminRoleData } from '../AdminData';
import { images } from '../../../../../library/common/constants/ImageConstants';
import { SocketContext } from '../../../../../main/context/socket';

const RoleTable = () => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const AdminRoleData = [
    {
      id: 1,
      role: OKTION_VARIABLE?.OKTION_EDIT_EVENT_DETAILS,
      owner: true,
      admin: true,
      manager: true,
    },
    {
      id: 2,
      role: OKTION_VARIABLE?.OKTION_ADDFEATURE_TOEVENT,
      owner: true,
      admin: true,
      manager: false,
    },
    {
      id: 3,
      role: OKTION_VARIABLE?.OKTION_EDIT_FEATURE_DETAILS,
      owner: true,
      admin: true,
      manager: true,
    },
    {
      id: 3,
      role: OKTION_VARIABLE?.OKTION_DELETE_FROM_EVENT,
      owner: true,
      admin: true,
      manager: false,
    },
    {
      id: 4,
      role: OKTION_VARIABLE?.OKTION_PUBLISHED_FEATURE,
      owner: true,
      admin: true,
      manager: false,
    },

    {
      id: 5,
      role: OKTION_VARIABLE?.OKTION_MANUAL_RAFFLE,
      owner: true,
      admin: true,
      manager: false,
    },
    {
      id: 6,
      role: OKTION_VARIABLE?.OKTION_SEE_EVENT_DASHBOARD,
      owner: true,
      admin: true,
      manager: true,
    },
    {
      id: 7,
      role: OKTION_VARIABLE?.OKTION_EDIT_ORG_DETAILS,
      owner: true,
      admin: true,
      manager: true,
    },
    {
      id: 8,
      role: OKTION_VARIABLE?.OKTION_INVITE_ATTENDEES,
      owner: true,
      admin: true,
      manager: true,
    },
    {
      id: 9,
      role: OKTION_VARIABLE?.OKTION_ADD_SPONSORS,
      owner: true,
      admin: true,
      manager: false,
    },
    {
      id: 10,
      role: OKTION_VARIABLE?.OKTION_ADD_MANAGER,
      owner: true,
      admin: true,
      manager: false,
    },
    {
      id: 11,
      role: OKTION_VARIABLE?.OKTION_ADD_ADMINS,
      owner: true,
      admin: false,
      manager: false,
    },
    {
      id: 12,
      role: OKTION_VARIABLE?.OKTION_TRANSFER_OWNERSHIP,
      owner: true,
      admin: false,
      manager: false,
    },
  ];

  return (
    <div className="mb-4 roleModal">
      <Table bordered className="role-table">
        <thead>
          <tr>
            <th></th>
            <th className="text-center">{OKTION_VARIABLE?.OKTION_OWNER}</th>
            <th className="text-center">{OKTION_VARIABLE?.OKTION_ADMIN}</th>
            <th className="text-center">{OKTION_VARIABLE?.OKTION_MANAGER}</th>
          </tr>
        </thead>

        <tbody>
          {AdminRoleData.map((item, i) => (
            <tr>
              <td>{item.role}</td>
              <td className="text-center">{item.owner && <img src={images.yellowTick} alt="" />}</td>
              <td className="text-center">{item.admin && <img src={images.yellowTick} alt="" />}</td>
              <td className="text-center">{item.manager && <img src={images.yellowTick} alt="" />}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default RoleTable;
