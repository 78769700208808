import React, { useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Col, FormText, Label } from 'reactstrap';
import FormGroup from 'library/common/components/FormGroupComponent';
import { images } from 'library/common/constants/ImageConstants';
import { deleteBankAccount, editBankAccount } from 'library/common/actions/BankAccountsAction';
import '../../../UserProfile/Frame/CardDetails/styles.scss';
import './styles.scss';
// import 'react-flags-select/css/react-flags-select.css';
import SelectBoxWithFlag from 'library/common/components/SelectBoxWithFlag';
import { useForm } from 'react-hook-form';
import PageLoader from 'library/common/components/PageLoader';
import Config from 'utility/Config';
// import ActionMessage from 'library/common/components/ActionMessage';
import { setApiMessage } from 'library/common/constants/function';
// import { EventEmitter } from '../../../../library/common/constants/event';
import axiosInstance from '../../../../main/axios';
import { URLS } from '../../../../library/common/constants/UrlConstants';
import { fetchFromStorage } from '../../../../utility';
import { identifiers } from '../../../../library/common/constants/IdentifierConstants';
// import { identifiers } from '../../../../library/common/constants/IdentifierConstants';
import { EventEmitter } from 'library/common/constants/event';
import { SocketContext } from '../../../../main/context/socket';
import { createDraftOrg } from 'modules/Organization/menu-items';
import { useNavigate } from 'react-router-dom';

const BankingDetailsItem = props => {
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const navigate = useNavigate();
  const isNewOrg = window.location.pathname === '/organization/organiser-details';
  // const [message, setMessage] = useState({
  //   display: false,
  //   type: '',
  //   message: '',
  // });
  /* const [selectedCurrency, setSelectedCurrency] = useState({
    value: 'AUD',
    label: 'AUD',
    flag: 'https://cdn.britannica.com/78/6078-004-233685F6/Flag-Australia.jpg',
    id: 4,
    name: 'Australian dollar',
    symbol: '$',
    abbreviation: 'AUD',
    status: 'active',
    countryId: 4,
    isRoutingRequired: true,
    createdAt: '2021-01-07T04:58:26.131Z',
    updatedAt: '2021-01-07T09:00:10.000Z',
    country: {
      id: 4,
      countryName: 'Austalia',
      flag: 'https://cdn.britannica.com/78/6078-004-233685F6/Flag-Australia.jpg',
      countryCode: 'AU',
      dialCode: '+61',
      createdBy: 28,
      updatedBy: '28',
      createdAt: '2021-01-07T05:01:03.809Z',
      updatedAt: '2021-01-08T04:15:48.000Z',
    },
    countryCode: 'AU',
  }); */
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [inputValues, setInputValues] = useState({
    routingNumber: props?.item?.routingNumber,
    accountNumber: props?.item?.accountNumber,
    beneficiaryname: props?.item?.beneficiaryName,
    beneficiaryaddress: props?.item?.beneficiaryAddress,
    bankname: props?.item?.bankName,
    // accountHolderName: props?.item?.accountHolderName,
  });
  const [Errors, setErrors] = useState({});
  var defaultValue = {
    routingnumber: inputValues?.routingNumber,
    accountNumber: inputValues?.accountNumber,
    beneficiaryname: props?.item?.beneficiaryName,
    beneficiaryaddress: props?.item?.beneficiaryAddress,
    bankname: props?.item?.bankName,
    // accountHolderName: inputValues?.accountHolderName,
  };
  const {
    register,
    formState: { errors },
    watch,
  } = useForm({ defaultValues: defaultValue });

  useEffect(() => {
    let allCurrencies = getCurrencyOptions();
    let tempSelectedCurrency = allCurrencies?.filter(currency => currency?.id === props?.item?.currencyId);
    setSelectedCurrency({ ...tempSelectedCurrency[0], countryCode: tempSelectedCurrency[0]?.country?.countryCode });
  }, [props?.item, props.currency]);

  const onSelectFlag = countryCode => {
    setErrors(Errors => {
      return { ...Errors, message: '', currency: '' };
    });
    setSelectedCurrency({ ...countryCode, countryCode: countryCode?.country?.countryCode });
    setIsEdit(true);
  };

  const handleChange = inputField => event => {
    const { value } = event.target;
    let inputValue = value;

    setInputValues({
      ...inputValues,
      [inputField]: inputValue,
    });
    setIsEdit(true);
  };

  const handleDelete = async id => {
    try {
      setLoading(true);
      await props.deleteBankAccount(id);
      EventEmitter.dispatch('setActionMessageShow');
      setLoading(false);
      setTimeout(() => {
        EventEmitter.dispatch('setActionMessageHide');
        props.toggleEvent === true && props.toggleModal();
      }, 4000);
      props.getBankAccountApi();
    } catch {
      setLoading(false);
    }
  };

  const handleInputChange = (inputField, inputValue) => {
    if (props?.CreateNew !== true) {
      setIsEdit(true);
    }
    setInputValues({
      ...inputValues,
      [inputField]: inputValue,
    });
    setErrors(Errors => {
      return { ...Errors, message: '', [inputField]: '' };
    });
  };

  const handleEditBankAccount = async () => {
    let id = props?.item?.id;
    createStripeToken(id);
  };

  const getCurrencyOptions = () => {
    return props?.currency?.length > 0
      ? props?.currency?.map(c => {
          return { value: c?.abbreviation, label: c?.abbreviation, flag: c?.country?.flag, ...c };
        })
      : [];
  };
  const createStripeToken = async bankItemId => {
    await setLoader(true);
    let addNewParams = { ...inputValues, country: selectedCurrency?.countryCode, currency: selectedCurrency?.value };

    var stripe = window.Stripe(Config?.stripeAPIKEY);
    var bankAccountParams = {
      country: addNewParams?.country,
      currency: addNewParams?.currency,
      account_number: addNewParams?.accountNumber, //'000123456789
      routing_number: addNewParams?.routingNumber, //'110000000'
      // accountHolderName: addNewParams?.accountHolderName, //'Jane Austen',
      account_holder_type: 'company',
    };

    stripe.createToken('bank_account', bankAccountParams).then(async response => {
      if (response?.error) {
        await setLoader(false);
        if (response?.error?.code === 'routing_number_invalid') {
          setErrors(Errors => {
            return { ...Errors, routingNumber: response?.error?.message };
          });
        } else if (response?.error?.code === 'account_number_invalid') {
          setErrors(Errors => {
            return { ...Errors, accountNumber: response?.error?.message };
          });
        } else {
          setErrors(Errors => {
            return { ...Errors, message: response?.error?.message };
          });
        }
      } else {
        await setLoader(false);
        let resData = response?.token;
        // const currentOrganization_id = fetchFromStorage(identifiers.userDetail);
        // console.log('currentOrganization_id', currentOrganization_id);
        const org_id = fetchFromStorage(identifiers.currentOrganization);
        // const currentOrganization_id = userDetail?.CurrentOrgDetails?.id;

        let addNewParams = {
          // ...inputValues,
          // currency: selectedCurrency?.value,
          stripeId: resData?.bank_account?.id,
          // accountHolderName: inputValues?.accountHolderName,
          accountHolderType: resData?.bank_account?.account_holder_type,
          routingNumber: inputValues?.routingNumber,
          accountNumber: inputValues?.accountNumber,
          // bankName: resData?.bank_account?.bank_name,
          bankName: inputValues?.bankname,
          // country: resData?.bank_account?.country,
          currencyId: selectedCurrency?.id,
          // customer: resData?.bank_account?.name,
          bankToken: resData?.id,
          last4: resData?.bank_account?.last4,
          id: bankItemId,
          beneficiaryName: watch('beneficiaryname'),
          beneficiaryAddress: watch('beneficiaryaddress'),
        };
        if (props?.CreateNew === true) {
          await setLoader(true);
          // console.log('bankItemId', bankItemId);
          // await props.createBankAccountAPI(addNewParams);
          try {
            const { status } = await axiosInstance.post(URLS.orgBankAccounts(org_id), addNewParams);
            if (status === 201) {
              await setLoader(false);
              // setInputValues({ routingNumber: '', accountNumber: '' });
              // props.setActionMessage(true, 'Success', OKTION_VARIABLE?.OKTION_BANKDETAILS_ADDED);
              setApiMessage('success', OKTION_VARIABLE?.OKTION_BANKDETAILS_ADDED);
              setTimeout(() => {
                // props.setActionMessage(false);
                props.toggleEvent === true && props.toggleModal();
              }, 4000);
              props.ToggleForm();
              props.getBankAccountApi();
              if (isNewOrg) {
                setTimeout(() => {
                  navigate(`/organization/${org_id}?banking-details`);
                }, 1000);
                // navigate(`/all-organizations`);
              }
            }
          } catch (err) {
            await setLoader(false);
            if (err?.response?.data?.msg === 'ERR_BANK_ACCOUNT_ALREADY_EXIST') {
              // props.setActionMessage(true, 'Error', OKTION_VARIABLE?.OKTION_BANK_ACCOUNT_EXIST);
              setApiMessage('error', OKTION_VARIABLE?.OKTION_BANK_ACCOUNT_EXIST);
              setTimeout(() => {
                // props.setActionMessage(false);
                props.toggleEvent === true && props.toggleModal();
              }, 4000);
              // props.ToggleForm();
              // props.getBankAccountApi();
            }
          }
        } else {
          // await props.editBankAccount(bankItemId, addNewParams);
          await setLoader(false);
          try {
            const { status } = await axiosInstance.put(URLS.orgBankAccounts(bankItemId), addNewParams);
            if (status === 201) {
              await setLoader(false);
              // setInputValues({ routingNumber: '', accountNumber: '' });
              setApiMessage('success', OKTION_VARIABLE?.OKTION_BANK_DETAILS_UPDATE);
              // props.setActionMessage(true, 'Success', OKTION_VARIABLE?.OKTION_BANK_DETAILS_UPDATE);
              setTimeout(() => {
                // props.setActionMessage(false);
                props.toggleEvent === true && props.toggleModal();
              }, 4000);
              setIsEdit(false);
              props.getBankAccountApi();
              props.ToggleForm();
            }
          } catch (err) {
            await setLoader(false);
            if (err?.response?.data?.msg === 'ERR_BANK_ACCOUNT_ALREADY_EXIST') {
              setApiMessage('error', OKTION_VARIABLE?.OKTION_BANK_ACCOUNT_EXIST);
              // props.setActionMessage(true, 'Error', OKTION_VARIABLE?.OKTION_BANK_ACCOUNT_EXIST);
              setTimeout(() => {
                // props.setActionMessage(false);
                props.toggleEvent === true && props.toggleModal();
              }, 4000);
              // props.ToggleForm();
              setInputValues({ inputValues });
              // props.getBankAccountApi();
            }
          }
          // props.setActionMessage(true, 'Success', 'Banking details updated successfully');
          /*  setTimeout(() => {
              props.setActionMessage(false);
            }, 4000); */
        }
      }
    });
    // .catch(async err => {
    //   setErrors({ message: err });
    //   await setLoader(false);
    // });
    // await setLoader(false);
  };

  const AddNew = async () => {
    if (isNewOrg) {
      let { status } = await createDraftOrg();
      if (status) {
        createStripeToken('new');
      }
    } else {
      createStripeToken('new');
    }
  };

  const validate = () => {
    setErrors({});
    /*   setActionMessage(); */
    let values = { ...inputValues, currency: selectedCurrency?.value };

    let flag = true;
    if (!values?.accountNumber || values?.accountNumber === '') {
      flag = false;
      Errors.accountNumber = OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE;
    } else {
      Errors.accountNumber = '';
    }

    if (!values?.routingNumber || values?.routingNumber === '') {
      flag = false;
      Errors.routingNumber = OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE;
    } else {
      Errors.routingNumber = '';
    }

    if (typeof values?.currency === 'undefined' || values?.currency === '') {
      flag = false;
      Errors.currency = OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE;
    } else {
      Errors.currency = '';
    }

    // if (typeof values?.accountHolderName === 'undefined' || values?.accountHolderName === '') {
    //   flag = false;
    //   Errors.accountHolderName = 'Account holder name is required';
    // } else {
    //   Errors.accountHolderName = '';
    // }

    if (typeof values?.beneficiaryname === 'undefined' || values?.beneficiaryname === '') {
      flag = false;
      Errors.beneficiaryname = OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE;
    } else {
      Errors.beneficiaryname = '';
    }

    if (typeof values?.beneficiaryaddress === 'undefined' || values?.beneficiaryaddress === '') {
      flag = false;
      Errors.beneficiaryaddress = OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE;
    } else {
      Errors.beneficiaryaddress = '';
    }

    if (typeof values?.bankname === 'undefined' || values?.bankname === '') {
      flag = false;
      Errors.bankname = OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE;
    } else {
      Errors.bankname = '';
    }

    setErrors({ ...Errors, Errors });
    if (flag && props?.CreateNew === true) {
      AddNew();
    } else if (flag) {
      handleEditBankAccount();
    }
    return flag;
  };
  return (
    <div className="cardInfo">
      {/* {message.display && (
        <div className="btn-block">
          <ActionMessage message={message.message} type={message.type} styleClass={message.styleClass} />
        </div>
      )} */}
      {loading && <PageLoader />}
      {loader && <PageLoader />}
      <FormGroup>
        <SelectBoxWithFlag
          {...props}
          selectOptions={[...getCurrencyOptions()]}
          selectedValue={selectedCurrency}
          onChange={onSelectFlag}
          disabled={props.is_view === true ? true : true}
          className="selectBorder currencySelect"
          placeholder={OKTION_VARIABLE?.OKTION_SELECT_CURRENCY}
        />
        {Errors?.currency !== '' && (
          <FormText className="text-danger" color="error">
            {Errors?.currency}
          </FormText>
        )}
      </FormGroup>
      <div className="row mt-2">
        <div className="col-sm-12">
          <FormGroup
            className={
              watch('beneficiaryname') !== '' && watch('beneficiaryname') !== undefined
                ? ' mb-3 focus_field fixed-fieldset'
                : ' mb-3 focus_field '
            }>
            <input
              className={`form-control ${errors.firstName && 'errorColor'}`}
              name="beneficiaryname"
              {...register('beneficiaryname', {
                onChange: e => {
                  handleInputChange('beneficiaryname', e.target.value);
                },
              })}
              type="text"
              placeholder={OKTION_VARIABLE?.OKTION_BENEFICIARY_FULLNAME}
              // onChange={e => {
              //   handleInputChange('beneficiaryname', e.target.value);
              // }}
            />
            <Label className="label-helper">{OKTION_VARIABLE?.OKTION_BENEFICIARY_FULLNAME}</Label>
            {Errors?.beneficiaryname !== '' && (
              <FormText className="text-danger" color="error">
                {Errors?.beneficiaryname}
              </FormText>
            )}
          </FormGroup>
        </div>

        <div className="col-sm-12">
          <FormGroup
            className={
              watch('beneficiaryaddress') !== '' && watch('beneficiaryaddress') !== undefined
                ? ' mb-3 focus_field fixed-fieldset'
                : ' mb-3 focus_field '
            }>
            <input
              className={`form-control ${errors.firstName && 'errorColor'}`}
              name="beneficiaryaddress"
              {...register('beneficiaryaddress', {
                onChange: e => {
                  handleInputChange('beneficiaryaddress', e.target.value);
                },
              })}
              type="text"
              placeholder={OKTION_VARIABLE?.OKTION_BENEFICIARY_ADDRESS}
              // onChange={e => {
              //   handleInputChange('beneficiaryaddress', e.target.value);
              // }}
            />
            <Label className="label-helper">{OKTION_VARIABLE?.OKTION_BENEFICIARY_ADDRESS}</Label>
            {Errors?.beneficiaryaddress !== '' && (
              <FormText className="text-danger" color="error">
                {Errors?.beneficiaryaddress}
              </FormText>
            )}
          </FormGroup>
        </div>

        <div className="col-sm-12">
          <FormGroup
            className={
              watch('accountNumber') !== '' && watch('accountNumber') !== undefined
                ? ' mb-3 focus_field fixed-fieldset'
                : ' mb-3 focus_field '
            }>
            <input
              className={`form-control ${errors.firstName && 'errorColor'}`}
              name="accountNumber"
              {...register('accountNumber', {
                onChange: e => {
                  handleInputChange('accountNumber', e.target.value);
                },
              })}
              type="text"
              placeholder={OKTION_VARIABLE?.OKTION_ACCOUNT_NUMBER}
              // onChange={e => {
              //   handleInputChange('accountNumber', e.target.value);
              // }}
            />
            {/* <input
              type="text"
              value={inputValues.accountNumber}
              placeholder="Account Number"
              name="accountnumber"
              onChange={e => {
                handleInputChange('accountNumber', e.target.value);
              }}
            /> */}
            <Label className="label-helper">{OKTION_VARIABLE?.OKTION_ACCOUNT_NUMBER}</Label>
            {Errors?.accountNumber !== '' && (
              <FormText className="text-danger" color="error">
                {Errors?.accountNumber}
              </FormText>
            )}
          </FormGroup>
        </div>

        <div className="col-sm-12">
          <FormGroup
            className={
              watch('routingnumber') !== '' && watch('routingnumber') !== undefined
                ? ' mb-3 focus_field fixed-fieldset'
                : ' mb-3 focus_field '
            }>
            <input
              className={`form-control ${errors.firstName && 'errorColor'}`}
              name="routingnumber"
              {...register('routingnumber', {
                onChange: e => {
                  handleInputChange('routingNumber', e.target.value);
                },
              })}
              type="text"
              placeholder={OKTION_VARIABLE?.OKTION_ROUTING_NUMBER}
              // onChange={e => {
              //   handleInputChange('routingNumber', e.target.value);
              // }}
            />
            <Label className="label-helper">{OKTION_VARIABLE?.OKTION_ROUTING_NUMBER}</Label>
            {Errors?.routingNumber !== '' && (
              <FormText className="text-danger" color="error">
                {Errors?.routingNumber}
              </FormText>
            )}
          </FormGroup>
        </div>

        <div className="col-sm-12">
          <FormGroup
            className={
              watch('bankname') !== '' && watch('bankname') !== undefined
                ? ' mb-3 focus_field fixed-fieldset'
                : ' mb-3 focus_field '
            }>
            <input
              className={`form-control ${errors.firstName && 'errorColor'}`}
              name="bankname"
              {...register('bankname', {
                onChange: e => {
                  handleInputChange('bankname', e.target.value);
                },
              })}
              type="text"
              placeholder={OKTION_VARIABLE?.OKTION_BANK_NAME}
              // onChange={e => {
              //   handleInputChange('bankname', e.target.value);
              // }}
            />
            <Label className="label-helper">{OKTION_VARIABLE?.OKTION_BANK_NAME}</Label>
            {Errors?.bankname !== '' && (
              <FormText className="text-danger" color="error">
                {Errors?.bankname}
              </FormText>
            )}
          </FormGroup>
        </div>

        {/* <div className="col-sm-12">
          <FormGroup
            className={
              watch('accountHolderName') !== '' && watch('accountHolderName') !== undefined
                ? ' mb-3 focus_field fixed-fieldset'
                : ' mb-3 focus_field '
            }>
            <input
              className={`form-control ${errors.firstName && 'errorColor'}`}
              name="accountHolderName"
              {...register}
              type="text"
              placeholder="Account holder name"
              onChange={e => {
                handleInputChange('accountHolderName', e.target.value);
              }}
            />
            <Label className="label-helper">Account holder name</Label>
            {Errors?.accountHolderName !== '' && (
              <FormText className="text-danger" color="error">
                {Errors?.accountHolderName}
              </FormText>
            )}
          </FormGroup>
        </div> */}
        {Errors?.message !== '' && (
          <div className="col-sm-12">
            <FormGroup className="focus_field mb-1">
              <small className="text-danger">{Errors?.message}</small>
            </FormGroup>
          </div>
        )}
        <Col sm="12" className="ps-1 pe-1 pt-1">
          <div className="d-flex justify-content-end border-top pt-2 pe-2">
            {props?.CreateNew === true ? (
              <img onClick={props.ToggleForm} className="me-2 cursor-pointer" src={images.deleteWithCircle} alt="" />
            ) : (
              <img
                onClick={() => handleDelete(props?.item.id)}
                className={(isEdit ? 'me-2 ' : '') + ' cursor-pointer'}
                src={images.deleteWithCircle}
                alt=""
              />
            )}
            {(isEdit || props?.CreateNew === true) && (
              <img onClick={validate} className="cursor-pointer" src={images.circleWithYellowTick} alt="" />
            )}
          </div>
        </Col>
      </div>
    </div>
  );
};

const mapStateToProps = ({ BankAccountsReducer }) => {
  return {
    bankAccountsList: BankAccountsReducer.bankAccounts,
  };
};

export default connect(mapStateToProps, { deleteBankAccount, editBankAccount })(BankingDetailsItem);
