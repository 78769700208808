import React, { useState, useContext, useEffect } from 'react';
import { Button, Col, Row, Modal, ModalBody } from 'reactstrap';
import EventTimeDetails from '../EventTimeDetails';
import { images } from '../../../../library/common/constants/ImageConstants';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../../library/common/constants/UrlConstants';
import axiosInstance from '../../../../main/axios';
import Config from 'utility/Config';
import QRCode from 'react-qr-code';
// import ActionMessage from '../../../../library/common/components/ActionMessage';
import { setApiMessage } from '../../../../library/common/constants/function';
import { ReactComponent as Closed } from '../../../../resources/images/close.svg';
import LogoutModal from '../Raffle/LogoutModal';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import anchorme from 'anchorme';
import PageLoader from '../../../../library/common/components/PageLoader';
import { SocketContext } from 'main/context/socket';

const About = props => {
  const params = useParams();
  const navigate = useNavigate();
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const [isToggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [qrModal, setQrModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  // const [message, setMessage] = useState({
  //   display: false,
  //   type: '',
  //   message: '',
  // });
  const handleClick = () => {
    if (!props?.isLoggedIn) {
      setToggle(true);
    }
    if (props?.isLoggedIn) {
      handleAddToCalendar();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  const handleAddToCalendar = async () => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.post(URLS.addToCalender(props?.eventDetails?.id));
      if (status === 200 || status === 201) {
        // setActionMessage(true, 'Success', OKTION_VARIABLE?.OKTION_EVENT_ADDED_IN_CALENDER);
        setApiMessage('success', OKTION_VARIABLE?.OKTION_EVENT_ADDED_IN_CALENDER);
        setTimeout(() => {
          navigate(`/my-event-page`);
        }, 4000);
        setLoading(false);
      }
    } catch (err) {
      if (err?.response?.status === 403 && err?.response?.data?.msg === OKTION_VARIABLE?.OKTION_ALREADY_ADDED) {
        // setActionMessage(true, 'Error', err?.response?.data?.msg);
        setApiMessage('error', err?.response?.data?.msg);
        // setTimeout(() => {
        //   setActionMessage(false);
        // }, 4000);
        setLoading(false);
      }
    }
    setLoading(false);
  };
  const toggle = () => {
    setToggle(!isToggle);
  };
  const handleButtonClick = () => {
    navigate('/login', {
      state: {
        redirectTo: 'event-details',
        eventId: params?.id,
      },
    });
  };
  // const setActionMessage = (display = false, type = '', message = '') => {
  //   setMessage({
  //     display,
  //     type,
  //     message,
  //   });
  // };
  const venueAddress = [
    props?.eventDetails?.venue?.addressLine1,
    props?.eventDetails?.venue?.addressLine2 + ',',
    <br />,
    props?.eventDetails?.venue?.city + ' ',
    props?.eventDetails?.venue?.state + ' ',
    props?.eventDetails?.venue?.country + ' ',
    props?.eventDetails?.venue?.postCode,
  ];

  return (
    <>
      {/* {message.display && (
        <div className="btn-block mt-2">
          <ActionMessage message={message.message} type={message.type} styleClass={message.styleClass} />
        </div>
      )} */}
      {loading && <PageLoader />}
      <Row className="res-org-about">
        <Col md="8" lg="9">
          <h4 className="pt-4 mb-3">{OKTION_VARIABLE?.OKTION_ABOUT_EVENT}</h4>
          <p className="fs-16 font-Segoe_UI">
            {/* {props?.eventDetails?.eventAbout} */}
            {props?.eventDetails?.eventAbout != null ? (
              <div>
                {parse(
                  anchorme({
                    input: (props?.eventDetails?.eventAbout).toString().replace(/\n/g, '<br/>'),
                    options: {
                      attributes: {
                        target: '_blank',
                        class: 'detected',
                      },
                    },
                  }),
                )}
              </div>
            ) : (
              ''
            )}
            {/* UNIQUE STONE HOMESTEAD HIDEAWAY on 220 Acres of Black Pine and Cypresses. Walk through bush paths for miles
            without meeting a soul. Climb the ridge for sunset views or just laze around the many comfy areas around the
            spacious grounds. The valley of Worondi Creek is hidden away between the ridge and Magnificent Mount Dangar
            that can be seen from the house and front lawn. It's an absolute hidden gem.The house is locally quarried
            stone build by hand by a previous owner. It has 3 bedrooms and can sleep 8 guests with a double mattress on
            veranda. Kitchen is new and modern with Miele dishwasher and induction cook top and full of crockery. Main
            bedroom has a massive open fireplace. Lounge has 2 couches and a barrel chair. Large TV with selection of DVDs
            ( no TV reception though...but hey it's wilderness ;). Dining area with 6 chairs and comfy sitting area with a
            slow combustion fireplace with cooktop for teapot. There is an entertainment area behind and in front of the
            house.
            <br />
            House has a stone water reservoir (not for swimming please) and is on rainwater.The property has 2 major
            walking trails through property heading up the ridge and will be a paradise for nature lovers. Front lawn has
            a bird feeder that attracts local birds and cockatoos. Closest town ,Merriwa is a farming and horse industry
            town with a pub, bakery many cafes as well as IGA and a hardware store. */}
          </p>
        </Col>
        <Col md="4" lg="3" className="pt-4 mt-3 mb-3">
          <div className="event-qr-wrap pt-lg-4 pb-3 border-bottom mb-3 d-flex hide-xs">
            <QRCode id="QRCode" size={77} value={`${Config.socketUrl}event-details/${params?.id}`} />
            <div className="qr-content ps-3 d-flex flex-column justify-content-center">
              <h4 className="pb-2">{OKTION_VARIABLE?.OKTION_QR}</h4>
              <span
                className="cursor-pointer"
                onClick={() => {
                  setQrModal(!qrModal);
                }}>
                {OKTION_VARIABLE?.OKTION_LARGE_VIEW}
              </span>
            </div>
          </div>
          {props?.timeZone !== undefined &&
            (props?.eventDetails?.location === 1 || props?.eventDetails?.location === 3) && (
              <EventTimeDetails
                img={images.watch}
                title={OKTION_VARIABLE?.OKTION_LEFTFILTER_EVENTDATE}
                // address="80 Pyrmont St,Pyrmont NSW 2009"
                // address={
                //   props?.eventDetails?.location === 1
                //     ? moment(props?.eventDetails?.endDate).format('YYYY-MM-DD h:mm a')
                //     : moment(props?.eventDetails?.venueEndDate).format('YYYY-MM-DD h:mm a')
                // }
                startDate={moment(props?.eventDetails?.startDate).format('DD/MM/YYYY hh:mm A')}
                endDate={moment(props?.eventDetails?.endDate).format('DD/MM/YYYY hh:mm A')}
                // startDate={
                //   props?.eventDetails?.location === 1
                //     ? moment(props?.eventDetails?.startDate).utcOffset(props?.timeZone).format('DD/MM/YYYY hh:mm A')
                //     : moment(props?.eventDetails?.venueStartDate).utcOffset(props?.timeZone).format('DD/MM/YYYY hh:mm A')
                // }
                // endDate={
                //   props?.eventDetails?.location === 1
                //     ? moment(props?.eventDetails?.endDate).utcOffset(props?.timeZone).format('DD/MM/YYYY hh:mm A')
                //     : moment(props?.eventDetails?.venueEndDate).utcOffset(props?.timeZone).format('DD/MM/YYYY hh:mm A')
                // }
                isLocation={false}
              />
            )}
          {props?.eventDetails?.location !== 1 && (
            <EventTimeDetails
              title={OKTION_VARIABLE?.OKTION_VENUE_LOCATION}
              isLocation
              img={images.location}
              endDate={moment(props?.eventDetails?.venueEndDate).format('DD/MM/YYYY hh:mm A')}
              startDate={moment(props?.eventDetails?.venueStartDate).format('DD/MM/YYYY hh:mm A')}
              // address="80 Pyrmont St,Pyrmont NSW 2009"
              address={venueAddress}
              secondaryTitle={OKTION_VARIABLE?.OKTION_LEFTFILTER_VENUE}
            />
          )}

          {props?.eventDetails?.location === 3 && (
            <p className="ms-5 ps-2 fw-500">+ &nbsp; {OKTION_VARIABLE?.OKTION_ONLINE_EVENT}</p>
          )}
          <Button
            className="main-button btn-block mb-3 mt-4 fs-20 fw-500 h-60"
            disabled={props.whitelabel !== true ? false : true}
            style={{
              backgroundColor: props?.eventDetails?.primaryColour ? props?.eventDetails?.primaryColour : '',
              borderColor: props?.eventDetails?.primaryColour ? props?.eventDetails?.primaryColour : '',
              color: props?.eventDetails?.primaryTextColour ? props?.eventDetails?.primaryTextColour : '',
            }}
            onClick={handleClick}>
            {OKTION_VARIABLE?.OKTION_ADD_TO_CALENDER}
          </Button>
        </Col>
        <Col sm="12">
          <hr />
        </Col>
        <LogoutModal isToggle={isToggle} toggle={toggle} handleClick={handleButtonClick} />

        <Modal
          size="xl"
          className="modal-dialog qr-modal"
          isOpen={qrModal}
          style={{ maxWidth: '406px', margin: '0 auto' }}
          centered>
          <Closed
            className="model-close"
            onClick={() => {
              setQrModal(!qrModal);
            }}
          />
          <div className="text-center pb-3 mb-1 pt-3 mt-1">
            <h4> {OKTION_VARIABLE?.OKTION_SCANE_QR}</h4>
          </div>
          <ModalBody className="text-center">
            <QRCode
              id="QRCode"
              size={width > 599 ? 366 : width > 375 ? 314 : 220}
              value={`${Config.socketUrl}event-details/${params?.id}`}
            />
          </ModalBody>
        </Modal>
      </Row>
    </>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};

export default connect(mapStateToProps, {})(About);
