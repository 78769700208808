import React from 'react';
import { FormGroup } from 'reactstrap';

const FormGroupComponent = ({ children, className, ...rest }) => {
  return (
    <FormGroup className={`form-group ${className}`} {...rest}>
      {children}
    </FormGroup>
  );
};

export default FormGroupComponent;
