import * as actionTypes from '../constants/StoreConstants';
import _ from 'lodash';

const initialState = {
  userCards: [],
  verifyCode: '',
  cartItemList: [],
  // cardErrorMessage: '',
  lastCardAddData: '',
  userCardData: '',
  checkoutCardData: '',
  // apiCallStatus: true,
  isCardaddAtPaymentTime: true,
};

const UserCardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.storeConstants.GET_USER_CARDS:
      return {
        ...state,
        userCards: action.payload,
      };
    case actionTypes.storeConstants.VERIFY_COUPON_CODE:
      return {
        ...state,
        verifyCode: action.payload,
      };
    case actionTypes.storeConstants.CART_DETAIL:
      return {
        ...state,
        cartItemList: action.payload,
      };
    case actionTypes.storeConstants.CREATE_USER_CARD:
      return {
        ...state,
        userCards: [...state.userCards, action.payload],
        lastCardAddData: action.payload,
      };
    case actionTypes.storeConstants.SET_CHECKOUT_CARD_DATA:
      return {
        ...state,
        userCardData: action.payload,
      };
    case actionTypes.storeConstants.SET_SINGLE_CARD_DATA:
      return {
        ...state,
        checkoutCardData: action.payload,
      };
    case actionTypes.storeConstants.SET_CARD_DETAILS_BY_ID:
      return {
        ...state,
        cartItemList: action.payload,
      };
    case actionTypes.storeConstants.SET_FLAGFOR_CARD_ADD:
      return {
        ...state,
        isCardaddAtPaymentTime: action.payload,
      };
    // case actionTypes.storeConstants.CREATE_USER_CARD_ERROR:
    //   console.log('CREATE_USER_CARD_ERROR', action.payload);
    //   return {
    //     ...state,
    //     cardErrorMessage: action.payload,
    //   };
    case actionTypes.storeConstants.UPDATE_USER_CARD:
      return {
        ...state,
        userCards: [
          ...state.userCards?.map(cardItem => {
            if (action.payload.id === cardItem?.id) {
              return { ...cardItem, ...action.payload?.card };
            }
            return cardItem;
          }),
        ],
      };
    case actionTypes.storeConstants.DELETE_USER_CARDS: {
      const data = _.reject(_.get(state, 'userCards'), c => c.card.id === action.payload);
      return { ...state, userCards: data };
    }
    default:
      return state;
  }
};

export default UserCardsReducer;
