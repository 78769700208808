// require('dotenv').config();

let BaseURL = 'https://ok2.staging.oktion.com.au/api/apricot';
let socketUrl = 'https://ok2.staging.oktion.com.au/';
let socketPath = '/api/socket.io/';
let stripeAPIKEY = 'pk_test_lsIxZ0GG145LRH5iWkrRS2I7';
let stripeAPIKEYDK = 'sk_test_19x6FmxGNsAoTcPs9CCCmkkM00cgzceAer';
//let imageURL = 'https://oktion2-testing.s3.amazonaws.com/public_asset/';
let imageURL = 'http://oktion-dev.s3.amazonaws.com/public_asset/';
let version = '2.1.1';
let oneSignalAppId = 'a82283bd-ec6b-4854-b062-2fa55e07e22d';
let apiVersion = '2.0.0';

export default {
  BaseURL: process.env.REACT_APP_API_URL || BaseURL,
  DeleteDelay: 3000,
  DefaultLimit: 10,
  stripeAPIKEY: process.env.REACT_APP_STRIPE_APIKEY_PK || stripeAPIKEY,
  stripeAPIKEYDK: process.env.REACT_APP_STRIPE_APIKEY_SK || stripeAPIKEYDK,
  imageURL: process.env.REACT_APP_IMAGE_URL_CONSTANT || imageURL,
  version: process.env.REACT_APP_VERSION || version,
  apiVersion: process.env.REACT_API_VERSION || apiVersion,
  appProtocol: process.env.REACT_APP_APP_PROTOCOL,
  socketUrl: process.env.REACT_APP_API_SOCKET_BASE_URL || socketUrl,
  socketPath: process.env.REACT_APP_API_SOCKET_PATH || socketPath,
  oneSignalAppId: process.env.REACT_ONESIGNAL_APP_ID || oneSignalAppId,
};
