import * as actionTypes from '../constants/StoreConstants';

const initialState = {
  subscription: [],
};

const SubscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.storeConstants.ADD_CART:
      return {
        ...state,
        subscription: action.payload,
      };
    default:
      return state;
  }
};

export default SubscriptionReducer;
