import React, { useState, useEffect, useContext } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
// import Auction from './Frames/Auction/Auction';
// import About from './Frames/About/About';
// import Donations from './Frames/Donation/Donation';
// import Raffle from './Frames/Raffle/Raffle';
// import Sponsors from './Frames/Sponsors/Sponsors';
// import Tickets from './Frames/Tickets/Tickets';
// import { URLS } from '../../../../../library/common/constants/UrlConstants';
// import { fetchFromStorage } from '../../../../../utility';
// import { identifiers } from '../../../../../library/common/constants/IdentifierConstants';
import PageLoader from '../../../../../library/common/components/PageLoader';
// import axiosInstance from '../../../../../main/axios';
import '../../styles.scss';
import EventCard from '../../../../../library/common/components/EventCard';
import { SocketContext } from 'main/context/socket';

import { useNavigate } from 'react-router-dom';
const TabWrapper = props => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const [activeTab, setActiveTab] = useState('1');
  const [loading, setloader] = useState(false);
  //   const [eventFeatures, seteventFeatures] = useState([]);

  const navigate = useNavigate();
  const [eventTabValue, setEventTabValue] = useState([
    { id: 1, name: 'Active Events' },
    { id: 2, name: 'Past Events' },
  ]);
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  useEffect(() => {
    // getEventDetailsById();
  }, []);
  //   const getEventDetailsById = async () => {
  //     const eventDetails = fetchFromStorage(identifiers.eventDetails);
  //     setloader(true);
  //     try {
  //       const { status, data } = await axiosInstance.get(URLS.getFeatureByEventId(eventDetails?.id));
  //       setloader(false);
  //       if (status === 200 || status === 304) {
  //         seteventFeatures(data?.data?.eventFeatures);
  //       }
  //     } catch (err) {
  //       setloader(false);
  //     }
  //   };
  //   console.log('eventFeatures', props.white_lable, props.preview_color, props?.eventDetails);
  const handleButtonClick = event => {
    navigate(`/event-details/${event?.id}`);
  };

  return (
    <div className="eventDetailsTab organization-profile-event">
      <Row>
        <Col>{loading && <PageLoader />}</Col>
      </Row>
      <Nav tabs className={props.white_label ? props.preview_color : 'event-tab border-bottom border-top'}>
        {eventTabValue.map((item, i) => (
          <NavItem className={`${activeTab === `${item.id}` && 'active'}`}>
            <NavLink
              className={props.white_label ? props.preview_color : ''}
              style={{ color: props.white_label ? props.preview_color : '' }}
              onClick={() => {
                toggle(`${item.id}`);
              }}>
              {item.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          {props?.organizationActiveEvent?.length > 0 ? (
            <div className="col-sm-12 p-0">
              {props?.organizationActiveEvent &&
                props?.organizationActiveEvent.map((item, index) => (
                  <>
                    <div onClick={() => handleButtonClick(item)} style={{ cursor: 'pointer' }}>
                      <EventCard key={index} image={item.image} event={item} isHorizontal isManaged={false} />
                    </div>
                  </>
                ))}
            </div>
          ) : (
            <h3 className="pt-4">{OKTION_VARIABLE?.OKTION_NO_EVENT}</h3>
          )}
        </TabPane>
        <TabPane tabId="2">
          {props?.organizationPastEvent?.length > 0 ? (
            <div className="col-sm-12">
              {props?.organizationPastEvent &&
                props?.organizationPastEvent.map((item, index) => (
                  <>
                    <div onClick={() => handleButtonClick(item)} style={{ cursor: 'pointer' }}>
                      <EventCard key={index} image={item.image} event={item} isHorizontal isManaged={false} />
                    </div>
                  </>
                ))}
            </div>
          ) : (
            <h3 className="pt-4">{OKTION_VARIABLE?.OKTION_NO_EVENT}</h3>
          )}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default TabWrapper;
