import React, { useState, useContext, useEffect } from 'react';
import { Col, Row, Table } from 'reactstrap';
import _ from 'lodash';
import { AdminData } from '../AdminData';
import { images } from '../../../../../library/common/constants/ImageConstants';
import axiosInstance from '../../../../../main/axios';
import { URLS } from '../../../../../library/common/constants/UrlConstants';
import { handleByRole } from '../../../../../library/common/actions/AdminActions';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageLoader from '../../../../../library/common/components/PageLoader';
import ConfirmationModal from './ConfirmationModal';
import { SocketContext } from '../../../../../main/context/socket';
import { setApiMessage } from 'library/common/constants/function';

const AdminsTab = props => {
  // const orgId = useParams();
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const orgId = useParams();
  // const [data, setData] = useState(AdminData);
  const [loading, setLoading] = useState(false);
  const [isToggle, setToggle] = useState(false);
  const [userId, setUserId] = useState('');
  const [isFilter, setFilter] = useState(false);
  const [adminList, setAdminList] = useState('');
  useEffect(() => {
    // props.handleByRole(orgId.id, 'manager');
    setAdminList(props.userlist);
  });

  // const toggleBundle = idx => {
  //   let auctionListTableData = [...AdminData];
  //   for (let i = 0; i < auctionListTableData.length; i++) {
  //     if (idx === auctionListTableData[i].id) {
  //       auctionListTableData[i].isCollapse = !auctionListTableData[i].isCollapse;
  //     }
  //   }
  //   setData(auctionListTableData);
  // };

  // useEffect(() => {
  //   alert();
  //   setAdminList(props.userlist);
  //   console.log('props.userlist', props.userlist);
  // }, []);

  const handleDeleteUser = async id => {
    setLoading(true);
    try {
      const { status } = await axiosInstance.put(URLS.removeOrgUser(id));
      if (status === 201) {
        setLoading(false);
        setToggle(false);
        // props.handleByRole(orgId.id, 'admin');
        // props.handleByRole(orgId.id, 'manager');
        if (props.activeTab === '1') {
          props.handleByRole(orgId.id, 'admin');
        } else {
          props.handleByRole(orgId.id, 'manager');
        }
      }
    } catch (err) {
      setLoading(false);
      setToggle(false);
      setApiMessage('error', err.response.data.msg);
    }
  };
  const toggle = id => {
    setToggle(!isToggle);
    setUserId(id);
  };
  const handleSort = () => {
    // setFilter(!isFilter);
    // let lowerCaseUsers = _.clone(props.userlist);
    // console.log('lowerCaseUsers here', lowerCaseUsers);
    // lowerCaseUsers = lowerCaseUsers.map(user => {
    //   console.log('useruser', user?.user);
    //   let aaa = user?.map(User => {
    //     console.log('USERUSER', User);
    //   });
    // let users = user?.user
    // user?.user?.firstName = user?.user?.firstName.toLowerCase();
    //   return user;
    // });
    // const sortedUsers = _.orderBy(lowerCaseUsers, ['firstName'], [isFilter ? 'desc' : 'asc']);
    // console.log('sortedUsers', sortedUsers)
    // setAdminList(sortedUsers);
  };

  return (
    <div className="invitedTable">
      {loading && <PageLoader />}
      <Row>
        <Col sm="12">
          {props.userlist && props.userlist.length < 1 && (
            <h6 className="mt-3 mb-3">{OKTION_VARIABLE?.OKTION_NODATA_FOUND}</h6>
          )}
          {adminList && adminList.length > 0 && (
            <Table className="invited-table">
              <thead>
                <tr>
                  <th className="invite-table-namecol">
                    {OKTION_VARIABLE?.OKTION_FULL_NAME}
                    {/* <img onClick={handleSort} className={`${isFilter && 'down'}`} src={images.DropdownDown} alt="" /> */}
                  </th>
                  <th>{OKTION_VARIABLE?.OKTION_EMAIL_ADDRESS}</th>
                  <th className="invite-table-statuscol">{OKTION_VARIABLE?.OKTION_INVITATION_STATUS}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {props.userlist.map((item, i) => (
                  <tr key={i}>
                    <td className="fullname-col">
                      <span>{item?.user?.firstName && item?.user?.firstName}</span>{' '}
                      <span>{item?.user?.lastName && item?.user?.lastName}</span>
                    </td>
                    <td className="email-col">{item.user.email}</td>
                    <td
                      className={`${
                        item.status === 'invited'
                          ? 'text-merigold status-col'
                          : item.status === 'declined'
                            ? 'text-red status-col'
                            : 'text-active status-col'
                      }`}>
                      {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                    </td>
                    <td className="text-end">
                      <img
                        onClick={() => toggle(item.id)}
                        className="cursor-pointer closeWithCircleIcon"
                        src={images.closeWithCircle}
                        alt=""
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
      <ConfirmationModal
        isToggle={isToggle}
        toggle={toggle}
        orgName={props?.organizationByIdData?.data?.organisations?.name}
        role={props.role}
        handleSubmitForm={() => handleDeleteUser(userId)}
      />
    </div>
  );
};

const mapStateToProps = ({ OrganizationReducer, adminReducer }) => {
  return {
    userList: adminReducer.userList,
    organizationByIdData: OrganizationReducer.organizationList,
  };
};

export default connect(mapStateToProps, { handleByRole })(AdminsTab);
