import { storeConstants } from '../constants/StoreConstants';
import axiosInstance from '../../../main/axios';
import { URLS } from '../constants/UrlConstants';
// import { fetchFromStorage } from '../../../utility';
// import { identifiers } from '../../../library/common/constants/IdentifierConstants';

export const handleByRole = (id, role) => dispatch => {
  // const user_id = fetchFromStorage(identifiers.userDetail);
  // console.log('action',user_id);
  return axiosInstance
    .get(URLS.organizationByRole(id, role))
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: storeConstants.GET_ROLE,
          payload: data,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: storeConstants.GET_ROLE,
        payload: [],
      });
    });
};
