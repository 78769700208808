import React, { useContext } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { images } from '../../../../../../../../../library/common/constants/ImageConstants';
import { SocketContext } from '../../../../../../../../../main/context/socket';

const NoOrgModal = ({ isToggle, toggle, handleClick, resetForm, handleSubmit }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  // console.log('isToggle', isToggle);
  return (
    <div>
      <Modal
        size="xl"
        className="modal-dialog add-price-modal logout-modal delete-event-Modal delete-lot-modal"
        isOpen={isToggle}>
        <div className="text-end pe-3">
          <img className="cursor-pointer" src={images.close} alt="close" onClick={toggle} />
        </div>{' '}
        <ModalBody>
          <p className="pt-4 pb-4 title">{OKTION_VARIABLE?.OKTION_SURE_WANT_TO_DELETE}</p>
          <div className=" d-flex justfycon justify-content-center">
            <Button className="app-button black-button me-2 h-60" onClick={toggle}>
              {OKTION_VARIABLE?.OKTION_KEEP}
            </Button>
            <Button className="app-button invite-button me-2 h-60" onClick={handleSubmit}>
              {OKTION_VARIABLE?.OKTION_DELETE_LOT}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default NoOrgModal;
