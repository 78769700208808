import React, { useState, useEffect, useContext } from 'react';
import { Button, Col, Row } from 'reactstrap';
import '../../../../EventDetails/styles.scss';
import axiosInstance from '../../../../../main/axios';
// import { fetchFromStorage } from '../../../../../utility';
import { URLS } from '../../../../../library/common/constants/UrlConstants';
import TabWrapper from './TabWrapper';
// import { identifiers } from '../../../../../library/common/constants/IdentifierConstants';
import '../../styles.scss';
import PageLoader from '../../../../../library/common/components/PageLoader';
import moment from 'moment';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LogoutModal from './../../../../EventDetails/Frames/Raffle/LogoutModal';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import anchorme from 'anchorme';
// import ActionMessage from '../../../../../library/common/components/ActionMessage';
import { setApiMessage } from '../../../../../library/common/constants/function';
import { SocketContext } from 'main/context/socket';

import { ReactComponent as SocialFacebook } from '../../../../../resources/images/social_facebook.svg';
import { ReactComponent as SocialInstagram } from '../../../../../resources/images/social_instagram.svg';
import { ReactComponent as SocialYoutube } from '../../../../../resources/images/social_youtube.svg';
import { ReactComponent as SocialTwitter } from '../../../../../resources/images/social_twitter.svg';
import { ReactComponent as SocialLinkedin } from '../../../../../resources/images/social_linkedin.svg';
import { ReactComponent as SocialPintrest } from '../../../../../resources/images/social_pintrest.svg';
import { ReactComponent as SocialCustom } from '../../../../../resources/images/social_custom.svg';

function isFutureDate(idate) {
  var today = new Date().getTime(),
    idate = idate.split('/');

  idate = new Date(idate[2], idate[1] - 1, idate[0]).getTime();
  var currentDateTS = new Date();
  var currentDate = moment(currentDateTS).format('DD/MM/YYYY');
  var selectedDate = moment(idate).format('DD/MM/YYYY');
  return today - idate < 0 || selectedDate === currentDate ? true : false;
}

const OrganiserProfile = props => {
  const params = useParams();
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  var orgId = params?.id;
  const navigate = useNavigate();
  const location = useLocation();
  const eventId = location?.state?.eventId;

  const [eventOutfit, setEventOutfit] = useState([]);
  const [organizationDetail, setOrganizationStatus] = useState([]);
  const [organizationPastEvent, setOrganizationPastEvent] = useState([]);
  const [organizationActiveEvent, setOrganizationActiveEvent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isToggle, setToggle] = useState(false);
  const [organizationData, setOrganizationData] = useState([]);
  const [isorgLoading, setisorgLoading] = useState(false);
  // const [message, setMessage] = useState({
  //   display: false,
  //   type: '',
  //   message: '',
  // });
  // const setActionMessage = (display = false, type = '', message = '') => {
  //   setMessage({
  //     display,
  //     type,
  //     message,
  //   });
  // };
  useEffect(() => {
    eventId !== undefined && getEventDetailsById();
    getOrganizationDetails();
    getPublicOrganizationDetails();
  }, []);

  const toggle = () => {
    setToggle(!isToggle);
  };
  let eventDetails = location?.state?.event;
  const getPublicOrganizationDetails = async () => {
    // const eventDetails = fetchFromStorage(identifiers.eventDetails);
    setisorgLoading(true);
    // const orgId = location?.state?.orgId;
    try {
      const { status, data } = await axiosInstance.get(URLS.publicOrganizationList(orgId));
      if (status === 200 || status === 304 || status === 201) {
        var publishedEventList = data?.data?.organisations?.eventDetails.filter(item => item?.status === 'published');

        var PAST_EVENT_LIST = [];
        var ACTIVE_EVENT_LIST = [];
        publishedEventList.map(item => {
          var finish_date = moment(item?.endDate).format('DD/MM/YYYY');
          const isFutureDatevalid = isFutureDate(finish_date);
          if (!isFutureDatevalid) {
            PAST_EVENT_LIST.push(item);
          }
          if (isFutureDatevalid) {
            ACTIVE_EVENT_LIST.push(item);
          }
        });
        setOrganizationPastEvent(PAST_EVENT_LIST);
        setOrganizationActiveEvent(ACTIVE_EVENT_LIST);
        setOrganizationData(data?.data?.organisations);
        setisorgLoading(false);
        // setOrganizationStatus(data?.data);
      }
    } catch (err) {
      setisorgLoading(false);
    }
  };
  const handleButtonClick = () => {
    // const orgId = location?.state?.orgId;
    navigate('/login', {
      redirectTo: `organiser-profile`,
      eventId: eventId,
      orgId: orgId,
    });
  };
  const getOrganizationDetails = async () => {
    // const eventDetails = fetchFromStorage(identifiers.eventDetails);
    // const orgId = location?.state?.orgId;

    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.organizationDetail(orgId));
      if (status === 200 || status === 304 || status === 201) {
        setOrganizationStatus(data?.data);
        // setOrganizationPastEvent(data?.data?.events?.pastEvents);
        // setOrganizationActiveEvent(data?.data?.events?.activeEvents);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const getEventDetailsById = async () => {
    setLoading(true);
    // const eventDetails = fetchFromStorage(identifiers.eventDetails);
    const previewStatus = location?.state?.previewStatus;
    var prevStatus = previewStatus !== undefined ? previewStatus : 0;
    try {
      const { status, data } = await axiosInstance.get(URLS.getPublicEventByid(eventId, prevStatus));
      if (status === 200 || status === 304) {
        setLoading(false);
        setEventOutfit(data?.data?.events);
      }
    } catch (err) {}
  };
  const handleClick = async () => {
    // const orgId = location?.state?.orgId;
    if (!props?.isLoggedIn) {
      setToggle(true);
    }
    if (props?.isLoggedIn) {
      // var eventDetails = fetchFromStorage(identifiers.eventDetails);
      setLoading(true);
      if (organizationDetail?.isFollow) {
        try {
          const { status } = await axiosInstance.post(URLS.unfollowOrganization(orgId, { id: orgId }));
          if (status === 200 || status === 304 || status === 201) {
            setApiMessage('success', OKTION_VARIABLE?.OKTION_REMOVE_FOLLOWINFLIST);
            // setActionMessage(true, 'Success', 'The organiser was removed to following list');
            getOrganizationDetails();
            setLoading(false);

            // setTimeout(() => {
            //   setActionMessage(false);
            // }, 3000);
          }
        } catch (err) {
          setLoading(false);
        }
      } else {
        try {
          const { status } = await axiosInstance.post(URLS.followOrganization(orgId, { id: orgId }));
          if (status === 200 || status === 304 || status === 201) {
            setApiMessage('success', OKTION_VARIABLE?.OKTION_FOLLOWING_LIST);
            // setActionMessage(true, 'Success', 'The organiser was added to following list');
            getOrganizationDetails();
            setLoading(false);
            // setTimeout(() => {
            //   setActionMessage(false);
            // }, 3000);
          }
        } catch (err) {
          setLoading(false);
        }
      }
    }
  };
  const handleContact = data => {
    if (!props?.isLoggedIn) {
      setToggle(true);
    }
    if (props?.isLoggedIn) {
      let obj = {
        fromUser: true,
        paramsData: {
          recipientId: data?.ownerDetail?.id,
          firstName: data?.ownerDetail?.firstName,
          lastName: data?.ownerDetail?.lastName,
          userProfilePicture: data?.ownerDetail?.userProfilePicture,
          referenceId: eventId,
          chatType: 'event',
          referenceName: eventOutfit?.eventName,
        },
      };
      navigate('/mail/' + data?.ownerDetail?.id, obj);
    }
  };

  const SOCIAL_ICONS = {
    facebook: <SocialFacebook />,
    instagram: <SocialInstagram />,
    youtube: <SocialYoutube />,
    twitter: <SocialTwitter />,
    linkedin: <SocialLinkedin />,
    pinterest: <SocialPintrest />,
    custom: <SocialCustom />,
  };

  const getSocialIcon = type => {
    return SOCIAL_ICONS[type] || null;
  };

  const orgFilter = props.AllOrganizationList.filter((item, index) => item.organisation?.id === Number(orgId));
  const ISDISABLE = orgFilter.length > 0 ? true : false;
  return (
    <div className="eventDetails">
      {(loading || isorgLoading) && <PageLoader />}
      <div className="container">
        <Row>
          <Col sm="12">
            <div className="organization-profile-main">
              <Row>
                <Col sm="12">
                  <div className="text-center mt-3 bottomSection">
                    <div className="text-center">
                      <img
                        className="imgWidth"
                        // src={identifiers.imageUrlConstant + organizationData?.imagePath}
                        src={organizationData?.imagePath}
                        alt={OKTION_VARIABLE?.OKTION_ORG_PROFILE}
                      />
                      {/*<div className='square'>Company Logo</div>*/}
                    </div>
                    <h5 className="mt-2 fw-normal text-center mb-2">
                      {organizationData?.name &&
                        parse(
                          anchorme({
                            input: (organizationData?.name).toString().replace(/\n/g, '<br/>'),
                            options: {
                              attributes: {
                                target: '_blank',
                                class: 'detected',
                              },
                            },
                          }),
                        )}
                    </h5>
                    {/*   <p className="fs-16 ps-5 pe-5"> */}
                    <div>
                      {organizationData?.aboutTheOrganisation &&
                        parse(
                          anchorme({
                            input: (organizationData?.aboutTheOrganisation).toString().replace(/\n/g, '<br/>'),
                            options: {
                              attributes: {
                                target: '_blank',
                                class: 'detected',
                              },
                            },
                          }),
                        )}
                    </div>
                    {/*   </p> */}
                    {/* {message.display && (
                      <div className="btn-block  org-msg-block ">
                        <ActionMessage message={message.message} type={message.type} styleClass={message.styleClass} />
                      </div>
                    )} */}
                    <Button
                      disabled={ISDISABLE}
                      className="main-button mb-3 mt-3 h-60 fs-20 fw-500 contact-btn btn-blue"
                      onClick={handleClick}>
                      {organizationDetail?.isFollow ? OKTION_VARIABLE?.OKTION_UNFOLLOW : OKTION_VARIABLE?.OKTION_FOLLOW}{' '}
                    </Button>
                    <Button
                      disabled={ISDISABLE}
                      className="main-button mb-3 mt-3 h-60 fs-20 fw-500 contact-btn ms-2"
                      onClick={() => handleContact(organizationData)}>
                      {OKTION_VARIABLE?.OKTION_CONTACT}
                    </Button>
                    {/* {eventOutfit?.organisation?.OrganisationSocialLinksList?.length > 0 && (
                      <ul className="social-icon-wrap mt-3 pt-3 mb-4 pb-4">
                        {eventOutfit?.organisation?.OrganisationSocialLinksList?.map(item => {
                          if (!item?.linkType) {
                            return null;
                          }
                          const icon = getSocialIcon(item?.linkType);
                          if (icon) {
                            return (
                              <li key={item?.id}>
                                <a href={item?.linkUrl} target="_blank">
                                  {icon}
                                </a>
                              </li>
                            );
                          }
                          return null;
                        })}
                      </ul>
                    )} */}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <TabWrapper organizationActiveEvent={organizationActiveEvent} organizationPastEvent={organizationPastEvent} />
          <LogoutModal isToggle={isToggle} toggle={toggle} handleClick={handleButtonClick} />
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = ({ authReducer, OrganizationReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
    AllOrganizationList: OrganizationReducer.AllOrganizationList,
  };
};
export default connect(mapStateToProps, {})(OrganiserProfile);
