import React from 'react';
import '../../styles.scss';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import $ from 'jquery';
import { images } from '../../../../../../../../../library/common/constants/ImageConstants';

import { CurrencyFormat } from 'utility';
class AuctionWallTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { count: 1 };
  }

  componentDidUpdate() {
    var $el = $('.table-responsive');
    function anim() {
      var st = $el.scrollTop();
      var sb = $el.prop('scrollHeight') - $el.innerHeight();
      $el.animate({ scrollTop: st < sb / 2 ? sb : 0 }, 8000, anim);
    }
    function stop() {
      $el.stop();
    }
    anim();
    $el.hover(stop, anim);
    // setTimeout(() => {
    //   this.autoScroller();
    // }, 4000);
    // setInterval(() => {
    //   this.autoScroller();
    // }, 25000);
  }
  // autoScroller() {
  //   var $el = $('.table-responsive');
  //   function anim() {
  //     var st = $el.scrollTop();
  //     var sb = $el.prop('scrollHeight') - $el.innerHeight();
  //     $el.animate({ scrollTop: st < sb / 2 ? sb : 0 }, 5000, anim);
  //   }
  //   function stop() {
  //     $el.stop();
  //   }
  //   anim();
  //   $el.hover(stop, anim);
  // }
  render() {
    const { feturesData, currencyAbbreviation, currencySymbol } = this.props;
    return (
      <>
        <div className="auctionWallTable">
          {feturesData && feturesData?.item.length > 0 ? (
            <div className="table-responsive tableContainer custome-Scrollbar">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="tableItemNo">LOT</TableCell>
                      <TableCell className="col-sm-4">LOT NAME</TableCell>
                      <TableCell className="col-sm-4">WINNING BIDDER</TableCell>
                      <TableCell className="col-sm-4">CURRENT BID AMOUNT</TableCell>
                    </TableRow>
                  </TableHead>
                  <tbody className="d-block custome-Scrollbar">
                    {feturesData &&
                      feturesData?.item.length > 0 &&
                      feturesData?.item?.map((item, i) => {
                        return (
                          <TableRow key={i} className={item?.isSold && 'sold-lot-overlay'}>
                            <TableCell className="tableItemNo">
                              {this.state.count + i}
                              {item?.isSold && <img alt="" src={item?.isSold && images.prizeIcon} />}
                            </TableCell>
                            <TableCell className="col-sm-4">{item?.itemName}</TableCell>
                            <TableCell className="col-sm-4" style={{ textTransform: 'capitalize' }}>
                              {item?.winner !== null
                                ? item?.reserveMet === true
                                  ? item?.winner?.firstName + ' ' + (item?.winner?.lastName ? item?.winner?.lastName : '')
                                  : '-'
                                : '-'}
                            </TableCell>
                            <TableCell className="col-sm-4">
                              <CurrencyFormat
                                value={
                                  item?.isSold === true && item?.markAsPaid === true
                                    ? item?.buyItNow !== null
                                      ? item?.buyItNow
                                      : item?.currentBid !== null
                                        ? item?.currentBid
                                        : 0
                                    : item?.currentBid !== null
                                      ? item?.currentBid
                                      : 0
                                }
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={currencyAbbreviation + ' ' + currencySymbol}
                                // thousandSpacing="2s"
                                thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                                decimalScale="2"
                                fixedDecimalScale={true}
                                className="currency-fromate"
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </tbody>
                </Table>
              </TableContainer>
            </div>
          ) : (
            <h1>No Winner Details</h1>
          )}
        </div>
        {/* <TicketSold /> */}
      </>
    );
  }
}

export default AuctionWallTable;
