import React, { useState, useEffect, useContext } from 'react';
import { Button, InputGroup, Modal, ModalBody } from 'reactstrap';
import FormGroup from 'library/common/components/FormGroupComponent';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { images } from '../../../../library/common/constants/ImageConstants';
import FileUpload from '../../../../library/common/components/FileUpload';
import axiosInstance from '../../../../main/axios';
import ImageDisplay from '../../../../library/common/components/FileUpload/frames/ImageDisplay';
import PageLoader from '../../../../library/common/components/PageLoader';
import { URLS } from '../../../../library/common/constants/UrlConstants';
import { useForm } from 'react-hook-form';
// import { Errors } from '../../../../library/common/constants/ErrorMessage';
import moment from 'moment';
import { connect } from 'react-redux';
import { fetchFromStorage } from '../../../../utility';
import { identifiers } from '../../../../library/common/constants/IdentifierConstants';
import SelectBoxWithFlag from '../../../../library/common/components/SelectBoxWithFlag';
import { getCurrentCountryDetails } from '../../../../library/common/actions/AuthActions';
import { EventEmitter } from 'library/common/constants/event';
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setApiMessage } from '../../../../library/common/constants/function';
import { SocketContext } from '../../../../main/context/socket';
import { useNavigate } from 'react-router-dom';
import { createDraftOrg } from 'modules/Organization/menu-items';

const ItemDetailsModal = props => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const navigate = useNavigate();
  const Errors = {
    mandatory: OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE,
  };
  const [dragId, setDragId] = useState();
  const [chars_left, setChars_left] = useState(2000);
  const [chars_left_name, setChars_left_name] = useState(75);
  const [chars_exceed, setChars_exceed] = useState(false);
  // const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [imageArray, setImageArray] = useState([]);
  // const [categories, setCategories] = useState([]);
  const [selectedCatId, setSelectedCatId] = useState('');
  // const [created, setCreated] = useState(false);
  const [imageLength, setImageLength] = useState(false);
  const [currency, setCurrency] = useState([]);
  const [currentDate, setCurrentDate] = useState(false);
  const [minDate, setMinDate] = useState(false);
  const [minTime, setMinTime] = useState(false);
  const [flag, getFlag] = useState('');
  const [isImage, setImage] = useState(false);
  let latestImageArray = [
    { name: '', displayOrder: 1, preview: '', id: 'Box-1' },
    { name: '', displayOrder: 2, preview: '', id: 'Box-2' },
    { name: '', displayOrder: 3, preview: '', id: 'Box-3' },
    { name: '', displayOrder: 4, preview: '', id: 'Box-4' },
    { name: '', displayOrder: 5, preview: '', id: 'Box-5' },
    { name: '', displayOrder: 6, preview: '', id: 'Box-6' },
  ];
  const [finalImageArray, setFinalImageArray] = useState([]);
  // const [defaultCurrency, setDefaultCurrency] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const isNewOrg = window.location.pathname === '/organization/organiser-details';

  /*  useEffect(() => {
    getCategoryList();
  }, []); */
  var defaultValue = {
    lotname: props.propsData?.name,
    aboutlot: props.propsData?.aboutItme,
    prizeValue: props.propsData?.value != null ? parseFloat(props.propsData?.value).toFixed(2) : '',
    startingBid: props.propsData?.startingBid != null ? parseFloat(props.propsData?.startingBid).toFixed(2) : '',
    increment: props.propsData?.bidIncrement != null ? parseFloat(props.propsData?.bidIncrement).toFixed(2) : '',
    // selectedCatId: != null ?  parseFloat props.propsData?.category?.id).toFixed(2) : '',
    reserveAmount: props.propsData?.reserveAmount != null ? parseFloat(props.propsData?.reserveAmount).toFixed(2) : '',
    buyItNow: props.propsData?.buyItNow != null ? parseFloat(props.propsData?.buyItNow).toFixed(2) : '',
    expiryDate: props.isUpdate && moment(props.propsData?.itemExpiryDate).format('YYYY-MM-DD'),
    expiryTime: props.isUpdate && moment(props.propsData?.itemExpiryDate).format('HH:mm'),
    postageHandleAmount:
      props.propsData?.postageAndHandeling != null ? parseFloat(props.propsData?.postageAndHandeling).toFixed(2) : '',
    postageHandleNote: props.propsData?.postageHandlingNote,
    currency: props.propsData?.currencyId,
    selectedCatId: props.propsData?.categoryId,
    duplicateLot: props.propsData?.totalDuplicateLot,
  };
  // console.log('props.propsData', defaultValue, props.propsData);
  const {
    handleSubmit,
    watch,
    formState: { errors },
    register,
    reset,
    clearErrors,
  } = useForm({ defaultValues: defaultValue });
  // const [message, setMessage] = useState({
  //   display: false,
  //   type: '',
  //   message: '',
  // });
  const validateReserveAmount = () => {
    if (parseInt(watch('reserveAmount')) < parseInt(watch('startingBid'))) {
      return OKTION_VARIABLE?.OKTION_RESERVE_AMOUNT_MUST_GREATER;
    }
    return true;
  };
  const validateBuyItNowAmount = () => {
    if (parseInt(watch('buyItNow')) !== 0 && parseInt(watch('buyItNow')) < parseInt(watch('reserveAmount'))) {
      return OKTION_VARIABLE?.OKTION_BUYITNOW_AMOUNT_MUST_GREATER;
    }
    return true;
  };
  useEffect(() => {
    // load date picker in safari
    const { detect } = require('detect-browser');
    const browser = detect();
    if (browser?.name === 'safari') {
      // date picker
      window.$(function () {
        window.$('#expiryDate').datepicker();
      });
      // time picker
      window.$(document).ready(function () {
        window.$('#expiryTime').timepicker({
          timeFormat: 'h:mm p',
          interval: 1,
          scrollbar: true,
        });
      });
    }
  }, []);
  useEffect(() => {
    EventEmitter.subscribe('removesupplylotimage', event => {
      // props.newLot && setFiles([]);
      props.newLot && setFinalImageArray(latestImageArray);
    });
    // props.newLot && EventEmitter.subscribe('removesupplylotimage', event => {setFiles([]);setImageArray([])});
  });
  useEffect(() => {
    reset(defaultValue);
  }, [props.propsData]);
  useEffect(() => {
    props.newLot === true && setSelectedCatId('');
    props.newLot === true && setSelectedCurrency('');
  }, [props.isToggle]);

  const handleLimit = (event, value) => {
    const valueLength = value - event.target.value.length;
    const charExceed = valueLength > value ? true : false;
    if (value === 75) {
      setChars_left_name(valueLength);
    } else {
      setChars_left(valueLength);
    }
    setChars_exceed(charExceed);
  };

  const handleMediaTypeChange = (file, index) => event => {
    // const { value } = event.target;
    // // const filesArray = [...files];
    // filesArray[index].mediaType = value;
    // this.setState({ files: filesArray });
  };

  useEffect(() => {
    var finalImage_Array = [...latestImageArray];
    if (props.propsData?.images !== undefined) {
      if (props.propsData?.images?.length > 0) {
        props.propsData.images.map((item, i) => {
          var boxId = `Box-${i + 1}`;
          const tempObj = {
            name: identifiers.imageUrlConstant + item?.name,
            preview: identifiers.imageUrlConstant + item?.name,
            displayOrder: item?.displayOrder,
            imagename: item?.name,
            id: boxId,
          };
          var fIndex = latestImageArray.findIndex(e => e.displayOrder === tempObj?.displayOrder);
          finalImage_Array[fIndex] = tempObj;
          return item;
        });
      }
      setFinalImageArray(finalImage_Array);
    }
    if (props.isUpdate !== true) {
      setFinalImageArray(latestImageArray);
    }
    // props.isUpdate !== true && setFiles([]);
  }, []);

  // useEffect(() => {
  //   var IMAGE_TEMP = [];
  //   if (props.propsData?.images !== undefined) {
  //     if (props.propsData?.images?.length > 0) {
  //       props.propsData.images.map((item, i) => {
  //         const tempObj = {
  //           preview: identifiers.imageUrlConstant + item?.name,
  //         };
  //         const temp_Obj = {
  //           name: item?.name,
  //           displayOrder: i + 1,
  //         };
  //         defaultImages[`uploader${i + 1}`] = [tempObj];
  //         IMAGE_TEMP[i] = temp_Obj;
  //         return item;
  //       });
  //       setFiles(defaultImages);
  //       setImageArray(IMAGE_TEMP);
  //     }
  //   }
  //   // props.getCurrentCountryDetails();
  // }, []);

  useEffect(
    () => {
      // (async () => {
      // try {
      //   const { status, data } = await axiosInstance.get(URLS.getCurrency());
      //   if (status === 200) {
      //     setCurrency(data.data.currencies);
      //   }
      // } catch (err) {
      //   // setLoading(false);
      // }

      // const eventDetails = fetchFromStorage(identifiers.eventDetails);
      // const { status, data } = await axiosInstance.get(URLS.getCategoryPublic(eventDetails?.id));
      // if (status === 200) {
      //   setCategories(data.data.categories);
      //   // setCatId()
      // }
      // })();

      let allCurrencies = getCurrencyOptions();
      let categoriesd = props.categoryList;
      if (props.isUpdate) {
        let tempSelectedCurrency = allCurrencies?.filter(currency => currency?.id === props.propsData?.currencyId);
        setSelectedCurrency({ ...tempSelectedCurrency[0], countryCode: tempSelectedCurrency[0]?.country?.countryCode });
        getFlag(tempSelectedCurrency[0]?.label);
        let tempCategories = categoriesd?.filter(category => category?.id === props?.propsData?.categoryId);
        setSelectedCatId(tempCategories[0]?.id);

        props?.propsData?.aboutItme !== null && setChars_left(2000 - props?.propsData?.aboutItme.length);
        props?.propsData?.name !== null && setChars_left_name(75 - props?.propsData?.name.length);
        // console.log('categories', tempCategories);
      } else {
        // var countryCodeToMatch =
        //   props?.countryDetails?.countryCode !== undefined ? props?.countryDetails?.countryCode : 'IN';
        const country_Details = fetchFromStorage(identifiers.countryDetails);
        let tempSelectedCurrency = allCurrencies?.filter(
          currency => currency?.country?.countryCode === country_Details?.countryCode,
        );
        setSelectedCurrency({ ...tempSelectedCurrency[0], countryCode: tempSelectedCurrency[0]?.country?.countryCode });
        getFlag(tempSelectedCurrency[0]?.label);
        // getFlag(tempSelectedCurrency.label);
        // let tempSelectedCurrency = allCurrencies[0];
        // setSelectedCurrency({ ...tempSelectedCurrency, countryCode: tempSelectedCurrency?.country?.countryCode });
        // getFlag(tempSelectedCurrency?.label);
      }
    },
    [reset, props.propsData, props.currencyList, props.categoryList, props.countryDetails, currency[0]?.id],
    register,
  );

  const handleFileUpload = (file, name, index) => {
    if (file[0] && file[0].size <= 10485760) {
      const filesArray = file?.map(file =>
        Object.assign(file, {
          mediaType: 'other',
        }),
      );
      const newFiles = [...filesArray];
      // setFiles({ ...files, [name]: newFiles });
      uploadImage(file, index);
      // console.log('files', files);
      setImageLength(false);
    } else {
      setImageLength(true);
    }
  };
  const handlePublish = () => {
    // setImage(true);
    handleSubmit(onSubmit)('published');
    setLoading(false);
  };
  const uploadImage = async (file, index) => {
    setLoading(true);
    const body = new FormData();
    const method = 'post';
    const url = URLS.multipleImageUpload;
    for (let i = 0; i < file.length; i++) {
      body.append('file', file[i]);
    }
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    try {
      const { status, data } = await axiosInstance[method](url, body, config);
      if (status === 201) {
        setLoading(false);
        var IMAGE_ARRAY = data?.files;
        var finalImage_Array = [...finalImageArray];
        var INDEX = index;
        IMAGE_ARRAY.map((item, i) => {
          const tempObj = {
            name: identifiers.imageUrlConstant + item,
            preview: identifiers.imageUrlConstant + item,
            displayOrder: INDEX + 1,
            imagename: item,
            id: `Box-${INDEX + 1}`,
          };
          INDEX = INDEX + 1;
          var fIndex = finalImage_Array.findIndex(e => e.displayOrder === tempObj?.displayOrder);
          finalImage_Array[fIndex] = tempObj;
          return item;
        });
        setFinalImageArray(finalImage_Array);
        var image_Length = finalImage_Array.filter(item => item?.name !== '');
        setImage(image_Length?.length === 0 ? true : false);
        // setImageArray([...imageArray, { name: data.file, displayOrder: index }]);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleSelectChange = e => {
    const value = e.target.value;
    setSelectedCatId(value);
  };

  const handleClose = () => {
    if (props?.newLot === true) {
      // setFiles([]);
      // setImageArray([]);
      setFinalImageArray(latestImageArray);
    }
  };

  const handleRemoveImage = element => () => {
    var filteredArray = [];
    finalImageArray.map((item, index) => {
      var boxId = `Box-${index + 1}`;
      const tempObj = {
        name: item.displayOrder === element?.displayOrder ? '' : item?.name,
        preview: item.displayOrder === element?.displayOrder ? '' : item?.preview,
        imagename: item.displayOrder === element?.displayOrder ? '' : item?.imagename,
        displayOrder: item.displayOrder,
        id: boxId,
      };
      filteredArray[index] = tempObj;
      return item;
    });
    setFinalImageArray(filteredArray);
    var image_Length = filteredArray.filter(item => item?.name !== '');
    setImage(image_Length?.length === 0 ? true : false);
  };

  const createSupplyLot = async requestData => {
    const currentOrgId = fetchFromStorage(identifiers.currentOrganization);

    try {
      const { status, data } = await axiosInstance.post(URLS.createSupply, requestData);
      setLoading(false);
      if (status === 200 || status === 201 || status === 304) {
        // EventEmitter.dispatch('updatesupplylotlist');
        setLoading(false);
        setApiMessage('success', data.msg);
        window.scrollTo(0, 0);
        if (isNewOrg) {
          setTimeout(() => {
            navigate(`/organization/${currentOrgId}?supply-item`);
          }, 1000);
        } else {
          EventEmitter.dispatch('updatesupplylotlist');
        }
        // props.getSupplyItem();
        // props.toggle();
      }
    } catch (err) {
      setLoading(false);
      if (err.response && err?.response?.data?.validation?.body?.message !== '"images" must contain at least 1 items') {
        setApiMessage('error', err?.response?.data?.validation?.body?.message);
      }
    }
  };
  const updateSupplyItem = async requestData => {
    // console.log('props?.propsData?.id', props?.propsData?.id);
    try {
      const { status, data } = await axiosInstance.put(URLS.updateSupplyItem(props?.propsData?.id), requestData);
      setLoading(false);
      if (status === 200 || status === 201 || status === 304) {
        window.scrollTo(0, 0);
        setLoading(false);
        setApiMessage('success', data.msg);
        props.getSupplyItem();

        setTimeout(() => {
          props.toggle();
        }, 4000);
      }
    } catch (err) {
      setLoading(false);
      if (err.response) {
        setApiMessage('error', err?.response?.data?.validation?.body?.message);
      } else {
        setApiMessage('error', OKTION_VARIABLE?.SOMETHING_WENT_WRONG);
      }
    }
  };

  const onSubmit = async (dataValue, status) => {
    var {
      lotname,
      aboutlot,
      prizeValue,
      startingBid,
      increment,
      reserveAmount,
      buyItNow,
      expiryDate,
      expiryTime,
      postageHandleAmount,
      postageHandleNote,
      duplicateLot,
    } = watch();

    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    setLoading(true);
    var itemExpiryDate = `${expiryDate} ${expiryTime}`;
    var finalImage_Array = finalImageArray?.filter(item => item?.name !== '');
    finalImage_Array.map((item, i) => {
      const tempObj = {
        name: item?.imagename,
        displayOrder: item?.displayOrder,
      };
      finalImage_Array[i] = tempObj;
      return item;
    });
    var requestData = {
      images: finalImage_Array,
      // ...(lotname !== '' && { name: lotname }),
      // ...(aboutlot !== '' && { aboutItme: aboutlot }),
      // ...(selectedCatId !== '' && { categoryId: selectedCatId }),
      // ...(prizeValue !== '' && { value: prizeValue }),
      // ...(startingBid !== '' && { startingBid: startingBid }),
      // ...(increment !== '' && { bidIncrement: increment }),
      // ...(reserveAmount !== '' && { reserveAmount: reserveAmount }),
      // ...(buyItNow !== '' && { buyItNow: buyItNow }),
      // ...(expiryDate !== '' && expiryTime !== '' && { itemExpiryDate: moment(itemExpiryDate).toISOString() }),
      // ...(postageHandleAmount !== '' && { postageAndHandeling: Number(postageHandleAmount) }),
      // ...(postageHandleNote !== '' && { postageHandlingNote: postageHandleNote }),
      name: lotname !== '' ? lotname : '',
      aboutItme: aboutlot !== '' ? aboutlot : '',
      categoryId: selectedCatId !== '' ? selectedCatId : '',
      value: prizeValue !== '' ? prizeValue : '',
      startingBid: startingBid !== '' ? startingBid : '',
      bidIncrement: increment !== '' ? increment : '',
      reserveAmount: reserveAmount !== '' ? reserveAmount : '',
      buyItNow: buyItNow !== '' ? buyItNow : '',
      itemExpiryDate: expiryDate !== '' && expiryTime !== '' ? moment(itemExpiryDate).toISOString() : '',
      postageAndHandeling: postageHandleAmount !== '' ? Number(postageHandleAmount) : '',
      postageHandlingNote: postageHandleNote !== '' ? postageHandleNote : '',
      status: status,
      itemStatus: 'available',
      ...(!props.newLot && { markAsClaimed: true }),
      currency: selectedCurrency !== '' ? selectedCurrency?.id : '',
      duplicateLots:
        duplicateLot === '' || duplicateLot === null || duplicateLot === undefined ? 0 : Number(duplicateLot),
      // ...(selectedCurrency !== '' && { currency: selectedCurrency?.id }),

      // name: lotname,
      // aboutItme: aboutlot,
      // categoryId: selectedCatId,
      // value: prizeValue,
      // startingBid: startingBid,
      // bidIncrement: increment,
      // reserveAmount: reserveAmount,
      // buyItNow: buyItNow,
      // itemExpiryDate: `${expiryDate} ${expiryTime}`,
      // postageAndHandeling: postageHandleAmount,
      // postageHandlingNote: postageHandleNote,
      // status: status,
      // itemStatus: 'available',
      // currency: selectedCurrency?.id,
      // duplicateLots: dataValue?.duplicateLot,
      // duplicateLots: duplicateLot === '' ? 1 : Number(duplicateLot),
    };
    var isValid = !minDate && !minTime && !isImage ? true : false;

    if (props?.propsData?.status === 'published' && status === 'draft') {
      setLoading(false);
      setApiMessage('error', OKTION_VARIABLE?.OKTION_DRAFT_TO_PUBLISHED_ERROR);
      // toast.error(
      //   <>
      //     <img src={images.infoIcon} alt="infoIcon" /> Can not change published supply lot status to draft
      //   </>,
      //   {
      //     position: 'top-center',
      //     autoClose: 2000,
      //     hideProgressBar: true,
      //     closeOnClick: false,
      //     pauseOnHover: false,
      //     draggable: false,
      //     progress: undefined,
      //     closeButton: false,
      //     draggable: false,
      //   },
      // );
    } else {
      if (isValid) {
        if (props?.isUpdate) {
          updateSupplyItem(requestData);
        } else {
          if (isNewOrg) {
            let { status } = await createDraftOrg();
            if (status) {
              createSupplyLot(requestData);
            }
          } else {
            createSupplyLot(requestData);
          }
          // setCreated(true);
        }
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    }
  };
  const getCurrencyOptions = () => {
    return props?.currencyList?.length > 0
      ? props?.currencyList?.map(c => {
          return { value: c?.abbreviation, label: c?.abbreviation, flag: c?.country?.flag, ...c };
        })
      : [];
  };
  const onSelectFlag = countryCode => {
    getFlag(countryCode?.label);
    setSelectedCurrency({ ...countryCode, countryCode: countryCode?.country?.countryCode });
  };
  const handleDateChange = event => {
    if (event.target.value === moment(new Date()).format('YYYY-MM-DD')) {
      setCurrentDate(true);
      setMinDate(false);
    } else if (event.target.value < moment(new Date()).format('YYYY-MM-DD')) {
      setMinDate(true);
    } else {
      setMinDate(false);
      setMinTime(false);
    }
  };

  const handleTimeChange = event => {
    if (currentDate === true) {
      if (event.target.value < moment(new Date()).format('HH:mm')) {
        setMinTime(true);
      } else {
        setMinTime(false);
      }
    }
  };

  const handleDrag = ev => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = ev => {
    const dragBox = finalImageArray.find(box => box.id === dragId);
    const dropBox = finalImageArray.find(box => box.id === ev.currentTarget.id);
    const dragBoxOrder = dragBox.displayOrder;
    const dropBoxOrder = dropBox.displayOrder;

    const newBoxState = finalImageArray.map(box => {
      if (box.id === dragId) {
        box.displayOrder = dropBoxOrder;
      }
      if (box.id === ev.currentTarget.id) {
        box.displayOrder = dragBoxOrder;
      }
      return box;
    });
    setFinalImageArray(newBoxState);
  };
  const handleClaimed = () => {};
  var expiry_Date = watch('expiryTime');

  return (
    <>
      {/* <ToastContainer autoClose={3000} /> */}
      <div className="AddPriceModal AddItemModal">
        <Modal
          size="lg"
          className="customModal add-price-modal maxwidth-900  supply-item-details addLotModal"
          isOpen={props.isToggle}>
          <div className="text-end" onClick={() => handleClose()}>
            <img className="close-icon cursor-pointer" onClick={props.toggle} src={images.close} alt="" />
          </div>
          <div className="modalHeader">
            <div>
              <p className="title">
                {props.newLot ? OKTION_VARIABLE?.OKTION_ADD_LOT : OKTION_VARIABLE?.OKTION_EDIT_LOT}
              </p>
            </div>
            {/* {!props.newLot ? (
            <div>
              <img
                src={
                  props.propsData?.markAsClaimed === false
                    ? images.unClaimed
                    : props.propsData?.markAsClaimed === true
                    ? images.claimed
                    : ''
                }
                onClick={handleClaimed}
              />
            </div>
          ) : (
            ''
          )} */}
          </div>
          <ModalBody>
            {loading && <PageLoader />}
            <div className="addPriceModalForm">
              <form>
                <Row className="supply-item lot-Images-block">
                  {finalImageArray
                    .sort((a, b) => a.displayOrder - b.displayOrder)
                    .map((item, index) => (
                      <Col
                        sm="4"
                        xs="12"
                        className="mb-4"
                        draggable={true}
                        id={item?.id}
                        onDragOver={ev => ev.preventDefault()}
                        onDragStart={handleDrag}
                        onDrop={handleDrop}>
                        {item?.name !== '' ? (
                          <ImageDisplay
                            // files={files[`uploader${index}`]}
                            files={[item]}
                            onRemove={e => handleRemoveImage(item)}
                            onChangeMediaType={handleMediaTypeChange}
                            // handleImageErrored={handleImageErrored}
                            // isImageLoaded={isImageLoaded}
                            // disabled={isFieldDisable}
                          />
                        ) : (
                          <FileUpload
                            accept="image/jpeg, image/png"
                            text={OKTION_VARIABLE?.OKTION_UPLOAD_LOT_IMAGE}
                            name={`uploader${index}`}
                            onDrop={file => handleFileUpload(file, `uploader${index}`, index)}
                            multiple
                          />
                        )}
                      </Col>
                    ))}
                  <Col>
                    {imageLength === true && <p className="text-danger">{OKTION_VARIABLE?.OKTION_IMAGE_VALIDATION}</p>}
                    {/* {isImage === true && files.length === 0 && <span className="text-danger">{Errors.mandatory}</span>} */}
                    {isImage && <span className="text-danger">{Errors.mandatory}</span>}
                  </Col>
                </Row>
                <Row className="img-specification">
                  <Col sm="12" className="mb-4">
                    <div className="message">
                      <p className="fs-12">
                        <strong>{OKTION_VARIABLE?.OKTION_IMAGE_SPECIFICATION}</strong>
                      </p>
                      <p className="fs-12">{OKTION_VARIABLE?.OKTION_UPLOAD_UPTO_SIX}</p>
                      <p className="fs-12">{OKTION_VARIABLE?.OKTION_FILE_TYPE}</p>
                      <p className="fs-12">{OKTION_VARIABLE?.OKTION_RECOMMENDED_DIMENSION}</p>
                      <p className="fs-12">{OKTION_VARIABLE?.OKTION_FILESIZE_LIMIT}</p>
                    </div>
                  </Col>
                </Row>
                <FormGroup
                  className={
                    watch('lotname') !== '' && typeof watch('lotname') !== 'undefined'
                      ? ' mb-2 form-group-style focus_field fixed-fieldset'
                      : ' mb-2 form-group-style focus_field '
                  }>
                  <input
                    {...register('lotname', { required: true, maxLength: 75, onChange: e => handleLimit(e, 75) })}
                    name="lotname"
                    // onChange={e => handleLimit(e, 75)}
                    type="text"
                    maxLength="75"
                    placeholder={`${OKTION_VARIABLE?.OKTION_LOT_PRIZE_NAME}*`}
                    className={`mb-0 form-control ${errors.lotname && 'errorColor'}`}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_LOT_PRIZE_NAME}</label>
                  {errors.lotname && <span className="text-danger">{Errors.mandatory}</span>}
                  <div className="char_limit">{chars_left_name}</div>
                </FormGroup>
                <FormGroup
                  className={
                    watch('aboutlot') !== '' && typeof watch('aboutlot') !== 'undefined'
                      ? ' mb-2 form-group-style focus_field fixed-fieldset'
                      : ' mb-2 form-group-style focus_field '
                  }>
                  <textarea
                    {...register('aboutlot', { required: true, maxLength: 2000, onChange: e => handleLimit(e, 2000) })}
                    // onChange={e => handleLimit(e, 2000)}
                    name="aboutlot"
                    type="textarea"
                    maxLength="2000"
                    placeholder={`${OKTION_VARIABLE?.OKTION_ABOUT_THIS_SUPPLYLOT}*`}
                    className={`mb-0 form-control ${errors.aboutlot && 'errorColor'}`}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_ABOUT_THIS_SUPPLYLOT}</label>
                  {errors.aboutlot && <span className="text-danger">{Errors.mandatory}</span>}
                  <div className="char_limit">{chars_left}</div>
                </FormGroup>
                <FormGroup className="mt-2 custome-dropdown-arrow">
                  <select
                    value={selectedCatId}
                    className={`mb-0 form-control ${errors.category && 'errorColor'}`}
                    {...register('category', { required: true, onChange: e => handleSelectChange(e) })}
                    // onChange={e => handleSelectChange(e)}
                    name="category">
                    <option value="" hidden>
                      {OKTION_VARIABLE?.OKTION_CATEGORY}
                    </option>
                    {props.categoryList.map((item, i) => (
                      <option value={item.id} key={i}>
                        {item?.categoryName}
                      </option>
                    ))}
                  </select>
                  {errors.category && <span className="text-danger">{Errors.mandatory}</span>}
                </FormGroup>
                <FormGroup className="mt-2 mb-4 ">
                  <SelectBoxWithFlag
                    {...props}
                    selectOptions={[...getCurrencyOptions()]}
                    selectedValue={selectedCurrency}
                    onChange={onSelectFlag}
                    className="mb-2 selectBorder currencySelect"
                  />
                </FormGroup>

                {/* <InputGroup
                  className={
                    watch('prizeValue') !== '' && typeof watch('prizeValue') !== 'undefined'
                      ? ' mb-3 form-group-style focus_field fixed-fieldset'
                      : ' mb-3 form-group-style focus_field '
                  }>
                  <input
                    {...register({ required: true })}
                    name="prizeValue"
                    placeholder={`${OKTION_VARIABLE?.OKTION_PRIZE_VALUE}*`}
                    type="number"
                    autoComplete="off"
                    className={`mb-0 form-control input_field-style ${errors.prizeValue && 'errorColor'}`}
                    onChange={e => {
                      e.target.value.replace(/[^0-9]/g, '');
                    }}
                    // onChange={e => {
                    //   e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    // }}
                    onBlur={e => (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : '')}
                  />

                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_PRIZE_VALUE}</label>
                  <span className="span-style">{flag && flag}</span>
                  {errors.prizeValue && <span className="text-danger">{Errors.mandatory}</span>}
                </InputGroup> */}
                {/* <InputGroup
                  className={
                    watch('startingBid') !== '' && typeof watch('startingBid') !== 'undefined'
                      ? ' mb-3 form-group-style focus_field fixed-fieldset'
                      : ' mb-3 form-group-style focus_field '
                  }>
                  <input
                    {...register({ required: true })}
                    name="startingBid"
                    placeholder={`${OKTION_VARIABLE?.OKTION_STARTING_BID}*`}
                    type="number"
                    autoComplete="off"
                    className={`mb-0 form-control input_field-style ${errors.startingBid && 'errorColor'}`}
                    onChange={e => {
                      e.target.value.replace(/[^0-9]/g, '');
                    }}
                    // onChange={e => {
                    //   e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    // }}
                    onBlur={e => (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : '')}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_STARTING_BID}</label>
                  <span className="span-style">{flag && flag}</span>
                  {errors.startingBid && <span className="text-danger">{Errors.mandatory}</span>}
                </InputGroup> */}
                {/* <InputGroup
                  className={
                    watch('increment') !== '' && typeof watch('increment') !== 'undefined'
                      ? ' mb-3 form-group-style focus_field fixed-fieldset'
                      : ' mb-3 form-group-style focus_field '
                  }>
                  <input
                    {...register({ required: true })}
                    name="increment"
                    type="number"
                    placeholder={`${OKTION_VARIABLE?.OKTION_INCREMENT}*`}
                    autoComplete="off"
                    className={`mb-0 form-control input_field-style ${errors.increment && 'errorColor'}`}
                    onChange={e => {
                      e.target.value.replace(/[^0-9]/g, '');
                    }}
                    // onChange={e => {
                    //   e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    // }}
                    onBlur={e => (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : '')}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_INCREMENT}</label>
                  <span className="span-style">{flag && flag}</span>
                  {errors.increment && <span className="text-danger">{Errors.mandatory}</span>}
                </InputGroup> */}
                <InputGroup
                  className={
                    watch('reserveAmount') !== '' && typeof watch('reserveAmount') !== 'undefined'
                      ? ' mb-3 form-group-style focus_field fixed-fieldset'
                      : ' mb-3 form-group-style focus_field '
                  }>
                  <input
                    className={`mb-0 form-control input_field-style ${errors.reserveAmount && 'errorColor'}`}
                    {...register('reserveAmount', {
                      required: true,
                      validate: validateReserveAmount,
                      onChange: e => {
                        e.target.value.replace(/[^0-9]/g, '');
                      },
                      onBlur: e =>
                        (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : ''),
                    })}
                    name="reserveAmount"
                    type="number"
                    placeholder={OKTION_VARIABLE?.OKTION_RESERVE_AMOUNT_REQUIRED}
                    autoComplete="off"
                    // onChange={e => {
                    //   e.target.value.replace(/[^0-9]/g, '');
                    // }}
                    // onChange={e => {
                    //   e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    // }}
                    onBlur={e => (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : '')}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_RESERVE_AMOUNT}</label>
                  <span className="span-style">{flag && flag}</span>
                  {/* {errors.reserveAmount && <span className="text-danger">{Errors.mandatory}</span>} */}
                  {errors.reserveAmount && errors?.reserveAmount?.type === 'required' && (
                    <span className="text-danger">{Errors.mandatory}</span>
                  )}
                  {errors.reserveAmount && errors?.reserveAmount?.type === 'validate' && (
                    <span className="text-danger">{errors?.reserveAmount?.message}</span>
                  )}
                </InputGroup>
                {/* <InputGroup
                  className={
                    watch('buyItNow') !== '' && typeof watch('buyItNow') !== 'undefined'
                      ? ' mb-3 form-group-style focus_field fixed-fieldset'
                      : ' mb-3 form-group-style focus_field '
                  }>
                  <input
                    className={`mb-0 form-control input_field-style ${errors.buyItNow && 'errorColor'}`}
                    // {...register({ required: true })}
                    {...register({ required: false, validate: validateBuyItNowAmount })}
                    name="buyItNow"
                    type="number"
                    placeholder={OKTION_VARIABLE?.OKTION_BUYITNOW_AMOUNT}
                    onChange={e => {
                      e.target.value.replace(/[^0-9]/g, '');
                    }}
                  
                    onBlur={e => (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : '')}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_BUYITNOW_AMOUNT}</label>
                  <span className="span-style">{flag && flag}</span>
                
                  {errors.buyItNow && errors?.buyItNow?.type === 'required' && (
                    <span className="text-danger">{Errors.mandatory}</span>
                  )}
                  {errors.buyItNow && errors?.buyItNow?.type === 'validate' && (
                    <span className="text-danger">{errors?.buyItNow?.message}</span>
                  )}
                </InputGroup> */}
                <Row>
                  <Col sm="6">
                    <FormGroup
                      className={
                        watch('expiryDate') !== '' && typeof watch('expiryDate') !== 'undefined'
                          ? ' mb-3 form-group-style focus_field fixed-fieldset'
                          : ' mb-3 form-group-style focus_field '
                      }>
                      <input
                        {...register('expiryDate', { required: true, onChange: e => handleDateChange(e) })}
                        name="expiryDate"
                        id="expiryDate"
                        max="9999-12-31"
                        type="date"
                        // min={moment(new Date()).format('YYYY-MM-DD')}
                        className={`mb-0 form-control ${errors.expiryDate && 'errorColor'}`}
                        placeholder={OKTION_VARIABLE?.OKTION_EXPIRY_DATE}
                        // onChange={e => handleDateChange(e)}
                      />
                      <label className="label-helper">{OKTION_VARIABLE?.OKTION_EXPIRY_DATE}</label>
                      {errors.expiryDate && <span className="text-danger">{Errors.mandatory}</span>}
                      {minDate && <span className="text-danger">Cannot enter a date in the past.</span>}
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup
                      className={
                        watch('expiryTime') !== '' && typeof watch('expiryTime') !== 'undefined'
                          ? ' mb-3 form-group-style focus_field fixed-fieldset'
                          : ' mb-3 form-group-style focus_field '
                      }>
                      <input
                        {...register('expiryTime', { required: true, onChange: e => handleTimeChange(e) })}
                        className={`form-control ${errors.expiryTime && 'errorColor'}`}
                        name="expiryTime"
                        id="expiryTime"
                        type="time"
                        placeholder={OKTION_VARIABLE?.OKTION_LOT_EXPIRY_TIME}
                        // onChange={e => handleTimeChange(e)}
                      />
                      {/* <input
                      {...register({ required: true })}
                      name="expiryTime"
                      type="time"
                      // min={moment(new Date()).format('HH:mm')}
                      placeholder="Lot Expiry Time"
                      className={`mb-0 form-control ${errors.expiryTime && 'errorColor'}`}
                      onChange={e => handleTimeChange(e)}
                    /> */}
                      <label className="label-helper">{OKTION_VARIABLE?.OKTION_LOT_EXPIRY_TIME}</label>
                      {errors.expiryTime && <span className="text-danger">{Errors.mandatory}</span>}
                      {minTime && <span className="text-danger">{OKTION_VARIABLE?.OKTION_PASTTIME_NOTALLOW}</span>}
                    </FormGroup>
                  </Col>
                </Row>
                <InputGroup
                  className={
                    watch('postageHandleAmount') !== '' && typeof watch('postageHandleAmount') !== 'undefined'
                      ? ' mb-3 form-group-style focus_field fixed-fieldset'
                      : ' mb-3 form-group-style focus_field '
                  }>
                  <input
                    // {...register({ required: true })}
                    {...register('postageHandleAmount', {
                      onChange: e => {
                        e.target.value.replace(/[^0-9]/g, '');
                      },
                      onBlur: e =>
                        (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : ''),
                    })}
                    name="postageHandleAmount"
                    type="number"
                    placeholder={OKTION_VARIABLE?.OKTION_POSTAGE_HANDLING_AMOUNT}
                    autoComplete="off"
                    className={`mb-0 form-control input_field-style ${errors.postageHandleAmount && 'errorColor'}`}
                    // onChange={e => {
                    //   e.target.value.replace(/[^0-9]/g, '');
                    // }}
                    // onChange={e => {
                    //   e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    // }}
                    // onBlur={e => (e.target.value = e.target.value !== '' ? parseFloat(e.target.value).toFixed(2) : '')}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_POSTAGE_HANDLING_AMOUNT}</label>
                  <span className="span-style">{flag && flag}</span>
                  {errors.postageHandleAmount && <span className="text-danger">{Errors.mandatory}</span>}
                </InputGroup>

                <FormGroup
                  className={
                    watch('postageHandleNote') !== '' && typeof watch('postageHandleNote') !== 'undefined'
                      ? ' mb-3 form-group-style focus_field fixed-fieldset'
                      : ' mb-3 form-group-style focus_field '
                  }>
                  <input
                    // {...register({ required: true })}
                    {...register('postageHandleNote', { required: false })}
                    name="postageHandleNote"
                    type="text"
                    placeholder={OKTION_VARIABLE?.OKTION_POSTAGE_NOTES}
                    className={`mb-0 form-control ${errors.postageHandleNote && 'errorColor'}`}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_POSTAGE_NOTES}</label>
                  {errors.postageHandleNote && <span className="text-danger">{Errors.mandatory}</span>}
                </FormGroup>
                {/* {console.log('dupvalue', watch('duplicateLot'))} */}
                <FormGroup
                  className={
                    watch('duplicateLot') !== '' && typeof watch('duplicateLot') !== 'undefined'
                      ? ' mb-3 form-group-style focus_field fixed-fieldset'
                      : ' mb-3 form-group-style focus_field '
                  }>
                  <input
                    {...register('duplicateLot', {
                      onChange: e => {
                        e.target.value.replace(/[^0-9]/g, '');
                      },
                    })}
                    name="duplicateLot"
                    type="number"
                    placeholder={OKTION_VARIABLE?.OKTION_DUPLICATE_LOT_PRIZES}
                    // onChange={e => {
                    //   e.target.value.replace(/[^0-9]/g, '');
                    // }}
                    // onChange={e => {
                    //   e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    // }}
                    className={`mb-0 form-control ${errors.duplicateLot && 'errorColor'}`}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_DUPLICATE_LOT_PRIZES}</label>
                  <span className="leave-info-text mb-2">{OKTION_VARIABLE?.OKTION_DUPLICATE_HINT}</span>
                  {errors.duplicateLot && <span className="text-danger">{Errors.mandatory}</span>}
                </FormGroup>
                <div className="mb-5 text-center modalFooter">
                  <Row className="ps-0 pe-0">
                    {!props.newLot ? (
                      <>
                        <Col sm="4">
                          <Button
                            className="app-button black-button mt-2 me-2 mWidth-100"
                            // onClick={value => handleSubmit(onSubmit)('deleted')}>
                            onClick={value => {
                              onSubmit('', 'deleted');
                            }}>
                            {OKTION_VARIABLE?.OKTION_DELETE_LOT}
                          </Button>
                        </Col>

                        <Col sm="4">
                          <Button
                            className="app-button main-button mt-2 me-2 mWidth-100"
                            onClick={value => {
                              onSubmit('', 'draft');
                              clearErrors();
                            }}>
                            {OKTION_VARIABLE?.OKTION_BUTTON_SAVE}
                          </Button>
                        </Col>
                        <Col sm="4">
                          <Button
                            // onClick={value => handleSubmit(onSubmit)('published')}
                            onClick={() => handlePublish()}
                            className="app-button blue-button mt-2 me-2 mWidth-100">
                            {OKTION_VARIABLE?.OKTION_BUTTON_PUBLISH}
                          </Button>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col sm="6">
                          <Button
                            className="app-button main-button mt-2 me-2 mWidth-100"
                            onClick={value => {
                              onSubmit('', 'draft');
                              clearErrors();
                            }}>
                            {OKTION_VARIABLE?.OKTION_BUTTON_SAVE}
                          </Button>
                        </Col>
                        <Col sm="6">
                          <Button
                            onClick={() => handlePublish()}
                            className="app-button blue-button mt-2 me-2 mWidth-100">
                            {OKTION_VARIABLE?.OKTION_BUTTON_PUBLISH}
                          </Button>
                        </Col>{' '}
                      </>
                    )}
                  </Row>
                </div>
              </form>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};
const mapStateToProps = ({ authReducer }) => {
  return {
    countryDetails: authReducer.countryDetails,
    currencyList: authReducer.currencyList,
    categoryList: authReducer.categoryList,
  };
};
export default connect(mapStateToProps, { getCurrentCountryDetails })(ItemDetailsModal);
