import { storeConstants } from '../constants/StoreConstants';
import axiosInstance from '../../../main/axios';
import { URLS } from '../constants/UrlConstants';

export const getEventById = id => dispatch => {
  return axiosInstance
    .get(URLS.getByEventIdData(id))
    .then(({ status, data }) => {
      if (status === 201 || status === 200) {
        dispatch({
          type: storeConstants.GET_EVENT,
          payload: data,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: storeConstants.GET_EVENT,
        payload: [],
      });
    });
};

export const updateEvent = (id, dataValue) => dispatch => {
  return axiosInstance
    .put(URLS.updateEvent(id), dataValue)
    .then(({ status, data }) => {
      if (status === 201 || status === 200) {
        dispatch({
          type: storeConstants.UPDATE_EVENT,
          payload: data,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: storeConstants.UPDATE_EVENT,
        payload: [],
      });
    });
};

export const setPreviewColor = data => dispatch => {
  dispatch({
    type: storeConstants.SET_PREVIEWCOLOR,
    payload: data,
  });
};

export const eventModalToggle = data => dispatch => {
  dispatch({
    type: storeConstants.EVENT_MODAL,
    payload: data,
  });
};
