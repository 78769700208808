import * as actionTypes from '../constants/StoreConstants';

const initialState = {
  AllOrganizationList: [],
  organizationList: [],
  updateOrganization: [],
};

const OrganizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.storeConstants.GET_ORGANIZATION:
      // console.log('org payload', action.payload);
      return {
        ...state,
        organizationList: action.payload,
      };
    case actionTypes.storeConstants.UPDATE_ORGANIZATION:
      // console.log('update payload', action.payload);
      return {
        ...state,
        updateOrganization: action.payload,
      };
    case actionTypes.storeConstants.SET_ALL_ORGANIZATION:
      return {
        ...state,
        AllOrganizationList: action.payload,
      };
    default:
      return state;
  }
};

export default OrganizationReducer;
