import React, { lazy, useEffect, useState, useContext, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { Route, Routes, useLocation, Navigate, useNavigate, useRoutes, Outlet } from 'react-router-dom';
import RaffleWall from '../../modules/Events/MyEvents/frames/Features/frames/Raffle/Frame/RaffleWall/RaffleWall';
import AuctionWall from '../../modules/Events/MyEvents/frames/Features/frames/Auction/Frame/AuctionWall/AuctionWall';
import SupplyItem from '../../modules/SupplyItem/SupplyItem';
import Preview from '../../modules/Events/MyEvents/frames/WhiteLabel/Preview';
import './styles.scss';
import { EventEmitter } from 'library/common/constants/event';
import { images } from '../../library/common/constants/ImageConstants';
import OrganiserProfile from '../../modules/Organization/AllOrganization/frames/OrganiserProfile';
import TermsAndCondition from '../../modules/Authentication/frames/Terms&Condition/terms_and_Condition';
import { fetchFromStorage, removeFromStorage } from '../../utility';
import headerResponseEventEmitter from 'library/utilities/headerResponse';
import { identifiers } from '../../library/common/constants/IdentifierConstants';
import { getNotificationList, setMailList } from '../../library/common/actions/NotificationAction';
import { getUserDetails, showNativePrompt } from '../../library/common/actions/AuthActions';
import { SocketContext } from '../../main/context/socket';
import RemoveOrganizationModal from '../../../src/modules/Authentication/frames/SignupOrganization/RemoveOrganizationModal';
import NewEventSelectModal from '../../../src/modules/Events/CreateEvent/NewEvent';
import VersionConroll from 'modules/VersionControll';

// import PublishOrgModal from './PublishOrgModal';

// import { saveToStorage } from '../../utility';

// import PrivateRoute from './PrivateRoute';
const AllOrganization = lazy(() => import('../../modules/Organization/AllOrganization/AllOrganization'));
const Notifications = lazy(() => import('../../modules/Notifications/Notifications'));
const Following = lazy(() => import('../../modules/Follow/Follow'));
const EventDetails = lazy(() => import('../../modules/EventDetails/EventDetails'));
const AppNavbar = lazy(() => import('library/common/components/AppNavbar'));
const Footer = lazy(() => import('library/common/components/Footer'));
const Home = lazy(() => import('modules/Home'));
const UserProfile = lazy(() => import('modules/UserProfile'));
const SearchResult = lazy(() => import('modules/SearchResult'));
const MobileViewSearch = lazy(() => import('modules/MobileViewSearch'));
const MobileViewSearchRearch = lazy(() => import('modules/MobileViewSearchResult'));
const MobileViewApplyFilter = lazy(() => import('modules/MobileViewApplyFilter'));
const Authentication = lazy(() => import('modules/Authentication'));
const Organization = lazy(() => import('modules/Organization'));
const CreateEvent = lazy(() => import('modules/Events/CreateEvent'));
const Checkout = lazy(() => import('modules/Checkout'));
const GuestCheckout = lazy(() => import('modules/Checkout/GuestCheckout/'));
const GuestAsEmail = lazy(() => import('modules/Checkout/GuestCheckout/EmailVerify'));
const ManageEvents = lazy(() => import('modules/Events/ManageEvents'));
const MyEvents = lazy(() => import('modules/Events/MyEvents'));
const MyEventsPage = lazy(() => import('modules/MyEvents/MyEventsPage'));
const MyContributionPage = lazy(() => import('../../library/common/components/EventCard/MyContribution'));
const ContributorSupplyItems = lazy(() => import('modules/ContributorSupplyItems/ContributorSupplyItems'));
const MailPage = lazy(() => import('modules/Mail/mail'));
// const Preview = lazy(() => import('modules/Events/MyEvents/frames/WhiteLabel/Preview'));
const ContributorSuplySearchResult = lazy(
  () => import('modules/ContributorSupplyItems/Frames/SearchResult/ContributorSuplySearchResult'),
);
const NotFound = lazy(() => import('modules/NotFound'));
const CrashPage = lazy(() => import('modules/CrashPage'));
const MaintainancePage = lazy(() => import('modules/MaintainancePage'));

const AllRoutes = ({ isLoggedIn, getNotificationList, getUserDetails, setMailList, modalToggle }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  // const headerResponse = useSelector(state => state.customHeaderReducer.headerResponse);
  // const hasUpgradeRequired = headerResponse.includes('Upgrade-Required');

  const [removeOrganizationModal, setRemoveOrganizationModal] = useState(false);
  const [headerUpgradeRequired, setHeaderUpgradeRequired] = useState(false);
  const [orgSocketData, setOrgSocketData] = useState({});
  if (location?.pathname === '/manage-event/' || location?.pathname === '/') {
    removeFromStorage(identifiers.color_data);
  }

  const { from } = location?.state || { from: { pathname: '/' } };
  const [close, setClose] = useState(true);
  const [isMaintenance, setIsMaintenance] = useState('');
  const [isMaintenanceType, setIsMaintenanceType] = useState('');
  const [isCrash, setIsCrash] = useState(false);
  const removeMsgRef = useRef(null);
  // useEffect(() => {
  //   setHeaderUpgradeRequired(hasUpgradeRequired);
  // }, [headerResponse]);

  useEffect(() => {
    const handleCustomHeadersUpdated = version => {
      if (version === 'Upgrade-Required') {
        setHeaderUpgradeRequired(true);
      }
    };

    headerResponseEventEmitter.on('headerResponseUpdated', handleCustomHeadersUpdated);

    return () => {
      headerResponseEventEmitter.off('headerResponseUpdated', handleCustomHeadersUpdated);
    };
  }, []);

  // CRASH REDIRECT
  // useEffect(() => {
  //   const handleHeadersDetails = response => {
  //     const errorCode = response?.status;
  //     if (errorCode === 404 || errorCode === 500 || errorCode === 503) {
  //       navigate('/crash');
  //     }
  //   };
  //   headerResponseEventEmitter.on('oktionCrash', handleHeadersDetails);

  //   return () => {
  //     headerResponseEventEmitter.off('oktionCrash', handleHeadersDetails);
  //   };
  // }, []);

  useEffect(() => {
    setIsCrash(false);
    document.body.classList.remove('crash');
    const handleHeadersDetails = response => {
      const errorCode = response?.status;
      if (errorCode === 404 || errorCode === 500 || errorCode === 503) {
        // localStorage.setItem(
        //   'previous_page',
        //   window.location.pathname === '/maintainance.html'
        //     ? '/'
        //     : window.location?.search !== ''
        //     ? window.location.pathname + window.location?.search
        //     : window.location.pathname,
        // );
        setTimeout(() => {
          setIsCrash(true);
          document.body.classList.add('crash');
        }, 10);
      }
    };
    headerResponseEventEmitter.on('oktionCrash', handleHeadersDetails);

    return () => {
      headerResponseEventEmitter.off('oktionCrash', handleHeadersDetails);
    };
  }, [location?.pathname]);

  // MAINTENANC REDIRECT
  useEffect(() => {
    const handleMaintenanceUpdated = maintenanceData => {
      setIsMaintenance(maintenanceData?.maintenance);
      if (maintenanceData?.maintenance === 'ON') {
        setIsMaintenanceType(maintenanceData?.maintenanceType);
        localStorage.setItem(
          'previous_page',
          window.location.pathname === '/maintainance.html'
            ? '/'
            : window.location?.search !== ''
              ? window.location.pathname + window.location?.search
              : window.location.pathname,
        );
        if (maintenanceData?.maintenanceType === 'redirect') {
          setTimeout(() => {
            window.location.href = '/maintainance.html';
          }, 10);
        }
      } else {
        localStorage.removeItem('previous_page');
      }
    };

    headerResponseEventEmitter.on('maintenanceMode', handleMaintenanceUpdated);

    return () => {
      headerResponseEventEmitter.off('maintenanceMode', handleMaintenanceUpdated);
    };
  }, []);

  let isLogin = location.pathname === '/welcome-message' || location.pathname === '/signup-organization';
  var checkForUserLogin = fetchFromStorage(identifiers.token);
  useEffect(() => {
    checkForUserLogin !== null && getNotificationList(1, 10);
    checkForUserLogin !== null && getUserDetails();
  }, [isLoggedIn]);

  useEffect(() => {
    // checkForUserLogin !== null && getNotificationList(1, 10);
    (location.pathname === '/login' || location.pathname === '/signup') && showNativePrompt();
    userSocket.socket.on('RemovedFromOrganisation', data => {
      handleRemoveOrg();
      setOrgSocketData(data);
      EventEmitter.dispatch('RemovedFromOrganisation', data);
    });
    setMailList();
  }, []);

  const handleRemoveOrg = () => {
    setRemoveOrganizationModal(true);
  };
  const handlePublish = () => {
    var currentOrg = JSON.parse(localStorage.getItem('currentOrganization'));
    if (currentOrg === orgSocketData?.data?.organisationId) {
      navigate('/all-organizations');
    } else {
      setRemoveOrganizationModal(false);
    }
    setRemoveOrganizationModal(false);
  };
  const handleToggle = () => {
    // setClose(false);
    if (removeMsgRef?.current) {
      removeMsgRef.current.classList.add('remove-msg');
    }
  };

  function AuthenticatedRoute({ component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props => (checkForUserLogin !== null ? <Component {...props} {...rest} /> : <Navigate to="/login" />)}
      />
    );
  }
  function PrivateRoute({ element, ...rest }) {
    const isAuthenticated = checkForUserLogin !== null;

    return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
  }

  let checkLocation =
    location.pathname.includes('/auction-wall/table/') ||
    location.pathname.includes('/auction-wall/item/') ||
    location.pathname.includes('/raffle-wall');

  var url = window.location.href;
  const spliturl = url?.split('/');
  const SplitRaffleUrl = url?.split('?');
  const eventId = spliturl && spliturl[spliturl?.length - 1];
  var raffleSplitUrl = spliturl && spliturl[spliturl?.length - 1];
  var event_id = parseInt(eventId);
  if (!isNaN(event_id)) {
    sessionStorage.setItem('urlValue', parseInt(eventId));
  }
  if (SplitRaffleUrl[SplitRaffleUrl?.length - 1] === 'event=Raffle') {
    sessionStorage.setItem('rafflrUrlValue', raffleSplitUrl);
    sessionStorage.removeItem('urlValue');
  }

  const Layout = () => {
    return (
      <>
        <Outlet />
      </>
    );
  };

  const publicPrivateRoutes = [
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '/terms-and-condition',
      element: <TermsAndCondition />,
    },
    {
      path: '/search-result',
      element: <SearchResult />,
    },
    {
      path: '/mobile-search',
      element: <MobileViewSearch />,
    },
    {
      path: '/mobile-search-result',
      element: <MobileViewSearchRearch />,
    },
    {
      path: '/mobile-apply-filter',
      element: <MobileViewApplyFilter />,
    },
    {
      path: '/raffle-wall',
      element: <RaffleWall />,
    },
    {
      path: '/auction-wall/:slug/:id',
      element: <AuctionWall />,
    },
    // {
    //   path: '/event-details',
    //   element: <EventDetails />,
    // },
    {
      path: '/event-details/:id',
      element: <EventDetails />,
    },
    {
      path: '/following',
      element: <Following />,
    },
    {
      path: '/preview/:id',
      element: <Preview />,
    },
    {
      path: '/guest-checkout',
      element: <GuestCheckout />,
    },
    {
      path: '/guest-as-email',
      element: <GuestAsEmail />,
    },
    {
      path: '/not-found',
      element: <NotFound />,
    },
    // Add more routes if needed
    {
      path: '*',
      element: <Navigate to="/not-found" />,
    },
  ];

  const publicRoutes = [
    {
      path: '/',
      element: <Layout />,
      children: [
        ...publicPrivateRoutes,
        {
          path: '/login',
          element: <Authentication />,
        },
        {
          path: '/signup',
          element: <Authentication />,
        },
        {
          path: '/guest-signup',
          element: <Authentication />,
        },
        {
          path: '/verify',
          element: <Authentication />,
        },
        {
          path: '/resend',
          element: <Authentication />,
        },
        {
          path: '/forgot-password',
          element: <Authentication />,
        },
        {
          path: '/forgot-reset-password',
          element: <Authentication />,
        },
        {
          path: '/welcome-message',
          element: <Authentication />,
        },
      ],
    },
  ];

  const privateRoutes = [
    {
      path: '/',
      element: <Layout />,
      children: [
        ...publicPrivateRoutes,
        {
          path: '/signup-organization',
          element: <Authentication />,
        },
        {
          path: '/organization/:id',
          element: <Organization />,
        },
        {
          path: '/create-event',
          element: <CreateEvent />,
        },
        {
          path: '/checkout',
          element: <Checkout />,
        },
        {
          path: '/manage-event',
          element: <ManageEvents />,
        },
        {
          path: '/manage-event/:id',
          element: <ManageEvents />,
        },
        {
          path: '/my-event/:id',
          element: <MyEvents />,
        },
        {
          path: '/my-event-page',
          element: <MyEventsPage />,
        },
        {
          path: '/my-event-page/contribution/:id',
          element: <MyContributionPage />,
        },
        {
          path: '/user-profile',
          element: <UserProfile />,
        },
        {
          path: '/contributor-supply-items',
          element: <ContributorSupplyItems />,
        },
        {
          path: '/contributor-supply-search-result',
          element: <ContributorSuplySearchResult />,
        },
        {
          path: '/supply-item',
          element: <SupplyItem />,
        },
        {
          path: '/social-links',
          element: <SupplyItem />,
        },
        {
          path: '/private-event-details/:id',
          element: <EventDetails />,
        },
        {
          path: '/notifications',
          element: <Notifications />,
        },
        {
          path: '/all-organizations',
          element: <AllOrganization />,
        },
        {
          path: '/organiser-profile/:id',
          element: <OrganiserProfile />,
        },
        {
          path: '/mail',
          element: <MailPage />,
        },
        {
          path: '/mail/:id',
          element: <MailPage />,
        },
      ],
    },
  ];

  const viewRoutes = checkForUserLogin !== null ? privateRoutes : publicRoutes;
  const routing = useRoutes(viewRoutes);

  return (
    <>
      {checkLocation === false && isMaintenance !== 'ON' && <AppNavbar isLogin={isLogin} />}
      {/* <AppNavbar isLogin={isLogin} /> */}
      <div
        className={
          location.pathname === '/my-event-page'
            ? ' bg-gray main-content'
            : location.pathname === '/following'
              ? 'bg-gray main-content container-main'
              : location.pathname === '/notifications'
                ? 'container-main main-content'
                : location.pathname === '/mail'
                  ? 'container-main main-content'
                  : 'main-content'
        }>
        {close === true ? (
          <div
            ref={removeMsgRef}
            className={location.pathname === '/organization/organiser-details' ? 'welcome-message' : ''}>
            {location.pathname === '/organization/organiser-details' ? (
              <>
                <p>{OKTION_VARIABLE?.OKTION_WELCOME_TEXT}</p>
                <div>
                  <img className="close-icon cursor-pointer" onClick={handleToggle} src={images.close} alt="" />
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
        {isMaintenance === 'ON' && isMaintenanceType === 'inline' && <MaintainancePage />}
        {isCrash && <CrashPage />}
        {isMaintenance !== 'ON' && isMaintenanceType !== 'inline' && !isCrash && (
          <>
            {/* {routing} */}
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/login" element={<Authentication />} />
              <Route exact path="/signup" element={<Authentication />} />
              <Route exact path="/guest-signup" element={<Authentication />} />
              <Route exact path="/verify" element={<Authentication />} />
              <Route exact path="/resend" element={<Authentication />} />
              <Route exact path="/forgot-password" element={<Authentication />} />
              <Route exact path="/terms-and-condition" element={<TermsAndCondition />} />
              <Route exact path="/forgot-reset-password" element={<Authentication />} />
              <Route exact path="/welcome-message" element={<Authentication />} />
              <Route exact path="/search-result" element={<SearchResult />} />
              <Route exact path="/mobile-search" element={<MobileViewSearch />} />
              <Route exact path="/mobile-search-result" element={<MobileViewSearchRearch />} />
              <Route exact path="/mobile-apply-filter" element={<MobileViewApplyFilter />} />
              <Route exact path="/raffle-wall" element={<RaffleWall />} />
              <Route exact path="/auction-wall/:slug/:id" element={<AuctionWall />} />
              <Route exact path="/event-details" element={<EventDetails />} />
              <Route path={'/event-details/:id'} element={<EventDetails />} />
              <Route exact path="/event-details/:id" element={<EventDetails />} />
              <Route exact path="/following" element={<Following />} />
              <Route exact path="/preview/:id" element={<Preview />} />
              <Route exact path="/guest-checkout" element={<GuestCheckout />} />
              <Route exact path="/guest-as-email" element={<GuestAsEmail />} />
              <Route element={<PrivateRoute />}>
                <Route exact path="/signup-organization" element={<Authentication />} />
                <Route exact path="/organization/:id" element={<Organization />} />
                <Route exact path="/create-event" element={<CreateEvent />} />
                <Route exact path="/checkout" element={<Checkout />} />
                <Route exact path="/manage-event" element={<ManageEvents />} />
                <Route exact path="/manage-event/:id" element={<ManageEvents />} />
                <Route exact path="/my-event/:id" element={<MyEvents />} />
                <Route exact path="/my-event-page" element={<MyEventsPage />} />
                <Route exact path="/my-event-page/contribution/:id" element={<MyContributionPage />} />
                <Route exact path="/user-profile" element={<UserProfile />} />
                <Route exact path="/contributor-supply-items" element={<ContributorSupplyItems />} />
                <Route exact path="/contributor-supply-search-result" element={<ContributorSuplySearchResult />} />
                <Route exact path="/supply-item" element={<SupplyItem />} />
                <Route exact path="/social-links" element={<SupplyItem />} />
                <Route exact path={'/private-event-details/:id'} element={<EventDetails />} />
                <Route exact path="/notifications" element={<Notifications />} />
                <Route exact path={'/all-organizations'} element={<AllOrganization />} />
                <Route exact path="/organiser-profile/:id" element={<OrganiserProfile />} />
                <Route exact path="/mail" element={<MailPage />} />
                <Route exact path="/mail/:id" element={<MailPage />} />
              </Route>
              <Route exact path="/not-found" element={<NotFound />} />
              <Route render={() => <Navigate to="/not-found" />} />
            </Routes>

            {/* <Routes>
              <Route exact path="/" component={Home} />
              <Route exact path="/login" component={Authentication} />
              <Route exact path="/signup" component={Authentication} />
              <Route exact path="/guest-signup" component={Authentication} />
              <Route exact path="/verify" component={Authentication} />
              <Route exact path="/resend" component={Authentication} />
              <Route exact path="/forgot-password" component={Authentication} />
              <Route exact path="/terms-and-condition" component={TermsAndCondition} />
              <Route exact path="/forgot-reset-password" component={Authentication} />
              <Route exact path="/welcome-message" component={Authentication} />
              <AuthenticatedRoute exact path="/signup-organization" component={Authentication} />
              <Route exact path="/search-result" component={SearchResult} />
              <Route exact path="/mobile-search" component={MobileViewSearch} />
              <Route exact path="/mobile-search-result" component={MobileViewSearchRearch} />
              <Route exact path="/mobile-apply-filter" component={MobileViewApplyFilter} />
              <AuthenticatedRoute exact path="/organization/:id" component={Organization} />
              <AuthenticatedRoute exact path="/create-event" component={CreateEvent} />
              <AuthenticatedRoute exact path="/checkout" component={Checkout} />
              <AuthenticatedRoute exact path="/manage-event" component={ManageEvents} />
              <AuthenticatedRoute exact path="/manage-event/:id" component={ManageEvents} />
              <AuthenticatedRoute exact path="/my-event/:id" component={MyEvents} />
              <AuthenticatedRoute exact path="/my-event-page" component={MyEventsPage} />
              <AuthenticatedRoute exact path="/my-event-page/contribution/:id" component={MyContributionPage} />
              <Route exact path="/raffle-wall" component={RaffleWall} />
              <Route exact path="/auction-wall/:slug/:id" component={AuctionWall} />
              <AuthenticatedRoute exact path="/user-profile" component={UserProfile} />
              <AuthenticatedRoute exact path="/contributor-supply-items" component={ContributorSupplyItems} />
              <AuthenticatedRoute
                exact
                path="/contributor-supply-search-result"
                component={ContributorSuplySearchResult}
              />
              <AuthenticatedRoute exact path="/supply-item" component={SupplyItem} />
              <AuthenticatedRoute exact path="/social-links" component={SupplyItem} />
              <Route exact path="/event-details" component={EventDetails} />
              <Route path={'/event-details/:id'} component={EventDetails} />
              <AuthenticatedRoute path={'/private-event-details/:id'} component={EventDetails} />
              <Route exact path="/event-details/:id" component={EventDetails} />
              <AuthenticatedRoute exact path="/notifications" component={Notifications} />
              <Route exact path="/following" component={Following} />
              <AuthenticatedRoute exact path={'/all-organizations'} component={AllOrganization} />
              <Route exact path="/preview/:id" component={Preview} />
              <AuthenticatedRoute exact path="/organiser-profile/:id" component={OrganiserProfile} />
              <AuthenticatedRoute exact path="/mail" component={MailPage} />
              <AuthenticatedRoute exact path="/mail/:id" component={MailPage} />
              <Route exact path="/guest-checkout" component={GuestCheckout} />
              <Route exact path="/guest-as-email" component={GuestAsEmail} />
              <Route exact path="/not-found" component={NotFound} />
              <Route render={() => <Navigate to="/not-found" />} />
            </Routes> */}
          </>
        )}
      </div>
      {!checkLocation && location.pathname !== '/crash' && !isCrash && isMaintenance !== 'ON' && <Footer />}
      <RemoveOrganizationModal
        isToggle={removeOrganizationModal}
        toggle={setRemoveOrganizationModal}
        handleClick={handlePublish}
        orgSocketData={orgSocketData}
      />
      <NewEventSelectModal iseventToggle={modalToggle} />
      <VersionConroll isToggle={headerUpgradeRequired} />
    </>
  );
};

const mapStateToProps = ({ authReducer, eventReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
    modalToggle: eventReducer.ModalToggle,
  };
};
export default connect(mapStateToProps, { getNotificationList, getUserDetails, setMailList })(AllRoutes);
