import * as actionTypes from '../constants/StoreConstants';
import { storeConstants } from '../constants/StoreConstants';
import { identifiers } from '../constants/IdentifierConstants';
import { fetchFromStorage, removeFromStorage, saveToStorage } from '../../../utility';
import axiosInstance from '../../../main/axios';
import { URLS } from '../constants/UrlConstants';
import { EventEmitter } from 'library/common/constants/event';
import Config from '../../../utility/Config';

// export const setAuth = user => dispatch => {
//   dispatch({ type: actionTypes.SET_AUTH, payload: user });
// };

// ONESIGNAL CONFIG START
window.OneSignal = window.OneSignal || [];
var OneSignal = window.OneSignal;

export const initOneSignalNotification = () => {
  OneSignal.push(function () {
    OneSignal.init({
      appId: Config.oneSignalAppId,
      // safari_web_id: config.ONE_SIGNAL_SAFARI_WEB_ID,
      // notifyButton: {
      //   enable: true,
      // },
      notificationClickHandlerMatch: 'origin',
      notificationClickHandlerAction: 'focus',
    });
  });
};
export const showNativePrompt = () => {
  OneSignal.push(function () {
    OneSignal.showNativePrompt();
  });
};
export const getUserId = () => {
  var user_Id;
  OneSignal.push(function () {
    user_Id = OneSignal.getUserId(function (userId) {
      return userId;
    });
  });
  return user_Id;
};
export function addListenerForNotificationOpened() {
  // OneSignal.push(function () {
  //   OneSignal.on('notificationDisplay', function (event) {
  //     console.warn('OneSignal notification displayed:', event);
  //   });
  //   //This event can be listened to via the `on()` or `once()` listener
  // });
  OneSignal.push([
    'addListenerForNotificationOpened',
    function (data) {
      // url={`${Config.BaseURL}event-details/${eventId}`}
      EventEmitter.dispatch('handlenotificationredirect', data);
      // handleNotificationRedirect();
    },
  ]);
  // function notifListen() {
  //   OneSignal.push([
  //     'addListenerForNotificationOpened',
  //     notificationData => {
  //       console.log('Received NotificationOpened:', notificationData);
  //       // EventEmitter.dispatch("receivedNotificationOpened", notificationData);
  //       notifListen();
  //     },
  //   ]);
  // }
  // notifListen();
}
export function isPushNotificationsEnabled() {
  OneSignal.push(function () {
    OneSignal.isPushNotificationsEnabled().then(function (isEnabled) {
      if (isEnabled) console.log('Push notifications are enabled!');
      else console.log('Push notifications are not enabled yet.');
    });
  });
}
export function setDefaultNotificationUrl(urlValue) {
  initOneSignalNotification();
  var redirectUrl = urlValue === undefined ? `${Config.socketUrl}` : `${Config.socketUrl}${urlValue}`;
  OneSignal.push(function () {
    OneSignal.setDefaultNotificationUrl(redirectUrl);
    // OneSignal.setDefaultNotificationUrl('http://192.168.0.130:3000');
  });
}
// END

export const setAuthentication = token => dispatch => {
  saveToStorage(identifiers.token, token);
  dispatch({
    type: storeConstants.SET_AUTHENTICATION,
    payload: token,
  });
};

// export const getAuth = () => dispatch => {
//   dispatch({ type: actionTypes.GET_AUTH });
// };

export const getAuth = () => dispatch => {
  const token = fetchFromStorage(identifiers.token);
  if (token) {
    dispatch(setAuthentication(token));
  }
};

export const logout = () => dispatch => {
  localStorage.removeItem('color_data');
  removeFromStorage(identifiers.token);
  removeFromStorage(identifiers.userDetail);
  removeFromStorage(identifiers.eventDetails);
  removeFromStorage(identifiers.is_view);
  removeFromStorage(identifiers.countryDetails);
  removeFromStorage(identifiers.currentOrganization);
  removeFromStorage(identifiers.color_data);
  removeFromStorage(identifiers.urlValue);
  removeFromStorage(identifiers.rafflrUrlValue);
  removeFromStorage(identifiers?.guestcheckoutdata);
  sessionStorage.removeItem('urlValue');
  sessionStorage.removeItem('rafflrUrlValue');
  dispatch({ type: actionTypes.LOGOUT });
};

export const setEmailForVerification = email => dispatch => {
  dispatch({ type: actionTypes.storeConstants.EMAIL_VERIFICATION, payload: email });
};

export const setEmailForForgotPassword = email => dispatch => {
  dispatch({ type: actionTypes.storeConstants.EMAIL_FORGOT_PASSWORD, payload: email });
};

export const getUserDetails = () => dispatch => {
  return axiosInstance
    .get(URLS.getUserDeatails)
    .then(({ status, data }) => {
      if (status === 200 || status === 201 || status === 304) {
        saveToStorage(identifiers.userDetail, data && data.data.user);
        const userDetail = fetchFromStorage(identifiers.userDetail);
        userDetail?.CurrentOrgDetails?.id !== undefined &&
          saveToStorage(identifiers.currentOrganization, userDetail?.CurrentOrgDetails?.id);
        dispatch({
          type: storeConstants.GET_USER_DETAILS,
          payload: data,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: storeConstants.GET_USER_DETAILS,
        payload: [],
      });
    });
};

export const setLoginUserData = data => dispatch => {
  dispatch({ type: actionTypes.storeConstants.SET_LOGIN_USER_DATA, payload: data });
};

export const getCurrentCountryDetails = () => dispatch => {
  return axiosInstance
    .get(URLS.countryDetails)
    .then(({ status, data }) => {
      if (status === 200 || status === 201 || status === 304) {
        // saveToStorage(identifiers.userDetail, data && data.data.user);
        dispatch({
          type: storeConstants.GET_COUNTRY_DETAILS,
          payload: data,
        });
        saveToStorage(identifiers.countryDetails, data);
      }
    })
    .catch(() => {
      dispatch({
        type: storeConstants.GET_COUNTRY_DETAILS,
        payload: [],
      });
    });
};

export const getCurrencyList = data => dispatch => {
  dispatch({ type: actionTypes.storeConstants.GET_CURRENCY_LIST, payload: data });
};

export const setPublicCategoryList = data => dispatch => {
  dispatch({ type: actionTypes.storeConstants.GET_CATEGORY_LIST, payload: data });
};

export const handleMenuStatus = data => dispatch => {
  dispatch({ type: actionTypes.storeConstants.MOBILE_MENU_STATUS, payload: data });
};
