import React, { useState, useEffect, useContext } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { images } from '../../library/common/constants/ImageConstants';
import './styles.scss';
// import { eventDetailsPageData } from './EventDetailsTab';
import EventCard from '../../library/common/components/EventCard';
// import { data } from '../Home/data';
// import Auction from './Frames/Auction/Auction';
import EventDetailsAuctionModal from './Frames/Auction/Frame/EventDetailsAuctionModal/EventDetailsAuctionModal';
import TabWrapper from './TabWrapper';
import axiosInstance from '../../main/axios';
import { URLS } from '../../library/common/constants/UrlConstants';
import { fetchFromStorage } from '../../utility';
import { identifiers } from '../../library/common/constants/IdentifierConstants';
import PageLoader from '../../library/common/components/PageLoader';
import { ReactComponent as SocialFacebook } from '../../resources/images/social_facebook.svg';
import { ReactComponent as SocialInstagram } from '../../resources/images/social_instagram.svg';
import { ReactComponent as SocialYoutube } from '../../resources/images/social_youtube.svg';
import { ReactComponent as SocialTwitter } from '../../resources/images/social_twitter.svg';
import { ReactComponent as SocialLinkedin } from '../../resources/images/social_linkedin.svg';
import { ReactComponent as SocialPintrest } from '../../resources/images/social_pintrest.svg';
import { ReactComponent as SocialCustom } from '../../resources/images/social_custom.svg';
// import { timeDiffCalc } from '../../library/common/constants/function';
// import { connectSocket, initSocketEvents } from '../../main/socket/socket.services';
import moment from 'moment';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import { images } from '../../library/common/constants/ImageConstants';
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  // RedditShareButton,
} from 'react-share';
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaPinterest,
  // FaShareAlt,
  FaWhatsapp,
  FaCopy,
  // FaRedditAlien,
} from 'react-icons/fa';
import { IconButton } from '@mui/material';
import Config from '../../utility/Config';
// import { saveToStorage } from '../../utility';
import Avatar from '@mui/material/Avatar';
import { EventEmitter } from 'library/common/constants/event';
import NoOrgModal from '../../modules/Events/CreateEvent/NoOrganisationModal';
import parse from 'html-react-parser';
import anchorme from 'anchorme';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { connect } from 'react-redux';
import LogoutModal from './Frames/Raffle/LogoutModal';
// import ActionMessage from '../../library/common/components/ActionMessage';
import Countdown from 'react-countdown';
import { SocketContext } from '../../main/context/socket';
import { goDeepLinking } from '../../library/common/constants/function';
import { setApiMessage, getAppLinkByMobileOS } from '../../library/common/constants/function';

// import AppBanner from 'smart-app-banner-react';
import { MobileView } from 'react-device-detect';

import { CurrencyFormat } from 'utility';

const EventDetails = props => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  // const [activeTab, setActiveTab] = useState(1);
  // const [isToggle, setToggle] = useState(true);
  const [whitelabel, setWhitelabel] = useState('');
  // const [eventdetail, setEventDetail] = useState({});
  const [isToggle, setToggle] = useState(false);
  const [eventOutfit, setEventOutfit] = useState();
  const [loading, setloader] = useState(false);
  const [isSocialMedia, setSocialMediaFlag] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [eventFeatures, setEventFeatures] = useState('');
  const [is_toggle, setIstoggle] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isloginToggle, setLoginToggle] = useState(false);
  const [socialData, setSocialData] = useState([]);
  const [eventOrgId, setEventOrgId] = useState(null);
  const [modalErrorText, setModalErrorText] = useState('');

  // const [message, setMessage] = useState({
  //   display: false,
  //   type: '',
  //   message: '',
  // });
  // const setActionMessage = (display = false, type = '', message = '') => {
  //   setMessage({
  //     display,
  //     type,
  //     message,
  //   });
  // };

  const params = useParams();

  const appInfo = {
    imageUrl: images.app_logo,
    name: 'Oktion',
    publisher: 'DigiGround Pty Ltd',
    infoText: 'Free',
    linkButtonText: 'VIEW',
    linkUrl: '',
  };

  appInfo.linkUrl = getAppLinkByMobileOS();

  var eventId = params?.id !== undefined ? params.id : eventDetails?.id;
  useEffect(() => {
    // goDeepLinking(`event-details/${params?.id}`);   // cause error message from Safari
    var checkForUserLogin = fetchFromStorage(identifiers.token);
    // var userDetail = fetchFromStorage(identifiers.userDetail);
    // getEvent();
    if (checkForUserLogin !== null) {
      getEventDetailsByToken();
    } else {
      getEventDetailsById();
    }
    setWhitelabel(props.whitelabel);

    // connect socket
    // var socketConnect = connectSocket('/api/socket.io/');
    // join socket
    // socketConnect !== null && initSocketEvents();
    // socketConnect !== null &&
    // userSocket.emit('JoinSocket', {
    //   userId: Number(userDetail?.id),
    // });
    userSocket.socket.on('currentBid', data => {
      EventEmitter.dispatch('socketUpdateBiddingData', data);
    });
    userSocket.socket.on('EventDetail', data => {
      EventEmitter.dispatch('socketUpdateEventDetailsData', data);
    });
    Object.keys(params).length === 0 && navigate('/');
  }, []);

  useEffect(() => {
    eventOrgId && GetSocialLinks();
  }, [eventOrgId]);

  useEffect(() => {
    EventEmitter.subscribe('activeTabData', event => setActiveTab(event));
    EventEmitter.subscribe('setFeaturesData', event => setEventFeatures(event));
  }, []);
  useEffect(() => {
    EventEmitter.subscribe('socketUpdateEventDetailsData', event => {
      Number(params?.id) === event?.events?.id && setEventOutfit(event?.events && event?.events);
      // if (event?.events?.id === eventId) {
      // }
      // const updatedEventDetails = event.events.map(
      //   (obj, index) => {
      //     // console.log('objobj', obj, event?.item);
      //     return event?.item?.id === obj?.id ? event?.item : obj;
      //   },
      // );
      // setLotArray(updateditemFollowingCardData);
      // console.log('updateditemFollowingCardData', updateditemFollowingCardData);
      // getDonadationData();
    });
  });
  var eventDetails = fetchFromStorage(identifiers.eventDetails);
  // var eventDetails = fetchFromStorage(identifiers.publicEventDetails);
  // const url = window.location.pathname;
  // const spliturl = url.split('/');
  // const id = spliturl[spliturl.length - 1];

  // console.log('eventdetails eventId', eventId);
  // const getEvent = async () => {
  //   // setLoading(true);
  //   const eventDetails = fetchFromStorage(identifiers.eventDetails);
  //   const eventId = eventDetails?.id;
  //   try {
  //     const { status, data } = await axiosInstance.get(URLS.getByEventIdData(eventId));
  //     if (status === 200) {
  //       console.log('datadatadatafile',data?.data?.events)
  //       setEventDetail(data?.data?.events);
  //       // setLoading(false);
  //     }
  //   } catch (err) {
  //     // setLoading(false);
  //   }
  // };
  // console.log('props?.previewPropsValue?.hexCode', props?.previewPropsValue?.hexCode);

  const GetSocialLinks = async () => {
    setloader(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.getsocialLInks(eventOrgId));
      if (status === 201) {
        let socialLinks = data?.data?.OrganisationSocialLinksList?.filter(item => {
          return item?.linkUrl !== '';
        });
        let otherLinks = data?.data?.OrganisationCustomSocialLinksList?.filter(item => {
          return item?.linkUrl !== '';
        });
        setSocialData([...socialLinks, ...otherLinks]);

        setloader(false);
      }
    } catch (err) {
      setloader(false);
    }
  };

  const getSocialIcon = type => {
    if (type === 'facebook') {
      return <SocialFacebook />;
    } else if (type === 'instagram') {
      return <SocialInstagram />;
    } else if (type === 'youtube') {
      return <SocialYoutube />;
    } else if (type === 'twitter') {
      return <SocialTwitter />;
    } else if (type === 'linkedin') {
      return <SocialLinkedin />;
    } else if (type === 'pinterest') {
      return <SocialPintrest />;
    } else if (type === 'custom') {
      return <SocialCustom />;
    }
  };

  const getEventDetailsById = async () => {
    setloader(true);
    const previewStatus = location?.state?.previewStatus;
    var prevStatus = previewStatus !== undefined ? previewStatus : 0;
    try {
      const { status, data } = await axiosInstance.get(URLS.getPublicEventByid(eventId, prevStatus));
      // console.log('datadatadata', data);
      setloader(false);
      if (status === 200 || status === 304) {
        if (data?.msg === 'You are not authorized') {
          setModalErrorText('');
          setIstoggle(true);
        } else {
          setEventOrgId(data?.data?.events?.organisation?.id);
          // if (props.whitelabel) {
          var tempData = data?.data?.events;

          // console.log('previewPropsValue', props.previewPropsValue);
          tempData = {
            ...tempData,
            primaryColour: props?.previewPropsValue?.hexCode[0],
            primaryTextColour: props?.previewPropsValue?.hexCode[1],
            secondaryColour: props?.previewPropsValue?.hexCode[2],
            secondaryTextColour: props?.previewPropsValue?.hexCode[3],
            background: props?.previewPropsValue?.background,
            pictureFit: props?.previewPropsValue?.backgroundSize,
            backgroundProperty: props?.previewPropsValue?.backgroundProperty,
          };

          setEventOutfit(tempData);

          // } else {
          //   setEventOutfit(data?.data?.events);
          // }
        }
      }
    } catch (err) {
      setloader(false);
      if (err?.response?.data?.errorCode === 'ERR_EVENT_SUSPENDED_CANCELLED') {
        setIstoggle(true);
        setModalErrorText(err?.response?.data?.msg);
      } else if (err?.response?.data?.msg === 'No such event exists') {
        navigate('/');
      }
    }
  };

  const handleCopyLink = async => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    setApiMessage('success', `${OKTION_VARIABLE?.OKTION_COPIED}!`);
    // setActionMessage(true, 'Success', `${OKTION_VARIABLE?.OKTION_COPIED}!`);
    // setTimeout(() => {
    //   setActionMessage(false);
    // }, 3000);
  };

  const getEventDetailsByToken = async () => {
    var token = fetchFromStorage(identifiers.token);
    const previewStatus = location?.state?.previewStatus;
    var prevStatus = previewStatus !== undefined ? previewStatus : 0;
    setloader(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.getPublicEventByToken(eventId, token?.token, prevStatus));
      setloader(false);
      if (status === 200 || status === 304) {
        // console.log('props.whitelabel');
        if (data?.msg === 'You are not authorized') {
          setModalErrorText('');
          setIstoggle(true);
        } else {
          setEventOrgId(data?.data?.events?.organisation?.id);
          if (props.whitelabel) {
            var tempData = data?.data?.events;

            tempData = {
              ...tempData,
              primaryColour: props?.previewPropsValue?.hexCode[0],
              primaryTextColour: props?.previewPropsValue?.hexCode[1],
              secondaryColour: props?.previewPropsValue?.hexCode[2],
              secondaryTextColour: props?.previewPropsValue?.hexCode[3],
              background: props?.previewPropsValue?.background,
              pictureFit: props?.previewPropsValue?.backgroundSize,
              backgroundProperty: props?.previewPropsValue?.backgroundProperty,
            };
            setEventOutfit(tempData);
          } else {
            setEventOutfit(data?.data?.events);
          }
        }
      }
    } catch (err) {
      setloader(false);
      if (err?.response?.data?.errorCode === 'ERR_EVENT_SUSPENDED_CANCELLED') {
        setModalErrorText(err?.response?.data?.msg);
        setIstoggle(true);
      } else if (err?.response?.data?.msg === 'No such event exists') {
        navigate('/');
      }
    }
  };

  const toggle = () => {
    setToggle(!isToggle);
  };
  const toggleModal = () => {
    setIstoggle(!is_toggle);
  };
  const handleClick = () => {
    navigate(`/organiser-profile/${eventOutfit?.organisation?.id}`, {
      state: {
        eventId: params?.id,
        orgId: eventOutfit?.organisation?.id,
      },
    });
  };

  const handleButtonClick = () => {
    setSocialMediaFlag(!isSocialMedia);
  };
  const handleItemClick = event => {
    window.location.reload(false);

    navigate(`/event-details/${event?.id}`);
    // saveToStorage(identifiers.eventDetails, event);
    // saveToStorage(identifiers.publicEventDetails, event);
  };
  // const handleTabChange = activeTab => () => {
  //   setActiveTab(activeTab);
  // };
  const handleFollowClick = isFollow => {
    if (!props?.isLoggedIn) {
      setLoginToggle(true);
    }
    if (props?.isLoggedIn) {
      handleAddToFollow(isFollow);
    }
  };

  const handleAddToFollow = async isFollow => {
    try {
      if (isFollow === false) {
        setloader(true);
        const { status } = await axiosInstance.post(URLS.eventFollow(eventId));
        if (status === 200 || status === 201) {
          setApiMessage('success', OKTION_VARIABLE?.OKTION_EVENT_ADDED);
          // setActionMessage(true, 'Success', OKTION_VARIABLE?.OKTION_EVENT_ADDED);
          getEventDetailsByToken();
          /*     setTimeout(() => { */
          // navigate('/following');
          // setActionMessage(false);
          /*  }, 2000); */
          // setTimeout(() => {
          //   setActionMessage(false);
          // }, 5000);
        }
        setloader(false);
      } else {
        setloader(true);
        const { status } = await axiosInstance.post(URLS.eventUnFollow(eventId));
        if (status === 200 || status === 201) {
          setApiMessage('success', OKTION_VARIABLE?.OKTION_REMOVE_FOLLOWING_LIST);
          // setActionMessage(true, 'Success', OKTION_VARIABLE?.OKTION_REMOVE_FOLLOWING_LIST);
          getEventDetailsByToken();
          // setTimeout(() => {
          //   setActionMessage(false);
          // }, 5000);
        }
        setloader(false);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setApiMessage('error', err?.response?.data?.msg);
        // setActionMessage(true, 'Error', err?.response?.data?.msg);
        /*     setTimeout(() => { */
        // navigate('/following');
        /* }, 2000); */
        // setTimeout(() => {
        //   setActionMessage(false);
        // }, 5000);
        setloader(false);
      }
    }
    setloader(false);
  };
  const Logintoggle = () => {
    setLoginToggle(!isloginToggle);
  };
  const handleloginButtonClick = () => {
    navigate('/login', {
      redirectTo: 'event-details',
      eventId: params?.id,
    });
  };
  const renderTime = ({ hours, minutes, days, seconds }) => {
    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
      EventEmitter.dispatch('updateauctionlotdata');
    }
    return (
      <>
        {days === 0 && hours === 0 && minutes === 0 && seconds === 0 ? (
          <>
            {activeTab === '2' ? (
              <h4 className="text-center red-color mt-2 fw-500">{OKTION_VARIABLE?.OKTION_AUCTION_CLOSE}</h4>
            ) : activeTab === '3' ? (
              <h4 className="text-center red-color mt-2 fw-500">{OKTION_VARIABLE?.OKTION_DONATION_CLOSE}</h4>
            ) : activeTab === '4' ? (
              <h4 className="text-center red-color mt-2 fw-500">{OKTION_VARIABLE?.OKTION_RAFFLE_CLOSED}</h4>
            ) : activeTab === '5' ? (
              <h4 className="text-center red-color mt-2 fw-500">{OKTION_VARIABLE?.OKTION_TICKETS_CLOSED}</h4>
            ) : (
              ''
            )}
          </>
        ) : (
          <div>
            {days > 0 ? days : 0}{' '}
            {days === 0 || days === 1 ? OKTION_VARIABLE?.OKTION_DAY : OKTION_VARIABLE?.OKTION_DAYS}{' '}
            {hours > 0 ? hours : 0}{' '}
            {hours === 0 || hours === 1 ? OKTION_VARIABLE?.OKTION_HOUR : OKTION_VARIABLE?.OKTION_HOURS}{' '}
            {minutes > 0 ? minutes : 0}{' '}
            {minutes === 0 || minutes === 1 ? OKTION_VARIABLE?.OKTION_MINUTE : OKTION_VARIABLE?.OKTION_MINUTES}{' '}
            {OKTION_VARIABLE?.OKTION_LEFT}
          </div>
        )}
      </>
    );
  };
  const handleEventStartDate = activeTab => {
    var donationFeatureData = eventFeatures && eventFeatures?.filter((item, index) => item.type === 'donation');
    var auctionFeatureData = eventFeatures && eventFeatures?.filter((item, index) => item.type === 'auction');
    var ticketFeatureData = eventFeatures && eventFeatures?.filter((item, index) => item.type === 'ticket');
    var raffleFeatureData = eventFeatures && eventFeatures?.filter((item, index) => item.type === 'raffle');

    const handleDateAndTime = date => {
      var today = moment();
      var endDate = moment(date && date);
      let totalDays = 0;
      let totalHours = 0;
      let clearHours = 0;
      let totalMinutes = 0;
      let clearMinutes = 0;
      totalDays = endDate.diff(today, 'days');
      totalHours = endDate.diff(today, 'hours');
      clearHours = totalHours % 24;
      totalMinutes = endDate.diff(today, 'minutes');
      clearMinutes = totalMinutes % 60;
      return (
        <h4
          className="aColor fw-500"
          style={{
            color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
          }}>
          {totalDays > 0 ? totalDays : 0} {totalDays === 1 ? OKTION_VARIABLE?.OKTION_DAY : OKTION_VARIABLE?.OKTION_DAYS}{' '}
          {clearHours > 0 ? clearHours : 0}{' '}
          {clearHours === 1 ? OKTION_VARIABLE?.OKTION_HOUR : OKTION_VARIABLE?.OKTION_HOURS}{' '}
          {clearMinutes > 0 ? clearMinutes : 0}{' '}
          {clearMinutes === 1 ? OKTION_VARIABLE?.OKTION_MINUTE : OKTION_VARIABLE?.OKTION_MINUTES}{' '}
          {OKTION_VARIABLE?.OKTION_LEFT}
        </h4>
      );
    };
    let DATE_NOW = moment(new Date()).format('YYYY-MM-DD');
    let CURRENT_TIME = moment(new Date()).format('HHmm');
    let EVENT_END_DATE = moment(eventOutfit?.endDate).format('YYYY-MM-DD');
    let EVENT_END_TIME = moment(eventOutfit?.endDate).format('HHmm');

    switch (activeTab) {
      case '2':
        return (
          <>
            <div className="text-center mb-3">
              <p className="res-time-remaning">{OKTION_VARIABLE?.OKTION_TIME_REMAINING_AUCTION}</p>
              <h4
                className="aColor fw-500"
                style={{
                  color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                }}>
                {auctionFeatureData && auctionFeatureData[0]?.isActive === true && (
                  <>
                    {/* {handleDateAndTime(auctionFeatureData && auctionFeatureData[0]?.endTime)} */}
                    <Countdown
                      date={auctionFeatureData && auctionFeatureData[0]?.endTime}
                      renderer={renderTime}
                      key={auctionFeatureData && auctionFeatureData[0]?.endTime}
                    />
                  </>
                )}
                {/* {timeDiffCalc(new Date(), new Date(moment(auctionFeatureData && auctionFeatureData[0].endTime)))} left */}
              </h4>{' '}
              {auctionFeatureData && auctionFeatureData[0]?.isActive === false ? (
                moment(auctionFeatureData && auctionFeatureData[0]?.startTime).isSameOrAfter(moment()) === false ? (
                  <h4 className="text-center red-color mt-2 fw-500">{OKTION_VARIABLE?.OKTION_AUCTION_CLOSE}</h4>
                ) : (
                  <h4 className="text-center red-color mt-2 fw-500">{OKTION_VARIABLE?.OKTION_NOT_STARTED}</h4>
                )
              ) : null}
            </div>
            {eventOutfit?.raisedAmountStatus === 1 && (
              <div className="text-center mb-3 fw-500">
                <p>{OKTION_VARIABLE?.OKTION_AMOUNT_RAISED_AUCITON}</p>
                <h4
                  className="aColor text-amount"
                  style={{
                    color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                  }}>
                  {/* {eventOutfit?.currency != null &&
                  `${eventOutfit?.currency?.abbreviation} ${eventOutfit?.currency?.symbol}`}
                {parseFloat(eventOutfit?.auctionAmountRaised).toFixed(2)} raised */}
                  <CurrencyFormat
                    value={eventOutfit?.auctionAmountRaised || '0.00'}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={`${eventOutfit?.currency != null ? eventOutfit?.currency?.abbreviation : ''} ${
                      eventOutfit?.currency != null ? eventOutfit?.currency?.symbol : ''
                    }`}
                    // thousandSpacing="2s"
                    thousandSpacing={eventOutfit?.currency?.symbol === '₹' ? '2s' : '3'}
                    decimalScale="2"
                    fixedDecimalScale={true}
                  />{' '}
                  {OKTION_VARIABLE?.OKTION_RAISED}
                  {eventOutfit?.targetAmountStatus === 1 && (
                    <>
                      {' '}
                      /{' '}
                      <CurrencyFormat
                        value={eventOutfit?.targetAmount || '0.00'}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={`${eventOutfit?.currency != null ? eventOutfit?.currency?.symbol : ''}`}
                        thousandSpacing={eventOutfit?.currency?.symbol === '₹' ? '2s' : '3'}
                        // thousandSpacing="2s"
                        decimalScale="2"
                        fixedDecimalScale={true}
                      />{' '}
                      {OKTION_VARIABLE?.OKTION_TARGET}
                    </>
                  )}
                </h4>
              </div>
            )}
          </>
        );
      case '3':
        return (
          <>
            <div className="text-center mb-3">
              <p className="res-time-remaning">{OKTION_VARIABLE?.OKTION_TIME_REMAINING_DONATION}</p>
              <h4
                className="aColor fw-500"
                style={{
                  color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                }}>
                {/* {timeDiffCalc(new Date(), moment(donationFeatureData && donationFeatureData[0]?.endTime))} left */}
                {donationFeatureData && donationFeatureData[0]?.isActive === true && (
                  <>
                    {/* {handleDateAndTime(donationFeatureData && donationFeatureData[0]?.endTime)} */}
                    <Countdown
                      date={donationFeatureData && donationFeatureData[0]?.endTime}
                      renderer={renderTime}
                      key={donationFeatureData && donationFeatureData[0]?.endTime}
                    />
                  </>
                )}
              </h4>{' '}
              {donationFeatureData && donationFeatureData[0]?.isActive === false ? (
                moment(donationFeatureData && donationFeatureData[0]?.startTime).isSameOrAfter(moment()) === false ? (
                  <h4 className="text-center red-color mt-2 fw-500">{OKTION_VARIABLE?.OKTION_DONATION_CLOSE}</h4>
                ) : (
                  <h4 className="text-center red-color mt-2 fw-500">{OKTION_VARIABLE?.OKTION_DONATION_NOT_STARTED}</h4>
                )
              ) : null}
            </div>
            {eventOutfit?.raisedAmountStatus === 1 && (
              <div className="text-center mb-3 fw-500">
                <p>{OKTION_VARIABLE?.OKTION_TOTAL_RAISED_DONATION}</p>
                <h4
                  className="aColor text-amount"
                  style={{
                    color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                  }}>
                  {/* {eventOutfit?.currency != null &&
                  `${eventOutfit?.currency?.abbreviation} ${eventOutfit?.currency?.symbol}`}
                {parseFloat(eventOutfit?.donationAmountRaised).toFixed(2)} raised */}
                  <CurrencyFormat
                    value={eventOutfit?.donationAmountRaised || '0.00'}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={`${eventOutfit?.currency != null ? eventOutfit?.currency?.abbreviation : ''} ${
                      eventOutfit?.currency != null ? eventOutfit?.currency?.symbol : ''
                    }`}
                    thousandSpacing={eventOutfit?.currency?.symbol === '₹' ? '2s' : '3'}
                    // thousandSpacing="2s"
                    decimalScale="2"
                    fixedDecimalScale={true}
                  />{' '}
                  {OKTION_VARIABLE?.OKTION_RAISED}
                  {eventOutfit?.targetAmountStatus === 1 && (
                    <>
                      {' '}
                      /{' '}
                      <CurrencyFormat
                        value={eventOutfit?.targetAmount || '0.00'}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={`${eventOutfit?.currency != null ? eventOutfit?.currency?.symbol : ''}`}
                        thousandSpacing={eventOutfit?.currency?.symbol === '₹' ? '2s' : '3'}
                        // thousandSpacing="2s"
                        decimalScale="2"
                        fixedDecimalScale={true}
                      />{' '}
                      {OKTION_VARIABLE?.OKTION_TARGET}
                    </>
                  )}
                </h4>
              </div>
            )}
          </>
        );
      case '4':
        let today = moment(new Date()).format('YYYY-MM-DD');
        let currentTime = moment(new Date()).format('HHmm');
        let raffleDrawDate = moment(raffleFeatureData[0]?.startTime).format('YYYY-MM-DD');
        let raffleDrawTime = moment(raffleFeatureData[0]?.startTime).format('HHmm');

        return (
          <>
            <div className="text-center mb-3">
              <p className="res-time-remaning">{OKTION_VARIABLE?.OKTION_DASHBOARD_TIME_REMAINING} </p>
              {moment(raffleDrawDate).isAfter(today) ? (
                <>
                  <h4
                    className="aColor fw-500"
                    style={{
                      color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                    }}>
                    {/* {timeDiffCalc(new Date(), new Date(moment(eventOutfit?.endDate)))} left */}
                    {/* {raffleFeatureData && raffleFeatureData[0]?.isActive === true && ( */}
                    {/* <> */}
                    {/* {handleDateAndTime(raffleFeatureData && raffleFeatureData[0]?.startTime)}{' '} */}
                    <Countdown
                      date={raffleFeatureData && raffleFeatureData[0]?.startTime}
                      renderer={renderTime}
                      key={raffleFeatureData && raffleFeatureData[0]?.startTime}
                    />
                    {/* </> */}
                    {/* )} */}
                  </h4>
                </>
              ) : (
                <>
                  {today === raffleDrawDate && raffleDrawTime > currentTime ? (
                    <h4
                      className="aColor fw-500"
                      style={{
                        color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                      }}>
                      <Countdown
                        date={raffleFeatureData && raffleFeatureData[0]?.startTime}
                        renderer={renderTime}
                        key={raffleFeatureData && raffleFeatureData[0]?.startTime}
                      />
                    </h4>
                  ) : (
                    <h4 className="text-center red-color mt-2 fw-500">{OKTION_VARIABLE?.OKTION_RAFFLE_CLOSED}</h4>
                  )}
                </>
              )}
            </div>
            {eventOutfit?.raisedAmountStatus === 1 && (
              <div className="text-center mb-3 fw-500">
                <p>{OKTION_VARIABLE?.OKTION_AMOUNT_RAISED_RAFFLE}</p>
                <h4
                  className="aColor text-amount"
                  style={{
                    color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                  }}>
                  {/* {eventOutfit?.currency != null &&
                  `${eventOutfit?.currency?.abbreviation} ${eventOutfit?.currency?.symbol}`}
                {parseFloat(eventOutfit?.raffleAmountRaised).toFixed(2)} raised */}
                  <CurrencyFormat
                    value={eventOutfit?.raffleAmountRaised || '0.00'}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={`${eventOutfit?.currency != null ? eventOutfit?.currency?.abbreviation : ''} ${
                      eventOutfit?.currency != null ? eventOutfit?.currency?.symbol : ''
                    }`}
                    thousandSpacing={eventOutfit?.currency?.symbol === '₹' ? '2s' : '3'}
                    // thousandSpacing="2s"
                    decimalScale="2"
                    fixedDecimalScale={true}
                  />{' '}
                  {OKTION_VARIABLE?.OKTION_RAISED}
                  {eventOutfit?.targetAmountStatus === 1 && (
                    <>
                      {' '}
                      /{' '}
                      <CurrencyFormat
                        value={eventOutfit?.targetAmount || '0.00'}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={`${eventOutfit?.currency != null ? eventOutfit?.currency?.symbol : ''}`}
                        thousandSpacing={eventOutfit?.currency?.symbol === '₹' ? '2s' : '3'}
                        // thousandSpacing="2s"
                        decimalScale="2"
                        fixedDecimalScale={true}
                      />{' '}
                      {OKTION_VARIABLE?.OKTION_TARGET}
                    </>
                  )}
                </h4>
              </div>
            )}
          </>
        );
      case '5':
        return (
          <>
            <div className="text-center mb-3">
              <p className="res-time-remaning">{OKTION_VARIABLE?.OKTION_TIME_REMAINING_FOREVENT}</p>
              {moment(EVENT_END_DATE).isAfter(DATE_NOW) ? (
                <h4
                  className="aColor fw-500"
                  style={{
                    color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                  }}>
                  {/* {timeDiffCalc(new Date(), new Date(moment(ticketFeatureData && ticketFeatureData[0].endTime)))} left */}
                  {/* {handleDateAndTime(eventOutfit?.venueStartDate)} */}
                  <Countdown date={eventOutfit?.venueEndDate} renderer={renderTime} key={eventOutfit?.venueEndDate} />
                </h4>
              ) : (
                <>
                  {DATE_NOW === EVENT_END_DATE && EVENT_END_TIME > CURRENT_TIME ? (
                    <h4
                      className="aColor fw-500"
                      style={{
                        color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                      }}>
                      {/* {timeDiffCalc(new Date(), new Date(moment(ticketFeatureData && ticketFeatureData[0].endTime)))} left */}
                      {/* {handleDateAndTime(eventOutfit?.venueStartDate)} */}
                      <Countdown
                        date={eventOutfit?.venueEndDate}
                        renderer={renderTime}
                        key={eventOutfit?.venueEndDate}
                      />
                    </h4>
                  ) : (
                    <h4 className="text-center red-color mt-2 mb-3 fw-500">{OKTION_VARIABLE?.OKTION_EVENT_CLOSE}</h4>
                  )}
                </>
              )}
            </div>
            {eventOutfit?.raisedAmountStatus === 1 && (
              <div className="text-center mb-3 fw-500">
                <p>{OKTION_VARIABLE?.OKTION_AMOUNT_RAISED_TICKET}</p>
                <h4
                  className="aColor text-amount"
                  style={{
                    color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                  }}>
                  {/* {`${eventOutfit?.currency?.abbreviation} ${eventOutfit?.currency?.symbol}${parseFloat(
                    eventOutfit?.ticketAmountRaised,
                  ).toFixed(2)}`}{' '}
                  raised */}
                  <CurrencyFormat
                    value={eventOutfit?.ticketAmountRaised || '0.00'}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={`${eventOutfit?.currency != null ? eventOutfit?.currency?.abbreviation : ''} ${
                      eventOutfit?.currency != null ? eventOutfit?.currency?.symbol : ''
                    }`}
                    thousandSpacing={eventOutfit?.currency?.symbol === '₹' ? '2s' : '3'}
                    // thousandSpacing="2s"
                    decimalScale="2"
                    fixedDecimalScale={true}
                  />{' '}
                  {OKTION_VARIABLE?.OKTION_RAISED}
                  {eventOutfit?.targetAmountStatus === 1 && (
                    <>
                      {' '}
                      /{' '}
                      <CurrencyFormat
                        value={eventOutfit?.targetAmount || '0.00'}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={`${eventOutfit?.currency != null ? eventOutfit?.currency?.symbol : ''}`}
                        thousandSpacing={eventOutfit?.currency?.symbol === '₹' ? '2s' : '3'}
                        // thousandSpacing="2s"
                        decimalScale="2"
                        fixedDecimalScale={true}
                      />{' '}
                      {OKTION_VARIABLE?.OKTION_TARGET}
                    </>
                  )}
                </h4>
              </div>
            )}
          </>
        );
      case '6':
        return (
          <>
            <div className="text-center mb-3">
              <p className="res-time-remaning">{OKTION_VARIABLE?.OKTION_TOTAL_TIME_REMAINING}</p>
              {moment(EVENT_END_DATE).isAfter(DATE_NOW) ? (
                <h4
                  className="aColor fw-500"
                  style={{
                    color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                  }}>
                  {/* {timeDiffCalc(new Date(), new Date(moment(eventOutfit?.endDate)))} left */}
                  {/* {handleDateAndTime(eventOutfit?.endDate)}{' '} */}
                  <Countdown date={eventOutfit?.endDate} renderer={renderTime} key={eventOutfit?.endDate} />
                </h4>
              ) : (
                <>
                  {DATE_NOW === EVENT_END_DATE && EVENT_END_TIME > CURRENT_TIME ? (
                    <h4
                      className="aColor fw-500"
                      style={{
                        color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                      }}>
                      {/* {timeDiffCalc(new Date(), new Date(moment(eventOutfit?.endDate)))} left */}
                      {/* {handleDateAndTime(eventOutfit?.endDate)}{' '} */}
                      <Countdown date={eventOutfit?.endDate} renderer={renderTime} key={eventOutfit?.endDate} />
                    </h4>
                  ) : (
                    <h4 className="text-center red-color mt-2 fw-500">{OKTION_VARIABLE?.OKTION_EVENT_CLOSE}</h4>
                  )}
                </>
              )}
            </div>
            {eventOutfit?.raisedAmountStatus === 1 && (
              <div className="text-center mb-3 fw-500">
                <p>{OKTION_VARIABLE?.OKTION_TOTAL_AMOUNT_RAISED}</p>
                <h4
                  className="aColor text-amount"
                  style={{
                    color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                  }}>
                  {/* {eventOutfit?.currency != null &&
                  `${eventOutfit?.currency?.abbreviation} ${eventOutfit?.currency?.symbol}`}
                {parseFloat(eventOutfit?.amountRaised).toFixed(2)} raised */}
                  <CurrencyFormat
                    value={eventOutfit?.amountRaised || '0.00'}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={`${eventOutfit?.currency != null ? eventOutfit?.currency?.abbreviation : ''} ${
                      eventOutfit?.currency != null ? eventOutfit?.currency?.symbol : ''
                    }`}
                    thousandSpacing={eventOutfit?.currency?.symbol === '₹' ? '2s' : '3'}
                    // thousandSpacing="2s"
                    decimalScale="2"
                    fixedDecimalScale={true}
                  />{' '}
                  {OKTION_VARIABLE?.OKTION_RAISED}
                  {eventOutfit?.targetAmountStatus === 1 && (
                    <>
                      {' '}
                      /{' '}
                      <CurrencyFormat
                        value={eventOutfit?.targetAmount || '0.00'}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={`${eventOutfit?.currency != null ? eventOutfit?.currency?.symbol : ''}`}
                        thousandSpacing={eventOutfit?.currency?.symbol === '₹' ? '2s' : '3'}
                        // thousandSpacing="2s"
                        decimalScale="2"
                        fixedDecimalScale={true}
                      />{' '}
                      {OKTION_VARIABLE?.OKTION_TARGET}
                    </>
                  )}
                </h4>
              </div>
            )}
          </>
        );

      default:
        return (
          <>
            <div className="text-center mb-3">
              <p className="res-time-remaning">{OKTION_VARIABLE?.OKTION_TOTAL_TIME_REMAINING}</p>
              {moment(EVENT_END_DATE).isAfter(DATE_NOW) ? (
                <h4
                  className="aColor fw-500"
                  style={{
                    color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                  }}>
                  {/* {timeDiffCalc(new Date(), new Date(moment(eventOutfit?.endDate)))} left */}
                  {/* {handleDateAndTime(eventOutfit?.endDate)} */}
                  <Countdown date={eventOutfit?.endDate} renderer={renderTime} key={eventOutfit?.endDate} />
                </h4>
              ) : (
                <>
                  {DATE_NOW === EVENT_END_DATE && EVENT_END_TIME > CURRENT_TIME ? (
                    <h4
                      className="aColor fw-500"
                      style={{
                        color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                      }}>
                      {/* {timeDiffCalc(new Date(), new Date(moment(eventOutfit?.endDate)))} left */}
                      {/* {handleDateAndTime(eventOutfit?.endDate)} */}
                      <Countdown date={eventOutfit?.endDate} renderer={renderTime} key={eventOutfit?.endDate} />
                    </h4>
                  ) : (
                    <h4 className="text-center red-color mt-2 mb-3 fw-500">{OKTION_VARIABLE?.OKTION_EVENT_CLOSE}</h4>
                  )}
                </>
              )}
            </div>
            {eventOutfit?.raisedAmountStatus === 1 && (
              <div className="text-center mb-3 fw-500">
                <p>{OKTION_VARIABLE?.OKTION_TOTAL_AMOUNT_RAISED}</p>
                <h4
                  className="aColor text-amount"
                  style={{
                    color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                  }}>
                  {/* {eventOutfit?.currency != null &&
                  `${eventOutfit?.currency?.abbreviation} ${eventOutfit?.currency?.symbol}`}
                {parseFloat(eventOutfit?.amountRaised).toFixed(2)} raised */}
                  <CurrencyFormat
                    value={eventOutfit?.amountRaised || '0.00'}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={`${eventOutfit?.currency != null ? eventOutfit?.currency?.abbreviation : ''} ${
                      eventOutfit?.currency != null ? eventOutfit?.currency?.symbol : ''
                    }`}
                    thousandSpacing={eventOutfit?.currency?.symbol === '₹' ? '2s' : '3'}
                    // thousandSpacing="2s"
                    decimalScale="2"
                    fixedDecimalScale={true}
                  />{' '}
                  {OKTION_VARIABLE?.OKTION_RAISED}
                  {eventOutfit?.targetAmountStatus === 1 && (
                    <>
                      {' '}
                      /{' '}
                      <CurrencyFormat
                        value={eventOutfit?.targetAmount || '0.00'}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={`${eventOutfit?.currency != null ? eventOutfit?.currency?.symbol : ''}`}
                        thousandSpacing={eventOutfit?.currency?.symbol === '₹' ? '2s' : '3'}
                        // thousandSpacing="2s"
                        decimalScale="2"
                        fixedDecimalScale={true}
                      />{' '}
                      {OKTION_VARIABLE?.OKTION_TARGET}
                    </>
                  )}
                </h4>
              </div>
            )}
          </>
        );
    }
  };

  var backColor =
    location?.state?.hexCode?.background_picture === false ? location?.state?.hexCode?.background_color : '';
  var defBackColoc = eventOutfit?.background === 'color' ? eventOutfit?.backgroundProperty : '';

  var backPicture = location?.state?.background === 'picture' ? `url(${location?.state?.backgroundProperty})` : '';
  var defPicture = eventOutfit?.background === 'picture' ? `url(${eventOutfit?.backgroundProperty})` : '';
  return (
    <div
      className="eventDetails home-container default-color"
      style={{
        // background: eventOutfit?.background === 'picture' ? `url(${eventOutfit?.backgroundProperty})` : '',
        // backgroundColor: eventOutfit?.background === 'color' ? eventOutfit?.backgroundProperty : '',
        background: props?.whitelabel === true ? backPicture : defPicture,
        backgroundColor: props?.whitelabel === true ? backColor : defBackColoc,
        backgroundSize: eventOutfit?.background === 'picture' ? eventOutfit?.pictureFit : 'contain',
        backgroundPosition: eventOutfit?.background === 'picture' ? eventOutfit?.pictureFit : '',
        backgroundRepeat: eventOutfit?.background === 'picture' ? eventOutfit?.pictureFit : '',
      }}>
      <div className="container">
        <MobileView>
          {/* <AppBanner appInfo={appInfo} position="bottom" onClose={() => {}} hidden={false} style={{ zIndex: 15 }} /> */}
        </MobileView>

        <Row>
          <Col>{loading && <PageLoader />}</Col>{' '}
          <Col sm="12">
            <div>
              <div className="event-image-wrapper mt-5">
                <Avatar
                  src={
                    eventOutfit?.eventImages !== null &&
                    identifiers?.imageUrlConstant + eventOutfit?.eventImages[0]?.name
                  }
                />{' '}
                {/* <img
                className="w-100"
                style={{ borderRadius: '5px 5px 0px 0px' }}
                src={
                  eventOutfit?.eventImages !== null && identifiers?.imageUrlConstant + eventOutfit?.eventImages[0]?.name
                } */}
                {/* // alt="event-image" */}
                {/* /> */}
              </div>
            </div>{' '}
            {/* {message.display && (
              <div className="btn-block follow-msg-block">
                <ActionMessage message={message.message} type={message.type} styleClass={message.styleClass} />
              </div>
            )} */}
            <div
              className="flow"
              style={{
                pointerEvents: props?.whitelabel === true && 'none',
                opacity: props?.whitelabel === true && '0.7',
              }}>
              <div onClick={() => handleFollowClick(eventOutfit?.isFollow)}>
                {eventOutfit?.isFollow && eventOutfit?.isFollow === true ? (
                  <>
                    <button
                      className="badge-wrapper p-0"
                      disabled={props?.whitelabel === true ? true : false}
                      style={{
                        backgroundColor: eventOutfit?.primaryColour ? eventOutfit?.primaryColour : '',
                        borderColor: eventOutfit?.primaryColour ? eventOutfit?.primaryColour : '',
                        // pointerEvents: eventOutfit?.status !== 'saved' ? 'visible' : 'none'
                      }}>
                      <BookmarkIcon
                        style={{
                          color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '#0080FF',
                          width: '30px',
                          height: '40px',
                        }}
                      />
                    </button>
                  </>
                ) : (
                  <>
                    <div
                      className="badge-wrapper"
                      style={{
                        pointerEvents: props?.whitelabel === true && 'none',
                        opacity: props?.whitelabel === true && '0.4',
                      }}>
                      <BookmarkIcon style={{ color: '#000', width: ' 30px', height: '40px' }} />
                    </div>
                  </>
                )}
              </div>
              <div
                className="badge-wrapper mb-3"
                onClick={handleButtonClick}
                style={{
                  backgroundColor: eventOutfit?.primaryColour ? eventOutfit?.primaryColour : '',
                  borderColor: eventOutfit?.primaryColour ? eventOutfit?.primaryColour : '',
                }}>
                <img src={images.networkIcon} alt="" />
              </div>
              {isSocialMedia && (
                <div className="social-media-main">
                  <div className="mb-3">
                    <IconButton style={{ background: '#3B5998' }}>
                      <FacebookShareButton
                        url={`${Config.socketUrl}event-details/${eventId}`}
                        // quote={funraise_Details?.Description}
                        // hashtag={'#' + postCaption}
                        className="socialMediaIcons">
                        <FaFacebookF fontSize="inherit" size="20px" />
                      </FacebookShareButton>
                    </IconButton>
                  </div>
                  <div className="mb-3">
                    <IconButton style={{ background: '#55ACEE' }}>
                      <TwitterShareButton
                        url={`${Config.socketUrl}event-details/${eventId}`}
                        // quote={funraise_Details?.Description}
                        // hashtag={postCaption}
                        className="socialMediaIcons">
                        <FaTwitter fontSize="inherit" size="20px" />
                      </TwitterShareButton>
                    </IconButton>
                  </div>
                  <div className="mb-3">
                    <IconButton style={{ background: '#007bb5' }}>
                      <LinkedinShareButton
                        url={`${Config.socketUrl}event-details/${eventId}`}
                        // quote={funraise_Details?.Description}
                        // hashtag={postCaption}
                        className="socialMediaIcons">
                        <FaLinkedinIn fontSize="inherit" size="20px" />
                      </LinkedinShareButton>
                    </IconButton>
                  </div>
                  <div className="mb-3">
                    <IconButton style={{ background: '#cb2027' }}>
                      <PinterestShareButton
                        url={`https://pinterest.com/pin/create/button/?url=${Config.socketUrl}event-details/${eventId}&media=&description=`}
                        color="#BD081C"
                        size="40px"
                        description="Check out this free React Component by @stephanwozniak, made for easily sharing pages on social media sites!"
                        media={`${Config.socketUrl}event-details/${eventId}`}
                        className="socialMediaIcons">
                        <FaPinterest fontSize="inherit" size="20px" />
                      </PinterestShareButton>
                    </IconButton>
                  </div>
                  <div className="mb-3">
                    <IconButton style={{ background: '#25d366' }}>
                      <WhatsappShareButton
                        url={`${Config.socketUrl}event-details/${eventId}`}
                        // quote={funraise_Details?.Description}
                        // hashtag={postCaption}
                        className="socialMediaIcons">
                        <FaWhatsapp fontSize="inherit" size="20px" />
                      </WhatsappShareButton>
                    </IconButton>
                  </div>
                  <div className="mb-3">
                    <IconButton style={{ background: '#ffc629' }}>
                      <button
                        className="copy_link_button"
                        onClick={() => {
                          navigator.clipboard.writeText(window.location.href);
                          handleCopyLink();
                        }}>
                        <FaCopy fontSize="inherit" size="20px" />
                      </button>
                    </IconButton>
                  </div>
                </div>
              )}
            </div>
            {/* <IconButton> */}
            {/* </IconButton> */}
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <div className="bg-white content mb-3">
              <h2 className="res-orgcard-name text-center hide-sm hide-md hide-lg hide-xl">
                {eventOutfit &&
                  eventOutfit?.eventName != null &&
                  parse(
                    anchorme({
                      input: (eventOutfit?.eventName).toString().replace(/\n/g, '<br/>'),
                      options: {
                        attributes: {
                          target: '_blank',
                          class: 'detected',
                        },
                      },
                    }),
                  )}
              </h2>
              <h2 className="text-center pt-4 pb-2 hide-xs">
                {eventOutfit &&
                  eventOutfit?.eventName != null &&
                  parse(
                    anchorme({
                      input: (eventOutfit?.eventName).toString().replace(/\n/g, '<br/>'),
                      options: {
                        attributes: {
                          target: '_blank',
                          class: 'detected',
                        },
                      },
                    }),
                  )}
              </h2>
              {/* <h2 className="text-center pt-4 pb-2 hide-xs">{eventOutfit?.eventName}</h2> */}
              <div className="text-center">
                {/* {console.log('logogo', props?.previewPropsValue?.hexCode?.['Logo_name'])} */}
                {eventOutfit?.organisation?.imagePath && eventOutfit?.organisation?.imagePath !== null ? (
                  <img
                    className="imgWidth"
                    // src={identifiers.imageUrlConstant + eventOutfit?.organisation?.imagePath}
                    src={
                      props?.previewPropsValue?.hexCode?.hasOwnProperty('Logo_name') === true &&
                      props?.previewPropsValue?.hexCode['Logo_name'] !== ''
                        ? props?.previewPropsValue?.hexCode['Logo_name']
                        : eventOutfit?.companyLogo !== null
                          ? eventOutfit?.companyLogo
                          : identifiers.imageUrlConstant + eventOutfit?.organisation?.imagePath
                    }
                    alt=""
                  />
                ) : (
                  <div className="square"></div>
                )}
                {/* <img className="imgWidth" src={images.nikeImg} alt="" /> */}
                {/* <div className="square fw-500">Company Logo</div> */}
              </div>
              <h5 className="res-orgby mt-2 fw-normal text-center pb-3 border-bottom mb-4 hide-sm hide-md hide-lg hide-xl">
                {`${OKTION_VARIABLE?.OKTION_BY} ${eventOutfit?.organisation?.name}`}
              </h5>
              <h5 className="mt-2 fw-normal text-center pb-4  hide-xs">
                {`${OKTION_VARIABLE?.OKTION_BY} ${eventOutfit?.organisation?.name}`}
              </h5>
              {socialData?.length > 0 && (
                <ul className="social-icon-wrap border-bottom mb-4 pb-4">
                  {socialData?.map(item => {
                    return (
                      <li>
                        <a href={item?.linkUrl} target="_blank">
                          {getSocialIcon(item?.linkType)}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              )}
              {handleEventStartDate(activeTab)}
              {/* <div className="text-center mb-3">
                <p className="res-time-remaning">Time Remaining Until Event Start Date</p>
                {handleEventStartDate(activeTab)}
                <h4
                  className="aColor fw-500"
                  style={{
                    color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                  }}>
                  {timeDiffCalc(new Date(), new Date(moment(eventOutfit?.endDate)))} left
                </h4>
              </div>
              <div className="text-center mb-3 fw-500">
                <p>Amount Raised by Ticket Sales</p>
                <h4
                  className="aColor"
                  style={{
                    color: eventOutfit?.secondaryColour ? eventOutfit?.secondaryColour : '',
                  }}>
                  {`${eventOutfit?.currency?.abbreviation} ${parseFloat(eventOutfit?.amountRaised).toFixed(2)}`} raised
                </h4>
              </div> */}
              <TabWrapper
                eventDetails={eventOutfit}
                preview_color={props.preview_color}
                featureName={location?.state?.featureName}
                whitelabel={props?.whitelabel}
              />

              {/*<ul className="event-tab border-bottom border-top">*/}
              {/*  {eventDetailsPageData.map(tab => (*/}
              {/*    <li*/}
              {/*      role="button"*/}
              {/*      tabIndex="-1"*/}
              {/*      className={`${activeTab === tab.id ? 'active' : ''}`}*/}
              {/*      key={tab.id}*/}
              {/*      onClick={handleTabChange(tab.id)}>*/}
              {/*      {tab.name}*/}
              {/*    </li>*/}
              {/*  ))}*/}
              {/*</ul>*/}
              <Row>
                {/*<Auction/>*/}
                {/*<Raffle />*/}
                {/*<About />*/}
                {/*<Tickets/>*/}
                {/*<Sponsors/>*/}
                {/*<Donations/>*/}
                {/*<Col sm="3" className="pt-4 mt-3 mb-3">*/}
                {/*  <EventTimeDetails*/}
                {/*    img={images.watch}*/}
                {/*    endDate="22 August, 5:00 PM"*/}
                {/*    startDate="22 August, 11:00 AM"*/}
                {/*    address="80 Pyrmont St,Pyrmont NSW 2009"*/}
                {/*    isLocation={false}*/}
                {/*  />*/}
                {/*  <EventTimeDetails*/}
                {/*    isLocation*/}
                {/*    img={images.location}*/}
                {/*    endDate="22 August, 5:00 PM"*/}
                {/*    startDate="22 August, 11:00 AM"*/}
                {/*    address="80 Pyrmont St,Pyrmont NSW 2009"*/}
                {/*  />*/}
                {/*  <h6 className="ms-5 ps-2">+ &nbsp; Online Event</h6>*/}
                {/*  <Button className="blue-button btn-block mb-3 mt-3">Add To Calendar</Button>*/}
                {/*</Col>*/}
                <Col sm="12">
                  {/*<h4 className="pt-4 mb-3">Raffle Ticket Bundles</h4>*/}
                  {/*<Row>*/}
                  {/*  {addToCardCard.map((item, i) => (*/}
                  {/*    <Col sm="3">*/}
                  {/*      <RaffleAddToCard*/}
                  {/*        key={i}*/}
                  {/*        ticketName={item.ticketName}*/}
                  {/*        price={item.price}*/}
                  {/*        noTicket={item.noTicket}*/}
                  {/*      />*/}
                  {/*      ,*/}
                  {/*    </Col>*/}
                  {/*  ))}*/}
                  {/*</Row>*/}

                  {/*<Sponsor/>*/}
                  <div className="text-center mt-3 bottomSection">
                    <div className="text-center">
                      {/* {console.log(
                        'imgWidth',
                        props?.previewPropsValue?.hexCode['Logoname'],
                        identifiers.imageUrlConstant + eventOutfit?.organisation?.imagePath,
                      )} */}
                      {eventOutfit?.organisation?.imagePath && eventOutfit?.organisation?.imagePath !== null ? (
                        <img
                          className="imgWidth"
                          src={
                            props?.previewPropsValue?.hexCode?.hasOwnProperty('Logo_name') === true &&
                            props?.previewPropsValue?.hexCode['Logo_name'] !== ''
                              ? props?.previewPropsValue?.hexCode['Logo_name']
                              : eventOutfit?.companyLogo !== null
                                ? eventOutfit?.companyLogo
                                : identifiers.imageUrlConstant + eventOutfit?.organisation?.imagePath
                          }
                          // src={identifiers.imageUrlConstant + eventOutfit?.organisation?.imagePath}
                          // src={
                          //   props?.previewPropsValue?.hexCode['Logoname'] !== '' ? identifiers.imageUrlConstant + props?.previewPropsValue?.hexCode['Logoname'] : identifiers.imageUrlConstant + eventOutfit?.organisation?.imagePath
                          // }
                          alt=""
                        />
                      ) : (
                        <div className="square"></div>
                      )}
                      {/*<div className='square'>Company Logo</div>*/}
                    </div>
                    <h5 className="res-evnt-org-name fw-normal text-center mb-2 hide-sm hide-md hide-lg hide-xl">
                      {eventOutfit?.organisation?.name}
                    </h5>
                    <h5 className="mt-2 fw-normal text-center mb-2 hide-xs">{eventOutfit?.organisation?.name}</h5>
                    <div className="p-15">
                      {eventOutfit?.organisation?.aboutTheOrganisation &&
                        parse(
                          anchorme({
                            input: (eventOutfit?.organisation?.aboutTheOrganisation).toString().replace(/\n/g, '<br/>'),
                            options: {
                              attributes: {
                                target: '_blank',
                                class: 'detected',
                              },
                            },
                          }),
                        )}
                    </div>
                    {/* <p className="fs-16 ps-5 pe-5">{eventOutfit?.organisation?.aboutTheOrganisation}</p> */}
                    <Button
                      className="main-button mb-3 mt-3 h-60 fs-20 fw-500 contact-btn"
                      onClick={handleClick}
                      disabled={props.whitelabel === true ? true : false}
                      style={{
                        backgroundColor: eventOutfit?.primaryColour ? eventOutfit?.primaryColour : '',
                        borderColor: eventOutfit?.primaryColour ? eventOutfit?.primaryColour : '',
                        color: eventOutfit?.primaryTextColour ? eventOutfit?.primaryTextColour : '',
                      }}>
                      {OKTION_VARIABLE?.OKTION_CONTACT}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {props.whitelabel !== true ? (
          <div className="pb-4">
            <h4 className="text-center pb-3 pt-3 fw-500">{OKTION_VARIABLE?.OKTION_OTHER_EVENT_LIKE}</h4>
            <Row className="justify-content-center">
              {eventOutfit?.similarEventFeatureDetails &&
                eventOutfit?.similarEventFeatureDetails.map((item, index) => (
                  <Col lg="3" md="4" sm="12" className="event-wrapper mb-2" onClick={() => handleItemClick(item)}>
                    <EventCard
                      key={index}
                      image={item.eventImages}
                      types={item.types}
                      event={item}
                      currency={eventOutfit?.currency?.abbreviation}
                      isManaged
                    />
                  </Col>
                ))}
            </Row>
          </div>
        ) : (
          ''
        )}
        <EventDetailsAuctionModal
          isToggle={isToggle}
          toggle={toggle}
          currencyData={eventOutfit?.currency?.abbreviation}
        />
      </div>
      <NoOrgModal
        isFromPublic={true}
        isToggle={is_toggle}
        toggle={toggleModal}
        handleClick={() => navigate('/')}
        modalErrorText={modalErrorText}
      />{' '}
      <LogoutModal isToggle={isloginToggle} toggle={Logintoggle} handleClick={handleloginButtonClick} />
    </div>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};
export default connect(mapStateToProps, {})(EventDetails);
