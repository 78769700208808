import React, { useContext } from 'react';
import { Card, CardBody, CardImg, CardSubtitle, CardTitle } from 'reactstrap';
import '../../../../../library/common/components/EventCard/styles.scss';
// import { images } from '../../../../../library/common/constants/ImageConstants';
import '../../../../Events/MyEvents/frames/Features/frames/Auction/styles.scss';
import { identifiers } from '../../../../../library/common/constants/IdentifierConstants';
import Barcode from 'react-barcode';
import { fetchFromStorage } from '../../../../../utility';
import QRCode from 'react-qr-code';
import moment from 'moment';
import { SocketContext } from 'main/context/socket';
const TicketsCard = ({
  data,
  isTicket,
  isBarcode,
  showImage,
  imageUrl,
  handleItemClick,
  printqrCode,
  printImage,
  raffleDrawDate,
}) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const eventDetails = fetchFromStorage(identifiers.eventDetails);

  const barcodeForRaffleTicket = JSON.stringify({
    eventName: eventDetails?.eventName,
    eventId: eventDetails?.id,
    eventDate: moment(eventDetails?.startDate).format('DD/MM/YYYY hh:mm A'),
    raffleTicketNumber: data?.ticketNumber,
    raffleTicketId: data?.id,
    raffleDrawDate: moment(raffleDrawDate).format('DD/MM/YYYY hh:mm A'),
  });
  const qrString = JSON.stringify({
    eventId: eventDetails?.id,
    eventDate: moment(eventDetails?.startDate).format('DD/MM/YYYY hh:mm A'),
    eventName: eventDetails?.eventName,
    ticketNumber: data?.ticketNumber,
    ticketId: data?.id,
    venueStartDate: moment(eventDetails?.venueStartDate != null && eventDetails?.venueStartDate).format(
      'DD/MM/YYYY hh:mm A',
    ),
  });
  return (
    <div className="wonItemPrizeCard cursor-pointer" style={{ pageBreakAfter: 'always' }}>
      <Card onClick={() => handleItemClick(data)}>
        {!showImage ? (
          ''
        ) : (
          <CardImg
            top
            width="100%"
            src={identifiers.imageUrlConstant + imageUrl}
            alt={OKTION_VARIABLE?.OKTION_CARD_IMAGE_CAP}
            className={printImage ? 'printImage' : ''}
          />
        )}
        <CardBody>
          {!isTicket && (
            <>
              <CardTitle className="mb-2 mt-2 fw-500">{data.ticketNo}</CardTitle>
              <CardSubtitle className="mb-3">
                {OKTION_VARIABLE?.OKTION_RAFFLETICKET_RAFFLE_DRAW} : {data.raffleDraw}
              </CardSubtitle>
            </>
          )}
          {isTicket && <CardTitle className="m-0 fw-500">{data.ticketNumber}</CardTitle>}
          {printqrCode && <CardTitle className="mb-3 mt-2 fw-500">{data.ticketName}</CardTitle>}
          {/* {!isBarcode && <img className="w-100" src={images.barcodeImg} alt="" />} */}
          <div className="barcode-svg">
            {!isBarcode && (
              <Barcode value={barcodeForRaffleTicket} width={2} height={280} format="CODE128" displayValue={false} />
            )}
          </div>
          {printqrCode && (
            <div className="text-center mt-2">
              <QRCode size={250} value={qrString} />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default TicketsCard;
