import React from 'react';

function UserIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26.328" height="26.327" viewBox="0 0 26.328 26.327">
      <g data-name="Group 5350">
        <g data-name="Group 3853" transform="translate(9.059 3.963)">
          <path
            fill="#fff"
            d="M-125.562 570.443a4.015 4.015 0 01-4.1-4.1v-1.7a4.014 4.014 0 014.1-4.1 4.015 4.015 0 014.1 4.1v1.7a4.015 4.015 0 01-4.1 4.1zm0-8.493a2.6 2.6 0 00-2.689 2.689v1.7a2.6 2.6 0 002.689 2.689 2.6 2.6 0 002.689-2.689v-1.7a2.6 2.6 0 00-2.689-2.689z"
            data-name="Path 49"
            transform="translate(129.667 -560.535)"></path>
        </g>
        <g data-name="Group 3854" transform="translate(5.662 14.154)">
          <path
            fill="#fff"
            d="M-120.663 586.037h-1.415v-2.831c0-1.913-3.949-3.256-6.086-3.256s-6.087 1.342-6.087 3.256v2.831h-1.415v-2.831c0-3.067 4.883-4.671 7.5-4.671s7.5 1.6 7.5 4.671z"
            data-name="Path 50"
            transform="translate(135.667 -578.535)"></path>
        </g>
        <g data-name="Group 3855">
          <path
            fill="#fff"
            d="M-132.5 579.862a13.178 13.178 0 01-13.167-13.162 13.178 13.178 0 0113.167-13.165 13.179 13.179 0 0113.161 13.165 13.179 13.179 0 01-13.161 13.162zm0-24.912a11.762 11.762 0 00-11.752 11.75 11.761 11.761 0 0011.752 11.747 11.762 11.762 0 0011.745-11.747 11.762 11.762 0 00-11.745-11.75z"
            data-name="Path 51"
            transform="translate(145.667 -553.535)"></path>
        </g>
      </g>
    </svg>
  );
}

export default UserIcon;
