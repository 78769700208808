//import socketio from 'socket.io-client';
import Config from '../../utility/Config';
import React from 'react';
import { fetchFromStorage } from '../../utility';
import { identifiers } from '../../library/common/constants/IdentifierConstants';
//const { io } = require('socket.io-client');

let socketInitialize = null;
const userDetail = fetchFromStorage(identifiers.userDetail);

const { io } = require('socket.io-client');

socketInitialize = io(Config.socketUrl, {
  jsonp: false,
  path: Config.socketPath,
  upgrade: false,
  reconnection: true,
});

export const socket = socketInitialize.connect();

export const socketJoin = socket.emit('JoinSocket', {
  userId: Number(userDetail?.id),
});

export const SocketContext = React.createContext();
