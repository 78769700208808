import * as actionTypes from '../constants/StoreConstants';

const initialState = {
  isLoggedIn: false,
  user: null,
  token: null,
  emailVerification: null,
  forgotPasswordResetEmail: null,
  userData: null,
  loginUserData: null,
  countryDetails: {},
  currencyList: [],
  categoryList: [],
  isShow: false,
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.storeConstants.SET_AUTHENTICATION:
      return {
        ...state,
        token: action.payload,
        isLoggedIn: true,
      };
    case actionTypes.GET_AUTH:
      return state;
    case actionTypes.storeConstants.EMAIL_VERIFICATION:
      return {
        ...state,
        emailVerification: action.payload,
      };
    case actionTypes.storeConstants.EMAIL_FORGOT_PASSWORD:
      return {
        ...state,
        forgotPasswordResetEmail: action.payload,
      };
    case actionTypes.storeConstants.GET_USER_DETAILS:
      return {
        ...state,
        userData: action.payload,
      };
    case actionTypes.storeConstants.SET_LOGIN_USER_DATA:
      return {
        ...state,
        loginUserData: action.payload,
      };
    case actionTypes.LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case actionTypes.storeConstants.GET_COUNTRY_DETAILS:
      return {
        ...state,
        countryDetails: action.payload,
      };
    case actionTypes.storeConstants.GET_CURRENCY_LIST:
      return {
        ...state,
        currencyList: action.payload,
      };
    case actionTypes.storeConstants.GET_CATEGORY_LIST:
      return {
        ...state,
        categoryList: action.payload,
      };
    case actionTypes.storeConstants.MOBILE_MENU_STATUS:
      return {
        ...state,
        isShow: action.payload,
      };
    default:
      return state;
  }
};

export default AuthReducer;
