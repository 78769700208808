import React from 'react';

import Profile from './frames/Profile';
import TransferOwnership from './frames/TransferOwnership';
import Usericon from './../../icons/usericon';
import TransferIcon from './../../icons/transfericon';
import BankingDetails from './frames/BankingDetails';
import BankingDetailsICon from '../../icons/BankingDetailsICon';
import Admin from './frames/Admins/Admin';
import AdminIcon from '../../icons/AdminIcon';
import SupplyLotIcon from '../../icons/supplyLotIcon';
import SupplyItem from '../SupplyItem/SupplyItem';
import SocialLinks from './frames/socialLinks';
import { images } from '../../library/common/constants/ImageConstants';
// import OrgVerification from './frames/Verification';
import CardDetails from './frames/CardDetails/CardDetails';
import { setApiMessage } from 'library/common/constants/function';
import { saveToStorage } from 'utility';
import axiosInstance from 'main/axios';
import { URLS } from 'library/common/constants/UrlConstants';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import Invoices from './Invoices';

export const menuItems = (orgId, OKTION_VARIABLE) => {
  let isNewOrg = orgId === 'organiser-details';
  return [
    {
      id: 1,
      name: OKTION_VARIABLE?.OKTION_ORGANIZATION_DETAILS,
      path: 'organiser-details',
      icon: <Usericon />,
      imgActive: images.personalDetailActive,
      imgInActive: images.personalDetailInActive,
      render: () => <Profile isNewOrg={isNewOrg} />,
    },
    // {
    //   id: 7,
    //   name: OKTION_VARIABLE?.OKTION_VERIFICATION,
    //   path: 'verification',
    //   icon: <Usericon />,
    //   imgActive: images.verificationActive,
    //   imgInActive: images.verificationInactive,
    //   render: () => <OrgVerification />,
    //   alertIcon: images.verificationAlertIcon,
    // },
    {
      id: 3,
      name: OKTION_VARIABLE?.OKTION_BANKING_DETAILS,
      path: 'banking-details',
      icon: <BankingDetailsICon />,
      imgActive: images.bankActive,
      imgInActive: images.bankInactive,
      render: orgId => <BankingDetails orgId={orgId} />,
      // disabled: isNewOrg,
    },
    {
      id: 8,
      name: 'Card Details',
      path: 'card-details',
      imgActive: images.cardDetailsActive,
      imgInActive: images.cardDetailsInActive,
      render: () => <CardDetails />,
      alertIcon: images.verificationAlertIcon,
      // disabled: isNewOrg,
    },
    {
      id: 6,
      name: OKTION_VARIABLE?.OKTION_ADMINS,
      path: 'admins',
      icon: <AdminIcon />,
      imgActive: images.admins_active,
      imgInActive: images.admins_inActive,
      render: () => <Admin />,
      // disabled: isNewOrg,
    },
    {
      id: 4,
      name: OKTION_VARIABLE?.OKTION_SUPPLY_LOT,
      path: 'supply-item',
      icon: <SupplyLotIcon />,
      imgActive: images.supplyActive,
      imgInActive: images.supplyInactive,
      render: () => <SupplyItem />,
      // disabled: isNewOrg,
    },
    {
      id: 5,
      name: OKTION_VARIABLE?.OKTION_SOCIAL_LINKS,
      path: 'social-links',
      icon: '',
      imgActive: images.socailLinkActiveIcon,
      imgInActive: images.socailLinkInactiveIcon,
      render: () => <SocialLinks />,
      // disabled: isNewOrg,
    },
    {
      id: 7,
      name: OKTION_VARIABLE?.OKTION_TRANSFER_OWNERSHIP,
      path: 'transfer-ownership',
      icon: <TransferIcon />,
      imgActive: images.transferActive,
      imgInActive: images.transferInactive,
      render: () => <TransferOwnership />,
      // disabled: isNewOrg,
    },
    {
      id: 9,
      name: 'Invoices',
      path: 'invoices',
      imgActive: images.invoicesActive,
      imgInActive: images.invoicesInactive,
      render: () => <Invoices isOrg={true} />,
    },
  ];
};

export const createDraftOrg = async () => {
  const sendData = {
    status: 'draft',
  };
  try {
    const { status, data } = await axiosInstance.post(URLS.createOrganization, sendData);
    if (status === 201) {
      axiosInstance
        .post(URLS.signInOrganisation(data.data.organisation.id))
        .then(async ({ status, data }) => {
          try {
            const { status, data } = await axiosInstance.get(URLS.getUserDataLoggedin());
            saveToStorage(identifiers.userDetail, data && data.data.user);
          } catch (err) {}
        })
        .catch(() => {});
      saveToStorage(identifiers.currentOrganization, data.data.organisation.id);
      return { status, data };
    }
  } catch (err) {
    if (err.response) {
      setApiMessage('error', err.response.data.msg);
    }
  }
};
