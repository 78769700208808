import React, { useState, useContext } from 'react';
import { Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import ItemDetailsSmallModal from './ItemDetailsSmallModal';
import ItemDetailsModal from './ItemDetailsModal';
import { images } from '../../../../library/common/constants/ImageConstants';
import { identifiers } from '../../../../library/common/constants/IdentifierConstants';
import '../../styles.scss';
import { SocketContext } from '../../../../main/context/socket';

import { CurrencyFormat } from 'utility';

const SupplyItemCard = ({
  img,
  status,
  itemName,
  startingBid,
  reserveAmount,
  increment,
  priceValue,
  isMonitor,
  data,
  key,
  is_view,
  item,
  getSupplyItem,
  tabValue,
}) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const [isToggle, setToggle] = useState(false);
  const [is_Toggle, setItemToggle] = useState(false);
  const toggle = () => {
    setToggle(!isToggle);
  };
  const itemToggle = () => {
    setItemToggle(!is_Toggle);
  };
  return (
    <div className={`${tabValue === 'available' && data?.totalDuplicateLot > 0 ? 'hasdublicate' : ''}`}>
      <div class="border-box"> </div>
      {tabValue === 'available' && data?.totalDuplicateLot > 0 && (
        <div className="lot-count">
          <span className="d-flex justify-content-center align-items-center rounded-circle">
            {'x' + (data?.totalDuplicateLot + 1)}
          </span>
        </div>
      )}
      <div className="wonItemPrizeCard supply-item-cart h-100">
        <Card className="h-100">
          {/* <CardImg
          top
          width="100%"
          className={img === false ? 'noImage' : 'supply-image'}
          src={img}
          alt="Card image cap"
        /> */}
          {/* {data?.images?.length > 0 ? ( */}
          <div
            className={data?.status === 'draft' || data?.auctionItem?.isSold ? 'imgWrapper imageOverlay' : 'imgWrapper'}
            style={{
              background: `url(${identifiers.imageUrlConstant + '' + data?.images[0]?.name})`,
            }}>
            {(data?.status === 'draft' || data?.auctionItem?.isSold) && (
              <div className="image-overlay-wrap">
                <img
                  alt=""
                  src={
                    data?.auctionItem?.isSold
                      ? images.prizeIcon
                      : data?.status === 'draft'
                        ? images.itemcardOverlayImage
                        : images.itemcardOverlayImage
                  }
                />
              </div>
            )}
          </div>
          {/* // ) : (
        //   <div */}
          {/* //     className={data?.status === 'draft' || data?.auctionItem?.isSold ? 'imgWrapper imageOverlay' : 'imgWrapper'}
        //     style={{
        //       background: `url(${identifiers.imageUrlConstant + '' + data?.images[0]?.name})`,
        //     }}>
        //     {data?.status === 'draft' ||
        //       (data?.auctionItem?.isSold && (
        //         <div className="image-overlay-wrap">
        //           <img alt="" src={data?.auctionItem?.isSold ? images.prizeIcon : images.itemcardOverlayImage} />
        //         </div>
        //       ))}
        //   </div>
        // )} */}
          <CardBody>
            <CardTitle
              style={{
                marginBotton: itemName !== null ? '26px' : '0px',
                marginTop: '16px',
                paddingBottom: itemName !== null ? '0px' : '26px',
              }}>
              {itemName !== null ? itemName : ''}
            </CardTitle>

            <CardSubtitle className="mb-2 item-status">
              {OKTION_VARIABLE?.OKTION_STATUS} : {status}
            </CardSubtitle>
            {/* <p className="fs-16 mb-1">
              {OKTION_VARIABLE?.OKTION_PRIZE_VALUE} :
             
              <CurrencyFormat
                value={priceValue !== null ? priceValue : '0.00'}
                displayType={'text'}
                thousandSeparator={true}
                prefix={data?.currency !== null && `${data?.currency?.abbreviation} ${data?.currency?.symbol}`}
                thousandSpacing={data?.currency?.symbol === '₹' ? '2s' : '3'}
                // thousandSpacing="2s"
                decimalScale="2"
                fixedDecimalScale={true}
              />
            </p> */}
            {/* {data?.currency !== null && `${data?.currency?.abbreviation} ${data?.currency?.symbol}`}
            {isNaN(parseFloat(startingBid)) ? '0.00' : parseFloat(startingBid).toFixed(2)} */}
            {/* <p className="fs-16 mb-1">
              {OKTION_VARIABLE?.OKTION_STARTING_BID} :
             
              <CurrencyFormat
                value={startingBid !== null ? startingBid : '0.00'}
                displayType={'text'}
                thousandSeparator={true}
                prefix={data?.currency !== null && `${data?.currency?.abbreviation} ${data?.currency?.symbol}`}
                // thousandSpacing="2s"
                thousandSpacing={data?.currency?.symbol === '₹' ? '2s' : '3'}
                decimalScale="2"
                fixedDecimalScale={true}
              />
            </p> */}
            {/* <p className="fs-16 mb-1">
             
              <CurrencyFormat
                value={increment !== null ? increment : '0.00'}
                displayType={'text'}
                thousandSeparator={true}
                prefix={data?.currency !== null && `${data?.currency?.abbreviation} ${data?.currency?.symbol}`}
                // thousandSpacing="2s"
                thousandSpacing={data?.currency?.symbol === '₹' ? '2s' : '3'}
                decimalScale="2"
                fixedDecimalScale={true}
              />
            </p> */}
            <p className="fs-16 mb-1">
              {OKTION_VARIABLE?.OKTION_RESERVE_AMOUNT} :
              {/* {data?.currency !== null && `${data?.currency?.abbreviation} ${data?.currency?.symbol}`}
            {isNaN(parseFloat(reserveAmount)) ? '0.00' : parseFloat(reserveAmount).toFixed(2)} */}
              <CurrencyFormat
                value={reserveAmount !== null ? reserveAmount : '0.00'}
                displayType={'text'}
                thousandSeparator={true}
                prefix={data?.currency !== null && `${data?.currency?.abbreviation} ${data?.currency?.symbol}`}
                // thousandSpacing="2s"
                thousandSpacing={data?.currency?.symbol === '₹' ? '2s' : '3'}
                decimalScale="2"
                fixedDecimalScale={true}
              />
            </p>
            <div className="d-flex">
              {!isMonitor && (
                <Button
                  className="app-button btn-block main-button me-2 w-100 mt-2 fw-500 fs-20 h-60"
                  onClick={itemToggle}
                  disabled={is_view === true ? true : false}>
                  {OKTION_VARIABLE?.OKTION_LOT_DETAILS}
                </Button>
              )}
              {isMonitor && (
                <Button
                  className="app-button btn-block main-button me-2 w-100 mt-2 h-60 fw-500 fs-20"
                  onClick={toggle}
                  disabled={is_view === true ? true : false}>
                  {OKTION_VARIABLE?.OKTION_MONITOR_LOT}
                </Button>
              )}
            </div>
          </CardBody>
        </Card>
        {is_Toggle && (
          <ItemDetailsModal
            isToggle={is_Toggle}
            toggle={itemToggle}
            isUpdate={true}
            propsData={data}
            getSupplyItem={getSupplyItem}
            tabValue={tabValue}
          />
        )}
        <ItemDetailsSmallModal
          isToggle={isToggle}
          toggle={toggle}
          data={data}
          getSupplyItem={getSupplyItem}
          tabValue={tabValue}
        />
      </div>
    </div>
  );
};

export default SupplyItemCard;
