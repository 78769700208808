import React, { useContext } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
// import { images } from '../../../library/common/constants/ImageConstants';
import { SocketContext } from '../../../main/context/socket';

const NoOrgModal = ({ isToggle, toggle, handleClick, isFromPublic, modalErrorText }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  return (
    <div>
      <Modal size="xl" className="modal-dialog add-price-modal logout-modal logout-modal-mobile" isOpen={isToggle}>
        <div className="text-end">
          {/* <img className="cursor-pointer" onClick={toggle} src={images.close} alt="" /> */}
        </div>
        <ModalBody>
          <div>{/* <img src={images.giftImage} alt="" /> */}</div>
          <h3 className="pt-4 pb-4">
            {isFromPublic
              ? modalErrorText
                ? modalErrorText
                : OKTION_VARIABLE?.OKTION_NOT_AUTHORIZED
              : OKTION_VARIABLE?.OKTION_COMPLETE_ORG_PROFILE}{' '}
          </h3>
          <div className=" d-flex justfycon justify-content-center">
            <Button style={{ width: '100px' }} className="app-button main-button mt-2 me-2 h-45" onClick={handleClick}>
              {OKTION_VARIABLE?.OKTION_OK}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default NoOrgModal;
