import React, { useState, useEffect, useContext } from 'react';
import '../styles.scss';
import { Button, Col, InputGroup, Row, FormText } from 'reactstrap';
import FormGroup from 'library/common/components/FormGroupComponent';
import MultiValueSelect from '../../../../../library/common/components/MultiValueSelect/MultiValueSelect';
import axiosInstance from '../../../../../main/axios';
import { URLS } from '../../../../../library/common/constants/UrlConstants';
import PageLoader from '../../../../../library/common/components/PageLoader';
import { useParams, useNavigate } from 'react-router-dom';
// import ActionMessage from '../../../../../library/common/components/ActionMessage';
import { setApiMessage } from '../../../../../library/common/constants/function';
import './styles.scss';
import { SocketContext } from '../../../../../main/context/socket';
import { createDraftOrg } from 'modules/Organization/menu-items';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import { fetchFromStorage } from 'utility';

const InviteUser = () => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const navigate = useNavigate();
  const orgId = useParams();
  const [isValidEmail, setEmailValidation] = useState(true);
  const [loading, setLoading] = useState(false);
  const [is_view, setIsView] = useState('');
  const [multiSelectEmail, setMultiSelectEmail] = useState([]);
  const [isEmpty, setEmpty] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const isNewOrg = window.location.pathname === '/organization/organiser-details';

  // const [message, setMessage] = useState({
  //   display: false,
  //   type: '',
  //   message: '',
  // });

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    handleResize();
  }, [width]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  const handleForm = async () => {
    if (isNewOrg) {
      let { status } = await createDraftOrg();
      if (status) {
        setTimeout(() => {
          handleInviteUser();
        }, 1000);
      }
    } else {
      handleInviteUser();
    }
  };
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    // this.setState({ width: window.innerWidth });
    handleResize();
  };
  const handleResize = () => {
    if (window.innerWidth <= 639) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const handleAdminForm = async () => {
    if (isNewOrg) {
      let { status } = await createDraftOrg();
      if (status) {
        setTimeout(() => {
          handleAdminUser();
        }, 1000);
      }
    } else {
      handleAdminUser();
    }
  };
  const handleAdminUser = async () => {
    const currentOrgId = fetchFromStorage(identifiers.currentOrganization);

    var emailValidate = multiSelectEmail.map(item => {
      var isValidEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(item);
      return isValidEmail;
    });

    const sendDataValue = {
      organisationId: currentOrgId,
      role: 'admin',
      emails: multiSelectEmail,
      isNewUser: true,
    };

    if (!emailValidate.includes(false)) {
      setLoading(true);
      setEmailValidation(true);
      try {
        const { status } = await axiosInstance.post(URLS.organizationChangeRole, sendDataValue);
        if (status === 201) {
          setApiMessage('success', OKTION_VARIABLE?.OKTION_INVITE_SUCCESS);
          // setActionMessage(true, 'Success', OKTION_VARIABLE?.OKTION_INVITE_SUCCESS);
          // setTimeout(() => {
          //   setActionMessage();
          // }, 3000);
          setLoading(false);
          if (isNewOrg) {
            setTimeout(() => {
              navigate(`/organization/${currentOrgId}?admins`);
            }, 1000);
          }
        }
      } catch (err) {
        setLoading(false);
        if (err.response && err?.response?.data?.msg !== 'specify either userId or email') {
          setApiMessage('error', err.response.data.msg);
          // setActionMessage(true, 'Error', err.response.data.msg);
          // setTimeout(() => {
          //   setActionMessage();
          // }, 3000);
        } else if (err?.response?.data?.msg === 'specify either userId or email') {
          setEmpty(true);
        }
      }
    } else {
      setEmailValidation(false);
    }
  };
  const handleInviteUser = async () => {
    const currentOrgId = fetchFromStorage(identifiers.currentOrganization);
    var emailValidate = multiSelectEmail.map(item => {
      var isValidEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(item);
      return isValidEmail;
    });

    const sendDataValue = {
      organisationId: currentOrgId,
      role: 'manager',
      emails: multiSelectEmail,
      isNewUser: true,
    };
    if (!emailValidate.includes(false)) {
      setLoading(true);
      setEmailValidation(true);
      try {
        const { status } = await axiosInstance.post(URLS.organizationChangeRole, sendDataValue);
        if (status === 201) {
          setApiMessage('success', OKTION_VARIABLE?.OKTION_INVITE_SUCCESS);
          // setActionMessage(true, 'Success', OKTION_VARIABLE?.OKTION_INVITE_SUCCESS);
          // setTimeout(() => {
          //   setActionMessage();
          // }, 3000);
          setLoading(false);
          if (isNewOrg) {
            setTimeout(() => {
              navigate(`/organization/${currentOrgId}?admins`);
            }, 1000);
          }
        }
      } catch (err) {
        setLoading(false);
        if (err.response && err?.response?.data?.msg !== 'specify either userId or email') {
          setApiMessage('error', err.response.data.msg);
          // setActionMessage(true, 'Error', err.response.data.msg);
          // setTimeout(() => {
          //   setActionMessage();
          // }, 3000);
        } else if (err?.response?.data?.msg === 'specify either userId or email') {
          setEmpty(true);
        }
      }
    } else {
      setEmailValidation(false);
    }
  };
  // const setActionMessage = (display = false, type = '', message = '') => {
  //   setMessage({
  //     display,
  //     type,
  //     message,
  //   });
  // };
  const handleCommaSeparateValue = multiValue => {
    setMultiSelectEmail(multiValue);
    setEmpty(false);
    if (multiValue?.length === 0) {
      setEmailValidation(true);
    }
  };

  return (
    <div>
      {/* {message.display && (
        <div className="btn-block">
          <ActionMessage message={message.message} type={message.type} styleClass={message.styleClass} />
        </div>
      )} */}
      {loading && <PageLoader />}
      <Row className="align-items-center justify-content-lg-between mb-4 mt-4">
        <Col sm="12">
          <h4 className="mb-3 fw-normal">{OKTION_VARIABLE?.OKTION_INVITE_NEWUSER}</h4>
          <FormGroup className={multiSelectEmail.length > 0 ? 'tag-fixedfield inviteFilter ' : '   inviteFilter'}>
            <InputGroup className="mt-2 search-filter invite-new-user">
              <MultiValueSelect
                defaultTags={multiSelectEmail && multiSelectEmail}
                placeholder={OKTION_VARIABLE?.OKTION_ENTER_EMAILADDRESS}
                handleChange={handleCommaSeparateValue}
                isEmail={false}
              />
              {!isMobile && (
                <>
                  <Button
                    // color="primary"
                    className="search-button invite-admin-button ms-2"
                    onClick={() => handleForm()}
                    disabled={is_view === true ? true : false}>
                    {OKTION_VARIABLE?.OKTION_INVITE_MANAGER}
                  </Button>
                  <Button
                    // color="primary"
                    className="search-button invite-admin-button ms-2"
                    onClick={() => handleAdminForm()}
                    disabled={is_view === true ? true : false}>
                    {OKTION_VARIABLE?.OKTION_INVITE_ADMIN}
                  </Button>
                </>
              )}
            </InputGroup>
            <FormText color="muted helpText">{OKTION_VARIABLE?.OKTION_TAG_TYPE}</FormText>
            {isMobile && (
              <>
                {!isValidEmail && <span className="text-danger">{OKTION_VARIABLE?.OKTION_ENTERVALID_EMAIL}</span>}
                {isEmpty && <span className="text-danger">{OKTION_VARIABLE?.OKTION_ENTER_EMAIL}</span>}
              </>
            )}
            {isMobile && (
              <div className="button-section search-filter mt-2">
                <Button
                  // color="primary"
                  className="search-button invite-admin-button invite-admin-button-smallscreen me-2"
                  onClick={() => handleForm()}
                  disabled={is_view === true ? true : false}>
                  {OKTION_VARIABLE?.OKTION_INVITE_MANAGER}
                </Button>
                <Button
                  // color="primary"
                  className="search-button invite-admin-button invite-admin-button-smallscreen ms-2"
                  onClick={() => handleAdminForm()}
                  disabled={is_view === true ? true : false}>
                  {OKTION_VARIABLE?.OKTION_INVITE_ADMIN}
                </Button>
              </div>
            )}
            {!isMobile && (
              <>
                {!isValidEmail && <span className="text-danger">{OKTION_VARIABLE?.OKTION_ENTERVALID_EMAIL}</span>}
                {isEmpty && <span className="text-danger">{OKTION_VARIABLE?.OKTION_ENTER_EMAIL}</span>}
              </>
            )}
          </FormGroup>
          {/* <InputGroup className="mt-2 mb-3 search-filter ">
            <Button
              // color="primary"
              className="search-button  ms-2"
              onClick={() => handleForm()}
              disabled={is_view === true ? true : false}>
              Invite as Manager
            </Button>
            <Button
              // color="primary"
              className="search-button  ms-2"
              onClick={() => handleAdminForm()}
              disabled={is_view === true ? true : false}>
              Invite as Admin
            </Button>
          </InputGroup> */}
          {/* {errors?.email !== '' && <span className="text-danger">{errors?.email}</span>} */}
          {/* {filterValue === '' && email === true && <span className="text-danger">Field is required</span>} */}
          {/* {inviteData === false && email === true && <span className="text-danger">Field is required</span>} */}
        </Col>
      </Row>
    </div>
  );
};

export default InviteUser;
