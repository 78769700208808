import React from 'react';
// import { Button } from 'library/common/components';
// import Button from 'reactstrap/lib/Button';
import './imageDisplayStyles.scss';
import { images } from '../../../constants/ImageConstants';

const ImageDisplay = ({
  files,
  onRemove,
  onChangeMediaType,
  handleImageErrored,
  isImageLoaded,
  imageRef,
  disabled,
}) => (
  <div className="image-display-list">
    {files?.length > 0 &&
      files?.map((file, key) => (
        <div className="image-item" key={key}>
          {/* <button onClick={onRemove(key)} className="remove-button exportBtn" disabled={disabled}>
            <img src={images.blackDelete} alt="" />{' '}
          </button> */}
          {/* {disabled ? (
            <button onClick={onRemove(key)} className="remove-button exportBtn" disabled={disabled}>
              <img src={images.blackDelete} alt="" />{' '}
            </button>
          ) : (
            <span onClick={onRemove(key)} className="remove-button exportBtn">
              <img src={images.blackDelete} alt="" />{' '}
            </span>
          )} */}

          <span
            onClick={disabled ? null : onRemove(key)}
            className={`remove-button exportBtn ${disabled ? 'cursor-default' : ''}`}>
            <img src={images.blackDelete} alt="" />{' '}
          </span>

          {/*<Button onClick={onRemove(key)} icon="fa-trash" styleClass="remove-button" />*/}
          {file.type === 'application/pdf' ? (
            <div className="pdf" title={file.name}>
              <div className="pdf-file">
                <a target="_blank" rel="noopener noreferrer" href={file.preview}>
                  {file.name}
                </a>
              </div>
              <div className="icon">
                <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
              </div>
            </div>
          ) : (
            <>
              {isImageLoaded ? (
                <dix className="noImageWrap"></dix>
              ) : (
                // <Avatar className="longImg" src={file.preview} alt={file.name} onError={handleImageErrored} />
                <img
                  // ref={imageRef}
                  className="longImg"
                  src={file.preview}
                  alt={file.name}
                  onError={handleImageErrored}
                  // onLoad={() =>
                  //   console.log('imgElement', imageRef.current.naturalWidth, imageRef.current.naturalHeight)
                  // } // print 150
                />
              )}
            </>
          )}
        </div>
      ))}
  </div>
);

export default ImageDisplay;
