import React, { useContext, useEffect, useState } from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import '../../styles.scss';
import { images } from '../../../../../../../../../library/common/constants/ImageConstants';
import RaffleWallCard from './RaffleWallCard';
import TicketSold from './TicketSold';
import { SocketContext } from 'main/context/socket';
import CongratulationOverlay from './CongratulationOverlay';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from 'main/axios';
import { URLS } from '../../../../../../../../../library/common/constants/UrlConstants';
import PageLoader from '../../../../../../../../../library/common/components/PageLoader';
import Countdown from 'react-countdown';
import moment from 'moment';
import { fetchFromStorage } from 'utility';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import { CurrencyFormat } from 'utility';

const RaffleWall = () => {
  const [flag, setFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [raffleFeatureDetails, setRaffleFeatureDetails] = useState('');

  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const navigate = useNavigate();
  const location = useLocation();
  const raffleFeatureData = location?.state?.raffleFeatureData[0];
  // var rafflePrizeOptions = raffleFeatureData && raffleFeatureData?.rafflePrizeOptions?.filter(x => x?.status === "published")

  const renderTime = ({ hours, minutes, days, seconds }) => {
    return (
      <>
        <div>
          {days > 0 ? ('0' + days).slice(-2) : '00'}:{hours > 0 ? ('0' + hours).slice(-2) : '00'}:
          {minutes > 0 ? ('0' + minutes).slice(-2) : '00'}
        </div>
      </>
    );
  };
  const getRaffleWallDetails = async () => {
    setLoading(true);
    var raffleItem = raffleFeatureData && raffleFeatureData;

    try {
      const { status, data } = await axiosInstance.get(URLS.getRaffleWallDetails(raffleItem?.id));
      if (status === 200) {
        setRaffleFeatureDetails(data?.data);
        setLoading(false);
        data?.data?.item?.isDraw && data?.data?.item?.isDraw === '1' && setFlag(true);
      }
    } catch (err) {}
  };
  useEffect(() => {
    getRaffleWallDetails();
    userSocket.socket.on('RaffleWall', data => {
      setRaffleFeatureDetails(data?.data);
      data?.data?.item?.isDraw && data?.data?.item?.isDraw === '1' && setFlag(true);
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setFlag(false);
    }, 9000);
  });
  var eventDetails = fetchFromStorage(identifiers?.eventDetails);
  return (
    <div className="raffleWall">
      {loading && <PageLoader />}
      <Container fluid={true}>
        <Row>
          <Col sm="4 ps-sm-0">
            <div className="left-side">
              {eventDetails && eventDetails?.eventImages !== null && eventDetails?.eventImages[0]?.name !== '' ? (
                <img
                  className="imageBox"
                  src={
                    eventDetails &&
                    eventDetails?.eventImages.length > 0 &&
                    identifiers.imageUrlConstant + '' + eventDetails?.eventImages[0]?.name
                  }
                  alt="EventImage"
                />
              ) : (
                <div className="box" />
              )}
              <div className="text-center mb-5">
                <h1 className="fs-70">{OKTION_VARIABLE?.OKTION_LIVE_RAFFLE}</h1>
                <h3 className="fs-36">{OKTION_VARIABLE?.OKTION_GET_YOUR_TICKET}</h3>
              </div>
              <div className="text-center mb-5">
                <h2 className="mb-2 fs-36">{OKTION_VARIABLE?.OKTION_TIME_REMAINING}</h2>
                <h1 className="fw-normal fs-63">
                  <Countdown date={moment.utc(raffleFeatureDetails?.featureDetail?.startTime)} renderer={renderTime} />
                </h1>
              </div>
              <div className="text-center mb-3">
                <h2 className="mb-2 fs-36">{OKTION_VARIABLE?.OKTION_AMOUNT_RAISED}</h2>
                <h1 className="fw-normal fs-63">
                  <CurrencyFormat
                    value={raffleFeatureDetails?.amountRaised?.raffleAmount || '0.00'}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={
                      raffleFeatureDetails && raffleFeatureDetails.currency !== null
                        ? raffleFeatureDetails?.currency?.abbreviation + ' ' + raffleFeatureDetails?.currency?.symbol
                        : ''
                    }
                    thousandSpacing={raffleFeatureDetails?.currency?.symbol === '₹' ? '2s' : '3'}
                    decimalScale="2"
                    fixedDecimalScale={true}
                    className="fs-50"
                  />
                </h1>
              </div>
              <div className="text-center">
                <img src={images.raffleWallImg} alt="" />
              </div>
            </div>
          </Col>
          <Col sm="8" className="mb-4">
            <RaffleWallCard raffleWallData={raffleFeatureDetails?.item?.rafflePrizeOptions} />
            <TicketSold totalTicket={raffleFeatureDetails?.item?.totalTicket} />
          </Col>
        </Row>
      </Container>
      {flag && <CongratulationOverlay />}
    </div>
  );
};

export default RaffleWall;
