import React, { useContext } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { SocketContext } from '../../../main/context/socket';

const NoPublishOrg = ({ isToggle, toggle, handleClick }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  return (
    <div>
      <Modal size="md" className="modal-dialog add-price-modal logout-modal org-message-modal" isOpen={isToggle}>
        <div className="text-end">
          {/* <img className="cursor-pointer" onClick={toggle} src={images.close} alt="" /> */}
        </div>
        <ModalBody>
          <div>{/* <img src={images.giftImage} alt="" /> */}</div>
          <h3 className="pt-4 pb-4">{OKTION_VARIABLE?.OKTION_DETAILS_INCOMPLETE}</h3>
          <p>{OKTION_VARIABLE?.OKTION_DETAILS_PUBLISH_ORGANISATION}</p>
          <div className=" d-flex  justify-content-center">
            <Button
              style={{ width: 'fitContent', fontWeight: '500', padding: '8px 15px' }}
              className="main-button mt-2 me-2 h-45"
              onClick={handleClick}>
              {OKTION_VARIABLE?.OKTION_GOTO_ORGANISATION_DETAILS}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default NoPublishOrg;
