import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { ReactComponent as Closed } from '../../../../resources/images/close.svg';
import './styles.scss';

const VerificationModal = ({
  isCloseIcon,
  isToggle,
  setToggleModal,
  title,
  subTitle,
  subDesc,
  cancelBtn,
  submitBtn,
  handleSubmit,
  className,
}) => {
  return (
    <div>
      <Modal
        className={`modal-dialog verification-delete-modal ${className}`}
        isOpen={isToggle}
        // toggle={() => setToggleModal(false)}
      >
        <h5 className="model-title">{title}</h5>
        {isCloseIcon && (
          <Closed
            className="model-close"
            onClick={() => {
              setToggleModal(false);
            }}
          />
        )}
        <ModalBody>
          {subTitle && <h2 className="model-title model-sub-title">{subTitle}</h2>}
          <p className="model-sub-text">{subDesc}</p>
          <div className="verification-modal-btn">
            {cancelBtn && (
              <Button
                className="btn-block h-45 main-button border-0 fw-500 cursor-pointer cancel-btn mt-0"
                onClick={() => {
                  setToggleModal(false);
                }}>
                {cancelBtn}
              </Button>
            )}
            {submitBtn && (
              <Button
                className="btn-block h-45 main-button border-0 fw-500 cursor-pointer delete-btn mt-0"
                onClick={() => {
                  handleSubmit();
                }}>
                {submitBtn}
              </Button>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default connect(null, {})(VerificationModal);
