import { storeConstants } from '../constants/StoreConstants';
import axiosInstance from '../../../main/axios';
import { URLS } from '../constants/UrlConstants';

export const addToCart = dataValue => dispatch => {
  return axiosInstance
    .post(URLS.addToCart, dataValue)
    .then(({ status, data }) => {
      if (status === 201) {
        dispatch({
          type: storeConstants.ADD_CART,
          payload: data,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: storeConstants.ADD_CART,
        payload: [],
      });
    });
};
