import React from 'react';
import AuctionWallPlatinumSponser from '../../../Events/MyEvents/frames/Features/frames/Auction/Frame/AuctionWall/AuctionWallPlatinumSponser';
import '../../../Events/MyEvents/frames/Features/frames/Auction/styles.scss';
import _ from 'lodash';

const Sponsors = props => {
  let sponser_sorted = props?.eventDetails && _.sortBy(props?.eventDetails?.sponsors, 'displayOrder');

  return (
    <div className="raffleWall auctionSmallWall">
      {props?.eventDetails?.sponsors &&
        sponser_sorted.map(
          item =>
            item?.sponsors?.length > 0 && <AuctionWallPlatinumSponser images={item?.sponsors} itemValue={item?.name} />,
        )}
      {/* <AuctionWallPlatinumSponser images={[1]} itemValue="Gold Sponsors" />
      <AuctionWallPlatinumSponser images={[1]} itemValue="Silver Sponsors" /> */}
    </div>
  );
};

export default Sponsors;
