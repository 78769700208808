import React from 'react';
import PageLoader from '../../../../library/common/components/PageLoader';
// import axiosInstance from '../../../../main/axios';
// import { URLS } from '../../../../library/common/constants/UrlConstants';
// import { useNavigate } from 'react-router-dom';
import Button from 'reactstrap/lib/Button';
import parse from 'html-react-parser';
import anchorme from 'anchorme';
import { CurrencyFormat } from 'utility';

const TicketsList = props => {
  // const navigate = useNavigate();
  // const [count, setCount] = useState(0);
  // const [loading, setLoading] = useState(false);

  // const handleAdd = () => {
  //   setCount(count + 1);
  // };
  // const handleMinus = () => {
  //   if (count > 0) {
  //     setCount(count - 1);
  //   }
  // };

  // const handleInputChange = event => {
  //   const value = parseInt(event.target.value);
  //   setCount(value);
  // };

  // const handleClick = async () => {
  //   props.handleBtnClick();
  //   setLoading(true);
  //   const senData = [
  //     {
  //       type: 'ticket',
  //       quantity: count,
  //       eventId: props?.item?.eventId,
  //       referenceId: null,
  //       purchaseReferenceId: props?.item?.id,
  //       isRaffleTicket: false,
  //     },
  //   ];
  //   try {
  //     const { status } = await axiosInstance.post(URLS.addToCart, senData);
  //     if (status === 201 || status === 200) {
  //       setLoading(false);
  //       navigate('/checkout');
  //     }
  //   } catch (err) {
  //     setLoading(false);
  //   }
  // };

  var ticketValue = props?.item?.eventFeatureOptions.filter((item, index) => item.key === 'priceperticket');

  const handleInputFocus = event => {
    event.target.select();
  };

  return (
    <>
      <div className="border-bottom">
        <div className="res-ticketlist-display">
          {props.loading && <PageLoader />}
          <div className="res-ticket-data">
            <h5 className="mb-2 rst-name">{props?.item?.featureName}</h5>
            <h5 className="fw-normal rst-price mb-2">
              {/* {props?.currency} {props?.currencySymbol} {props.count[props.index] * ticketValue[0]?.value} */}
              <CurrencyFormat
                value={ticketValue[0]?.value || '0.00'}
                displayType={'text'}
                thousandSeparator={true}
                prefix={`${props?.eventDetails?.currency != null ? props?.currency : ''} ${
                  props?.eventDetails?.currency != null ? props?.currencySymbol : ''
                }`}
                thousandSpacing="2s"
                decimalScale="2"
                fixedDecimalScale={true}
                className="rst-price"
              />
            </h5>
            <p className="fs-16 rst-desc">
              {/* {props?.item?.featureAbout}*/}
              {props?.item?.featureAbout !== null ? (
                <div>
                  {parse(
                    anchorme({
                      input: (props?.item?.featureAbout).toString().replace(/\n/g, '<br/>'),
                      options: {
                        attributes: {
                          target: '_blank',
                          class: 'detected',
                        },
                      },
                    }),
                  )}
                </div>
              ) : (
                ''
              )}
            </p>
          </div>
          <div className="d-flex">
            <Button
              id={props.index}
              className="addButtons"
              onClick={() => props.handleMinus(props.index, props.itemNumber)}
              style={{
                backgroundColor: props?.eventDetails?.primaryColour ? props?.eventDetails?.primaryColour : '',
                borderColor: props?.eventDetails?.primaryColour ? props?.eventDetails?.primaryColour : '',
                color: props?.eventDetails?.primaryTextColour ? props?.eventDetails?.primaryTextColour : '',
                cursor: props?.isVenueEndDateDisable || props?.whitelabel === true ? 'default' : 'pointer',
              }}
              disabled={props?.isVenueEndDateDisable || props?.whitelabel === true ? true : false}>
              -
            </Button>
            {/* <div className="addButtons" onClick={handleMinus}>
              -
            </div> */}
            <input
              value={props.count[props.index]}
              onChange={props.handleInputChange}
              onFocus={handleInputFocus}
              className="addButtonInput h-45"
              type="number"
              placeholder=""
              name={props.index}
              disabled={props?.isVenueEndDateDisable || props?.whitelabel === true}
            />
            <Button
              id={props.index}
              className="addButtons"
              onClick={() => props.handleAdd(props.index, props.itemNumber)}
              style={{
                backgroundColor: props?.eventDetails?.primaryColour ? props?.eventDetails?.primaryColour : '',
                borderColor: props?.eventDetails?.primaryColour ? props?.eventDetails?.primaryColour : '',
                color: props?.eventDetails?.primaryTextColour ? props?.eventDetails?.primaryTextColour : '',
                cursor: props?.isVenueEndDateDisable || props?.whitelabel === true ? 'default' : 'pointer',
              }}
              disabled={props?.isVenueEndDateDisable || props?.whitelabel === true ? true : false}>
              +
            </Button>
            {/* <div className="addButtons" onClick={handleAdd}>
              +
            </div> */}
          </div>
        </div>
        {/* <Button className="" onClick={handleClick}>
          Add To Cart
        </Button> */}
      </div>
    </>
  );
};

export default TicketsList;
