import * as actionTypes from '../constants/StoreConstants';

const initialState = {
  featureList: [],
  dashboardData: [],
  featureRaffleData: {},
  supply_lot_list: [],
};

const FeatureReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.storeConstants.FEATURELIST:
      return {
        ...state,
        featureList: action.payload,
      };
    case actionTypes.storeConstants.DASHBOARD:
      return {
        ...state,
        dashboardData: action.payload,
      };
    case actionTypes.storeConstants.EVENT_STATS:
      return {
        ...state,
        eventStatistics: action.payload,
      };
    case actionTypes.storeConstants.FEATURE_RAFFLE:
      return {
        ...state,
        featureRaffleData: action.payload,
      };
    default:
      return state;
  }
};

export default FeatureReducer;
