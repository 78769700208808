import React, { useEffect, useState } from 'react';
import './toogle.scss';
import { EventEmitter } from '../../../../library/common/constants/event';

const ToggleSwitch = props => {
  useEffect(() => {
    setChecked(props?.defaultValue);
  }, [props.defaultValue]);
  const [isChecked, setChecked] = useState(props?.defaultValue);

  const handleToggle = e => {
    setChecked(isChecked);
    if (props.onChange) {
      props.onChange(!isChecked);
    }
  };
  const resetSwitch = () => {
    setChecked(props?.defaultValue);
  };
  EventEmitter.subscribe('resetSwitchValue', resetSwitch);
  return (
    <div className="toggle_switch d-flex">
      <label className="switch m-0">
        <input checked={isChecked} type="checkbox" onChange={handleToggle} disabled={props?.disabled} />
        <span className={`slider round ${props?.disabled ? 'cursor-default' : ''}`}></span>
      </label>
    </div>
  );
};

export default ToggleSwitch;
