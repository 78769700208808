import React, { useState, useEffect, useContext } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import AvailableTab from './Frame/AvailableTab';
import axiosInstance from '../../main/axios';
import { URLS } from '../../library/common/constants/UrlConstants';
import { fetchFromStorage } from '../../utility';
import { identifiers } from '../../library/common/constants/IdentifierConstants';
import { connect } from 'react-redux';
import {
  getCurrencyList,
  setPublicCategoryList,
  getCurrentCountryDetails,
} from '../../library/common/actions/AuthActions';
import { SocketContext } from '../../main/context/socket';

const TabWrapper = props => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const [activeTab, setActiveTab] = useState('1');
  const [defaultSelectedCurrency, setTempSelectedCurrency] = useState('');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  useEffect(() => {
    (async () => {
      props.getCurrentCountryDetails();
      try {
        const { status, data } = await axiosInstance.get(URLS.getCurrency());
        if (status === 200) {
          props.getCurrencyList(data.data.currencies);
          // setCurrency(data.data.currencies);
        }
      } catch (err) {
        // setLoading(false);
      }
      const eventDetails = fetchFromStorage(identifiers.eventDetails);
      const { status, data } = await axiosInstance.get(URLS.getCategoryPublic(eventDetails?.id));
      if (status === 200) {
        props.setPublicCategoryList(data.data.categories);
        // setCategories(data.data.categories);
        // setCatId()
      }
    })();
  }, []);

  useEffect(() => {
    let allCurrencies = getCurrencyOptions();
    const countryDetails = fetchFromStorage(identifiers.countryDetails);

    let tempSelectedCurrency = allCurrencies?.filter(
      // currency => currency?.country?.countryCode === countryDetails?.countryCode,
      currency => currency?.country?.countryCode === countryDetails?.country_code,
    );
    setTempSelectedCurrency(tempSelectedCurrency);
  }, [props?.currencyList, props.countryDetails]);

  const getCurrencyOptions = () => {
    return props?.currencyList?.length > 0
      ? props?.currencyList?.map(c => {
          return { value: c?.abbreviation, label: c?.abbreviation, flag: c?.country?.flag, ...c };
        })
      : [];
  };
  return (
    <div className="raffleTab">
      <Nav tabs>
        <NavItem className={`${activeTab === '1' && 'active'}`}>
          <NavLink
            onClick={() => {
              toggle('1');
            }}>
            {OKTION_VARIABLE?.OKTION_AVAILABLE}
          </NavLink>
        </NavItem>
        <NavItem className={`${activeTab === '2' && 'active'}`}>
          <NavLink
            onClick={() => {
              toggle('2');
            }}>
            {OKTION_VARIABLE?.OKTION_ACTIVE}
          </NavLink>
        </NavItem>
        <NavItem className={`${activeTab === '3' && 'active'}`}>
          <NavLink
            onClick={() => {
              toggle('3');
            }}>
            {OKTION_VARIABLE?.OKTION_CLOSED}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <AvailableTab tabValue="available" defaultSelectedCurrency={defaultSelectedCurrency} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <AvailableTab isMonitor={true} tabValue="active" defaultSelectedCurrency={defaultSelectedCurrency} />
        </TabPane>
        <TabPane tabId="3">
          <AvailableTab isMonitor={true} tabValue="sold" defaultSelectedCurrency={defaultSelectedCurrency} />
        </TabPane>
      </TabContent>
    </div>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    currencyList: authReducer.currencyList,
    countryDetails: authReducer.countryDetails,
  };
};

export default connect(mapStateToProps, { getCurrencyList, setPublicCategoryList, getCurrentCountryDetails })(
  TabWrapper,
);
