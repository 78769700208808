import React from 'react';

import SignupOrganization from 'modules/Authentication/frames/SignupOrganization';

const Profile = isNewOrg => {
  document.title = 'Organization Details';
  return <SignupOrganization isOrgDetailPage={true} isNewOrganisation={isNewOrg.isNewOrg} />;
};

export default Profile;
