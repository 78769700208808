import React, { useEffect, useContext } from 'react';
import { Button, InputGroup, Modal, ModalBody, Row } from 'reactstrap';
import { images } from '../../../../../../../../../library/common/constants/ImageConstants';
import Col from 'reactstrap/lib/Col';
import { useForm } from 'react-hook-form';
import { SocketContext } from '../../../../../../../../../main/context/socket';

const MarkAsReadModal = ({ isToggle, toggle, handleAmountChange, handleConfirm, currency, markAsPaid, amountPaid }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  // const [amount, setAmount] = useState('');
  // const handleInputChange = (e) => {
  //   const value = e.target.value;
  //   setAmount(value);
  // };
  let defaultValue = {
    amountPaid: parseFloat(amountPaid).toFixed(2),
  };
  const { watch, register, reset } = useForm({ defaultValues: defaultValue });
  useEffect(() => {
    reset(defaultValue);
  }, [amountPaid]);
  return (
    <div>
      <Modal size="lg" className="customModal add-price-modal markAsReadModal maxwidth-900" isOpen={isToggle}>
        <div className="text-end">
          <img className="cursor-pointer" onClick={toggle} src={images.close} alt="" />
        </div>
        <div className="d-flex modalHeader">
          <div>
            <Row className="justify-content-end align-items-baseline">
              <Col sm="8">
                <h2 className="mb-3">{OKTION_VARIABLE?.OKTION_MARK_AS_PAID}</h2>
              </Col>
              <Col sm="4">
                <div className="draft_widget">
                  <div>{OKTION_VARIABLE?.OKTION_SCAN_CODE}</div>
                  <img src={images.scanCode} alt="" />
                </div>
              </Col>
            </Row>
            <p className="bodyText">{OKTION_VARIABLE?.OKTION_WOULD_MARK_AS_PAID}</p>
          </div>
        </div>
        <ModalBody>
          <div className="draw_table">
            <Col sm="12">
              {/* <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>$</InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Amount Paid"
                  onChange={e => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    handleAmountChange(e);
                  }}
                />
              </InputGroup> */}
              <InputGroup
                className={
                  watch('amountPaid') !== '' &&
                  typeof watch('amountPaid') !== 'undefined' &&
                  watch('amountPaid') !== null
                    ? ' mb-3 form-group-style focus_field fixed-fieldset'
                    : ' mb-3 form-group-style focus_field '
                }>
                <input
                  className={`mb-0 form-control input_field-style`}
                  // placeholder="Increment*"
                  {...register('amountPaid', {
                    required: false,
                    onChange: e => {
                      e.target.value.replace(/[^0-9.]/g, '');
                      handleAmountChange(e);
                    },
                  })}
                  name="amountPaid"
                  type="number"
                  // defaultValue={markAsPaid == true ? amountPaid : null}
                  disabled={markAsPaid === true ? true : false}
                  placeholder={OKTION_VARIABLE?.OKTION_AMOUNT_PAID}
                  // onChange={e => {
                  //   e.target.value.replace(/[^0-9.]/g, '');
                  //   handleAmountChange(e);
                  // }}
                />
                <label className="label-helper">{OKTION_VARIABLE?.OKTION_AMOUNT_PAID}</label>
                <span className="span-style">{currency}</span>
                {/* {errors.increment && <span className="text-danger">{Errors.mandatory}</span>} */}
              </InputGroup>
              {markAsPaid === true ? (
                <p className="fs-18 color-blue fw-500 pt-3 pb-3 text-center">
                  {OKTION_VARIABLE?.OKTION_PAYMENT_RECEIVED}
                </p>
              ) : (
                <div className="d-flex mt-4 mb-5">
                  <Button className="app-button black-button mt-2 me-2 w-100" onClick={toggle}>
                    {OKTION_VARIABLE?.OKTION_BUTTON_CANCEL}
                  </Button>
                  <Button className="app-button main-button mt-2 w-100 me-0" onClick={() => handleConfirm(true)}>
                    {OKTION_VARIABLE?.OKTION_BUTTON_CONFIRM}
                  </Button>
                </div>
              )}
              <p className="fw-500 fs-16">{OKTION_VARIABLE?.OKTION_LOT_WINNERS}</p>
              <p className="fs-12">{OKTION_VARIABLE?.OKTION_WINNER_WILL_NOTIFIY}</p>
            </Col>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default MarkAsReadModal;
