import * as actionTypes from '../constants/StoreConstants';

const initialState = {
  headerResponse: [],
};

const CustomHeaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.storeConstants.SET_CUSTOM_HEADERS:
      return {
        ...state,
        headerResponse: [...state.headerResponse, action.payload],
      };
    default:
      return state;
  }
};

export default CustomHeaderReducer;
