import React, { useContext } from 'react';
import { images } from '../../../../library/common/constants/ImageConstants';
import '../../styles.scss';
import EventTimeDetails from '../EventTimeDetails';
import moment from 'moment';
import { SocketContext } from 'main/context/socket';

const TicketPrintComponent = ({ TicketCartlist, eventDetails, venueAddress, timeZone, isEventDetailsShow }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  return (
    <>
      <div className="ticket-print-main p-5" style={{ pageBreakAfter: isEventDetailsShow !== true && 'always' }}>
        <div className="align-item-center justify-content-between align-self-start d-flex pb-5">
          <div className="site-logo">
            <a className="d-block" rel="noopener noreferrer" href="http://www.oktion.com.au/" target="_blank">
              <img src={images.logo} alt={OKTION_VARIABLE?.OKTION_LOGO} />
            </a>
          </div>
          <div className="site-title"></div>
        </div>

        {isEventDetailsShow && (
          <>
            <h3>{OKTION_VARIABLE?.OKTION_EVENT_DETAILS}</h3>
            <div className="event-details">
              <div className="d-flex">
                <p className="w-25">{OKTION_VARIABLE?.OKTION_EVENT_NAME}:</p>
                <p className="w-75">{eventDetails?.eventName}</p>
              </div>
              <div className="d-flex">
                <p className="w-25">{OKTION_VARIABLE?.OKTION_ORGANISER}:</p>
                <p className="w-75">{eventDetails?.organisation?.name}</p>
              </div>
              <div className="d-flex">
                <p className="w-25">{OKTION_VARIABLE?.OKTION_WHEN}:</p>
                <p className="w-75">
                  {timeZone !== undefined && (
                    <EventTimeDetails
                      //   title={eventDetails?.location === 1 ? 'Event Date' : 'Venue Event Date'}
                      img={images.watch}
                      startDate={
                        eventDetails?.location === 1
                          ? moment(eventDetails?.startDate).format('DD/MM/YYYY hh:mm A')
                          : moment(eventDetails?.venueStartDate).format('DD/MM/YYYY hh:mm A')
                      }
                      endDate={
                        eventDetails?.location === 1
                          ? moment(eventDetails?.endDate).format('DD/MM/YYYY hh:mm A')
                          : moment(eventDetails?.venueEndDate).format('DD/MM/YYYY hh:mm A')
                      }
                      isLocation={false}
                    />
                  )}
                </p>
              </div>
              <div className="d-flex">
                <p className="w-25">{OKTION_VARIABLE?.OKTION_WHERE}:</p>
                <p className="w-75">
                  {eventDetails?.venue !== null && (
                    <EventTimeDetails
                      title=""
                      isLocation
                      img={images.location}
                      // endDate="22 August, 5:00 PM"
                      // startDate="22 August, 11:00 AM"
                      // address="80 Pyrmont St,Pyrmont NSW 2009"
                      address={venueAddress}
                    />
                  )}
                </p>
              </div>
            </div>
          </>
        )}
        <div className="ticketlist">
          <h3>{OKTION_VARIABLE?.OKTION_YOUR_TICKETS}</h3>
          <div className="ticket-table">
            <div className="table-headmain">{OKTION_VARIABLE?.OKTION_TICKET_INFO}</div>
            {TicketCartlist.map((item, index) => (
              <>
                <div className="ticket-item">
                  {item?.ticketNumber} {item?.user?.firstName} {item?.user?.lastName} {item?.user?.phoneNumber}{' '}
                  {item?.user?.email}
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="ticket-footer pt-5">
          <h3 className="pb-2">{OKTION_VARIABLE?.OKTION_TITLE}</h3>
          <p>
            {OKTION_VARIABLE?.OKTION_SUITE} 8.02, {OKTION_VARIABLE?.OKTION_LEVEL} 8
          </p>
          <p>227 {OKTION_VARIABLE?.OKTION_ADDRESS}, 2000</p>
          <p>PH: 1300 {OKTION_VARIABLE?.OKTION_TITLE_CAPITAL} (1300 658 466)</p>
          <a className="d-block" rel="noopener noreferrer" href="http://www.oktion.com.au/" target="_blank">
            oktion.com.au
          </a>
        </div>
      </div>
    </>
  );
};

export default TicketPrintComponent;
