export const AdminData = [
  {
    id: 1,
    name: 'Test Test',
    emailId: 'test@gmail.com',
  },
  {
    id: 2,
    name: 'Test Test',
    emailId: 'test@gmail.com',
  },
];

export const AdminRoleData = [
  {
    id: 1,
    role: 'Edit event details',
    owner: true,
    admin: true,
    manager: true,
  },
  {
    id: 2,
    role: 'Add features to events',
    owner: true,
    admin: true,
    manager: false,
  },
  {
    id: 3,
    role: 'Edit feature details',
    owner: true,
    admin: true,
    manager: true,
  },
  {
    id: 3,
    role: 'Delete features from events',
    owner: true,
    admin: true,
    manager: false,
  },
  {
    id: 4,
    role: 'Publish features',
    owner: true,
    admin: true,
    manager: false,
  },

  {
    id: 5,
    role: 'Manually draw raffles',
    owner: true,
    admin: true,
    manager: false,
  },
  {
    id: 6,
    role: 'See event dashboard',
    owner: true,
    admin: true,
    manager: true,
  },
  {
    id: 7,
    role: 'Edit organisation details',
    owner: true,
    admin: true,
    manager: true,
  },
  {
    id: 8,
    role: 'Invite attendees',
    owner: true,
    admin: true,
    manager: true,
  },
  {
    id: 9,
    role: 'Add sponsors',
    owner: true,
    admin: true,
    manager: false,
  },
  {
    id: 10,
    role: 'Add managers',
    owner: true,
    admin: true,
    manager: false,
  },
  {
    id: 11,
    role: 'Add admins',
    owner: true,
    admin: false,
    manager: false,
  },
  {
    id: 12,
    role: 'Transfer ownership',
    owner: true,
    admin: false,
    manager: false,
  },
];
