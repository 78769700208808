import React, { useState, useEffect, useContext } from 'react';
import { Button, Card, CardBody, CardFooter, CardSubtitle, CardTitle } from 'reactstrap';
import '../../../../../library/common/components/EventCard/styles.scss';
import { images } from '../../../../../library/common/constants/ImageConstants';
import { identifiers } from '../../../../../library/common/constants/IdentifierConstants';
import '../../../../Events/MyEvents/frames/Features/frames/Auction/styles.scss';
import EventDetailsAuctionModal from '../../../../EventDetails/Frames/Auction/Frame/EventDetailsAuctionModal/EventDetailsAuctionModal';
// import add from '../../../../Events/MyEvents/frames/Features/frames/Auction/Frame/ItemTab/AddItemModal';
import AddItemModal from '../../../../Events/MyEvents/frames/Features/frames/Auction/Frame/ItemTab/AddItemModal';
import LogoutModal from '../../../../../modules/EventDetails/Frames/Raffle/LogoutModal';
// import CarouselSlider from '../../../../UserProfile/Frame/WinLoss/Frame/WinLossModal/Slider';
import axiosInstance from '../../../../../main/axios';
import { URLS } from '../../../../../library/common/constants/UrlConstants';
import { fetchFromStorage } from '../../../../../utility';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { EventEmitter } from 'library/common/constants/event';
import DeleteFeatureModal from '../../../../Events/MyEvents/frames/Tickets/frame/DeleteFeatureModal';
import { getUserCards } from '../../../../../library/common/actions/UserCardsAction';
import { SocketContext } from '../../../../../main/context/socket';
import BiddingAmountCount from '../../../../EventDetails/Frames/Auction/Frame/EventDetailsAuctionModal/BiddingAmountCount';
import { setApiMessage } from '../../../../../library/common/constants/function';
import PageLoader from '../../../../../library/common/components/PageLoader';
import { saveToStorage } from 'library/utilities/Storage';
import { CurrencyFormat } from 'utility';

const ItemFollowingCards = ({
  data,
  isBid,
  categoryList,
  index,
  ContributionData,
  eventFeatures,
  isLoggedIn,
  eventDetailsData,
  auction_data,
  userCards,
  getUserCards,
  whitelabel,
  location,
  auctionUpdatedData,
  isOrganiser,
  eventOutfit,
  auctionDetails,
  isloading,
  feturedItem,
}) => {
  const params = useParams();
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const [isToggle, setToggle] = useState(false);
  // const [auctionDetails, setAuctionDetails] = useState([]);
  // const [eventOutfit, setEventOutfit] = useState();
  // const [biddingData, setBiddingData] = useState({});
  const navigate = useNavigate();
  const [is_toggle, set_Toggle] = useState(false);
  const [isLotToggle, setisLotToggle] = useState(false);
  const [countToggle, setCountToggle] = useState(false);
  const [count, setCount] = useState(data?.startBid);
  const [loading, setLoading] = useState(false);
  // const [quickBid, setQuickBid] = useState(false);
  const [operationPerformed, setOperationPerformed] = useState(false);
  const [buyitNowObj, setBuyitNowObj] = useState('');
  const [bidButtonStatus, setBidButtonStatus] = useState(false);
  const [isloginToggle, setisloginToggle] = useState(false);
  const [bidType, setBidType] = useState('');
  const [lotId, setlotId] = useState(new URLSearchParams(window.location.search).get('lotid'));

  var auctionFeatureData = eventFeatures && eventFeatures?.filter(item => item.type === 'auction');

  var eventDetails = fetchFromStorage(identifiers.eventDetails);
  const url = window.location.pathname;
  const spliturl = url.split('/');
  const id = spliturl[spliturl.length - 1];
  var eventId = eventDetails !== null ? eventDetails?.id : id;
  var userDetails = fetchFromStorage(identifiers?.userDetail);

  // useEffect(() => {
  //   getUserCards();
  //   // getEventDetailsById();
  //   // setBiddingData(data);
  //   // getAuctionDeatails();
  // }, []);

  useEffect(() => {
    setCount(data?.currentBid === 0 ? data?.startBid : Number((data?.currentBid + data?.bidIncrement).toFixed(2)));
    var category = categoryList?.filter(item => item.id === data?.categoryId);
  }, [data]);

  // useEffect(() => {
  //   // getEventDetailsById();
  //   setBiddingData(data);
  //   // getAuctionDeatails();
  //   // getUserCards();
  // }, [data]);

  // useEffect(() => {
  //   getAuctionDeatails();
  // }, [auctionDetails]);
  const toggle = val => {
    if (isLoggedIn && userCards?.length > 0) {
    } else {
      setToggle(!isToggle);
      // val ? setBidButtonStatus(true) : setBidButtonStatus(false);
    }
  };

  // const Eventtoggle = () => {
  //   setisLotToggle(false);
  // };
  // const getAuctionDeatails = async () => {
  //   console.log('countisss');
  //   const token = fetchFromStorage(identifiers.token);
  //   const previewStatus = location?.state?.previewStatus;
  //   var prevStatus = previewStatus !== undefined ? previewStatus : 0;
  //   var queryString = `?token=${token?.token}&previewStatus=${prevStatus}`;
  //   const auctionDetails = fetchFromStorage(identifiers.eventDetails);
  //   // setloader(true);
  //   try {
  //     const { data } = await axiosInstance.get(URLS.auctionAll(params?.id, queryString));
  //     // const { status, data } = await axiosInstance.get(URLS.auctionAll(auctionDetails?.id, queryString));

  //     setAuctionDetails(data?.data?.eventFeatures[0]?.auctionOptions[index]);
  //   } catch (err) {}
  // };
  // const resetBiddingData = () => {
  //   setBiddingData(auctionDetails);
  //   // setCall(false)
  //   // setCount(data?.currentBid);
  // };
  // EventEmitter.subscribe('updateBiddingData', resetBiddingData);
  const handleModalOpen = () => {
    set_Toggle(!is_toggle);
  };
  const handleBid = index => {
    handleSetBuyItNow();
    if (!isLoggedIn) {
      setToggle(true);
    } else {
      toggle();
      setToggle(true);
      // }
    }
    // if (!isLoggedIn) {
    //   setisloginToggle(!isloginToggle);
    // }
  };
  const paymenttoggle = () => {
    if (!isLoggedIn) {
      handleSetBuyItNow(true);
      setisloginToggle(!isloginToggle);
      setToggle(!isToggle);
    } else if (isLoggedIn && userCards?.length <= 0 && userDetails?.userType !== 'guest') {
      set_Toggle(!is_toggle);
    } else {
      handleAddtoCart();
    }
  };

  const handleAddtoCart = async () => {
    setLoading(true);
    const senData = [
      {
        type: 'item',
        eventId: id,
        referenceId: null,
        quantity: 1,
        purchaseReferenceId: data?.id,
        isRaffleTicket: false,
      },
    ];
    try {
      const { status } = await axiosInstance.post(URLS.addToCart, senData);
      if (status === 201 || status === 200) {
        setLoading(false);
        navigate('/checkout');
      }
    } catch (err) {
      setLoading(false);
      setApiMessage('error', err.response.data.msg);
    }
  };
  const toggleCount = () => {
    setCountToggle(!countToggle);
  };
  const handleQuickBid = () => {
    // toggleCount();
    if (isOrganiser === true) {
      toggle();
    } else {
      toggleCount();
    }
    // userCards.length > 0 ? toggleCount() : handleModalOpen();

    setOperationPerformed(false);
  };
  const handleAdd = () => {
    setCount(Number((count + data?.bidIncrement).toFixed(2)));
  };
  const handleMinus = () => {
    if (data?.currentBid === 0) {
      if (count > data?.startBid) {
        setCount(Number((count - data?.bidIncrement).toFixed(2)));
      }
    } else {
      if (count > data?.currentBid + data?.bidIncrement) {
        setCount(Number((count - data?.bidIncrement).toFixed(2)));
      } else {
        setCount(data?.currentBid + data?.bidIncrement);
      }
    }
  };

  // const getEventDetailsById = async () => {
  //   console.log('hellooo');
  //   const previewStatus = location?.state?.previewStatus;
  //   var prevStatus = previewStatus !== undefined ? previewStatus : 0;
  //   try {
  //     const { status, data } = await axiosInstance.get(URLS.getPublicEventByid(eventId, prevStatus));
  //     if (status === 200 || status === 304) {
  //       setEventOutfit(data?.data?.events);
  //       console.log('iseventt');
  //     }
  //   } catch (err) {}
  // };
  const handleClick = () => {
    navigate('/login', {
      state: {
        redirectTo: 'event-details',
        featureName: 'auction',
        eventId: id,
      },
    });
  };
  const handleBidNow = async () => {
    setBidType('bidNow');
    const token = fetchFromStorage(identifiers.token);
    const Data = data;
    var queryString = `?token=${token?.token}&sort=PO`;
    if (userDetails?.userType === 'guest') {
      handleSetBuyItNow();
      setisloginToggle(!isloginToggle);
      setBidButtonStatus(true);
    } else {
      if (
        (isLoggedIn && (userCards?.length > 0 || auctionFeatureData?.[0]?.isPaymentAutomatic === false)) ||
        userDetails?.userType === 'guest'
      ) {
        setLoading(true);

        const requestData = {
          bidAmount: count,
        };
        try {
          const { status } = await axiosInstance.post(URLS.createBid(Data?.id), requestData);
          if (status === 201) {
            // setBidding(true);
            setApiMessage('success', 'Success');

            const { status, data } = await axiosInstance.get(URLS.auctionAll(id, queryString));
            if (status === 200 || status === 201) {
              // setCount(Number((count - data?.bidIncrement).toFixed(2)));
              var AUCTION_FEATURE = data?.data?.eventFeatures?.find(item => item?.type === 'auction');
              var auction_data = AUCTION_FEATURE?.auctionOptions?.find(item => item.id === Data?.id);
              setOperationPerformed(true);
              auction_data?.currentBid !== undefined || auction_data?.bidIncrement !== undefined
                ? setCount(Number((auction_data?.currentBid + auction_data?.bidIncrement).toFixed(2)))
                : setCount(Number((data?.currentBid + data?.bidIncrement).toFixed(2)));

              EventEmitter.dispatch('updateBiddingData', auction_data);
              // Event_Emitter.dispatch('updateBiddingData', auction_data);
              setLoading(false);
            }
          }
        } catch (err) {
          setLoading(false);
          if (err?.response?.data?.msg === 'ERR_BIDDING_ALREADY_EXISTS') {
            setApiMessage('error', OKTION_VARIABLE?.OKTION_OUTBID);
          } else if (err?.response?.data?.msg === 'ERR_BIDDING_INTERVAL_MISMATCH') {
            setApiMessage('error', OKTION_VARIABLE?.OKTION_INTERVAL_MISMATCH);
          } else if (err?.response?.data?.errCode === 'ERR_AUCTION_CLOSED') {
            setApiMessage('error', err?.response?.data?.msg);
          } else {
            setApiMessage('error', err?.response?.data?.msg);
          }
        }
      } else if (
        isLoggedIn &&
        userCards?.length <= 0 &&
        userDetails?.userType !== 'guest' &&
        auctionFeatureData?.[0]?.isPaymentAutomatic !== false
      ) {
        set_Toggle(!is_toggle);
      } else {
        handleSetBuyItNow();
        setisloginToggle(!isloginToggle);
        setBidButtonStatus(true);
      }
    }
  };

  const handleSetBuyItNow = value => {
    var obj = {
      // id: 3895,
      type: 'item',
      quantity: 1,
      referenceId: null,
      subscriptionName: null,
      subscriptionPrice: null,
      name: data?.itemName,
      amount: value ? data?.currentBid : data?.buyItNow,
      purchaseReferenceId: data?.id,
      isRaffleTicket: false,
      donationAmount: null,
      eventId: eventOutfit?.id,
      currency: eventOutfit?.currency?.abbreviation,
      symbol: eventOutfit?.currency?.symbol,
      event: eventOutfit,
      eventName: eventOutfit?.eventName,
      digiCommission: 0,
      itemDetails: data,
      cardCharedAmount: 0,
      // hasBidFeature: value,
    };
    setBuyitNowObj(obj);
  };

  const handleGuestBuyitNow = () => {
    var obj = {
      // id: 3895,
      type: 'item',
      quantity: 1,
      referenceId: null,
      subscriptionName: null,
      subscriptionPrice: null,
      name: data?.itemName,
      amount: data?.buyItNow,
      purchaseReferenceId: data?.id,
      isRaffleTicket: false,
      donationAmount: null,
      eventId: eventOutfit?.id,
      currency: eventOutfit?.currency?.abbreviation,
      symbol: eventOutfit?.currency?.symbol,
      event: eventOutfit,
      eventName: eventOutfit?.eventName,
      digiCommission: 0,
      itemDetails: data,
      cardCharedAmount: 0,
      // hasBidFeature: value,
    };
    updateGuestCardList(obj);
    navigate('/guest-checkout', {
      state: {
        eventId: params?.id,
      },
    });
  };

  const updateGuestCardList = data => {
    // Fetch the existing guestCheckoutData from storage or initialize it as an empty array
    let guestCheckoutData = fetchFromStorage(identifiers?.guestcheckoutdata) || [];

    // Check if singleItem is an array or object using optional chaining
    if (data?.constructor === Array) {
      // If it's an array, concatenate it with the existing guestCheckoutData
      guestCheckoutData = [...guestCheckoutData, ...data];
    } else if (data) {
      // If it's an object (not null or undefined), push it to the guestCheckoutData array
      guestCheckoutData.push(data);
    }

    // Save the updated guestCheckoutData to storage
    saveToStorage(identifiers?.guestcheckoutdata, guestCheckoutData);

    // Dispatch an event or perform any other necessary actions
    setTimeout(() => {
      EventEmitter.dispatch('updateguestcheckout', guestCheckoutData);
    }, 300);
  };

  const handleBuyItNow = async () => {
    const Data = data;

    const requestData = {
      eventId: id,
    };
    // if ((isLoggedIn && userCards?.length > 0) || userDetails?.userType === 'guest')
    if (isLoggedIn && userDetails?.userType === 'normal') {
      setLoading(true);
      try {
        const { status } = await axiosInstance.put(URLS.buyItNow(Data?.id), requestData);
        if (status === 200) {
          setOperationPerformed(true);
          setApiMessage('success', OKTION_VARIABLE?.OKTION_BID_SUCCESSFULLY);
          navigate('/checkout', {
            state: {
              FROM_WHERE: 'PUBLIC_EVENT_DETAILS',
              eventId: eventId,
            },
          });
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        if (err?.response?.data?.errCode === 'ERR_CART_ITEM_ALREADY_EXISTS') {
          setApiMessage('error', OKTION_VARIABLE?.OKTION_BIDDING_CARTITEM_EXIST);
        } else {
          setApiMessage('error', err?.response?.data?.msg);
        }
      }
    } else if (isLoggedIn && userDetails?.userType === 'guest') {
      handleGuestBuyitNow();
    }
    // else if (isLoggedIn && userCards?.length <= 0 && userDetails?.userType !== 'guest') {
    //   set_Toggle(!is_toggle);
    // }
    else {
      handleSetBuyItNow();
      setisloginToggle(!isloginToggle);
    }
  };
  const handleSetMaxBid = async () => {
    setBidType('bidMax');
    if (userDetails?.userType === 'guest') {
      handleSetBuyItNow();
      setisloginToggle(!isloginToggle);
      setBidButtonStatus(true);
    } else {
      if (
        (isLoggedIn && (userCards?.length > 0 || auctionFeatureData?.[0]?.isPaymentAutomatic === false)) ||
        userDetails?.userType === 'guest'
      ) {
        const Data = data;
        setLoading(true);
        const requestData = {
          myMaxBid: count,
        };
        const token = fetchFromStorage(identifiers.token);
        var queryString = `?token=${token?.token}&sort=PO`;
        try {
          const { status } = await axiosInstance.post(URLS.createBid(Data?.id), requestData);
          if (status === 201) {
            setApiMessage('success', OKTION_VARIABLE?.OKTION_SET_MAX_BID);
            const { status, data } = await axiosInstance.get(URLS.auctionAll(eventId, queryString));
            if (status === 200) {
              var auction_data = data?.data?.eventFeatures[0]?.auctionOptions?.find(item => item.id === Data?.id);
              // setAuctionData(auction_data);
              setOperationPerformed(true);
              EventEmitter.dispatch('updateBiddingData', auction_data);
              // Event_Emitter.dispatch('updateBiddingData', auction_data);
            }
            setLoading(false);
          }
        } catch (err) {
          setLoading(false);
          if (
            err?.response?.data?.errCode === 'ERR_BIDDING_ALREADY_EXISTS' ||
            err?.response?.data?.errCode === 'ERR_AUCTION_CLOSED'
          ) {
            setApiMessage('error', err?.response?.data?.msg);
          } else {
            setApiMessage('error', err?.response?.data?.msg);
          }
        }
      } else if (
        isLoggedIn &&
        userCards?.length <= 0 &&
        userDetails?.userType !== 'guest' &&
        auctionFeatureData?.[0]?.isPaymentAutomatic !== false
      ) {
        set_Toggle(!is_toggle);
      } else {
        handleSetBuyItNow();
        setisloginToggle(!isloginToggle);
        setBidButtonStatus(true);
      }
    }
  };
  const currency = eventDetailsData?.currency != null ? eventDetailsData?.currency?.abbreviation : '';
  const currencySymbol = eventDetailsData?.currency != null ? eventDetailsData?.currency?.symbol : '';

  // var buttonStatus = auctionFeatureData?.[0]?.isActive ? false : true;
  return (
    <div
      className={
        auctionFeatureData && (auctionFeatureData?.[0]?.isActive === false || data?.isSold === true)
          ? 'wonItemPrizeCard itemFollowingCardsModal h-100'
          : 'wonItemPrizeCard itemFollowingCardsModal h-100'
      }>
      <Card
        className={
          auctionFeatureData &&
            (auctionFeatureData?.[0]?.isActive === false || data?.isSold === true || data?.markAsPaid)
            ? 'h-100'
            : 'no-blur h-100'
        }>
        <div
          className={
            data?.status === 'saved' || data?.isSold || data?.markAsPaid
              ? 'imgWrapper imageOverlay d-block'
              : 'imgWrapper d-block'
          }
          onClick={() => handleBid(index)}
          style={{
            background: `url(${identifiers.imageUrlConstant + '' + data?.images[0]?.name})`,
            backgroundPosition: 'center',
          }}>
          {data?.status === 'saved' || data?.isSold || data?.markAsPaid ? (
            <div className="image-overlay-wrap">
              <img
                alt=""
                src={data?.isSold || data?.markAsPaid ? images.prizeIcon : images.itemcardOverlayImage}
                className="h-auto"
              />
            </div>
          ) : (
            <img alt="" src={identifiers.imageUrlConstant + '' + data?.images[0]?.name} width="100%" />
          )}
          {/* {data?.images?.length > 0 ? (
            <CardImg
              top
              width="100%"
              src={identifiers.imageUrlConstant + '' + data?.images[0]?.name}
              alt="Card image cap"
            />
          ) : (
            ''
            // <div className="noImage"></div>
          )} */}
        </div>
        <CardBody>
          {(isloading || loading) && <PageLoader />}
          {countToggle === false ? (
            <>
              <div onClick={() => handleBid(index)}>
                <CardTitle className="mb-2 mt-2 fw-500">{data?.itemName}</CardTitle>
                <CardTitle className="mb-2 mt-2 fw-500">
                  {data?.providedBy && data?.providedBy == 'sponsoredBy'
                    ? OKTION_VARIABLE?.OKTION_SPONSOR_BY
                    : data?.providedBy == 'donatedBy'
                      ? OKTION_VARIABLE?.OKTION_DONATION_BY
                      : OKTION_VARIABLE?.OKTION_PROVIDED_BY}{' '}
                  : {data?.itemProvidedByName}
                </CardTitle>
                <CardSubtitle className="mb-2">
                  {OKTION_VARIABLE?.OKTION_CURRENT_BID} :
                  <CurrencyFormat
                    value={data?.currentBid !== null ? Number(data?.currentBid).toFixed(2) : '0.00'}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={`${currency} ${currencySymbol}`}
                    thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                    decimalScale="2"
                    fixedDecimalScale={true}
                  />
                </CardSubtitle>
                <CardSubtitle className="mb-2">
                  {OKTION_VARIABLE?.OKTION_STARTING_BID} :
                  <CurrencyFormat
                    value={data?.startBid !== null ? data?.startBid : '0.00'}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={`${currency} ${currencySymbol}`}
                    thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                    decimalScale="2"
                    fixedDecimalScale={true}
                  />
                </CardSubtitle>
                {data?.buyItNow !== 0 && data?.buyItNow !== null && (
                  <CardSubtitle className="mb-2">
                    {OKTION_VARIABLE?.OKTION_BUYITNOW} :
                    <CurrencyFormat
                      value={data?.buyItNow !== null ? data?.buyItNow : '0.00'}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={`${currency} ${currencySymbol}`}
                      // thousandSpacing="2s"
                      thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                      decimalScale="2"
                      fixedDecimalScale={true}
                    />
                  </CardSubtitle>
                )}
                {data?.winner !== null ?
                  <CardSubtitle className="subTitleText mb-2">
                    {OKTION_VARIABLE?.OKTION_CURRENT_WINNER} :
                    {data?.reserveMet
                      ? `${data?.winner?.firstName && data?.winner?.firstName} ${data?.winner?.lastName ? data?.winner?.lastName : ''
                      }`
                      : OKTION_VARIABLE?.OKTION_RESERVE_NOT_MET}
                  </CardSubtitle>

                  : (
                    <CardSubtitle className="subTitleText mb-2">
                      {OKTION_VARIABLE?.OKTION_CURRENT_WINNER} : {OKTION_VARIABLE?.OKTION_NO_CURRENT_WINNER}
                    </CardSubtitle>
                  )}
              </div>
            </>
          ) : (
            <BiddingAmountCount
              data={auctionDetails}
              handleSetMaxBid={handleSetMaxBid}
              handleBidNow={handleBidNow}
              handleBuyItNow={handleBuyItNow}
              handleAdd={handleAdd}
              handleMinus={handleMinus}
              count={count}
              currency={currency}
              currencySymbol={currencySymbol}
              singleItem={data}
              setQuickBid={true}
              auctionFeatureData={auctionFeatureData}
            />
          )}
        </CardBody>
        <CardFooter className="p-0">
          {auctionFeatureData?.[0]?.isActive && isBid && (
            <Button
              onClick={() => handleQuickBid()}
              className={
                eventDetailsData?.primaryColour
                  ? 'w-100 btn-block fs-20 fw-500 h-60 d-flex justify-content-center align-items-center'
                  : 'w-100 main-button btn-block fs-20 fw-500 h-60 d-flex justify-content-center align-items-center'
              }
              style={{
                backgroundColor:
                  countToggle === true
                    ? '#393939'
                    : operationPerformed === true
                      ? '#0080FF'
                      : eventDetailsData?.primaryColour
                        ? eventDetailsData?.primaryColour
                        : '',
                borderColor:
                  countToggle === true
                    ? '#393939'
                    : operationPerformed === true
                      ? '#0080FF'
                      : eventDetailsData?.primaryColour
                        ? eventDetailsData?.primaryColour
                        : '',
                color:
                  countToggle === true
                    ? '#FFFFFF'
                    : eventDetailsData?.primaryTextColour
                      ? eventDetailsData?.primaryTextColour
                      : '',
                marginTop: data?.buyItNow === 0 || data?.buyItNow === null ? '0px' : '',
                marginBottom: data?.buyItNow === 0 || data?.buyItNow === null ? '0px' : '',
              }}
              disabled={!auctionFeatureData?.[0]?.isActive || whitelabel === true ? true : false}>
              {countToggle === false
                ? OKTION_VARIABLE?.OKTION_BID
                : operationPerformed === true
                  ? OKTION_VARIABLE?.OKTION_BUTTON_FINISH
                  : OKTION_VARIABLE?.OKTION_BUTTON_CANCEL}{' '}
              <img
                className="ms-2"
                src={countToggle === false ? images.uppArraow : images.downArrow}
                alt=""
                style={{
                  pointerEvents: auctionFeatureData?.[0]?.isActive === false ? 'none' : '',
                }}
              />{' '}
            </Button>
          )}
          {!auctionFeatureData?.[0]?.isActive && (
            <Button
              className="main-button btn-block fs-20 fw-500 h-60 d-flex justify-content-center align-items-center btn btn-secondary
              "
              onClick={() => {
                setToggle(!isToggle);
                handleSetBuyItNow();
              }}>
              {OKTION_VARIABLE?.OKTION_DASHBOARD_MORE_DETAILS}
            </Button>
          )}
        </CardFooter>
      </Card>
      {isBid === true ? (
        <EventDetailsAuctionModal
          auctionDetails={auctionDetails}
          itemFollowing_isToggle={isToggle}
          itemFollowing_toggle={() => {
            setToggle(false);
          }}
          isfromWinningLot={!auctionFeatureData?.[0]?.isActive ? true : false}
          isGuestCheckout={!auctionFeatureData?.[0]?.isActive ? false : true}
          makePayment_toggle={() => paymenttoggle()}
          isUpdate={true}
          data={data}
          singleItem={data}
          categoryList={categoryList}
          index={index}
          currency={currency}
          currencySymbol={currencySymbol}
          eventDetailsData={eventDetailsData}
          auctionUpdatedData={auctionUpdatedData}
          auctionFeatureData={auctionFeatureData}
          setQuickBid={false}
          buyitNowObj={buyitNowObj}
          userCards={userCards}
          handleSetBuyItNow={handleSetBuyItNow}

        // closeModal={() => setToggle(false)}
        />
      ) : (
        isToggle && (
          <AddItemModal
            auctionDetails={auctionDetails}
            isToggle={isToggle}
            toggle={() => {
              setToggle(!isToggle);
            }}
            closeModal={() => setToggle(false)}
            isUpdate={true}
            data={data}
            categoryList={categoryList}
            index={index}
            getDonadationData={() => EventEmitter.dispatch('updateAuctionData')}
            currency={currency}
            isModal
            feturedData={feturedItem}
          />
        )
      )}
      <LogoutModal
        isToggle={isloginToggle}
        toggle={() => setisloginToggle(!isloginToggle)}
        data={data}
        handleClick={handleClick}
        isGuestCheckout={!auctionFeatureData?.[0]?.isActive ? false : true}
        isfromWinningLot={!auctionFeatureData?.[0]?.isActive ? true : false}
        singleItem={buyitNowObj}
        lotId={lotId}
        guestUserStatus={bidButtonStatus}
        bidType={bidType}
        bidCount={count}
      />

      <DeleteFeatureModal
        isToggle={is_toggle}
        toggle={handleModalOpen}
        handleDeleteFeature={() => navigate('/user-profile?user-profile-card-details')}
        FROM_WHERE="contribution"
      />
      {/* <EventDetailsAuctionModal
        auctionDetails={auctionDetails}
        itemFollowing_isToggle={isLotToggle}
        isfromWinningLot={!auctionFeatureData?.[0]?.isActive ? true : false}
        itemFollowing_toggle={() => Eventtoggle()}
        makePayment_toggle={() => paymenttoggle()}
        isUpdate={true}
        data={data}
        singleItem={data}
        categoryList={categoryList}
        index={index}
        currency={currency}
        currencySymbol={currencySymbol}
        eventDetailsData={eventDetailsData}
        auctionUpdatedData={auctionUpdatedData}
        auctionFeatureData={auctionFeatureData}
        setQuickBid={false}
        // closeModal={() => setToggle(false)}
      /> */}
    </div>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};

export default connect(mapStateToProps, {})(ItemFollowingCards);
