import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import {
  Button,
  ButtonDropdown,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  Row,
  Table,
} from 'reactstrap';
import { AdminData } from '../AdminData';
import { images } from '../../../../../library/common/constants/ImageConstants';
import axiosInstance from '../../../../../main/axios';
import { URLS } from '../../../../../library/common/constants/UrlConstants';
import { useParams, useNavigate } from 'react-router-dom';
import PageLoader from '../../../../../library/common/components/PageLoader';
// import ActionMessage from '../../../../../library/common/components/ActionMessage';
import { setApiMessage } from '../../../../../library/common/constants/function';
import { handleByRole } from '../../../../../library/common/actions/AdminActions';
import { connect } from 'react-redux';
import { identifiers } from '../../../../../library/common/constants/IdentifierConstants';
import { fetchFromStorage } from '../../../../../utility';
import { EventEmitter } from 'library/common/constants/event';
import { SocketContext } from '../../../../../main/context/socket';
import { createDraftOrg } from 'modules/Organization/menu-items';

const AdminTable = props => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const navigate = useNavigate();
  const orgId = useParams();
  const [adminList, setAdminList] = useState('');
  const [loading, setLoading] = useState(false);
  const [email, isEmail] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [isFilter, setFilter] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [is_view, setIsView] = useState('');
  const [errors, setErrors] = useState(false);
  const [tabvalue, setTabValue] = useState('1');
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const isNewOrg = window.location.pathname === '/organization/organiser-details';

  // const [message, setMessage] = useState({
  //   display: false,
  //   type: '',
  //   message: '',
  // });

  useEffect(() => {
    const is_view = fetchFromStorage(identifiers.is_view);
    setIsView(is_view);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    handleResize();
  }, [width]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    // this.setState({ width: window.innerWidth });
    handleResize();
  };

  const handleResize = () => {
    if (window.innerWidth <= 639) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const handleChange = e => {
    const value = e.target.value.trim();
    setFilterValue(value);
    setErrors(false);
    if (filterValue === '') {
      setAdminList('');
    }
    // getUserFilterData();
  };
  const handleSearch = async e => {
    const value = e.target.value.trim();
    setFilterValue(value);
    setErrors(false);
    if (filterValue === '') {
      setAdminList('');
    } else {
      setLoading(true);
      await getUserFilterData();
    }
  };
  const handleForm = async e => {
    e.preventDefault();

    setErrors(false);
    if (filterValue === '' || filterValue === false) {
      isEmail(true);
    } else {
      setLoading(true);
      await getUserFilterData();
    }
  };

  const getUserFilterData = async () => {
    // setActionMessage(false);
    isEmail(false);
    try {
      const { status, data } = await axiosInstance.get(URLS.getUserData(filterValue));
      if (status === 201) {
        setAdminList(data.data.users);
        if (data.data.users.length > 0 && adminList) {
          setShowMessage(true);
          adminList.push({ isCollapse: false });
        }
        setLoading(false);
      }
    } catch (err) {
      setShowMessage(true);
      setLoading(false);
    }
  };

  const [data, setData] = useState(AdminData);

  const toggleBundle = idx => {
    let auctionListTableData = [...adminList];
    for (let i = 0; i < auctionListTableData.length; i++) {
      if (idx === auctionListTableData[i].id) {
        auctionListTableData[i].isCollapse = !auctionListTableData[i].isCollapse;
      }
    }
    setData(auctionListTableData);
  };

  EventEmitter.subscribe('handle_role_update_list', tab => setTabValue(tab));

  // MANGE ROLES AND ORG.
  const handleRolesAndOrg = async (id, role, email) => {
    if (isNewOrg) {
      let { status } = await createDraftOrg();
      if (status) {
        setTimeout(() => {
          handleRole(id, role, email);
        }, 1000);
      }
    } else {
      handleRole(id, role, email);
    }
  };

  const handleRole = async (id, role, email) => {
    // setActionMessage();
    const currentOrgId = fetchFromStorage(identifiers.currentOrganization);
    setApiMessage();
    const sendDataValue = {
      organisationId: currentOrgId,
      // userId: id,
      role: role,
      emails: [email],
      isNewUser: false,
    };
    setLoading(true);
    try {
      const { status } = await axiosInstance.post(URLS.organizationChangeRole, sendDataValue);
      if (status === 201) {
        setApiMessage('success', OKTION_VARIABLE?.OKTION_ROLECHANGE_SUCCESS);
        // setActionMessage(true, 'Success', OKTION_VARIABLE?.OKTION_ROLECHANGE_SUCCESS);
        // setTimeout(() => {
        //   setActionMessage();
        // }, 4000);
        if (tabvalue === '1') {
          props.handleByRole(orgId.id, 'admin');
        } else {
          props.handleByRole(orgId.id, 'manager');
        }
        if (isNewOrg) {
          setTimeout(() => {
            navigate(`/organization/${currentOrgId}?admins`);
          }, 1000);
        }
        // props.handleByRole(orgId.id, role);
        setLoading(false);
        setFilterValue('');
      }
    } catch (err) {
      setLoading(false);
      if (err.response) {
        setApiMessage('error', err.response.data.msg);
        // setActionMessage(true, 'Error', err.response.data.msg);
        // setTimeout(() => {
        //   setActionMessage();
        // }, 4000);
      }
    }
  };

  // const setActionMessage = (display = false, type = '', message = '') => {
  //   setMessage({
  //     display,
  //     type,
  //     message,
  //   });
  // };

  const handleSort = () => {
    setFilter(!isFilter);
    let lowerCaseUsers = _.clone(adminList);
    lowerCaseUsers = lowerCaseUsers.map(user => {
      user.firstName = user.firstName.toLowerCase();
      return user;
    });
    const sortedUsers = _.orderBy(lowerCaseUsers, ['firstName'], [isFilter ? 'desc' : 'asc']);
    setAdminList(sortedUsers);
  };
  // console.log('render', adminList);
  return (
    <div className="invitedTable mb-4">
      {/* {message.display && (
        <div className="btn-block">
          <ActionMessage message={message.message} type={message.type} styleClass={message.styleClass} />
        </div>
      )} */}
      <Row>
        <Col sm="12">
          {' '}
          <h4 className="mb-3 fw-normal">{OKTION_VARIABLE?.OKTION_SEARCH_USER}</h4>
          <form onSubmit={handleForm}>
            {!isMobile ? (
              <InputGroup className="mt-2 mb-3 search-filter">
                <Input
                  onChange={handleChange}
                  type="text"
                  placeholder={OKTION_VARIABLE?.OKTION_SEARCH_USERBY_NAME_EMAIL}
                  value={filterValue}
                />
                <Button
                  // color="primary"
                  className="search-button search invite ms-2"
                  disabled={is_view === true ? true : false}>
                  {OKTION_VARIABLE?.OKTION_SEARCH}
                </Button>
              </InputGroup>
            ) : (
              <InputGroup className="mt-2 mb-0 search-filter">
                <Input
                  onChange={handleSearch}
                  type="text"
                  placeholder={OKTION_VARIABLE?.OKTION_SEARCH_USERBY_NAME_EMAIL}
                  value={filterValue}
                />
              </InputGroup>
            )}
            {filterValue === '' && email === true && (
              <span className="text-danger">{OKTION_VARIABLE?.OKTION_ENTER_NAME}</span>
            )}
          </form>
          {filterValue !== '' && adminList && adminList.length > 0 && (
            <Table className="existing-user-table">
              <thead>
                <tr>
                  <th className="field-width">
                    {OKTION_VARIABLE?.OKTION_FULL_NAME}
                    <img onClick={handleSort} className={`${isFilter && 'down'}`} src={images.DropdownDown} alt="" />
                  </th>
                  <th>{OKTION_VARIABLE?.OKTION_EMAIL_ADDRESS}</th>
                  <th></th>
                </tr>
              </thead>
              {/* {console.log('adminList', adminList)} */}
              <tbody>
                {adminList.map((item, i) => (
                  <tr key={i}>
                    <td>
                      {item.firstName === null ? ' ' : item.firstName + ' '}{' '}
                      {item.lastName === null ? ' ' : item.lastName}
                    </td>
                    <td>{item.email}</td>
                    <td className="text-end">
                      <ButtonDropdown className="ms-2" isOpen={item.isCollapse} toggle={() => toggleBundle(item.id)}>
                        <DropdownToggle>
                          <img src={images.Menu} alt="" />
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              handleRolesAndOrg(item.id, 'admin', item.email);
                            }}>
                            {OKTION_VARIABLE?.OKTION_MAKE_ADMIN}
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              handleRolesAndOrg(item.id, 'manager', item.email);
                            }}>
                            {OKTION_VARIABLE?.OKTION_MAKE_MANAGER}
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {adminList && adminList?.length === 0 && filterValue?.length !== 0 && (
            <span className="text-danger"> {OKTION_VARIABLE?.OKTION_FINDUSER_ERRORMSG}</span>
          )}
        </Col>
      </Row>
      {loading && <PageLoader />}
    </div>
  );
};

export default connect(null, { handleByRole })(AdminTable);
