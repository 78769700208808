import React, { Suspense, useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import AllRoutes from 'main/routes';
import { SocketContext, socket } from 'main/context/socket';
import {
  initOneSignalNotification,
  addListenerForNotificationOpened,
  isPushNotificationsEnabled,
  setDefaultNotificationUrl,
} from 'library/common/actions/AuthActions';
import { connect } from 'react-redux';
import { logout } from 'library/common/actions/AuthActions';
import { EventEmitter } from 'library/common/constants/event';
import { useNavigate } from 'react-router-dom';
import messagesEn from 'main/locales/en.json';
import messagesDe from 'main/locales/de.json';
import messagesFr from 'main/locales/fr.json';
import { NotificationContainer } from 'react-notifications';
import { initSocketEvents } from 'main/socket/socket.services';
import { fetchFromStorage, saveToStorage, removeFromStorage } from 'utility';
import { identifiers } from 'library/common/constants/IdentifierConstants';

initOneSignalNotification();
addListenerForNotificationOpened();
isPushNotificationsEnabled();
// setDefaultNotificationUrl();

function App({ logout }) {
  const navigate = useNavigate();

  const menuMessages = {
    en: { ...messagesEn },
    de: { ...messagesDe },
    fr: { ...messagesFr },
    // pt: { ...messagesPt },
  };

  const [locale, setLocale] = useState('en');
  const [messages, setMessages] = useState(menuMessages['en']);

  useEffect(() => {
    initSocketEvents(socket);
    // initOneSignalNotification();
    // addListenerForNotificationOpened();
    // isPushNotificationsEnabled();
    // setDefaultNotificationUrl();
    EventEmitter.subscribe('handlenotificationredirect', event => {
      initOneSignalNotification();
      handleNotificationRedirect(event);
    });
  }, []);
  const switchLanguage = lang => {
    setLocale(lang);
    setMessages(menuMessages[lang]);
    saveToStorage(identifiers?.language, lang);
  };

  const handleNotificationRedirect = item => {
    var notificationObj = item?.data?.tag;
    const ORG_DETAILS = 'org-details';
    const EVENT_DETAILS = 'event-details';
    const PUBLIC_EVENT_DETAILS = 'public-event-detail';
    const SUPPLYLOT_ADDTOEVENT = 'supplylot-addto-event';
    const LOT_SOLD = 'lot-sold';
    const AUCTION_WINNER = 'auction-winner';
    const AUCTION_BUY_IT_NOW = 'auction-buy-it-now';
    const EVENT_ATTENDEE = 'event-attendee';
    const RAFFLE_WINNER = 'raffle-winner';
    const RAFFLE_MANUAL_DRAW = 'raffle-manual-draw';
    const DECLINED_OWNERSHIP = 'decline-ownership';
    const MAIL_MESSAGE = 'mail-message';

    var featureName =
      notificationObj === 'lot-sold' || notificationObj === 'auction-winner' || notificationObj === 'auction-buy-it-now'
        ? 'auction'
        : notificationObj === 'raffle-winner'
          ? 'raffle'
          : '';
    switch (notificationObj) {
      case ORG_DETAILS:
      case DECLINED_OWNERSHIP:
        navigate('/all-organizations');
        setDefaultNotificationUrl('all-organizations');
        return true;
      case RAFFLE_MANUAL_DRAW:
        navigate(`/my-event/${item?.data?.data?.eventId}?event=Raffle`);
        return true;
      case RAFFLE_WINNER:
      case EVENT_ATTENDEE:
      case AUCTION_WINNER:
      case AUCTION_BUY_IT_NOW:
      case LOT_SOLD:
      case EVENT_DETAILS:
        // navigate(`/event-details/${item?.data?.data?.eventId}`);
        // setDefaultNotificationUrl(`event-details/${item?.data?.data?.eventId}`);
        navigate(`/my-event/${item?.data?.data?.eventId}?event=details`, {
          state: {
            featureName: featureName,
          },
        });
        return true;
      case PUBLIC_EVENT_DETAILS:
        // navigate(`/event-details/${item?.data?.data?.eventId}`);
        setDefaultNotificationUrl(`event-details/${item?.data?.data?.eventId}`);
        return true;
      // case LOT_SOLD:
      //   navigate(`/event-details/${item?.data?.data?.eventId}`);
      //   setDefaultNotificationUrl(`/event-details/${item?.data?.data?.eventId}`);
      //   return true;
      case SUPPLYLOT_ADDTOEVENT:
        // const { status, data } = axiosInstance.get(URLS.getByEventIdData(item?.data?.data?.eventId));
        // saveToStorage(identifiers.eventDetails, data?.data?.events);
        navigate(`/my-event/${item?.data?.data?.eventId}?event=details`, {
          state: {
            featureName: featureName,
          },
        });
        return true;
      case MAIL_MESSAGE:
        navigate('/mail', item?.extraData);
        return true;
      default:
        // navigate('/');
        return true;
    }
  };
  var objectToPass = {
    socket: socket,
    locale: locale,
    messages: messages,
    switchLanguage: switchLanguage,
  };
  useEffect(() => {
    var lanVal = fetchFromStorage(identifiers?.language) === null ? 'en' : fetchFromStorage(identifiers?.language);
    setLocale(lanVal);
    setMessages(menuMessages[lanVal]);
  }, []);

  // useEffect(() => {
  //   window.addEventListener('beforeunload', handleWindowClose);
  //   return () => {
  //     window.removeEventListener('beforeunload', handleWindowClose);
  //   };
  // }, []);

  // const handleWindowClose = event => {
  //   const userDetail = fetchFromStorage(identifiers.userDetail);
  //   if (event.type === 'beforeunload' && performance.getEntriesByType('navigation')[0].type === 'navigate') {
  //     if (userDetail?.userType == 'guest') {
  //       logout();
  //     }
  //   }
  // };

  return (
    <SocketContext.Provider value={objectToPass}>
      <Suspense
        fallback={
          <div className="loader_wrapper">
            <Spinner type="grow" color="warning" />
          </div>
        }>
        <NotificationContainer />
        <AllRoutes />
      </Suspense>
    </SocketContext.Provider>
  );
}

export default connect(null, {
  logout,
})(App);
